import { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import closeLogo from "../../assets/close-circle.png";
import DeleteIcon from "../../assets/close-circle.png";
import Modal from "@material-ui/core/Modal";
import { ApplyButton } from "../applyButton";
import { ChangeDataSourcePriorityModal } from "./ChangeDataSourcePriorityModal";
import { IDataSource, IEditFieldModalProp, ISingleData } from "./SingleFieldEditModal";
import { MultiLevelFilter } from "../multiLevelFilter.tsx/filter";
import { CheckboxSelect } from "../checkbox";
import { CircularProgress, MenuItem, TextField } from "@material-ui/core";
import AddRowIcon from "../../assets/add-circle.png";
import { InputTypeEnums } from "../../pages/companyView/types";
import { ICountry, IMaterialUISelectItem, ISelectItem, Level } from "../../interfaces";
import { apiConfig } from "../../services/api/apiConfig";
import { Service } from "../../services/api/service";
import { useAuth } from "../../shared/hooks/auth-hook";
import PersonEdit from "./PersonEdit";
import { AxiosResponse } from "axios";
import { MultiSelect } from "../multiselect";
import { validationRules } from "./edit-modal-validation-rules";
import { convertTaxonomySegmentToLevel } from "../../utils/industries";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    padding: theme.spacing(1.5, 3, 1.5),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    minWidth: "400px",
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  closeButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 24,
    width: 24,
    backgroundImage: `url(${closeLogo})`,
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  title: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "28px",
    letterSpacing: "0",
    lineHeight: "34px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 20px",
  },
  body: {
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
  },
  cancelButton: {
    fontFamily: "Montserrat",
    fontSize: 12,
    borderRadius: 3,
    height: 38,
    textTransform: "none",
    border: "1px solid",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D748D",
    width: "74px",
  },
  actions: {
    display: "flex",
    paddingRight: "30px",
    justifyContent: "flex-end",
    marginTop: "10px",
  },
  dataSourceTitle: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    marginTop: "20px",
    marginBottom: "20px",
  },
  dataSourceRows: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "20vh",
    overflowY: "auto",
    overflowX: "hidden",
  },
  dataSourceRow: {
    // TODO: Input have to align start, not a center
    // TODO: Include and Board Members columns should take space
    // TODO: All text inputs have to except Description are should be single line and have the same height
    // TODO: Description should be multiline
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10px",
  },
  dataSourceRowTitle: {
    color: "#4D748D",
    fontFamily: "Montserrat",
  },
  dataSourceRowIndex: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontWeight: 500,
    minWidth: "25px",
  },
  dataSourceRowData: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    flexDirection: "row",
    display: "flex",
    "& div": {
      width: "150px",
    },
  },
  dataSourceSelectData: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    flexDirection: "row",
    display: "flex",
    width: "250px",
    "& div": {
      width: "250px",
    },
  },
  dataSourceRowAlternativeNames: {
    "& div": {
      width: "300px !important",
    },
  },
  dataSourceRowSubsidiaries: {
    marginLeft: "15px",
    "& div": {
      width: "350px !important",
    },
  },
  dataExecutiveOtherRows: {
    marginLeft: "15px",
    "& div": {
      width: "350px",
    },
  },
  dataExecutiveSummary: {
    marginLeft: "15px",
    "& div": {
      width: "350px",
    },
  },
  dataBoardMemberTitleRow: {
    marginLeft: "15px",
    "& div": {
      width: "150px",
    },
  },
  dataSourceRowAction: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    paddingLeft: "20px",
    width: "50px",
    display: "grid",
    marginRight: "10px",
    placeItems: "center",
    "& label": {
      margin: "0px !important",
    },
  },
  dataSourceRowActionDelete: {
    height: "20px",
    weight: "20px",
    cursor: "pointer",
  },
  dataManualText: {
    marginBlock: "20px",
    fontFamily: "Oswald",
    fontSize: "15px",
    lineHeight: "20px",
    cursor: "pointer",
  },
  addRowContainer: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  addRowText: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: "14px",
  },
  addColumnIcon: {
    height: "20px",
    width: "20px",
    marginRight: "10px",
  },
  noData: {
    fontStyle: "italic",
    color: "grey",
    fontFamily: "Montserrat",
    fontSize: 12,
    height: 35,
    fontWeight: 500,
    paddingLeft: "5px",
  },
  errorDiv: {
    color: "#c40014",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "10px",
    whiteSpace: "pre-line",
  },
}));

interface Props {
  name: string;
  property_name: string;
  title?: string;
  input_type: InputTypeEnums;
  isVisible: boolean;
  property_edit_data_for_fields: IEditFieldModalProp<IListData> | null;
  handleSaveEdit: (property_name: string, data: IEditFieldModalProp<ISingleData | IListData>) => void;
  cancel: () => void;
  companyId: number;
  value_text?: string;
  summary_text?: string;
  noShowPersonTitle?: boolean;
  noShowBoardMember?: boolean;
  noShowInclude?: boolean;
  get_select_data_function?: (token: string) => Promise<AxiosResponse>;
}

export const ListFieldEditModal: FC<Props> = ({
  name,
  property_edit_data_for_fields,
  handleSaveEdit,
  property_name,
  title,
  input_type,
  isVisible,
  cancel,
  companyId,
  value_text,
  summary_text,
  noShowPersonTitle,
  noShowBoardMember,
  noShowInclude,
  get_select_data_function,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [editDataForField, setEditDataForField] = useState<IEditFieldModalProp<IListData> | null>(null);
  const [copyEditData, setCopyEditData] = useState<IEditFieldModalProp<IListData> | null>(null);
  const [selectData, setSelectData] = useState<IMaterialUISelectItem[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectDataFlat, setSelectDataFlat] = useState<any[]>([]);
  const [selectDataLevel, setSelectDataLevel] = useState<Level>({ selected_id: null, items: {} });
  const boardMembersTitles = ["CEO", "Founder", "Chairman", "Board", "Owner"];
  const { token } = useAuth();

  const manual_entry_data_source_id = editDataForField?.dataSources.find(
    (ds: IDataSource) => ds.name === "Manual Entry"
  )?.id;

  const manual_data = editDataForField?.data?.filter(
    (data: IListData) =>
      data.data_source_id === manual_entry_data_source_id ||
      ((data as any).sourceId && (data as any).sourceId === manual_entry_data_source_id)
  );

  useEffect(() => {
    if (property_edit_data_for_fields === null) fetchData();
    else {
      setEditDataForField(property_edit_data_for_fields);
      setCopyEditData(property_edit_data_for_fields);
    }
    if (get_select_data_function) {
      get_select_data_function(token as string)
        .then((response) => {
          if (property_name === "operation_areas") return response.data;
          if (property_name === "industry" || property_name === "secondary_industries") return response.data;
          return response?.data?.data;
        })
        .then((data) => {
          if (input_type === InputTypeEnums.multi_select_select_input) {
            setSelectData(data?.map((item: any) => ({ value: item.id, label: item.name })));
          } else if (input_type === InputTypeEnums.select_input) {
            setSelectData(data?.map((item: ISelectItem) => ({ value: item.id, label: item.name })));
          } else if (input_type === InputTypeEnums.multi_level_select_input) {
            setSelectDataFlat(data);
            setSelectDataLevel(convertTaxonomySegmentToLevel(data));
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property_name]);

  // ACTIONS
  const fetchData = async () => {
    setLoading(true);
    let path = `${apiConfig.companiesDataManager}/${companyId}`;
    if (property_name === "chairman" || property_name === "board") {
      path += "/executives";
    } else {
      path += `/${property_name}`;
    }
    await Service.get(path, token as string)
      .then((response: any) => {
        setEditDataForField(response.data);
        setCopyEditData(response.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const validateData = () => {
    let validation = true;

    const validationRule =
      editDataForField && editDataForField.field_name ? validationRules[editDataForField.field_name] : undefined;

    if (validationRule) {
      const res = validationRule(editDataForField?.data);
      if (res.status === "INVALID") {
        setErrorMessage(res.reason);
        validation = false;
      }
    } else {
      const personPropertyNames: string[] = ["executives", "board_members", "founders"];

      if (input_type === InputTypeEnums.multi_level_select_input) {
        editDataForField?.data?.forEach((item: any) => {
          if (!item || !item.industryId) {
            setErrorMessage("There cannot be empty values. Please enter.");
            validation = false;
          }
        });
      } else {
        editDataForField?.data?.forEach((item: IListData) => {
          if (
            (item?.value === "" && !personPropertyNames.includes(property_name)) ||
            item?.value === 0 ||
            (item?.title && item?.title === "")
          ) {
            setErrorMessage("There cannot be empty values. Please enter.");
            validation = false;
          }
        });
      }
    }

    return validation;
  };

  const handleSave = () => {
    if (validateData() && editDataForField) {
      handleSaveEdit(property_name, editDataForField);
      close();
    }
  };

  const handleClose = () => {
    if (copyEditData) handleSaveEdit(property_name, copyEditData);
    close();
  };

  const close = () => {
    setEditDataForField(null);
    setCopyEditData(null);
    cancel();
  };

  const handlePriorityChange = (newDataSource: IDataSource[]) => {
    if (editDataForField)
      setEditDataForField({
        ...editDataForField,
        dataSources: newDataSource,
      });
  };

  const sortList = (a: IListData, b: IListData) => {
    const ds_a = editDataForField?.dataSources.find((ds: IDataSource) => ds.id === a.data_source_id);
    const ds_b = editDataForField?.dataSources.find((ds: IDataSource) => ds.id === b.data_source_id);
    if (ds_a && ds_b) {
      if (ds_a.priority > ds_b.priority) return -1;
      if (ds_a.priority < ds_b.priority) return 1;
      return 0;
    } else return 0;
  };

  const handleDelete = (item_to_be_deleted: IListData) => {
    if (editDataForField)
      setEditDataForField({
        ...editDataForField,
        data: editDataForField?.data.filter((data: IListData) => data !== item_to_be_deleted),
      });
  };

  const addRow = () => {
    if (editDataForField) {
      const newRow =
        input_type !== InputTypeEnums.multi_level_select_input
          ? {
              id: null,
              value: "",
              data_source_id: Number(manual_entry_data_source_id),
              include: true,
              title: title as any,
            }
          : {
              id: null,
              sourceId: 2,
              include: true,
            };

      setEditDataForField({
        ...editDataForField,
        data: [...editDataForField.data, newRow as any],
      });
    }
  };

  const handlePrioritizeManualInputCheckboxChange = (checked: boolean) => {
    const newEditDataForField = editDataForField as IEditFieldModalProp<IListData>;
    if (checked) {
      setEditDataForField({
        ...newEditDataForField,
        data: newEditDataForField.data.map((data: IListData) =>
          data.data_source_id === manual_entry_data_source_id ? { ...data, include: true } : { ...data, include: false }
        ),
        overrideWithManual: checked,
      });
    } else {
      setEditDataForField({
        ...newEditDataForField,
        data: newEditDataForField.data.map((data: IListData) =>
          data.data_source_id === manual_entry_data_source_id
            ? data
            : {
                ...data,
                include:
                  copyEditData?.data.find((copy_data: IListData) => {
                    const { include: copy_include, ...copy_data_rest } = copy_data;
                    const { include, ...data_rest } = data;
                    return JSON.stringify(copy_data_rest) === JSON.stringify(data_rest);
                  })?.include || false,
              }
        ),
        overrideWithManual: checked,
      });
    }
  };

  // ROWS
  const renderRows = () => {
    if (editDataForField?.data.length === 0) return <div className={classes.noData}>No Data Found</div>;
    if (input_type === InputTypeEnums.multi_select_select_input) {
      return renderMultiSelectInputs(editDataForField?.data as IListData[]);
    }
    return editDataForField?.data
      ?.sort(sortList)
      ?.filter((data: IListData) => (title ? data.title === title : data))
      .map((data: IListData, index: number) => {
        if (input_type === InputTypeEnums.executive_input) {
          return renderExecutives(data, index);
        } else if (input_type === InputTypeEnums.boards_input) {
          return renderBoardMembers(data, index);
        } else if (input_type === InputTypeEnums.subsidiaries_input) {
          return renderSubsidiaries(data, index);
        } else if (input_type === InputTypeEnums.key_persons_input) {
          return renderKeyPersons(data, index);
        } else if (input_type === InputTypeEnums.select_input) {
          return renderSelectInputs(data, index, data.value);
        } else if (input_type === InputTypeEnums.string_input) {
          return renderStringInputs(data, index);
        } else if (input_type === InputTypeEnums.multi_level_select_input) {
          return renderStringInputs(data, index);
        } else {
          return renderAlternativeNames(data, index);
        }
      });
  };
  const renderMultiSelectInputs = (data: IListData[], is_manual_entry?: boolean) => {
    const arrayForMultiSelect = selectData.map((item) => ({
      id: parseInt(item.value + ""),
      name: item.label,
    })) as ICountry[];
    return (
      <div>
        <MultiSelect
          title="Countries"
          items={arrayForMultiSelect}
          selected={data ? (Array.from(new Set(data.map((item) => item.value))) as number[]) : []}
          setSelected={(values) => {
            if (is_manual_entry) {
              const valuesNumberArray: number[] = Array.from(
                new Set(values.map((item) => parseInt(item + "")))
              ) as number[];
              const newEditDataForField = { ...editDataForField } as IEditFieldModalProp<IListData>;
              const not_manual_entry_data =
                newEditDataForField.data?.length > 0
                  ? newEditDataForField.data.filter(
                      (item: IListData) => item.data_source_id !== manual_entry_data_source_id
                    )
                  : [];
              const manual_entry_selected_values = arrayForMultiSelect
                .map((item) => ({ value: item.id, title: item.name, data_source_id: manual_entry_data_source_id }))
                .filter((item) => valuesNumberArray.includes(item.value));
              newEditDataForField.data = [...not_manual_entry_data, ...manual_entry_selected_values] as IListData[];
              setEditDataForField(newEditDataForField);
            }
          }}
          disabled={!is_manual_entry}
          stringify={(item: ICountry) => item.name}
          value={(item: ICountry) => item.id}
        />
      </div>
    );
  };
  const renderStringInputs = (data: IListData, index: number) => {
    return (
      <div className={classes.dataSourceRow} key={index}>
        <div className={classes.dataSourceRowIndex}>{index + 1}</div>
        <div className={`${classes.dataSourceRowData} ${classes.dataSourceRowAlternativeNames}`}>
          <TextField
            size="small"
            variant="outlined"
            value={(data as any)?.name || data?.title || data?.value}
            disabled
          />
        </div>
        {!noShowInclude && (
          <div className={classes.dataSourceRowAction}>
            <CheckboxSelect
              checkboxLabel=""
              checked={data.include}
              setChecked={(checked: boolean) => {
                const newEditDataForField = editDataForField as IEditFieldModalProp<IListData>;
                setEditDataForField({
                  ...newEditDataForField,
                  data: newEditDataForField?.data.map((d) => (d === data ? { ...d, include: checked } : d)),
                });
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const renderSelectInputs = (data: IListData, index: number, valueForSelect: string | number | undefined) => {
    const is_this_row_manual_entry = data.data_source_id === manual_entry_data_source_id;
    if (selectData.length) {
      if (data?.value !== "" || is_this_row_manual_entry) {
        return (
          <div className={classes.dataSourceRow} key={index}>
            <div className={classes.dataSourceRowIndex}>{index + 1}</div>
            <div className={classes.dataSourceSelectData}>
              <TextField
                autoFocus
                select={true}
                size="small"
                variant="outlined"
                disabled
                fullWidth
                value={valueForSelect}
                SelectProps={{
                  MenuProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                <MenuItem value="" style={{ height: 30 }}></MenuItem>
                {selectData?.map((data: IMaterialUISelectItem) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className={classes.dataSourceRowAction}>
              {/* {is_this_row_manual_entry && (
                <img
                  src={DeleteIcon}
                  className={classes.dataSourceRowActionDelete}
                  alt="delete icon"
                  onClick={() => handleDelete(data)}
                />
              )} */}
            </div>
          </div>
        );
      } else {
        return (
          <div className={classes.noData} key={index}>
            No Data Found
          </div>
        );
      }
    } else {
      return null;
    }
  };

  const renderSelectInputsManualInput = (
    data: IListData,
    index: number,
    valueForSelect: string | number | undefined
  ) => {
    const is_this_row_manual_entry = data.data_source_id === manual_entry_data_source_id;
    if (selectData.length) {
      if (data?.value !== "" || is_this_row_manual_entry) {
        return (
          <div className={classes.dataSourceRow} key={index}>
            <div className={classes.dataSourceRowIndex}>{index + 1}</div>
            <div className={classes.dataSourceSelectData}>
              <TextField
                autoFocus
                select={true}
                size="small"
                variant="outlined"
                disabled={!is_this_row_manual_entry}
                fullWidth
                value={valueForSelect}
                SelectProps={{
                  MenuProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
                onChange={(e) => {
                  const newEditDataForField = editDataForField as IEditFieldModalProp<IListData>;
                  setEditDataForField({
                    ...newEditDataForField,
                    data: newEditDataForField?.data.map((r: IListData) =>
                      r === data
                        ? {
                            ...r,
                            value: e.target.value,
                            title: e.target.value
                              ? String(selectData.find((item) => item.value === e.target.value)?.label)
                              : "",
                          }
                        : r
                    ),
                  });
                }}
              >
                <MenuItem value="" style={{ height: 30 }}></MenuItem>
                {selectData?.map((data: IMaterialUISelectItem) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className={classes.dataSourceRowAction}>
              {is_this_row_manual_entry && (
                <img
                  src={DeleteIcon}
                  className={classes.dataSourceRowActionDelete}
                  alt="delete icon"
                  onClick={() => handleDelete(data)}
                />
              )}
            </div>
          </div>
        );
      } else {
        return (
          <div className={classes.noData} key={index}>
            No Data Found
          </div>
        );
      }
    } else {
      return null;
    }
  };

  const renderExecutives = (data: IListData, index: number) => {
    return (
      <div className={classes.dataSourceRow} key={index}>
        <div className={classes.dataSourceRowIndex}>{index + 1}</div>
        <div className={classes.dataSourceRowData}>
          <TextField size="small" variant="outlined" value={data.value} disabled />
        </div>
        <div className={`${classes.dataSourceRowData} ${classes.dataExecutiveOtherRows}`}>
          <TextField size="small" variant="outlined" value={data.summary} disabled multiline minRows={2} maxRows={2} />
        </div>
        <div className={`${classes.dataSourceRowData} ${classes.dataExecutiveOtherRows}`}>
          <TextField size="small" variant="outlined" value={data.image} disabled multiline minRows={2} maxRows={2} />
        </div>
        <div className={classes.dataSourceRowAction}>
          <CheckboxSelect
            checkboxLabel=""
            checked={!!data.board_member}
            setChecked={(checked: boolean) => {
              const newEditDataForField = editDataForField as IEditFieldModalProp<IListData>;
              setEditDataForField({
                ...newEditDataForField,
                data: newEditDataForField?.data.map((d) => (d === data ? { ...d, board_member: checked } : d)),
              });
            }}
          />
        </div>
        <div className={classes.dataSourceRowAction}>
          <CheckboxSelect
            checkboxLabel=""
            checked={data.include}
            setChecked={(checked: boolean) => {
              const newEditDataForField = editDataForField as IEditFieldModalProp<IListData>;
              setEditDataForField({
                ...newEditDataForField,
                data: newEditDataForField?.data.map((d) => (d === data ? { ...d, include: checked } : d)),
              });
            }}
          />
        </div>
      </div>
    );
  };

  const renderBoardMembers = (data: IListData, index: number) => {
    return (
      <div className={classes.dataSourceRow} key={index}>
        <div className={classes.dataSourceRowIndex}>{index + 1}</div>
        <div className={classes.dataSourceRowData}>
          <TextField size="small" variant="outlined" value={data.value} disabled />
        </div>
        <div className={`${classes.dataSourceRowData} ${classes.dataExecutiveOtherRows}`}>
          <TextField size="small" variant="outlined" value={data.summary} disabled multiline minRows={2} maxRows={2} />
        </div>
        <div className={`${classes.dataSourceRowData} ${classes.dataExecutiveOtherRows}`}>
          <TextField size="small" variant="outlined" value={data.image} disabled multiline minRows={2} maxRows={2} />
        </div>
        <div className={`${classes.dataSourceRowData} ${classes.dataBoardMemberTitleRow}`}>
          <TextField size="small" variant="outlined" value={data.title} disabled />
        </div>
        <div className={classes.dataSourceRowAction}>
          <CheckboxSelect
            checkboxLabel=""
            checked={!!data.board_member}
            setChecked={(checked: boolean) => {
              const newEditDataForField = editDataForField as IEditFieldModalProp<IListData>;
              setEditDataForField({
                ...newEditDataForField,
                data: newEditDataForField?.data.map((d) => (d === data ? { ...d, board_member: checked } : d)),
              });
            }}
          />
        </div>
        <div className={classes.dataSourceRowAction}>
          <CheckboxSelect
            checkboxLabel=""
            checked={data.include}
            setChecked={(checked: boolean) => {
              const newEditDataForField = editDataForField as IEditFieldModalProp<IListData>;
              setEditDataForField({
                ...newEditDataForField,
                data: newEditDataForField?.data.map((d) => (d === data ? { ...d, include: checked } : d)),
              });
            }}
          />
        </div>
      </div>
    );
  };

  const renderAlternativeNames = (data: IListData, index: number) => {
    return (
      <div className={classes.dataSourceRow} key={index}>
        <div className={classes.dataSourceRowIndex}>{index + 1}</div>
        <div className={`${classes.dataSourceRowData} ${classes.dataSourceRowAlternativeNames}`}>
          <TextField size="small" variant="outlined" value={data.value} disabled />
        </div>
        <div className={classes.dataSourceRowAction}>
          <CheckboxSelect
            checkboxLabel=""
            checked={data.include}
            setChecked={(checked: boolean) => {
              const newEditDataForField = editDataForField as IEditFieldModalProp<IListData>;
              setEditDataForField({
                ...newEditDataForField,
                data: newEditDataForField?.data.map((d) => (d === data ? { ...d, include: checked } : d)),
              });
            }}
          />
        </div>
      </div>
    );
  };

  const renderSubsidiaries = (data: IListData, index: number) => {
    return (
      <div className={classes.dataSourceRow} key={index}>
        <div className={classes.dataSourceRowIndex}>{index + 1}</div>
        <div className={`${classes.dataSourceRowData} ${classes.dataSourceRowAlternativeNames}`}>
          <TextField size="small" variant="outlined" value={data.value} disabled />
        </div>
        <div className={classes.dataSourceRowAction}>
          <CheckboxSelect
            checkboxLabel=""
            checked={data.include}
            setChecked={(checked: boolean) => {
              const newEditDataForField = editDataForField as IEditFieldModalProp<IListData>;
              setEditDataForField({
                ...newEditDataForField,
                data: newEditDataForField?.data.map((d) => (d === data ? { ...d, include: checked } : d)),
              });
            }}
          />
        </div>
      </div>
    );
  };

  const renderKeyPersons = (data: IListData, index: number) => {
    return (
      <PersonEdit
        data={data}
        index={index}
        key={index}
        is_manual_entry={false}
        editDataForField={editDataForField}
        setEditDataForField={(newEditDataForField: IEditFieldModalProp<IListData> | null) => {
          newEditDataForField = {
            ...newEditDataForField,
            data: newEditDataForField?.data.map((item) => ({
              ...item,
              full_name: item.first_name + " " + item.last_name,
            })),
          } as IEditFieldModalProp<IListData> | null;
          setEditDataForField(newEditDataForField);
        }}
        handleDelete={handleDelete}
        noShowTitle={noShowPersonTitle}
        noShowBoardMember={noShowBoardMember}
        noShowInclude={noShowInclude}
      />
    );
  };

  type CompanyTaxonomySegment = {
    id: number | null;
    industryId: string;
    nodeId: string;
    name: string;
    search: string;
    path: string;
    description: string;
    sourceId: number;
    include: boolean;
  };
  const handleLevelSelect = (data: CompanyTaxonomySegment, selectedId: string | number | null, level: Level) => {
    const newEditDataForField = editDataForField as IEditFieldModalProp<IListData>;

    const marketSegment = selectedId ? selectDataFlat.find((element) => element.id === selectedId) : null;
    const convertIdToIndustryId = (m: any) => {
      const { id: industryId, ...rest } = m;
      return { industryId, ...rest };
    };
    const companyMarketSegment = marketSegment ? convertIdToIndustryId(marketSegment) : null;

    const { id, include, sourceId } = data;
    const updatedElement = { id, include, sourceId, ...companyMarketSegment };

    setEditDataForField({
      ...newEditDataForField,
      data: newEditDataForField?.data.map((r: any) => (r === data ? updatedElement : r)),
    });
    setSelectDataLevel(level);
  };

  // MANUAL INPUT ROWS
  const renderManualInputRows = () => {
    if (input_type === InputTypeEnums.multi_select_select_input) {
      return renderMultiSelectInputs(manual_data !== undefined ? manual_data : [], true);
    }
    if (manual_data?.length === 0) {
      return <div className={classes.noData}>No Data Found</div>;
    }

    if (input_type === InputTypeEnums.executive_input) {
      return renderExecutivesManualInputRows();
    } else if (input_type === InputTypeEnums.boards_input) {
      return renderBoardMembersManualInputRows();
    } else if (input_type === InputTypeEnums.subsidiaries_input) {
      return renderSubsidiariesManualInputRows();
    } else if (input_type === InputTypeEnums.key_persons_input) {
      return renderKeyPersonsManualInputRows();
    } else if (input_type === InputTypeEnums.select_input) {
      return renderSelectManualInputRows();
    } else if (input_type === InputTypeEnums.multi_level_select_input) {
      return renderMultiLevelManualInput();
    } else {
      return renderAlternativeNamesManualInputRows();
    }
  };

  const renderMultiLevelManualInput = () => {
    const manualEntryRows = (editDataForField?.data ?? [])
      .sort(sortList)
      .filter((data: any) => data.sourceId === manual_entry_data_source_id) as unknown as CompanyTaxonomySegment[];

    return manualEntryRows.map((row: any, index: number) => {
      if (selectDataLevel?.items) {
        return (
          <div className={classes.dataSourceRow} key={index}>
            <div className={classes.dataSourceRowIndex}>{index + 1}</div>
            <div>
              <MultiLevelFilter
                key={index}
                title={name}
                data={selectDataLevel}
                selected={row?.industryId as string}
                selectedName={row?.name}
                setSelected={(selected, level) => handleLevelSelect(row, selected as string, level)}
                isSingle
                isDisabled={false}
                width="300px"
                right="-50%"
                bottom="0"
              />
            </div>
            <div style={{ paddingLeft: 0, width: "40px", marginRight: 0 }} className={classes.dataSourceRowAction}>
              <img
                src={DeleteIcon}
                className={classes.dataSourceRowActionDelete}
                alt="delete icon"
                onClick={() => handleDelete(row)}
              />
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const renderSelectManualInputRows = () => {
    return editDataForField?.data
      ?.filter((data: IListData) => data.data_source_id === manual_entry_data_source_id)
      ?.filter((data: IListData) => (title ? data.title === title : data))
      .map((item, key) => renderSelectInputsManualInput(item, key, item.value));
  };

  const renderExecutivesManualInputRows = () => {
    return editDataForField?.data
      ?.filter((data: IListData) => data.data_source_id === manual_entry_data_source_id)
      ?.filter((data: IListData) => (title ? data.title === title : data))
      .map((data: IListData, index: number) => {
        return (
          <div className={classes.dataSourceRow} key={index}>
            <div className={classes.dataSourceRowIndex}>{index + 1}</div>
            <div className={classes.dataSourceRowData}>
              <TextField
                autoFocus
                size="small"
                variant="outlined"
                value={data.value}
                onChange={(e) =>
                  setEditDataForField({
                    ...editDataForField,
                    data: editDataForField?.data.map((d) => (d === data ? { ...d, value: e.target.value } : d)),
                  })
                }
              />
            </div>
            <div className={`${classes.dataSourceRowData} ${classes.dataExecutiveOtherRows}`}>
              <TextField
                autoFocus
                size="small"
                variant="outlined"
                value={data.summary}
                multiline
                minRows={2}
                maxRows={2}
                onChange={(e) =>
                  setEditDataForField({
                    ...editDataForField,
                    data: editDataForField?.data.map((d) => (d === data ? { ...d, summary: e.target.value } : d)),
                  })
                }
              />
            </div>
            <div className={`${classes.dataSourceRowData} ${classes.dataExecutiveOtherRows}`}>
              <TextField
                autoFocus
                size="small"
                variant="outlined"
                value={data.image}
                multiline
                minRows={2}
                maxRows={2}
                onChange={(e) =>
                  setEditDataForField({
                    ...editDataForField,
                    data: editDataForField?.data.map((d) => (d === data ? { ...d, image: e.target.value } : d)),
                  })
                }
              />
            </div>
            <div className={classes.dataSourceRowAction}>
              <img
                src={DeleteIcon}
                className={classes.dataSourceRowActionDelete}
                alt="delete icon"
                onClick={() => handleDelete(data)}
              />
            </div>
          </div>
        );
      });
  };

  const renderBoardMembersManualInputRows = () => {
    const selected_titles = editDataForField?.data
      ?.filter((data: IListData) => data.data_source_id === manual_entry_data_source_id)
      .filter((item: IListData) => item.title === "CEO" || item.title === "Chairman" || item.title === "Owner")
      .map((item: IListData) => item.title);

    return editDataForField?.data
      ?.filter((data: IListData) => data.data_source_id === manual_entry_data_source_id)
      .map((data: IListData, index: number) => {
        return (
          <div className={classes.dataSourceRow} key={index}>
            <div className={classes.dataSourceRowIndex}>{index + 1}</div>
            <div className={classes.dataSourceRowData}>
              <TextField
                autoFocus
                size="small"
                variant="outlined"
                value={data.value}
                onChange={(e) =>
                  setEditDataForField({
                    ...editDataForField,
                    data: editDataForField?.data.map((d) => (d === data ? { ...d, value: e.target.value } : d)),
                  })
                }
              />
            </div>
            <div className={`${classes.dataSourceRowData} ${classes.dataExecutiveOtherRows}`}>
              <TextField
                autoFocus
                size="small"
                variant="outlined"
                value={data.summary}
                multiline
                minRows={2}
                maxRows={2}
                onChange={(e) =>
                  setEditDataForField({
                    ...editDataForField,
                    data: editDataForField?.data.map((d) => (d === data ? { ...d, summary: e.target.value } : d)),
                  })
                }
              />
            </div>
            <div className={`${classes.dataSourceRowData} ${classes.dataExecutiveOtherRows}`}>
              <TextField
                autoFocus
                size="small"
                variant="outlined"
                value={data.image}
                multiline
                minRows={2}
                maxRows={2}
                onChange={(e) =>
                  setEditDataForField({
                    ...editDataForField,
                    data: editDataForField?.data.map((d) => (d === data ? { ...d, image: e.target.value } : d)),
                  })
                }
              />
            </div>
            <div className={`${classes.dataSourceRowData} ${classes.dataBoardMemberTitleRow}`}>
              <TextField
                autoFocus
                select
                size="small"
                variant="outlined"
                fullWidth
                value={data.title}
                onChange={(e) => {
                  setEditDataForField({
                    ...editDataForField,
                    data: editDataForField?.data.map((d) => (d === data ? { ...d, title: e.target.value as any } : d)),
                  });
                }}
              >
                {boardMembersTitles?.map((title: string) => (
                  <MenuItem key={title} value={title} disabled={selected_titles?.includes(title as any)}>
                    {title}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className={classes.dataSourceRowAction}>
              <img
                src={DeleteIcon}
                className={classes.dataSourceRowActionDelete}
                alt="delete icon"
                onClick={() => handleDelete(data)}
              />
            </div>
          </div>
        );
      });
  };

  const renderKeyPersonsManualInputRows = () => {
    return editDataForField?.data
      ?.filter((data: IListData) => data.data_source_id === manual_entry_data_source_id)
      .map((data: IListData, index: number) => {
        return (
          <PersonEdit
            data={data}
            index={index}
            key={`manual-${index}`}
            is_manual_entry={true}
            editDataForField={editDataForField}
            setEditDataForField={(newEditDataForField: IEditFieldModalProp<IListData> | null) => {
              newEditDataForField = {
                ...newEditDataForField,
                data: newEditDataForField?.data.map((item) => ({
                  ...item,
                  full_name: item.first_name + " " + item.last_name,
                })),
              } as IEditFieldModalProp<IListData> | null;
              setEditDataForField(newEditDataForField);
            }}
            handleDelete={handleDelete}
            noShowTitle={noShowPersonTitle}
            noShowBoardMember={noShowBoardMember}
            noShowInclude={noShowInclude}
          />
        );
      });
  };

  const renderAlternativeNamesManualInputRows = () => {
    return editDataForField?.data
      ?.filter((data: IListData) => data.data_source_id === manual_entry_data_source_id)
      .map((data: IListData, index: number) => {
        return (
          <div className={classes.dataSourceRow} key={index}>
            <div className={classes.dataSourceRowIndex}>{index + 1}</div>
            <div className={`${classes.dataSourceRowData} ${classes.dataSourceRowAlternativeNames}`}>
              <TextField
                size="small"
                variant="outlined"
                value={data.value}
                onChange={(e) =>
                  setEditDataForField({
                    ...editDataForField,
                    data: editDataForField?.data.map((d) => (d === data ? { ...d, value: e.target.value } : d)),
                  })
                }
              />
            </div>
            <div className={classes.dataSourceRowAction}>
              <img
                src={DeleteIcon}
                className={classes.dataSourceRowActionDelete}
                alt="delete icon"
                onClick={() => handleDelete(data)}
              />
            </div>
          </div>
        );
      });
  };

  const renderSubsidiariesManualInputRows = () => {
    return editDataForField?.data
      ?.filter((data: IListData) => data.data_source_id === manual_entry_data_source_id)
      .map((data: IListData, index: number) => {
        return (
          <div className={classes.dataSourceRow} key={index}>
            <div className={classes.dataSourceRowIndex}>{index + 1}</div>
            <div className={`${classes.dataSourceRowData} ${classes.dataSourceRowAlternativeNames}`}>
              <TextField size="small" variant="outlined" value={data.value} disabled />
            </div>
            <div className={classes.dataSourceRowAction}>
              <img
                src={DeleteIcon}
                className={classes.dataSourceRowActionDelete}
                alt="delete icon"
                onClick={() => handleDelete(data)}
              />
            </div>
          </div>
        );
      });
  };

  // HEADERS
  const renderHeaders = (action_name: string, is_manual_entry?: boolean) => {
    if (editDataForField?.data.length === 0) return null;
    if (manual_data?.length === 0 && action_name === "Delete") return null;
    if (input_type === InputTypeEnums.executive_input) {
      return renderExecutivesHeaders(action_name);
    } else if (input_type === InputTypeEnums.boards_input) {
      return renderBoardMembersHeaders(action_name);
    } else if (input_type === InputTypeEnums.key_persons_input) {
      return renderKeyPersonsHeaders(action_name, is_manual_entry);
    } else if (input_type === InputTypeEnums.select_input) {
      return renderSelectInputHeaders(action_name);
    } else if (input_type === InputTypeEnums.string_input) {
      return renderStringInputHeaders();
    } else if (input_type === InputTypeEnums.multi_select_select_input) {
      return renderMultiSelectCountryIputHeaders();
    } else {
      return renderAlternativeNamesHeaders(action_name);
    }
  };
  const renderMultiSelectCountryIputHeaders = () => {
    return (
      <div className={classes.dataSourceRow}>
        <div className={classes.dataSourceRowIndex}></div>
        <div className={classes.dataSourceSelectData}>Countries</div>
      </div>
    );
  };
  const renderStringInputHeaders = () => {
    return (
      <div className={classes.dataSourceRow}>
        <div className={classes.dataSourceRowIndex}></div>
        <div className={classes.dataSourceSelectData}>Value</div>
      </div>
    );
  };
  const renderSelectInputHeaders = (action_name: string) => {
    return (
      <div className={classes.dataSourceRow}>
        <div className={classes.dataSourceRowIndex}></div>
        <div className={classes.dataSourceSelectData}>Value</div>
        <div className={classes.dataSourceRowAction}>{action_name}</div>
      </div>
    );
  };
  const renderExecutivesHeaders = (action_name: string) => {
    return (
      <div className={classes.dataSourceRow}>
        <div className={classes.dataSourceRowIndex}>{}</div>
        <div className={classes.dataSourceRowData}>
          <div>{value_text ? value_text : "Value"}</div>
        </div>
        <div className={`${classes.dataSourceRowData} ${classes.dataExecutiveOtherRows}`}>
          <div>{summary_text ? summary_text : "Summary"}</div>
        </div>
        <div className={`${classes.dataSourceRowData} ${classes.dataExecutiveOtherRows}`}>
          <div>Image</div>
        </div>
        <div className={`${classes.dataSourceRowData} ${classes.dataBoardMemberTitleRow}`}>
          <div>Board Member</div>
        </div>
        <div className={classes.dataSourceRowAction}>{action_name}</div>
      </div>
    );
  };

  const renderBoardMembersHeaders = (action_name: string) => {
    return (
      <div className={classes.dataSourceRow}>
        <div className={classes.dataSourceRowIndex}>{}</div>
        <div className={classes.dataSourceRowData}>
          <div>{value_text ? value_text : "Value"}</div>
        </div>
        <div className={`${classes.dataSourceRowData} ${classes.dataExecutiveOtherRows}`}>
          <div>{summary_text ? summary_text : "Summary"}</div>
        </div>
        <div className={`${classes.dataSourceRowData} ${classes.dataExecutiveOtherRows}`}>
          <div>Image</div>
        </div>
        <div className={`${classes.dataSourceRowData} ${classes.dataBoardMemberTitleRow}`}>
          <div>Title</div>
        </div>
        <div className={`${classes.dataSourceRowData} ${classes.dataBoardMemberTitleRow}`}>
          <div>Board Member</div>
        </div>
        {action_name === "Delete" && <div className={classes.dataSourceRowAction}>{action_name}</div>}
      </div>
    );
  };
  const renderKeyPersonsHeaders = (action_name: string, is_manual_entry?: boolean) => {
    return (
      <div className={classes.dataSourceRow}>
        <div className={classes.dataSourceRowIndex}>{}</div>
        <div className={classes.dataSourceRowData}>
          <div>First Name</div>
        </div>
        <div className={`${classes.dataSourceRowData} ${classes.dataBoardMemberTitleRow}`}>
          <div>Last Name</div>
        </div>
        <div className={`${classes.dataSourceRowData} ${classes.dataExecutiveSummary}`}>
          <div>{summary_text ? summary_text : "Summary"}</div>
        </div>
        {!noShowPersonTitle && (
          <div className={`${classes.dataSourceRowData} ${classes.dataBoardMemberTitleRow}`}>
            <div>Title</div>
          </div>
        )}
        {!noShowBoardMember && (
          <div className={`${classes.dataSourceRowAction}`} style={{ width: "50px" }}>
            <div>Board Member</div>
          </div>
        )}
        {!noShowInclude && (
          <div className={`${classes.dataSourceRowAction}`} style={{ width: "50px" }}>
            <div>Include</div>
          </div>
        )}
        {action_name === "Delete" && (
          <div className={classes.dataSourceRowAction} style={{ width: "50px" }}>
            {action_name}
          </div>
        )}
        {is_manual_entry && (
          <div className={`${classes.dataSourceRowAction}`} style={{ width: "50px" }}>
            <div>Edit</div>
          </div>
        )}
      </div>
    );
  };

  const renderAlternativeNamesHeaders = (action_name: string) => {
    return (
      <div className={classes.dataSourceRow}>
        <div className={classes.dataSourceRowIndex}>{}</div>
        <div className={`${classes.dataSourceRowData} ${classes.dataSourceRowAlternativeNames}`}>
          <div>Value</div>
        </div>
        <div className={classes.dataSourceRowAction}>{action_name}</div>
      </div>
    );
  };

  const ids_of_subsidiaries = editDataForField?.data?.map((data: IListData) => data.id);
  if (companyId) ids_of_subsidiaries?.push(companyId);

  return loading ? (
    <Modal open={loading} onClose={handleClose}>
      <div className={classes.container}>
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      </div>
    </Modal>
  ) : (
    <>
      <Modal open={isVisible} onClose={handleClose} style={{ zIndex: 500 }} disableEnforceFocus>
        <div className={classes.container}>
          <div>
            <button onClick={cancel} className={classes.closeButton} />
          </div>
          <div className={classes.title}>Edit: {name}</div>

          <div className={classes.body}>
            <div style={{ marginBottom: "10px" }}>
              <CheckboxSelect
                checkboxLabel="Prioritize Manual Data"
                size="small"
                labelStyling={{ fontSize: "16px", fontWeight: 500, color: "#4D748D" }}
                checked={(editDataForField?.overrideWithManual as boolean) || false}
                setChecked={handlePrioritizeManualInputCheckboxChange}
              />
            </div>
            {editDataForField?.dataSources && (
              <div>
                <ChangeDataSourcePriorityModal
                  name={name}
                  onClose={handlePriorityChange}
                  data={editDataForField.dataSources}
                />
              </div>
            )}
            <div>
              <div className={classes.dataSourceTitle}>Consolidated Data</div>
              <>{renderHeaders(input_type === InputTypeEnums.select_input ? "" : "Include")}</>
              <div className={classes.dataSourceRows}>{renderRows()}</div>
              <div className={classes.dataManualText}>Enter Data Manually</div>
              <>{renderHeaders("Delete", true)}</>
              <div className={classes.dataSourceRows}>{renderManualInputRows()}</div>
              <div className={classes.addRowContainer} onClick={addRow}>
                <img src={AddRowIcon} className={classes.addColumnIcon} alt="add row" />
                <div className={classes.addRowText}>Add row</div>
              </div>
            </div>
          </div>
          <div className={classes.errorDiv}>{errorMessage}</div>
          <div className={classes.actions}>
            <button className={classes.cancelButton} onClick={handleClose}>
              Cancel
            </button>
            <span style={{ marginLeft: 10 }} />
            <ApplyButton text="Apply" apply={handleSave} />
          </div>
        </div>
      </Modal>
      {/*<AddSubsidiaryModal*/}
      {/*  isVisible={isAddSubsidiaryModalVisible}*/}
      {/*  setIsVisible={setIsAddSubsidiaryModalVisible}*/}
      {/*  onSave={addNewSubsidiary}*/}
      {/*  subsidiaryIds={ids_of_subsidiaries}*/}
      {/*/>*/}
    </>
  );
};

export interface IListData {
  data_source_id: number;
  id: number | null;
  include: boolean;
  value: string | number;
  summary?: string;
  image?: string;
  title?: "CEO" | "Founder" | "Chairman" | "Board" | "Owner" | string;
  //Key Person Fields
  first_name?: string;
  last_name?: string;
  full_name?: string;
  linkedin_url?: string;
  board_member?: boolean;
}
