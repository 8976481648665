import { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  container: {
    paddingLeft: 0,
    display: "flex",
    position: "sticky",
    top: "150px",
  },
  section: {
    height: 15,
    padding: "20px 0px 20px 30px",
    paddingRight: 0,
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
    borderLeft: "3px solid #D8D8D8",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
  },
  active: {
    borderLeft: "3px solid #2F556D !important",
  },
}));

interface Props {
  sectionNames: Section[];
  scrollToSection: (id: string) => void;
}

interface Section {
  name: string;
  id: string;
  help?: boolean;
}

export const NavigationBar: FC<Props> = ({ sectionNames, scrollToSection }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState<string>("");
  const scroll = (id: string, index: number) => {
    scrollToSection(id);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const observers: any = [];
    const itemRefs = [];
    for (const section of sectionNames) {
      const find = document.getElementById(section.id);
      if (find) {
        itemRefs.push(find);
      }
    }
    for (const item of itemRefs) {
      const observer = new IntersectionObserver(([entry]) => {
        entry.isIntersecting && setActiveItem(item.id);
      });
      observer.observe(item);
      observers.push(observer);
    }
    return () => {
      for (const observer of observers) {
        observer.disconnect();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className={classes.container}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {sectionNames.map((section, index) => (
          <div
            key={index}
            className={`${classes.section} ${section.id === activeItem ? classes.active : null}`}
            onClick={() => (section?.help ? navigate("/help") : scroll(section.id, index))}
          >
            {section.name}
          </div>
        ))}
      </div>
    </div>
  );
};
