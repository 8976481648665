import { makeStyles } from "@material-ui/core";
import ToolTip from "./toolTip";

type Data = {
  sourceId: number;
};
type ShowDatSourceProps = {
  data: Data | Data[];
  getDataSource: (id: number) => { name: string; endpoint: string; id: number };
  children: React.ReactElement<any, any>;
};
const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: "10px",
    lineHeight: "14px",
    fontFamily: "Montserrat",
    "& a": {
      color: "#4D748D",
    },
  },
}));
export const ShowDataSources = ({ data, getDataSource, children }: ShowDatSourceProps) => {
  const classes = useStyles();
  const data1 = Array.isArray(data) ? data : [data];
  const dataSources = Array.from(
    new Set(
      data1
        .filter((item) => item.sourceId)
        .map((item) => item.sourceId)
        .map((item) => getDataSource(item))
        .filter((item) => item)
        .map((item) => item.name)
    )
  );
  const message = `This data is from ${
    dataSources.length === 1 ? dataSources[0] : `combination of ${dataSources.join(",")}`
  }`;
  return (
    <ToolTip
      title={
        dataSources.length > 0 ? (
          <p className={classes.tooltip}>{message}</p>
        ) : (
          <p className={classes.tooltip}>No data source is found</p>
        )
      }
      placement="top"
      arrow
    >
      {children}
      {/* <span
        className={isEdit ? `${classes.companyName} ${classes.hover}` : classes.companyName}
        onClick={editModalFunctions.openNameEdit}
        style={{ cursor: isEdit ? "pointer" : "default" }}
      >
        {getHighestPriorityData("name") ? getHighestPriorityData("name")?.value : data?.name?.value}
      </span> */}
    </ToolTip>
  );
};
