import { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import closeLogo from "../../assets/close-circle.png";
import { ApplyButton } from "../applyButton";
import menuIcon from "../../assets/more-circle.svg";
import { IDataSource } from "./SingleFieldEditModal";
import { SortableContainer, SortableElement } from "react-sortable-hoc"; // TODO: CHANGE PACKAGE

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    width: "450px",
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  closeButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 24,
    width: 24,
    backgroundImage: `url(${closeLogo})`,
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  title: {
    color: "#4D748D",
    fontFamily: "Oswald",
    fontSize: "28px",
    letterSpacing: "0",
    lineHeight: "34px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  body: {
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
  },
  dataSourceRow: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "15px",
  },
  cancelButton: {
    fontFamily: "Montserrat",
    fontSize: 12,
    borderRadius: 3,
    height: 38,
    textTransform: "none",
    border: "1px solid",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D748D",
    width: "74px",
  },
  actions: {
    display: "flex",
    paddingRight: "30px",
    justifyContent: "flex-end",
    marginTop: "30px",
  },
  closeModalContainer: {
    marginInline: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  menuIcon: {
    height: 25,
  },
  menuText: {
    marginLeft: 10,
    color: "#4D748D",
  },
  dragDropItem: {
    borderBottom: "1px solid #4D748D",
    padding: "10px 5px 10px 15px",
    marginInline: "10px",
    cursor: "move",
    display: "flex",
    "&:last-child": {
      borderBottom: "0px",
    },
  },
  lineContainer: {
    marginTop: "3px",
    marginRight: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  line: {
    height: "1px",
    width: "15px",
    border: 0,
    margin: 0,
    borderTop: "2px solid #979797",
    marginBottom: "5px",
    display: "block",
    padding: 0,
    "&:last-child": {
      marginBottom: 0,
    },
  },
}));

interface Props {
  name: string;
  data: IDataSource[];
  onClose: (newDataSource: IDataSource[]) => void;
}

export const ChangeDataSourcePriorityModal: FC<Props> = ({ name, onClose, data }) => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const [dataSources, setDataSources] = useState<IDataSource[]>(
    data.sort((a: IDataSource, b: IDataSource) => b.priority - a.priority)
  );

  const closeModal = () => {
    setIsVisible(false);
    setDataSources(data.sort((a: IDataSource, b: IDataSource) => b.priority - a.priority));
  };
  const openModal = () => setIsVisible(true);

  const onSort = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (oldIndex === newIndex) return;
    const element = dataSources[oldIndex];
    dataSources.splice(oldIndex, 1);
    dataSources.splice(newIndex, 0, element);
    setDataSources([...dataSources]);
  };

  const SortableItem = SortableElement((item: { value: IDataSource }) => {
    return (
      <div className={classes.dragDropItem}>
        <div className={classes.lineContainer}>
          <hr className={classes.line}></hr>
          <hr className={classes.line}></hr>
          <hr className={classes.line}></hr>
        </div>
        <span>
          <span>{item.value.name}</span>
          <span>{item.value.type === "Computed" ? ` [${item.value.type}]` : ""}</span>
        </span>
      </div>
    );
  });

  const SortableList = SortableContainer(({ items }: { items: IDataSource[] }) => {
    return (
      <div>
        {items.map((value: IDataSource, index: number) => (
          <SortableItem key={`item-${value.id}`} index={index} value={value} />
        ))}
      </div>
    );
  });

  const handleSave = () => {
    onClose(dataSources.map((item: IDataSource, index: number) => ({ ...item, priority: dataSources.length - index })));
    setIsVisible(false);
  };

  return (
    <div>
      <div className={classes.closeModalContainer} onClick={openModal}>
        <img src={menuIcon} className={classes.menuIcon} alt="menu" />
        <div className={classes.menuText}>Change Data Source Priority</div>
      </div>
      <Modal open={isVisible} onClose={closeModal}>
        <div className={classes.container}>
          <div>
            <button onClick={closeModal} className={classes.closeButton} />
          </div>
          <div className={classes.title}>Data Provider</div>
          <div>Choose your data source provider for {name}</div>
          <div className={classes.body}>
            <SortableList items={dataSources} onSortEnd={onSort} helperClass="draggable-item" />
          </div>
          <div className={classes.actions}>
            <button className={classes.cancelButton} onClick={closeModal}>
              Cancel
            </button>
            <span style={{ marginLeft: 10 }} />
            <ApplyButton text="Apply" apply={handleSave} />
          </div>
        </div>
      </Modal>
    </div>
  );
};
