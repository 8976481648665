import { FC } from "react";
import "../../styles/main.scss";
import search from "../../assets/white-search.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  searchButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 24,
    width: 24,
    backgroundImage: `url(${search})`,
    marginLeft: "-3rem",
  },
}));

interface Props {
  value: string;
  setValue: (value: string) => void;
  search: () => void;
}

export const SearchInput: FC<Props> = ({ value, setValue, search }) => {
  const classes = useStyles();
  const handleChange = (event: any) => {
    setValue(event.target.value);
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      search();
    }
  }

  return (
    <div className="form__group field">
      <input
        type="input"
        className="form__field"
        placeholder="Search Database"
        name="search"
        id="search"
        value={value}
        onChange={(event) => handleChange(event)}
        autoComplete="off"
        onKeyDown={(event) => handleKeyDown(event)}
      />
      <label htmlFor="search" className="form__label">
        Search Database
      </label>
      <button className={classes.searchButton} onClick={search}></button>
    </div>
  );
};
