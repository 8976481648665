import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";

import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import { useAuth } from "../../shared/hooks/auth-hook";
import { Filters } from "./Filters";

type CollectionQueryResponse<T> = {
  data: T[];
  count: number;
};

type CompanyUpdateItem = {
  company: {
    id: number;
    name: string;
  };
  createdAt: string;
  details: string | null;
  dataSource: {
    id: number;
    name: string;
  };
  id: number;
  status: {
    id: number;
    name: string;
  };
  updatedAt: string | null;
};

type CompanyUpdateFilters = {
  dataSourceId?: number;
  companyId?: number;
  statusId?: number;
};

async function getCompanyUpdates(token: string, filters: CompanyUpdateFilters, page = 0) {
  const params = new URLSearchParams();
  if (page) {
    params.set("page", page.toString());
  }

  if (filters.statusId) {
    params.set("statusId", filters.statusId.toString());
  }

  const query = params.toString();

  let url = `${process.env.REACT_APP_DATA_MANAGER_URL}/company-updates`;

  if (query) {
    url += `?${query}`;
  }

  const res = await fetch(url, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (res.ok) {
    const collection = (await res.json()) as CollectionQueryResponse<CompanyUpdateItem>;
    return collection;
  } else {
    try {
      const err = await res.json();
      console.warn("company-updates", err);
    } catch {
      console.warn("company-updates: unknown error");
    }
    throw new Error("company-updates failed");
  }
}

const useCompanyUpdates = (token: string, filters: CompanyUpdateFilters, page: number) => {
  return useQuery({
    queryKey: ["company-updates", filters, page],
    queryFn: () => getCompanyUpdates(token!, filters, page),
    keepPreviousData: true,
  });
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ROWS_PER_PAGE = 20;
const ROWS_PER_PAGE_OPTIONS = [20];

export const CompanyUpdates = () => {
  const { token } = useAuth();

  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<{
    dataSourceId?: number;
    companyId?: number;
    statusId?: number;
  }>({});

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const queryClient = useQueryClient();

  const { status, data, error } = useCompanyUpdates(token ?? "", filters, page);

  return (
    <div>
      <Container maxWidth="lg">
        <Typography sx={{ mt: 4, mb: 2 }} variant="h4" component="div">
          Company update logs
        </Typography>
        <div>
          <Filters filters={filters} setFilters={setFilters} />
        </div>
        <div>
          {status === "loading" ? (
            "Loading.."
          ) : error instanceof Error ? (
            <span>Error: {error.message}</span>
          ) : (
            <>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="company updates table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Company</TableCell>
                      <TableCell align="right">Data Source</TableCell>
                      <TableCell align="right">Execution Date</TableCell>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="right">Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.data.map((row) => (
                      <StyledTableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          <Link href={`/companies/${row.company.id}`} target="_blank" rel="noopener">
                            {row.company.name}
                          </Link>
                        </TableCell>
                        <TableCell align="right">{row.dataSource.name}</TableCell>
                        <TableCell align="right">{new Date(row.createdAt).toLocaleString()}</TableCell>
                        <TableCell align="right">{row.status.name}</TableCell>
                        <TableCell align="right">{row.details}</TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={data?.count ?? 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={ROWS_PER_PAGE}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              />
            </>
          )}
        </div>
      </Container>
    </div>
  );
};
