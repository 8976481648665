
export const formatHqAddress = (data: any) => {
  let addressValue = "";
  if (data?.headquarter_address?.value?.hq_address)
    addressValue += `${data?.headquarter_address?.value?.hq_address}, `;
  if (data?.headquarter_address?.value?.state) addressValue += `${data?.headquarter_address?.value?.state}, `;
  if (data?.headquarter_address?.value?.country) addressValue += `${data?.headquarter_address?.value?.country}, `;
  if (data?.headquarter_address?.value?.zip) addressValue += `${data?.headquarter_address?.value?.zip}`;

  if (addressValue.endsWith(", ")) addressValue = addressValue.substring(0, addressValue.length - 2);

  return { sourceId: data?.headquarter_address?.sourceId, value: addressValue };
};
