import { makeStyles } from "@material-ui/core";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../assets/resultsBackground.png";

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    paddingTop: "100px",
    display: "flex",
    "&::before": {
      content: "''",
      width: "100%",
      height: 130,
      position: "absolute",
      top: 0,
      left: -5,
      zIndex: -1,
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
    },
  },
  backButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    alignItems: "center",
    display: "inline-flex",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 600,
    height: 30,
  },
  backButtonDiv: {
    minWidth: 0,
    position: "absolute",
    top: "15px",
    left: "20px",
  },
  leftArrow: {
    border: "solid white",
    borderWidth: "0 3px 3px 0",
    display: "inline-block",
    padding: 3,
    transform: "rotate(135deg)",
    marginRight: 10,
  },
  body: {
    background: "white",
    marginTop: 70,
    marginLeft: 70,
    color: "#666666",
    fontSize: "28px",
    fontFamily: "Montserrat",
    fontStyle: "italic",
  },
}));

export const HelpComponent: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={classes.backButtonDiv}>
        <button className={classes.backButton} onClick={() => navigate(-1)}>
          <i className={classes.leftArrow} />
          Back
        </button>
      </div>
      <div className={classes.body}>COMING SOON</div>
    </div>
  );
};
