import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  banner: {
    width: "100%",
    minHeight: "362px",
    maxHeight: "385px",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "10px",
    paddingBottom: "50px",
  },
  leftNavigation: {
    width: "467px",
    marginTop: "40px",
  },
  saveSection: {
    height: "98px",
    width: "100%",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
    position: "sticky",
    bottom: 0,
    display: "flex",
    placeItems: "center",
  },
  button: {
    fontFamily: "Montserrat",
    fontSize: 12,
    borderRadius: 3,
    height: 38,
    textTransform: "none",
    border: "1px solid",
    cursor: "pointer",
    textAlign: "center",
  },
  actionButtons: {
    justifySelf: "flex-end",
    marginLeft: "auto",
    marginRight: "120px",
  },
  lastEditedDate: {
    color: "#333333",
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: "10px",
    marginBottom: "10px",
    marginRight: "5px",
    marginLeft: "auto",
    width: "max-content",
    fontSize: "14px",
  },
  loadingContainer: {
    margin: "auto",
    width: "50%"
  },
  errorDiv: {
    color: "#c40014",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
    marginTop: "10px",
  },
  dropdownButton: {
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: "12px",
    float: "left",
    clear: "left",
    height: "40px",
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
    border: "none",
    backgroundColor: "#FFFFFF",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(77, 116, 141, 0.1)",
    },
  },
}));
