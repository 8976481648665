import { createContext } from "react";
import { PermissionType } from "../types/permissions";
import { User, UserData } from "../types/user";

export type LoginInput = {
  token: string;
  userData: UserData;
  expirationDate?: Date;
};

export type LoginArgs = [loginInput: LoginInput, callback: VoidFunction];
export type LoginFunction = (...args: LoginArgs) => void;

export type LogoutArgs = [callback: VoidFunction];
export type LogoutFunction = (...args: LogoutArgs) => void;

type AuthContextType = {
  isLoggedIn: boolean;
  token: string | null;
  user: User | null;
  hasPermission: (permission: PermissionType) => boolean;
  login: LoginFunction;
  logout: LogoutFunction;
};

export const AuthContext = createContext<AuthContextType>(null!);
