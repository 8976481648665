import { useState, FC, ReactNode } from "react";
import "../../styles/main.scss";
import { ClearFilters } from "../clearfilters";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface Props {
  title: string;
  children: ReactNode;
  type: string;
  number: number;
  clear: () => void;
}

export const ResultsAccordion: FC<Props> = ({ title, children, type, number, clear }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        style={{
          borderRadius: 0,
          borderBottom: "1px solid #E1E1E1",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ fill: "#5F8298" }} />}
          sx={{ height: 50, width: 350, padding: "14px 30px" }}
        >
          <Typography
            style={{
              color: "#333333",
              fontFamily: "Montserrat",
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {(type === "multi" || type === "nested") && (
            <Typography>
              <span
                style={{
                  color: "#666666",
                  fontFamily: "Montserrat",
                  fontSize: 12,
                  paddingLeft: 13,
                  float: "left",
                }}
              >
                ({number} filters selected - <ClearFilters text="Clear" clear={clear} />)
              </span>
              <br />
            </Typography>
          )}
          <div style={{ paddingLeft: 13 }}>{children}</div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
