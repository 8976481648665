import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";

import AddRowIcon from "../../../assets/add-circle.png";

import type { DataSource, QuarterlyRevenueItem, QuarterlyRevenuesEditData } from "./edit-data";
import { RevenueItemCard } from "./revenue-item-card";

const useStyles = makeStyles((theme) => {
  return {
    addRowContainer: {
      marginTop: "10px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    addRowText: {
      color: "#4D748D",
      fontFamily: "Montserrat",
      fontSize: "14px",
    },
    addColumnIcon: {
      height: "20px",
      width: "20px",
      marginRight: "10px",
    },
  };
});

type QuarterlyRevenuesByYear = {
  year: number;
  Q1?: QuarterlyRevenueItem;
  Q2?: QuarterlyRevenueItem;
  Q3?: QuarterlyRevenueItem;
  Q4?: QuarterlyRevenueItem;
};

const quarterToFiscalPeriod = [null, "Q1", "Q2", "Q3", "Q4"] as const;

function resolveDataPointsConsolidated(editData: QuarterlyRevenuesEditData): QuarterlyRevenuesByYear[] {
  const dataSourcesNormalized = editData.dataSources.reduce<Record<number, DataSource>>((acc, it) => {
    acc[it.id] = it;
    return acc;
  }, {});

  const items = editData.data.filter((it) => it.include);

  const byYearMap = new Map<number, QuarterlyRevenuesByYear>();

  for (const item of items) {
    const { year, quarter } = item;
    const fiscalPeriod = quarterToFiscalPeriod[quarter];

    if (fiscalPeriod) {
      const byYear = byYearMap.get(year) ?? { year };

      const currentItem = byYear[fiscalPeriod];
      const currentItemPriority = currentItem ? dataSourcesNormalized[currentItem.dataSourceId].priority : -100;

      const itemPriority = dataSourcesNormalized[item.dataSourceId].priority;

      if (itemPriority > currentItemPriority) {
        byYear[fiscalPeriod] = item;
        byYearMap.set(year, byYear);
      }
    }
  }
  return Array.from(byYearMap.values()).sort((a, b) => b.year - a.year);
}

function resolveDataPointsByDataSource(editData: QuarterlyRevenuesEditData, dataSourceId: number) {
  const items = editData.data.filter((it) => it.dataSourceId === dataSourceId);

  const byYearMap = new Map<number, QuarterlyRevenuesByYear>();

  for (const item of items) {
    const { year, quarter } = item;
    const fiscalPeriod = quarterToFiscalPeriod[quarter];

    if (fiscalPeriod) {
      const byYear = byYearMap.get(year) ?? { year };
      byYear[fiscalPeriod] = item;
      byYearMap.set(year, byYear);
    }
  }
  return Array.from(byYearMap.values()).sort((a, b) => b.year - a.year);
}

function getYearOptions(start: number, end: number) {
  return Array.from({ length: end - start + 1 }, (_, i) => end - i);
}

const currentYear = new Date().getFullYear();
const YEAR_OPTIONS = getYearOptions(2000, currentYear);

type ConsolidatedView = {
  id: number;
  name: string;
  isConsolidated: true;
};

type DataSourceView = {
  id: number;
  name: string;
  isConsolidated: false;
  dataSource: DataSource;
};

export type ViewOption = ConsolidatedView | DataSourceView;

const StyledTableYearCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Montserrat, sans-serif",
    color: "#333",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "14px",
    borderBottom: "none",
  },
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F6F6F6",
    fontFamily: "Montserrat, sans-serif",
    color: "#666",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
  },
}));

const StyledTableRevenueItemCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Montserrat, sans-serif",
    color: "#333",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "14px",
    borderBottom: "none",
  },
}));

type Props = {
  currentView: ViewOption | undefined;
  editData: QuarterlyRevenuesEditData;
  setEditData: Dispatch<SetStateAction<QuarterlyRevenuesEditData>>;
};

export const EditQuarterlyRevenuesFormTable = ({ editData, setEditData, currentView }: Props) => {
  const classes = useStyles();

  const [currentEditableQuarter, setCurrentEditableQuarter] = useState<{ year: number; quarter: number } | null>(null);
  const [yearToAdd, setYearToAdd] = useState<number | null>(null);

  if (!currentView) {
    return null;
  }

  const dataSource = currentView.isConsolidated ? null : currentView.dataSource;

  const isManualDataSource = dataSource !== null && dataSource.type === "Manual";

  const consolidatedDataPoints = resolveDataPointsConsolidated(editData);

  const groupedDataPoints = dataSource
    ? resolveDataPointsByDataSource(editData, dataSource.id)
    : consolidatedDataPoints;

  const toggleInclude = (year: number, quarter: number, dataSourceId: number) => {
    setEditData((prevState) => {
      const newData = prevState.data.map((item) => {
        if (item.year === year && item.quarter === quarter && item.dataSourceId === dataSourceId) {
          return { ...item, include: !item.include };
        }
        return item;
      });
      return {
        ...prevState,
        data: newData,
      };
    });
  };

  const onCreateItem = (year: number, quarter: number) => {
    if (dataSource && isManualDataSource) {
      updateRevenueItem(year, quarter, dataSource.id, {
        id: null,
        year,
        quarter,
        dataSourceId: dataSource.id,
        include: true,
        value: 0,
        currency: "USD",
      });
    }
  };

  const updateRevenueItem = (
    year: number,
    quarter: number,
    dataSourceId: number,
    updatedFields: Partial<QuarterlyRevenueItem>
  ) => {
    setEditData((prevState) => {
      const existingItem = prevState.data.find(
        (item) => item.year === year && item.quarter === quarter && item.dataSourceId === dataSourceId
      );

      if (existingItem) {
        return {
          ...prevState,
          data: prevState.data.map((item) => {
            if (item.year === year && item.quarter === quarter && item.dataSourceId === dataSourceId) {
              return { ...item, ...updatedFields };
            }
            return item;
          }),
        };
      } else {
        return {
          ...prevState,
          data: [...prevState.data, updatedFields as QuarterlyRevenueItem],
        };
      }
    });
  };

  const deleteRevenueItem = (year: number, quarter: number, dataSourceId: number) => {
    setEditData((prevState) => {
      return {
        ...prevState,
        data: prevState.data.filter(
          (item) => !(item.year === year && item.quarter === quarter && item.dataSourceId === dataSourceId)
        ),
      };
    });
  };

  const addYearRow = () => {
    if (yearToAdd && dataSource && isManualDataSource) {
      const quarterToAdd = 1;
      const newItem: QuarterlyRevenueItem = {
        dataSourceId: dataSource.id,
        year: yearToAdd,
        quarter: quarterToAdd,
        id: null,
        value: 0,
        include: true,
        currency: "USD",
      };

      updateRevenueItem(yearToAdd, quarterToAdd, dataSource.id, newItem);
      setCurrentEditableQuarter({ year: yearToAdd, quarter: quarterToAdd });
    }
  };

  return (
    <Box sx={{ height: 380, display: "flex", flexDirection: "column", gap: 1 }}>
      <TableContainer component={Paper} sx={{ flex: 1, overflow: "auto" }}>
        <Table stickyHeader size="small" aria-label="company yearly revenues">
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell align="left">Year</StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">Q1</StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">Q2</StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">Q3</StyledTableHeaderCell>
              <StyledTableHeaderCell align="left">Q4</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedDataPoints.map(({ year, Q1, Q2, Q3, Q4 }) => (
              <TableRow key={year} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <StyledTableYearCell align="left">{year}</StyledTableYearCell>
                {[Q1, Q2, Q3, Q4].map((item, index) => {
                  const quarter = index + 1;
                  const fiscalPeriod = quarterToFiscalPeriod[quarter];
                  const consolidatedDataPointsThisYear = consolidatedDataPoints.find((it) => it.year === year);
                  const consolidatedDataPoint =
                    consolidatedDataPointsThisYear && fiscalPeriod
                      ? consolidatedDataPointsThisYear[fiscalPeriod]
                      : null;

                  const isHighestPriority =
                    !!item && !!consolidatedDataPoint && consolidatedDataPoint.dataSourceId === item.dataSourceId;

                  return (
                    <StyledTableRevenueItemCell
                      key={quarter}
                      align="left"
                      sx={{
                        position: "relative",
                      }}
                    >
                      <RevenueItemCard
                        item={item}
                        canEdit={isManualDataSource}
                        canToggleInclude={!!dataSource && !isManualDataSource}
                        onCreate={() => onCreateItem(year, quarter)}
                        onDelete={() => {
                          if (dataSource && isManualDataSource) {
                            deleteRevenueItem(year, quarter, dataSource.id);
                          }
                        }}
                        onToggleInclude={() => {
                          if (dataSource && !isManualDataSource) {
                            toggleInclude(year, quarter, dataSource.id);
                          }
                        }}
                        isHighestPriority={isHighestPriority}
                        isEditing={
                          isManualDataSource &&
                          !!currentEditableQuarter &&
                          currentEditableQuarter.year === year &&
                          currentEditableQuarter.quarter === quarter
                        }
                        onStartEdit={() => setCurrentEditableQuarter({ year, quarter })}
                        onCancelEdit={() => setCurrentEditableQuarter(null)}
                        onApplyEdit={(editState) => {
                          if (dataSource && isManualDataSource) {
                            updateRevenueItem(year, quarter, dataSource.id, {
                              value: editState.value === "" ? 0 : editState.value,
                              currency: editState.currency,
                            });
                            setCurrentEditableQuarter(null);
                          }
                        }}
                      ></RevenueItemCard>
                    </StyledTableRevenueItemCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ flexShrink: 0 }}>
        {isManualDataSource && (
          <div className={classes.addRowContainer} onClick={addYearRow}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="yearly-revenues-table-add-row-input-label">Year</InputLabel>
              <Select
                size="small"
                value={yearToAdd}
                label="Year"
                labelId="yearly-revenues-table-add-row-input-label"
                onChange={(e) => setYearToAdd(e.target.value as any)}
                MenuProps={{
                  anchorOrigin: { vertical: "top", horizontal: "left" },
                  transformOrigin: { vertical: "bottom", horizontal: "left" },
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                      overflow: "auto",
                    },
                  },
                }}
              >
                <MenuItem value={null as any}>Select Year</MenuItem>
                {YEAR_OPTIONS.map((year) => (
                  <MenuItem key={year} value={year} disabled={!!groupedDataPoints.find((it) => it.year === year)}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <img src={AddRowIcon} className={classes.addColumnIcon} alt="add row" />
            <div className={classes.addRowText}>Add row</div>
          </div>
        )}
      </Box>
    </Box>
  );
};
