import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "../styles/main.scss";
import { ClearFilters } from "../components/clearfilters";
import { ApplyButton } from "../components/applyButton";
import { ResultsSearch } from "../components/result/resulstsSearchInput";
import { ResultsAccordion } from "../components/result/resultsAccordionMenu";
import { Pager } from "../components/result/pager";
import backgroundImage from "../assets/resultsBackground.png";
import exportIcon from "../assets/export.png";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  ISearchCompany,
  ICountry,
  IRangeItem,
  ISelectItem,
  ILetterFilter,
  IState,
  ParentItem,
  Level,
  TaxonomySegment,
} from "../interfaces";
import { CheckboxSelect } from "../components/checkbox";
import { DatePickerComponent } from "../components/datepicker";
import buildQuery from "odata-query";
import { apiConfig } from "../services/api/apiConfig";
import { Service } from "../services/api/service";
import { useAuth } from "../shared/hooks/auth-hook";
import { CompanyRow } from "../components/result/companyRow";
import { AsyncQueue } from "../utils/asyncQueue";
import { MultiLevelFilter } from "../components/multiLevelFilter.tsx/filter";
import { MultiSelect } from "../components/multiselect";
import { ExcelExport } from "../utils/exportToExcel";
import { DropdownMenu } from "../components/dropdownMenu";
import { TextField } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { convertTaxonomySegmentToLevel } from "../utils/industries";

//Component element styles
const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    paddingTop: "100px",
    display: "flex",
    "&::before": {
      content: "''",
      width: "100%",
      height: 130,
      position: "absolute",
      top: 0,
      left: -5,
      zIndex: -1,
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
    },
  },
  filters: {
    background: "white",
    width: 350,
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
    marginTop: 30,
  },
  results: {
    background: "white",
    marginTop: 30,
    marginLeft: 30,
    padding: "50px 70px 50px 70px",
    width: "100%",
  },
  filtersText: {
    color: "#666666",
    fontFamily: "Oswald",
    fontSize: 22,
    float: "left",
    clear: "left",
    marginBottom: 10,
  },
  resultsText: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: 39,
    fontWeight: 500,
    flex: 1,
  },
  resultsCountText: {
    color: "#666666",
    fontFamily: "Oswald",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "center",
    marginLeft: 10,
  },
  countText: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 700,
    float: "left",
    clear: "left",
    marginBottom: 10,
  },
  totalCountText: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: 14,
  },
  clearFiltersDiv: {
    float: "left",
    clear: "left",
    "& .MuiButton-root": {
      padding: 0,
    },
    marginBottom: 20,
  },
  searchInputDiv: {
    float: "left",
    clear: "left",
    width: 290,
    height: 38,
    marginBottom: 30,
  },
  rectangle: {
    height: 1,
    width: "100%",
    backgroundColor: "#E1E1E1",
    clear: "left",
  },
  buttons: {
    height: 80,
    display: "flex",
    justifyContent: "end",
    padding: 20,
  },
  exportButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    alignItems: "center",
    display: "inline-flex",
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 500,
  },
  selectedFilter: {
    borderRadius: 4,
    backgroundColor: "#75AED2",
    color: "#FFFFFF",
    fontFamily: "Open Sans",
    fontSize: 10,
    fontWeight: "bold",
    padding: "5px 10px",
    display: "inline-block",
    marginRight: 5,
  },
  refineText: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 700,
  },
  letterShape: {
    boxSizing: "border-box",
    height: 25,
    width: 25,
    border: "1px solid #4D748D",
    borderRadius: 16.5,
    textAlign: "center",
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: 12,
    marginTop: 15,
    marginRight: 8,
    cursor: "pointer",
  },
  selectedLetter: {
    backgroundColor: "#4D748D",
    color: "#FFFFFF",
  },
  companyLetter: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: 32,
    fontWeight: 500,
    marginLeft: 2,
  },
  line: {
    boxSizing: "border-box",
    height: 2,
    width: 21,
    border: "2px solid #4D748D",
    backgroundColor: "#4D748D",
    marginBottom: 30,
  },
  divider: {
    boxSizing: "border-box",
    height: 1,
    border: "1px solid #ECECEC",
  },
  leftArrow: {
    border: "solid white",
    borderWidth: "0 3px 3px 0",
    display: "inline-block",
    padding: 3,
    transform: "rotate(135deg)",
    marginRight: 10,
  },
  backButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    alignItems: "center",
    display: "inline-flex",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 600,
    height: 30,
  },
  backButtonDiv: {
    minWidth: 0,
    position: "absolute",
    top: "15px",
    left: "20px",
  },
  paginationContainer: {
    marginTop: 60,
  },
  letterDisabled: {
    cursor: "auto",
    background: "#CCCCCC",
    color: "#FFFFFF",
  },
  dropdownButton: {
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: "12px",
    float: "left",
    clear: "left",
    height: "40px",
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
    border: "none",
    backgroundColor: "#FFFFFF",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(77, 116, 141, 0.1)",
    },
  },
}));

const countryStates: any = {};
const asyncQueue = new AsyncQueue();
export const ResultsComponent: FC = () => {
  //the data coming from search page
  const data: any = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const alphabet1 = "$0123456789".split("");
  const alphabet2 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const { token } = useAuth();

  //the filters that are used for letter filtering by clicking refine part
  const [letterFilter, setLetterFilter] = useState<string[]>([]);
  const [resultExistLetters, setResultExistLetters] = useState<string[]>([]);
  const [refilter, setRefilter] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [totalPageNumber, setTotalPageNumber] = useState<number>(1);
  const [resultLength, setResultLength] = useState<number>(10);
  const excelExport = new ExcelExport();

  //companies that is filtered
  const [companies, setCompanies] = useState<ISearchCompany[]>([]);

  // total company numbers
  const [totalCount, setTotalCount] = useState<number>(0);
  // number of results
  const [filteredCount, setFilteredCount] = useState<number>(0);
  // Paging details TODO With "0-0 of 0 companies" display NO Results page instead.
  const currentPageFirstIndex = (page - 1) * resultLength + (filteredCount > 0 ? 1 : 0);
  const currentPageLastIndex = (page - 1) * resultLength + companies.length;

  //selected filter chips under results header
  const [selectedFilters, setSelectedFilters] = useState<any>({});

  //search input value on the left
  const [searchInput, setSearchInput] = useState<string>("");

  //countries filter
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<ICountry[]>([]);
  useEffect(() => {
    if (data.state?.selectedCountry)
      setSelectedCountry(
        data.state?.selectedCountry
          .map((item: number) => countries.find((item2) => item2.id === item))
          .filter((item: ICountry) => !!item)
      );
  }, [countries]);
  //states filter
  const [states, setStates] = useState<IState[]>([]);
  const [selectedState, setSelectedState] = useState<IState[]>([]);

  const [industryCodes, setIndustryCodes] = useState<{
    isic: string;
    naics: string;
    nace: string;
  }>({
    isic: "",
    naics: "",
    nace: "",
  });

  const setIndustryCode: (type: "isic" | "naics" | "nace", codes: string) => void = (type, codes) =>
    setIndustryCodes((state) => ({ ...state, [type]: codes }));
  const onIndustryCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "isic" || name === "naics" || name === "nace") {
      setIndustryCode(name, value);
    }
  };

  //primary industry filter
  const [primaryIndustriesLevel, setPrimaryIndustriesLevel] = useState<Level>(
    data.state?.primaryIndustriesLevel || { selected_id: null, items: {} }
  );
  const [selectedPrimaryIndustry, setSelectedPrimaryIndustry] = useState<number[]>(
    data.state?.selectedPrimaryIndustry || []
  );

  //secondary industry filter
  const [secondaryIndustriesLevel, setSecondaryIndustriesLevel] = useState<Level>(
    data.state?.secondaryIndustriesLevel || { selected_id: null, items: {} }
  );
  const [selectedSecondaryIndustry, setSelectedSecondaryIndustry] = useState<number[]>(
    data.state?.selectedSecondaryIndustry || []
  );

  //operation areas filter
  const [operationAreas, setOperationAreas] = useState<ISelectItem[]>([]);
  const [selectedOperationAreas, setSelectedOperationAreas] = useState<number[]>([]);

  //ownership types filter
  const [ownershipTypes, setOwnershipTypes] = useState<ISelectItem[]>([]);
  const [selectedOwnershipTypes, setSelectedOwnershipTypes] = useState<number[]>([]);

  //number of employee filter
  const [employeeNumberRanges, setEmployeeNumberRanges] = useState<IRangeItem[]>([]);
  const [selectedEmployeeNumberRanges, setSelectedEmployeeNumberRanges] = useState<number[]>([]);

  //number of revenue filter
  const [revenueNumberRanges, setRevenueNumberRanges] = useState<IRangeItem[]>([]);
  const [selectedRevenueNumberRanges, setSelectedRevenueNumberRanges] = useState<number[]>([]);

  //number of store filter
  const [storeNumberRanges, setStoreNumberRanges] = useState<IRangeItem[]>([]);
  const [selectedStoreNumberRanges, setSelectedStoreNumberRanges] = useState<number[]>([]);

  //RTN member filter
  const [rtnMember, setRtnMember] = useState<boolean>(false);

  //P2P member filter
  const [p2pMember, setP2pMember] = useState<boolean>(false);

  const [showActiveProfiles, setShowActiveProfiles] = useState(true);
  const [showArchivedProfiles, setShowArchivedProfiles] = useState(false);

  //Last edit date filter
  const [lastEditDate, setLastEditDate] = useState<(Date | null)[]>([null, null]);
  const [firstLoaded, setFirstLoaded] = useState(false);
  useEffect(() => {
    for (const key of Object.keys(countryStates)) {
      delete countryStates[key];
    }
    const fetchInitialData = async () => {
      const promises = [];
      let countries1: ICountry[] = [];
      promises.push(
        new Promise((resolve) => {
          Service.get(apiConfig.countries, token as string).then((countries) => {
            setCountries(countries.data);
            setOperationAreas(countries.data);
            countries1 = countries.data as ICountry[];
            resolve("Done");
          });
        })
      );

      promises.push(
        new Promise((resolve) => {
          Service.get(apiConfig.ownershipTypes, token as string).then((ownerShipTypes) => {
            setOwnershipTypes(ownerShipTypes.data);
            resolve("Done");
          });
        })
      );

      promises.push(
        new Promise((resolve) => {
          Service.get(apiConfig.employeeNumberRanges, token as string).then((employeeNumberRanges) => {
            setEmployeeNumberRanges(employeeNumberRanges.data);
            resolve("Done");
          });
        })
      );

      promises.push(
        new Promise((resolve) => {
          Service.get(apiConfig.revenueNumberRanges, token as string).then((revenueNumberRanges) => {
            setRevenueNumberRanges(revenueNumberRanges.data);
            resolve("Done");
          });
        })
      );
      promises.push(
        new Promise((resolve) => {
          Service.get(apiConfig.storeNumberRanges, token as string).then((storeNumberRanges) => {
            setStoreNumberRanges(storeNumberRanges.data);
            resolve("Done");
          });
        })
      );
      const json = getFilterJsonFromQuery();

      if (!json?.primaryIndustriesLevel?.items || !json?.secondaryIndustriesLevel?.items) {
        promises.push(
          new Promise((resolve) => {
            Service.get(apiConfig.companiesPrimaryIndustriesAll, token as string)
              .then((response: AxiosResponse<TaxonomySegment[]>) =>
                setPrimaryIndustriesLevel(convertTaxonomySegmentToLevel(response.data))
              )
              .catch(console.error);
            Service.get(apiConfig.companiesSecondaryIndustriesAll, token as string)
              .then((response: AxiosResponse<TaxonomySegment[]>) =>
                setSecondaryIndustriesLevel(convertTaxonomySegmentToLevel(response.data))
              )
              .catch(console.error);

            resolve("Done");
          })
        );
      }
      await Promise.all(promises);
      const searchInput = json?.searchInput ? json?.searchInput : "";
      setSearchInput(searchInput);
      const selectedCountry = countries1.filter((item: ICountry) => json?.selectedCountry?.includes(item.id));
      const selectedPrimaryIndustry = json?.selectedPrimaryIndustry;
      const selectedSecondaryIndustry = json?.selectedSecondaryIndustry;
      const selectedOwnershipTypes = json?.selectedOwnershipTypes;
      const selectedEmployeeNumberRanges = json?.selectedEmployeeNumberRanges;
      const selectedRevenueNumberRanges = json?.selectedRevenueNumberRanges;
      const selectedStoreNumberRanges = json?.selectedStoreNumberRanges;
      const selectedOperationAreas = json?.selectedOperationAreas;
      const rtn_member = json?.rtn_member !== undefined ? (json?.rtn_member ? true : false) : undefined;
      const p2pi_member = json?.p2pi_member !== undefined ? (json?.p2pi_member ? true : false) : undefined;
      const letters = json?.letters ? json?.letters.split("") : undefined;
      if (selectedCountry?.length) setSelectedCountry(selectedCountry);

      if (json?.isic) setIndustryCode("isic", json.isic);
      if (json?.naics) setIndustryCode("naics", json.naics);
      if (json?.nace) setIndustryCode("nace", json.nace);

      setShowArchivedProfiles(json?.status?.includes("archived") ?? false);
      setShowActiveProfiles(json?.status?.includes("active") ?? true);

      if (selectedPrimaryIndustry?.length) setSelectedPrimaryIndustry(selectedPrimaryIndustry);

      if (selectedSecondaryIndustry?.length) setSelectedSecondaryIndustry(selectedSecondaryIndustry);

      if (selectedOwnershipTypes?.length) {
        setSelectedOwnershipTypes(selectedOwnershipTypes);
      }
      if (selectedEmployeeNumberRanges?.length) setSelectedEmployeeNumberRanges(selectedEmployeeNumberRanges);
      if (selectedRevenueNumberRanges?.length) setSelectedRevenueNumberRanges(selectedRevenueNumberRanges);
      if (selectedStoreNumberRanges?.length) setSelectedStoreNumberRanges(selectedStoreNumberRanges);
      if (selectedOperationAreas?.length) setSelectedOperationAreas(selectedOperationAreas);
      if (json?.modified_at && Array.isArray(json?.modified_at)) {
        setLastEditDate(json?.modified_at?.map((item: string) => new Date(item)));
      }
      if (rtn_member !== undefined) setRtnMember(rtn_member);
      if (p2pi_member !== undefined) setP2pMember(p2pi_member);

      if (letters) {
        setLetterFilter(letters);
      }
      if (json?.page) setPage(json?.page);
      if (json?.resultLength) setResultLength(json?.resultLength);
      if (json?.primaryIndustriesLevel) {
        setPrimaryIndustriesLevel(json?.primaryIndustriesLevel as Level);
      }
      if (json?.secondaryIndustriesLevel) {
        setSecondaryIndustriesLevel(json?.secondaryIndustriesLevel as Level);
      }

      setFirstLoaded(true);
    };
    fetchInitialData();
  }, []);

  const [statesFirstFetched, setStatesFirstFetched] = useState<boolean>(false);
  useEffect(() => {
    firstLoaded && applyFilters();
  }, [firstLoaded]);
  useEffect(() => {
    const countryIds: number[] = selectedCountry.map((item) => item.id);
    if (statesFirstFetched) {
      const newSelectedStates = selectedState.filter((item) => countryIds.includes(item.country_id));
      setSelectedState(newSelectedStates);
    }
    for (let i = 0; i < selectedCountry.length; i++) {
      const country = selectedCountry[i];
      asyncQueue.insert(
        "result-page-country-" + country.id,
        async () => {
          const response = await Service.get(`${apiConfig.countries}/${country.id}/states`, token as string);
          return response.data;
        },
        (error: any, response: any) => {
          if (!error) {
            countryStates[country.id] = response;
            const selectedStatesInitialDefine =
              !statesFirstFetched && Object.keys(countryStates).length === selectedCountry.length;
            // if (selectedStatesInitialDefine) {
            //   setStatesFirstFetched(true);
            // }
            manageStates(selectedStatesInitialDefine);
          }
        }
      );
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (statesFirstFetched) {
      setRefilter(!refilter);
    }
  }, [statesFirstFetched]);

  useEffect(() => {
    if (statesFirstFetched) {
      const json = getFilterJsonFromQuery();
      if (json?.selectedState?.length) {
        setSelectedState(states.filter((item) => json?.selectedState?.includes(item.id)));
      }
    }
  }, [statesFirstFetched, states]);

  const setSelectedFiltersState = () => {
    //place filters that are selected in search page
    const filters: any = {};
    if (selectedCountry) {
      selectedCountry.forEach((el) => {
        filters[countries.filter((country) => country.id === el.id)[0]?.name] = {
          state: (ids: number[]) =>
            setSelectedCountry(selectedCountry.filter((item: ICountry) => ids.includes(item.id))),
          data: countries,
          selected: selectedCountry.map((item) => item.id),
          type: "multi",
        };
      });
    }

    if (selectedState) {
      selectedState.forEach((el) => {
        filters[states.filter((state) => state.id === el.id)[0]?.name] = {
          state: (ids: number[]) => setSelectedState(selectedState.filter((item: IState) => ids.includes(item.id))),
          data: states,
          selected: selectedState.map((item) => item.id),
          type: "multi",
        };
      });
    }
    if (selectedPrimaryIndustry) {
      selectedPrimaryIndustry.forEach((el) => {
        const item = findNestedObjectByKey(primaryIndustriesLevel, el);

        filters[item?.name as string] = {
          state: setSelectedPrimaryIndustry,
          data: primaryIndustriesLevel,
          selected: selectedPrimaryIndustry,
          type: "nested",
        };
      });
    }

    if (selectedSecondaryIndustry) {
      selectedSecondaryIndustry.forEach((el) => {
        const item = findNestedObjectByKey(secondaryIndustriesLevel, el);
        filters[item?.name as string] = {
          state: setSelectedSecondaryIndustry,
          data: secondaryIndustriesLevel,
          selected: selectedSecondaryIndustry,
          type: "nested",
        };
      });
    }

    if (selectedOwnershipTypes.length) {
      selectedOwnershipTypes.forEach((el) => {
        filters[ownershipTypes.filter((ow) => ow.id === el)[0]?.name] = {
          state: setSelectedOwnershipTypes,
          data: ownershipTypes,
          selected: selectedOwnershipTypes,
          type: "multi",
        };
      });
    }

    if (selectedEmployeeNumberRanges.length) {
      selectedEmployeeNumberRanges.forEach((el) => {
        filters[employeeNumberRanges.filter((ow) => ow.id === el)[0]?.name] = {
          state: setSelectedEmployeeNumberRanges,
          data: employeeNumberRanges,
          selected: selectedEmployeeNumberRanges,
          type: "multi",
        };
      });
    }

    if (selectedRevenueNumberRanges.length) {
      selectedRevenueNumberRanges.forEach((el) => {
        filters[revenueNumberRanges.filter((ow) => ow.id === el)[0]?.name] = {
          state: setSelectedRevenueNumberRanges,
          data: revenueNumberRanges,
          selected: selectedRevenueNumberRanges,
          type: "multi",
        };
      });
    }
    if (selectedStoreNumberRanges.length) {
      selectedStoreNumberRanges.forEach((el) => {
        filters[storeNumberRanges.filter((ow) => ow.id === el)[0]?.name] = {
          state: setSelectedStoreNumberRanges,
          data: storeNumberRanges,
          selected: selectedStoreNumberRanges,
          type: "multi",
        };
      });
    }

    if (selectedOperationAreas.length) {
      selectedOperationAreas.forEach((el) => {
        filters[operationAreas.filter((oa) => oa.id === el)[0]?.name] = {
          state: setSelectedOperationAreas,
          data: operationAreas,
          selected: selectedOperationAreas,
          type: "multi",
          helper: "OPERATION AREA",
        };
      });
    }

    if (rtnMember) filters["RTN Member"] = { state: setRtnMember, type: "checkbox" };
    if (p2pMember) filters["P2P Member"] = { state: setP2pMember, type: "checkbox" };

    setSelectedFilters(filters);
  };

  useEffect(() => {
    firstLoaded && applyFilters();
  }, [refilter, page, resultLength]);
  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    page === 1 ? setRefilter(!refilter) : setPage(1);
  }, [letterFilter]);

  const manageStates = (selectedStatesInitialDefine: boolean) => {
    let statesArray: IState[] = [];
    const allSelectedCountryStates = selectedCountry
      .map((item) => countryStates[item.id])
      .filter((item) => item && item.length > 0);
    for (let i = 0; i < allSelectedCountryStates.length; i++) {
      if (i > 0) statesArray = statesArray.concat({ id: 0, country_id: 0, name: "" });
      statesArray = statesArray.concat(allSelectedCountryStates[i]);
    }
    statesArray = statesArray.filter((item, key) => {
      if (key === 0 && item && item.id === 0) return false;
      return !!item;
    });
    setStates(statesArray);
    const json = getFilterJsonFromQuery();
    if (selectedStatesInitialDefine && json?.selectedState?.length > 0) {
      setSelectedState(
        json.selectedState
          .map((item: number) => statesArray.find((item2) => item2.id === item))
          .filter((item: IState) => (item ? true : false))
      );
      setStatesFirstFetched(true);
      // setRefilter(!refilter);
    }
  };

  //clear all filters
  const clearAllFilter = () => {
    setSearchInput("");
    setSelectedCountry([]);
    setSelectedState([]);
    setIndustryCodes({ isic: "", naics: "", nace: "" });
    setSelectedPrimaryIndustry([]);
    setSelectedSecondaryIndustry([]);
    setSelectedOwnershipTypes([]);
    setSelectedEmployeeNumberRanges([]);
    setSelectedRevenueNumberRanges([]);
    setSelectedStoreNumberRanges([]);
    setSelectedOperationAreas([]);
    setRtnMember(false);
    setP2pMember(false);
    setLastEditDate([null, null]);
  };

  const searchAction = () => {
    setPage(1);
    applyFilters();
  };

  //apply selected filters and send request
  const applyFilters = async () => {
    const filterData: any = {};
    if (selectedCountry.length) {
      filterData.selectedCountry = selectedCountry.map((el) => el.id);
    }
    if (selectedState.length) {
      filterData.selectedState = selectedState.map((el) => el.id);
    }

    if (industryCodes.isic) {
      filterData.isic = industryCodes.isic;
    }
    if (industryCodes.naics) {
      filterData.naics = industryCodes.naics;
    }
    if (industryCodes.nace) {
      filterData.nace = industryCodes.nace;
    }

    if (selectedPrimaryIndustry.length) {
      filterData.selectedPrimaryIndustry = selectedPrimaryIndustry;
    }
    if (selectedSecondaryIndustry.length) {
      filterData.selectedSecondaryIndustry = selectedSecondaryIndustry;
    }
    if (selectedOwnershipTypes.length) {
      filterData.selectedOwnershipTypes = selectedOwnershipTypes;
    }
    if (selectedEmployeeNumberRanges.length) {
      filterData.selectedEmployeeNumberRanges = selectedEmployeeNumberRanges;
    }
    if (selectedRevenueNumberRanges.length) {
      filterData.selectedRevenueNumberRanges = selectedRevenueNumberRanges;
    }
    if (selectedStoreNumberRanges.length) {
      filterData.selectedStoreNumberRanges = selectedStoreNumberRanges;
    }
    if (selectedOperationAreas.length) {
      filterData.selectedOperationAreas = selectedOperationAreas;
    }
    if (lastEditDate[0] && lastEditDate[1]) {
      filterData.modified_at = lastEditDate;
    }
    if (rtnMember) {
      filterData.rtn_member = rtnMember ? 1 : 0;
    }
    if (p2pMember) {
      filterData.p2pi_member = p2pMember ? 1 : 0;
    }
    if (searchInput) {
      filterData.searchInput = searchInput;
    }
    let letters = "";
    letterFilter.forEach((letter: string) => (letters = letters + letter));
    if (letters.length > 0) {
      filterData.letters = letters;
    }
    filterData.page = page;
    filterData.resultLength = resultLength;
    filterData.primaryIndustriesLevel = selectedPrimaryIndustry;
    filterData.secondaryIndustriesLevel = selectedSecondaryIndustry;

    if (showActiveProfiles || showArchivedProfiles) {
      const status = [];
      if (showActiveProfiles) {
        status.push("active");
      }
      if (showArchivedProfiles) {
        status.push("archived");
      }
      filterData.status = status.join(",");
    }

    setSelectedFiltersState();

    navigate(`/result?json=${JSON.stringify(filterData)}`, {
      state: {
        history: `/search`,
      },
    });
  };

  const removeFilterChip = (filter: string) => {
    if (selectedFilters[filter].type === "single") {
      selectedFilters[filter].state("");
    } else if (selectedFilters[filter].type === "checkbox") {
      selectedFilters[filter].state(false);
    } else if (selectedFilters[filter].type === "nested") {
      const ids: any = [];
      const item = findNestedObjectByName(selectedFilters[filter].data, filter);
      ids.push(item?.id);
      selectedFilters[filter].state([...selectedFilters[filter].selected.filter((el: any) => el !== ids[0])]);
    } else {
      const ids: any = [];
      selectedFilters[filter].data.forEach((el: any) => {
        if (el.name === filter) ids.push(el.id);
      });
      selectedFilters[filter].state([...selectedFilters[filter].selected.filter((el: any) => el !== ids[0])]);
    }
    setRefilter(!refilter);
  };

  const findNestedObjectByKey = (object: Level, keyToMatch: string | number): ParentItem | null => {
    const items = object?.items;
    const entries = Object.entries(items);

    if (entries.length > 0) {
      for (let i = 0; i < entries.length; i++) {
        const [objectKey, objectValue] = entries[i];

        if (objectKey === keyToMatch) {
          return items[keyToMatch];
        }

        if (objectValue.children) {
          const child = findNestedObjectByKey(objectValue?.children, keyToMatch);

          if (child) {
            return child;
          }
        }
      }
    }
    return null;
  };

  const findNestedObjectByName = (object: Level, name: string): ParentItem | null => {
    const items = object?.items;
    const entries = Object.entries(items);

    if (entries.length > 0) {
      for (let i = 0; i < entries.length; i++) {
        const [objectKey, objectValue] = entries[i];

        if (objectValue?.name === name) {
          return items[objectKey];
        }

        if (objectValue.children) {
          const child = findNestedObjectByName(objectValue?.children, name);

          if (child) {
            return child;
          }
        }
      }
    }

    return null;
  };

  const togggleLetterFilter = (letter: string) => {
    if (letterFilter.indexOf(letter) !== -1) {
      setLetterFilter(letterFilter.filter((el) => el !== letter));
    } else {
      setLetterFilter([...letterFilter, letter]);
    }
  };

  const letter_company_object: ILetterFilter = {};
  companies?.forEach((company: ISearchCompany) => {
    const first_letter: string | undefined = company.name.charAt(0).toLocaleUpperCase();
    if (first_letter) {
      if (!letter_company_object[first_letter]) {
        letter_company_object[first_letter] = [];
      }
      letter_company_object[first_letter]?.push(company);
    }
  });

  const handleChangePage = (event: any, page: number, resultLength?: number) => {
    if (resultLength) setResultLength(resultLength);
    setPage(page);
  };

  const handlePrimaryIndustrySelect = (selected: (string | number)[] | string | number, level: Level) => {
    setSelectedPrimaryIndustry(selected as number[]);
    setPrimaryIndustriesLevel(level);
  };

  const handleSecondaryIndustrySelect = (selected: (string | number)[] | string | number, level: Level) => {
    setSelectedSecondaryIndustry(selected as number[]);
    setSecondaryIndustriesLevel(level);
  };
  useEffect(() => {
    if (firstLoaded) {
      const filterQuery = getFilterQuery(true);
      Service.get(`${apiConfig.search}${filterQuery}`, token as string)
        .then((response: any) => response.data)
        .then((data: any) => {
          setCompanies(data?.response?.data);

          setTotalCount(data?.response?.meta?.totalCompanyNumber);
          setFilteredCount(data?.response?.meta?.filteredCompanyNumber);

          setTotalPageNumber(data?.response?.meta?.pageCount);
          setResultExistLetters(data?.response?.meta?.existLetters);
        });
    }
  }, [location.search, firstLoaded]);

  const getFilterQuery = (paginated: boolean) => {
    const json = getFilterJsonFromQuery();
    const filter = [];
    const searchInput = json?.searchInput ? json?.searchInput : "";
    if (json?.selectedCountry?.length)
      filter.push({
        or: json?.selectedCountry?.map((el: number) => ({ hq_country_id: el })),
      });

    if (json?.selectedState?.length)
      filter.push({
        or: json?.selectedState?.map((el: number) => ({ hq_state_id: el })),
      });

    if (json?.selectedOwnershipTypes?.length)
      filter.push({
        or: json?.selectedOwnershipTypes?.map((el: number) => ({ ownership_type_id: el })),
      });
    if (json?.selectedEmployeeNumberRanges?.length)
      filter.push({
        or: json?.selectedEmployeeNumberRanges?.map((el: number) => ({
          employee_num_range_id: el,
        })),
      });
    if (json?.selectedRevenueNumberRanges?.length)
      filter.push({
        or: json?.selectedRevenueNumberRanges?.map((el: number) => ({ revenue_range_id: el })),
      });
    if (json?.selectedStoreNumberRanges?.length)
      filter.push({
        or: json?.selectedStoreNumberRanges?.map((el: number) => ({ store_num_range_id: el })),
      });
    if (json?.selectedOperationAreas?.length)
      filter.push({
        or: json?.selectedOperationAreas.map((el: number) => ({ hq_country_id: el })),
      });

    if (json?.modified_at && Array.isArray(json?.modified_at))
      filter.push({
        modified_at: {
          ge: new Date(json?.modified_at[0])?.toISOString().replace("T", " ").substring(0, 19),
          le: new Date(json?.modified_at[1])?.toISOString().replace("T", " ").substring(0, 19),
        },
      });
    if (json?.rtn_member !== undefined) filter.push({ rtn_member: json.rtn_member ? 1 : 0 });
    if (json?.p2pi_member !== undefined) filter.push({ p2pi_member: json.p2pi_member ? 1 : 0 });
    let letters = "";
    if (json?.letters) {
      letters = json?.letters;
    }

    const isic = json?.isic ?? null;
    const naics = json?.naics ?? null;
    const nace = json?.nace ?? null;

    const primary_industries = json?.selectedPrimaryIndustry?.length ? json?.selectedPrimaryIndustry : null;
    const secondary_industries = json?.selectedSecondaryIndustry?.length ? json?.selectedSecondaryIndustry : null;

    const page = json?.page ? json?.page : 1;
    const resultLength = json?.resultLength ? json?.resultLength : 10;

    let params: any = {
      ...(searchInput && { search: searchInput }),
      ...(filter.length > 0 && {
        filter: buildQuery({ filter }).substring(9),
      }),
      ...(letters && { letters: letters }),
      ...(isic && { isic: isic }),
      ...(naics && { naics: naics }),
      ...(nace && { nace: nace }),
      ...(primary_industries && { primary_industries }),
      ...(secondary_industries && { secondary_industries }),
      status: json.status,
    };

    if (paginated) {
      params = {
        ...params,
        ...(page && { page: page }),
        ...{
          count: resultLength,
        },
      };
    }

    return `?${Object.keys(params)
      .map((item) => `${item}=${params[item]}`)
      .join("&")}`;
  };

  const getFilterJsonFromQuery = () => {
    const params = new URLSearchParams(location.search);

    let json: any = {};
    try {
      json = JSON.parse(params.get("json") ?? "");
    } catch (e) {
      console.error(e);
    }

    return json;

    const searchQueryString: string = location.search.startsWith("?")
      ? location.search.substr(6, location.search.length)
      : "";
    // let json: any;
    try {
      json = JSON.parse(decodeURI(searchQueryString));
    } catch (err) {
      json = {};
    }
    return json;
  };

  const handleExcelExportButtonClick = async () => {
    const filterQuery = getFilterQuery(false);
    await Service.get(`${apiConfig.search}${filterQuery}`, token as string)
      .then((response: any) => response.data)
      .then((data: any) => {
        excelExport.export("companies", data?.response?.data);
      });
  };

  return (
    <div id="results-page-container" className={classes.container}>
      <div className={`${classes.backButtonDiv} no-print`}>
        <button className={classes.backButton} onClick={() => navigate(data.state?.history || "/search")}>
          <i className={classes.leftArrow} />
          Back
        </button>
      </div>
      <div className={`${classes.filters} no-print`}>
        <div style={{ padding: 30 }}>
          <div style={{ display: "block" }}>
            <span className={classes.filtersText}>Filter</span>
            <span className={classes.countText}>
              Showing {filteredCount} results <span className={classes.totalCountText}>(Out of {totalCount})</span>
            </span>
          </div>
          <div className={classes.clearFiltersDiv}>
            <ClearFilters text="Clear all filters" clear={clearAllFilter} />
          </div>
          <div className={classes.searchInputDiv}>
            <ResultsSearch
              label="Search Database"
              value={searchInput}
              setValue={(value: string) => setSearchInput(value)}
              search={searchAction}
            />
          </div>
        </div>
        <div className={classes.rectangle}></div>
        <div>
          <ResultsAccordion
            title="Industry Codes"
            type=""
            number={0}
            clear={() => console.log("clear-industry-codes-input")}
          >
            <TextField
              id="isic-code-filter"
              label="ISIC Code"
              name="isic"
              placeholder="Enter ISIC Code(s)"
              value={industryCodes.isic}
              onChange={onIndustryCodeChange}
              variant="standard"
            />
            <TextField
              id="naics-code-filter"
              label="NAICS Code"
              name="naics"
              placeholder="Enter NAICS Code(s)"
              value={industryCodes.naics}
              onChange={onIndustryCodeChange}
              variant="standard"
            />
            <TextField
              id="nace-code-filter"
              name="nace"
              label="NACE Code"
              placeholder="Enter NACE Code(s)"
              value={industryCodes.nace}
              onChange={onIndustryCodeChange}
              variant="standard"
            />
          </ResultsAccordion>
          <ResultsAccordion
            number={selectedPrimaryIndustry?.length}
            title="Primary Industry"
            clear={() => setSelectedPrimaryIndustry([])}
            type="nested"
          >
            <MultiLevelFilter
              data={primaryIndustriesLevel}
              selected={selectedPrimaryIndustry}
              setSelected={handlePrimaryIndustrySelect}
              title="Primary Industry"
            />
          </ResultsAccordion>

          <ResultsAccordion
            number={selectedSecondaryIndustry?.length}
            title="Secondary Industry"
            clear={() => setSelectedSecondaryIndustry([])}
            type="multi"
          >
            <MultiLevelFilter
              data={secondaryIndustriesLevel}
              selected={selectedSecondaryIndustry}
              setSelected={handleSecondaryIndustrySelect}
              title="Secondary Industry"
            />
          </ResultsAccordion>

          <ResultsAccordion
            number={selectedCountry?.length}
            title="HQ Country"
            clear={() => setSelectedCountry([])}
            type="multi"
          >
            <MultiSelect
              title="HQ Country"
              items={countries}
              selected={selectedCountry.map((item) => item.id)}
              setSelected={(values: string[] | number[]) => {
                const values2: number[] = values.map((item) => parseInt(item + ""));
                const newSelectedCountries: ICountry[] = countries.filter(
                  (item) => item.id && values2.includes(item.id)
                );
                setSelectedCountry(newSelectedCountries);
              }}
              stringify={(item: ICountry) => item.name}
              value={(item: ICountry) => item.id}
            />
          </ResultsAccordion>

          <ResultsAccordion
            number={selectedState?.length}
            title="HQ State/Province"
            clear={() => setSelectedState([])}
            type="multi"
          >
            <MultiSelect
              title="HQ State/Province"
              items={states}
              selected={selectedState.map((item) => item.id)}
              setSelected={(values: string[] | number[]) => {
                const values2: number[] = values.map((item) => parseInt(item + ""));
                const selectedStates: IState[] = states.filter((item) => item.id && values2.includes(item.id));
                setSelectedState(selectedStates);
              }}
              stringify={(item: IState) => item.name}
              value={(item: IState) => item.id}
            />
          </ResultsAccordion>

          <ResultsAccordion
            number={selectedOwnershipTypes?.length}
            title="Ownership Type"
            clear={() => setSelectedOwnershipTypes([])}
            type="multi"
          >
            <MultiSelect
              title="Ownership Type"
              items={ownershipTypes}
              stringify={(item: ISelectItem) => item.name}
              value={(item: ISelectItem) => item.id}
              selected={selectedOwnershipTypes}
              setSelected={(values: string[] | number[]) => setSelectedOwnershipTypes(values as number[])}
            />
          </ResultsAccordion>

          <ResultsAccordion
            number={selectedEmployeeNumberRanges?.length}
            title="Number of Employee Range"
            clear={() => setSelectedEmployeeNumberRanges([])}
            type="multi"
          >
            <MultiSelect
              title="Number of Employee Range"
              items={employeeNumberRanges}
              stringify={(item: IRangeItem) => item.name}
              value={(item: IRangeItem) => item.id}
              selected={selectedEmployeeNumberRanges}
              setSelected={(values: string[] | number[]) => setSelectedEmployeeNumberRanges(values as number[])}
            />
          </ResultsAccordion>

          <ResultsAccordion
            number={selectedRevenueNumberRanges?.length}
            title="Number of Revenue Range"
            clear={() => setSelectedRevenueNumberRanges([])}
            type="multi"
          >
            <MultiSelect
              title="Number of Revenue Range"
              items={revenueNumberRanges}
              stringify={(item: IRangeItem) => item.name}
              value={(item: IRangeItem) => item.id}
              selected={selectedRevenueNumberRanges}
              setSelected={(values: string[] | number[]) => setSelectedRevenueNumberRanges(values as number[])}
            />
          </ResultsAccordion>

          <ResultsAccordion
            number={selectedRevenueNumberRanges?.length}
            title="Number of Store Range"
            clear={() => setSelectedStoreNumberRanges([])}
            type="multi"
          >
            <MultiSelect
              title="Number of Store Range"
              items={storeNumberRanges}
              stringify={(item: IRangeItem) => item.name}
              value={(item: IRangeItem) => item.id}
              selected={selectedStoreNumberRanges}
              setSelected={(values: string[] | number[]) => setSelectedStoreNumberRanges(values as number[])}
            />
          </ResultsAccordion>

          <ResultsAccordion
            number={selectedOperationAreas?.length}
            title="Area of Operations"
            clear={() => setSelectedOperationAreas([])}
            type="multi"
          >
            <MultiSelect
              title="Area of Operations"
              items={operationAreas}
              stringify={(item: ISelectItem) => item.name}
              value={(item: ISelectItem) => item.id}
              selected={selectedOperationAreas}
              setSelected={(values: string[] | number[]) => setSelectedOperationAreas(values as number[])}
            />
          </ResultsAccordion>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 10,
            }}
          >
            <CheckboxSelect
              checkboxLabel="RTN Member"
              checked={rtnMember}
              setChecked={(checked: boolean) => setRtnMember(checked)}
              labelStyling={{
                marginLeft: -3,
                color: "#4d748d",
                fontFamily: "Montserrat, sans-serif",
                fontSize: 12,
                fontWeight: rtnMember ? "bold" : 500,
              }}
            />
            <CheckboxSelect
              checkboxLabel="P2P Member"
              checked={p2pMember}
              setChecked={(checked: boolean) => setP2pMember(checked)}
              labelStyling={{
                marginLeft: -3,
                color: "#4d748d",
                fontFamily: "Montserrat, sans-serif",
                fontSize: 12,
                fontWeight: p2pMember ? "bold" : 500,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <CheckboxSelect
              checkboxLabel="Include active profiles"
              checked={showActiveProfiles}
              setChecked={(checked: boolean) => setShowActiveProfiles(checked)}
              labelStyling={{
                marginLeft: -3,
                color: "#4d748d",
                fontFamily: "Montserrat, sans-serif",
                fontSize: 12,
                fontWeight: showActiveProfiles ? "bold" : 500,
              }}
            />{" "}
            <CheckboxSelect
              checkboxLabel="Include archived profiles"
              checked={showArchivedProfiles}
              setChecked={(checked: boolean) => setShowArchivedProfiles(checked)}
              labelStyling={{
                marginLeft: -3,
                color: "#4d748d",
                fontFamily: "Montserrat, sans-serif",
                fontSize: 12,
                fontWeight: showArchivedProfiles ? "bold" : 500,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <DatePickerComponent
              title="Last Edit Date"
              dates={lastEditDate}
              setDateValue={(value: Date[]) => setLastEditDate(value)}
            />
          </div>
        </div>
        <div className={classes.buttons}>
          <ClearFilters text="Clear all filters" clear={clearAllFilter} />
          <span style={{ marginLeft: 10 }}></span>
          <ApplyButton text="Apply" apply={searchAction} />
        </div>
      </div>
      <div id="results-page-list" className={classes.results}>
        <div style={{ display: "flex" }}>
          <span className={classes.resultsText}>
            Results{" "}
            <span className={classes.resultsCountText}>
              {`${currentPageFirstIndex}-${currentPageLastIndex} of ${filteredCount} ${
                filteredCount === 1 ? "company" : "companies"
              }`}
            </span>
          </span>
          <div className="no-print">
            <DropdownMenu title="Export Results" icon={exportIcon}>
              <div className="no-print">
                <button className={classes.dropdownButton} onClick={handleExcelExportButtonClick}>
                  Export to Excel
                </button>
                <button className={classes.dropdownButton} onClick={() => window.print()}>
                  Export to PDF
                </button>
              </div>
            </DropdownMenu>
          </div>
        </div>
        <div style={{ marginTop: 20 }} className="no-print">
          {Object.keys(selectedFilters).map((filter: string, index: number) => (
            <div className={classes.selectedFilter} key={index}>
              {filter.toUpperCase()} {selectedFilters[filter]?.helper ? `(${selectedFilters[filter]?.helper})` : null}
              <span
                style={{ marginLeft: 10, marginTop: -3, cursor: "pointer" }}
                onClick={() => removeFilterChip(filter)}
              >
                x
              </span>
            </div>
          ))}
        </div>
        <div style={{ marginTop: 30 }} className="no-print">
          <span className={classes.refineText}>Refine:</span>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {alphabet1.map((letter: string, index: number) => (
              <div
                className={`${classes.letterShape}${
                  letterFilter.indexOf(letter) !== -1 ? ` ${classes.selectedLetter}` : ""
                }${
                  resultExistLetters.map((item) => item.toLocaleLowerCase()).indexOf(letter.toLowerCase()) === -1 &&
                  letterFilter.indexOf(letter) === -1
                    ? ` ${classes.letterDisabled}`
                    : ""
                }`}
                key={index}
                onClick={() =>
                  (resultExistLetters.map((item) => item.toLocaleLowerCase()).indexOf(letter.toLowerCase()) !== -1 ||
                    letterFilter.indexOf(letter) !== -1) &&
                  togggleLetterFilter(letter)
                }
              >
                <p style={{ marginTop: 3 }}>{letter}</p>
              </div>
            ))}
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {alphabet2.map((letter: string, index: number) => (
              <div
                className={`${classes.letterShape}${
                  letterFilter.indexOf(letter) !== -1 ? ` ${classes.selectedLetter}` : ""
                }${
                  resultExistLetters.map((item) => item.toLocaleLowerCase()).indexOf(letter.toLowerCase()) === -1 &&
                  letterFilter.indexOf(letter) === -1
                    ? ` ${classes.letterDisabled}`
                    : ""
                }`}
                key={index}
                onClick={() =>
                  (resultExistLetters.map((item) => item.toLocaleLowerCase()).indexOf(letter.toLowerCase()) !== -1 ||
                    letterFilter.indexOf(letter) !== -1) &&
                  togggleLetterFilter(letter)
                }
              >
                <p style={{ marginTop: 3 }}>{letter}</p>
              </div>
            ))}
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          {Object.keys(letter_company_object).map((letter: string, index: number) => (
            <div key={index} style={{ marginTop: 35 }}>
              <span className={classes.companyLetter}>{letter.toLocaleUpperCase()}</span>
              <div className={classes.line}></div>
              {letter_company_object[letter].map((company: ISearchCompany, index: number) => (
                <div key={index}>
                  <CompanyRow company={company} />
                  <div className={classes.divider}></div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className={`${classes.paginationContainer} no-print`}>
          <Pager
            page={page}
            totalPageNumber={totalPageNumber}
            handleChangePage={handleChangePage}
            resultLength={resultLength}
          />
        </div>
      </div>
    </div>
  );
};
