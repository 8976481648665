function buildApiConfig(): Record<string, string> {
  const isLocal = process.env.REACT_APP_MOCK_API === "true";

  if (isLocal) {
    const dataExtractorURL = process.env.REACT_APP_DATA_EXTRACTOR_URL;
    const dataManagerURL = process.env.REACT_APP_DATA_MANAGER_URL;

    return {
      //filters
      search: `${dataExtractorURL}/search`,
      companiesDataExtractor: `${dataExtractorURL}/companies`,
      countries: `${dataExtractorURL}/countries`,
      cities: `${dataExtractorURL}/cities`,
      industries: `${dataExtractorURL}/api/v1/industries`,
      // naceCodes: `${dataExtractorURL}/nace_codes`,
      // naicsCodes: `${dataExtractorURL}/naics_codes`,
      // isicCodes: `${dataExtractorURL}/isic_codes`,
      employeeNumberRanges: `${dataExtractorURL}/employee-number-ranges`,
      revenueNumberRanges: `${dataExtractorURL}/revenue-number-ranges`,
      storeNumberRanges: `${dataExtractorURL}/store-number-ranges`,
      ownershipTypes: `${dataExtractorURL}/ownership-types`,
      relationshipTypes: `${dataExtractorURL}/relationship-types`,
      // Data Manager APIs
      companyCreation: `${dataManagerURL}/company_creation_requests`,
      companiesDataManager: `${dataManagerURL}/companies`,
      validate: `${dataManagerURL}/validate`,
      users: `${dataManagerURL}/users`,
      userRoles: `${dataManagerURL}/user-roles`,
      me: `${dataManagerURL}/me`,
      titles: `${dataManagerURL}/titles`,
      storeTypes: `${dataManagerURL}/store_types`,
      storeSelectedItems: `${dataManagerURL}/store_selected_items`,
      // V1
      v1: `${dataManagerURL}/api/v1`,
      companiesV1: `${dataExtractorURL}/api/v1/companies`,
      companiesPrimaryIndustriesAll: `${dataExtractorURL}/api/v1/company-primary-industries`,
      companiesSecondaryIndustriesAll: `${dataExtractorURL}/api/v1/company-secondary-industries`,
      companyRefreshDataSources: `${dataManagerURL}/api/v1/companies`,
      admin: `${dataManagerURL}/api/v1/admin`,
      // Others
      taxonomyDBMarketSegments: `${dataManagerURL}/api/taxonomy-database/taxonomy/market-segments`,
    };
  }

  const baseURL = `${process.env.REACT_APP_API_URL}/api/v1`;
  return {
    companies: `${baseURL}/companies`,
    countries: `${baseURL}/countries`,
    cities: `${baseURL}/cities`,
    industries: `${baseURL}/industries`,
    // naceCodes: `${baseURL}/nace_codes`,
    // naicsCodes: `${baseURL}/naics_codes`,
    // isicCodes: `${baseURL}/isic_codes`,
    employeeNumberRanges: `${baseURL}/employee-number-ranges`,
    revenueNumberRanges: `${baseURL}/revenue-number-ranges`,
    storeNumberRanges: `${baseURL}/store-number-ranges`,
    ownershipTypes: `${baseURL}/ownership-types`,
    relationshipTypes: `${baseURL}/relationship-types`,
    companyCreation: `${baseURL}/company_creation_requests`,
    validate: `${baseURL}/validate`,
    users: `${baseURL}/users`,
    userRoles: `${baseURL}/user-roles`,
    me: `${baseURL}/me`,
    titles: `${baseURL}/titles`,
    storeTypes: `${baseURL}/store_types`,
    storeSelectedItems: `${baseURL}/edit_company_store_show_types`,
    taxonomyDBMarketSegments: `${baseURL}/taxonomy/market-segments`,
    // V1
    v1: `${baseURL}/api/v1`,
    companiesV1: `${baseURL}/companies`,
    companiesPrimaryIndustriesAll: `${baseURL}/api/v1/companies-primary-industries-all`,
    companiesSecondaryIndustriesAll: `${baseURL}/api/v1/companies-secondary-industries-all`,
    companyRefreshDataSources: `${baseURL}/api/v1/companies`,
    admin: `${baseURL}/api/v1/admin`,
  };
}

export const apiConfig = buildApiConfig();
