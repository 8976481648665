import { makeStyles } from "@material-ui/core";
import { FC, MouseEventHandler, ReactNode } from "react";
import SearchIcon from "../../assets/searchIcon.png";
const useStyles = makeStyles(() => ({
  title_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "32px",
    lineHeight: "34px",
    fontFamily: "Oswald",
    color: "#333",
    marginBottom: "20px",
  },
  container: {
    width: "853px",
    marginBottom: "30px",
    backgroundColor: "#fff",
    border: "1px solid #E1E1E1",
    padding: "42px 30px 45px 30px",
  },
  dropdown_icon: {
    height: "30px",
    width: "30px",
    cursor: "pointer",
  },
  createButton: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: "12px",
    textAlign: "center",
    height: "38px",
    width: "136px",
    border: "1px solid #4D748D",
    borderRadius: "3px",
    backgroundColor: "#FFFFFF",
    float: "right",
    cursor: "pointer",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  searchBox: {
    width: "235px",
    height: "38px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background: "#FFFFFF",
    border: "1px solid #E1E1E1",
  },
  searchInput: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    height: "14px",
    marginTop: "12px",
    marginLeft: "15px",
    border: "none",
    width: "170px",
  },
  searchIcon: {
    width: "30px",
    height: "30px",
    marginTop: "4px",
  },
}));

interface Props {
  children: ReactNode;
  title: string;
  style?: Record<string, unknown>;
  onClickButton: MouseEventHandler;
  searchOnChange: (keyword: string) => void;
}

export const UsersListSectionCard: FC<Props> = ({ children, title, style, onClickButton, searchOnChange }) => {
  const classes = useStyles();

  return (
    <div style={style} className={classes.container}>
      <div className={classes.title_container}>
        <span className={classes.title}>{title}</span>
        <button className={classes.createButton} onClick={onClickButton}>
          Add Permission
        </button>
      </div>
      <div className={classes.searchContainer}>
        <div className={classes.searchBox}>
          <input
            type="text"
            placeholder="Search name, last name"
            className={classes.searchInput}
            onChange={(e) => searchOnChange(e.target.value)}
          />
          <img src={SearchIcon} className={classes.searchIcon} />
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};
