import { FC } from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

import { useAuth } from "../shared/hooks/auth-hook";
import profile from "../assets/profile.svg";

const useStyles = makeStyles(() => ({
  dropdown: {
    position: "relative",
    display: "inline-block",
    "&:hover $dropdownContent": {
      display: "block",
    },
  },
  dropdownContent: {
    display: "none",
    position: "absolute",
    zIndex: 1,
    width: "160px",
    marginLeft: "-10%",
    paddingTop: "10px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  image: {
    width: "24px",
    height: "24px",
  },
  title: {
    color: "#666666",
    fontFamily: "Oswald",
    fontSize: "12px",
    fontWeight: 600,
    marginLeft: "10px",
  },
  titleDiv: {
    display: "flex",
    placeItems: "center",
    marginTop: "5px",
  },
}));

const StyledMenuLink = styled(Link)(({ theme }) => ({
  width: "100%",
  padding: "13px 15px",
  color: "#333333",
  fontFamily: "Montserrat",
  fontSize: "12px",
  lineHeight: "14px",
  textDecoration: "none",
}));

export const ManageUsersMenu: FC = () => {
  const classes = useStyles();
  const { hasPermission } = useAuth();

  return (
    <div className={classes.dropdown}>
      <div className={classes.titleDiv}>
        <img className={classes.image} src={profile} alt="profile" />
        <span className={classes.title}>MANAGE USERS</span>
      </div>
      <div className={classes.dropdownContent}>
        {(hasPermission("CompanyCreate.ReadWrite.asUser") || hasPermission("CompanyCreate.ReadWrite.All")) && (
          <MenuItem sx={{ p: 0 }}>
            <StyledMenuLink to="/request">User requests</StyledMenuLink>
          </MenuItem>
        )}

        {(hasPermission("UserRoles.ReadWrite.Editor") || hasPermission("UserRoles.ReadWrite.All")) && (
          <MenuItem sx={{ p: 0 }}>
            <StyledMenuLink to="/manage-users">Manage users</StyledMenuLink>
          </MenuItem>
        )}

        {(hasPermission("UserRoles.ReadWrite.Editor") || hasPermission("UserRoles.ReadWrite.All")) && (
          <MenuItem sx={{ p: 0 }}>
            <StyledMenuLink to="/company-updates">Company updates</StyledMenuLink>
          </MenuItem>
        )}
      </div>
    </div>
  );
};
