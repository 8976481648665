import { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { List } from "./level";
import { Level, ParentItem } from "../../interfaces";

interface Props {
  level: Level;
  onClick: (item: ParentItem, justShowList?: boolean) => void;
  selectedList: (string | number)[];
  semiSelectedList: (string | number)[];
  singleItem: string | number;
  parentItems?: (string | number)[];
}

const useStyles = makeStyles(() => ({
  box: {
    display: "flex",
    flexDirection: "row",
  },
}));

export const Tree: FC<Props> = ({ level, onClick, selectedList, semiSelectedList, singleItem, parentItems = [] }) => {
  const classes = useStyles();
  const [parentId, setParentId] = useState<string | number | null>(null);

  const handleClick = (item: ParentItem, justShowList = false) => {
    setParentId(item?.id);
    onClick(item, justShowList);
  };

  return (
    <div className={classes.box}>
      <List
        selectedList={selectedList}
        level={level}
        open={parentId}
        onClick={handleClick}
        semiSelectedList={semiSelectedList}
        singleItem={singleItem}
        parentItems={parentItems}
      />
      {parentId && (
        <Tree
          selectedList={selectedList}
          level={level?.items[parentId]?.children as Level}
          onClick={onClick}
          semiSelectedList={semiSelectedList}
          singleItem={singleItem}
          parentItems={parentItems}
        />
      )}
    </div>
  );
};
