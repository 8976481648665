import { makeStyles } from "@material-ui/core";
import { TextField } from "@mui/material";
import { useState, ChangeEvent, useEffect } from "react";
import { FC } from "react";

import closeIcon from "../../../assets/close-circle.png";
import { ButtonApply } from "./buttonApply";
import { ButtonCancel } from "./buttonCancel";
import {
  InsightEditFormData,
  InsightEditFormErrors,
  FormValidateHandler,
  FormCancelHandler,
  FormSaveHandler,
} from "./insight.model";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: theme.spacing(2, 4, 3),
    width: "800px",
  },
  dialogTopBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogTitle: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "32px",
    letterSpacing: 0,
    lineHeight: "34px",
  },
  closeIcon: {
    marginLeft: "10px",
    padding: "6px",
    border: "none",
    background: "transparent",
    cursor: "pointer",
  },
  dialogContent: {
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
  },
  formGroup: {
    marginBottom: "20px",
  },
  dialogActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "15px",
  },
}));

interface Props {
  validate: FormValidateHandler<InsightEditFormData>;
  data: InsightEditFormData | null;
  onCancel: FormCancelHandler;
  onSave: FormSaveHandler<InsightEditFormData>;
}

export const InsightEditDialog: FC<Props> = ({ validate, data, onCancel, onSave }) => {
  const [validatedOnce, setValidatedOnce] = useState<boolean>(false);
  const [errors, setErrors] = useState<InsightEditFormErrors>(null);
  const [formData, setFormData] = useState<InsightEditFormData>({
    id: data ? data.id : Date.now(),
    type: data ? data.type : "",
    value: data ? data.value : "",
  });

  useEffect(() => {
    if (validatedOnce) {
      const errors = validate(formData);
      setErrors(errors);
    }
  }, [validatedOnce, formData, validate]);

  const handleChange = (field: keyof InsightEditFormData) => (e: ChangeEvent<HTMLTextAreaElement>) => {
    setFormData((prevState) => ({ ...prevState, [field]: e.target.value }));
  };

  const handleApply = () => {
    if (!validatedOnce) {
      setValidatedOnce(true);
    }

    const errors = validate(formData);
    setErrors(errors);

    if (!errors) {
      onSave(formData);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const classes = useStyles();

  return (
    <div className={classes.dialogContainer}>
      <div className={classes.dialogTopBar}>
        <div className={classes.dialogTitle}>{data ? `Edit ${data.type} Insight` : "Add New Insight"}</div>
        <button onClick={onCancel} className={classes.closeIcon}>
          <img src={closeIcon} width="24" height="24" alt="Close" />
        </button>
      </div>
      <div className={classes.dialogContent}>
        <div>
          <div className={classes.formGroup}>
            <TextField
              id="insight-section-name-field"
              label="Insight name"
              variant="standard"
              onChange={handleChange("type")}
              value={formData.type}
              error={Boolean(errors && errors.type && errors.type[0])}
              helperText={errors && errors.type && errors.type[0] ? errors.type[0] : null}
            />
          </div>
          <div className={classes.formGroup}>
            <TextField
              id="insight-section-value-field"
              label="Insight description"
              multiline
              fullWidth
              minRows={10}
              maxRows={20}
              onChange={handleChange("value")}
              value={formData.value}
              error={Boolean(errors && errors.value && errors.value[0])}
              helperText={errors && errors.value && errors.value[0] ? errors.value[0] : null}
            />
          </div>
        </div>
      </div>
      <div className={classes.dialogActions}>
        <ButtonCancel onClick={handleCancel}>Cancel</ButtonCancel>
        <ButtonApply onClick={handleApply}>Apply</ButtonApply>
      </div>
    </div>
  );
};
