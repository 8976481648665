import { FC } from "react";
import ProgressBar from "react-customizable-progressbar";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    left: "50%",
    paddingBottom: "10px",
    textAlign: "center",
  },
  value: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "22px",
    fontWeight: 500,
    letterSpacing: "0",
    lineHeight: "24px",
    marginBottom: "2px",
  },
  completed: {
    color: "#7D86A9",
    fontFamily: "Roboto",
    fontSize: "10px",
    letterSpacing: "0",
    lineHeight: "11px",
    textAlign: "center",
  },
}));

interface Props {
  data: any;
}

const CircularProgress: FC<Props> = ({ data }) => {
  const classes = useStyles();

  return (
    <div style={{ padding: 0 }}>
      <ProgressBar
        progress={data}
        radius={55}
        strokeWidth={4}
        strokeColor="#4D748D"
        trackStrokeWidth={4}
        pointerRadius={2}
        pointerStrokeWidth={4}
        pointerStrokeColor="#4D748D"
      >
        <div className={classes.container}>
          <div className={classes.value}>{data}%</div>
          <div className={classes.completed}>Completed</div>
        </div>
      </ProgressBar>
    </div>
  );
};

export default CircularProgress;
