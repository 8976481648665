import { makeStyles } from "@material-ui/core";
import { FC, ReactNode } from "react";

const useStyles = makeStyles(() => ({
  title_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "32px",
    lineHeight: "34px",
    fontFamily: "Oswald",
    color: "#333",
    marginBottom: "20px",
  },
  container: {
    width: "853px",
    marginBottom: "30px",
    backgroundColor: "#fff",
    border: "1px solid #E1E1E1",
    padding: "42px 30px 45px 30px",
  },
  dropdown_icon: {
    height: "30px",
    width: "30px",
    cursor: "pointer",
  },
}));

interface Props {
  children: ReactNode;
  title: string;
  style?: Record<string, unknown>;
  className?: string;
}

export const SectionCard: FC<Props> = ({ children, title, style, className }) => {
  const classes = useStyles();

  return (
    <div style={style} className={`${classes.container} ${className ? ` ${className}` : ""}`}>
      <div className={classes.title_container}>
        <span className={classes.title}>{title}</span>
      </div>
      <div>{children}</div>
    </div>
  );
};
