import { FC, MouseEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ParentItem } from "../../interfaces";
import { Level } from "../../interfaces";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

interface Props {
  level: Level;
  onClick: (item: ParentItem, justShowList?: boolean) => void;
  open: string | number | null;
  selectedList: (string | number)[];
  semiSelectedList: (string | number)[];
  singleItem: string | number;
  parentItems?: (string | number)[];
}

const useStyles = makeStyles(() => ({
  list: {
    boxSizing: "border-box",
    width: "270px",
    minWidth: "270px",
    maxHeight: "300px",
    border: "1px solid #E1E1E1",
    borderRadius: "4px 0 0 4px",
    backgroundColor: "#FFFFFF",
    overflowX: "auto",
  },
  item: {
    display: "flex",
    flexDirection: "row",
  },
  label: {
    "& .MuiTypography-root": {
      color: "#666666",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
    },
  },
  selectedLabel: {
    "& .MuiTypography-root": {
      fontWeight: 700,
    },
  },
  line: {
    paddingLeft: "16px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  arrow: {
    border: "solid #4D748D",
    borderWidth: "0 3px 3px 0",
    display: "inline-block",
    padding: "3px",
    transform: "rotate(-45deg)",
    marginRight: "10px",
  },
}));

export const List: FC<Props> = ({
  level,
  onClick,
  open,
  selectedList,
  semiSelectedList,
  singleItem,
  parentItems = [],
}) => {
  const classes = useStyles();

  const handleClick = (e: MouseEvent, item: ParentItem, justShowList = false) => {
    onClick(item, justShowList);
    e.preventDefault();
  };

  return level ? (
    <div className={classes.list}>
      {level?.items &&
        Object.keys(level?.items)?.map((id) => (
          <div className={classes.item} key={id}>
            <div
              style={{
                backgroundColor:
                  (id === open && level?.items[id].children) || parentItems?.indexOf(id) > -1 ? "#ECECEC" : "#FFFFFF",
              }}
              className={classes.line}
            >
              <FormControlLabel
                label={level?.items[id].name}
                className={
                  selectedList.includes(id) || semiSelectedList.includes(id)
                    ? `${classes.label} ${classes.selectedLabel}`
                    : classes.label
                }
                control={
                  <Checkbox
                    checked={selectedList.includes(id) || singleItem === id}
                    indeterminate={semiSelectedList.includes(id)}
                    style={{
                      fill: "#2f556d",
                      color: selectedList.includes(id) || semiSelectedList.includes(id) ? "#2f556d" : "#666666",
                    }}
                  />
                }
                onClick={(e) => handleClick(e, level?.items[id])}
              />
              {level?.items[id].childrenCount > 0 && (
                <p
                  style={{ margin: "8px", cursor: "pointer" }}
                  onClick={(e) => {
                    handleClick(e, level?.items[id], true);
                  }}
                >
                  <i className={classes.arrow}></i>
                </p>
              )}
            </div>
          </div>
        ))}
    </div>
  ) : null;
};
