import { FC } from "react";
import { makeStyles } from "@material-ui/core";
import { LinearProgress as LinearProgressBar } from "@mui/material";
import nFormatter from "../../../utils/numberToStringConverter";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10px",
  },
  progress_bar_container: {
    width: "60%",
    marginLeft: "60px",
    marginRight: "20px",
  },
  value: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: "12px",
    letterSpacing: "0",
    lineHeight: "14px",
    marginRight: "5px",
  },
  title: {
    width: "10%",
  },
  currency: {
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "14px",
  },
}));

interface Props {
  data: any;
}

const LinearProgress: FC<Props> = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={`${classes.value} ${classes.title}`}>{data?.title}</div>
      <div className={classes.progress_bar_container}>
        <LinearProgressBar value={data?.prc} variant="determinate" />
      </div>
      <div className={classes.value}>{nFormatter(data?.value)}</div>
      <div className={classes.currency}>USD</div>
    </div>
  );
};

export default LinearProgress;
