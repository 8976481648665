import { apiConfig } from "../../../services/api/apiConfig";

export type DataSourceType = "Manual" | "External" | "Computed";

export type DataSource = {
  id: number;
  name: string;
  type: DataSourceType;
  priority: number;
};

export type RevenueItem = {
  id: number | null;
  year: number;
  dataSourceId: number;
  include: boolean;
  value: number;
  currency: string;
};

export type YearlyRevenuesEditData = {
  data: RevenueItem[];
  dataSources: DataSource[];
};

export async function getYearlyRevenueEditData(companyId: number, token: string): Promise<YearlyRevenuesEditData> {
  const url = `${apiConfig.companiesDataManager}/${companyId}/yearly-revenues`;

  const res = await fetch(url, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (res.ok) {
    const data = await res.json();
    return data as YearlyRevenuesEditData;
  } else {
    const body = await res.json();
    throw new Error(`error yearly revenues for edit. ${body}`);
  }
}
