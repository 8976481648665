import { makeStyles } from "@material-ui/core";
import { FC } from "react";
import { IUser, Person } from "../../interfaces";
import { UserDetail } from "./userDetail";
import AddRowIcon from "../../assets/add-circle.png";
const useStyles = makeStyles(() => ({
  header: {
    height: "34px",
    backgroundColor: "#F6F6F6",
    display: "flex",
    padding: "10px 15px",
    width: "100%",
  },
  headerText: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
  },
  lastHeader: {
    marginRight: "36px",
    justifySelf: "flex-end",
    marginLeft: "auto",
  },
  topLineDiv: {
    marginBottom: "15px",
    display: "flex",
    flexDirection: "row",
    height: "14px",
  },
  topLine: {
    width: "calc(100% - 160px)",
    height: "1px",
    background: "#4D748D",
    marginTop: "12px",
  },
  topLineText: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 15px",
    width: "130px",
    justifyContent: "space-between",
  },
  topLineTextImg: {
    width: "16px",
    height: "16px",
    marginTop: "4px",
  },
  topLineTextSpan: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#4D748D",
    fontWeight: 500,
  },
}));

interface Props {
  data: IUser[];
  selectUser: (roleId: number, email: string) => void;
  onRemove: (email: string) => void;
}

export const UserListTable: FC<Props> = ({ data, selectUser, onRemove }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.topLineDiv}>
        <div className={classes.topLine}></div>
        <div className={classes.topLineText}>
          <img src={AddRowIcon} className={classes.topLineTextImg} />
          <span className={classes.topLineTextSpan}>User</span>
        </div>
        <div className={classes.topLine}></div>
      </div>
      <div className={classes.header}>
        <span className={classes.headerText} style={{ width: "20%", marginRight: "30px" }}>
          User Full Name
        </span>
      </div>
      {data.map((item, key) => (
        <UserDetail
          key={key}
          data={item}
          index={key}
          onClick={() => selectUser(item.roleId, item.email)}
          onRemove={() => onRemove(item.email)}
        />
      ))}
    </div>
  );
};
