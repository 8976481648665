import { FC } from "react";
import { Area, XAxis, AreaChart, Tooltip, YAxis } from "recharts";
import nFormatter from "../../../utils/numberToStringConverter";
import { TooltipProps } from 'recharts';

interface Props {
  data: any;
  selected: string;
}

const AreaGraph: FC<Props> = ({ data, selected }) => {
  const CustomizedLabel = (props: any) => {
    const { x, y, stroke, value, index } = props;
    const yOffset = (index % 2 === 1) ? 25 : 0
    return (
      <text x={x} y={y + yOffset} dy={-10} fill={stroke} fontSize={10} textAnchor="middle">
        {nFormatter(value, true)}
      </text>
    );
  };

  const CustomTooltip = ({ active, payload, label, }: TooltipProps<number, string>) => {
    if (active && payload && payload.length && payload[0].value) {
      return (
        <div className="custom-tooltip" style={{backgroundColor: 'white', border: '1px solid #4D748D'}}>
          <div style={{
            fontSize: 12,
            fontFamily: "Oswald",
            padding: 8,
            paddingBottom: 4,
            paddingTop: 4
          }}>
            <p className="label">{`${label}: ${nFormatter(payload[0].value)}`}</p>
          </div>
        </div>
      );
    }

    return null;
  };

  const graphData = data[selected]?.value?.slice(-10);

  const maxValue = Math.max(...(graphData?.map((data: any) => Number(data.value)) || [0])) * 1.1;
  const minValue = Math.min(...(graphData?.map((data: any) => Number(data.value)) || [0])) * 0.9;

  return (
    <div style={{ padding: 0 }}>
      <AreaChart width={335} height={200} data={graphData} margin={{ top: 10, right: 20, left: 20, bottom: 10 }}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#369CD4" stopOpacity={1} />
            <stop offset="95%" stopColor="#369CD4" stopOpacity={0} />
          </linearGradient>
        </defs>
        <YAxis height={200} type="number" ticks={[minValue, maxValue]} hide />
        <XAxis
          dataKey={"label"}
          axisLine={false}
          tickLine={false}
          tick={{ fill: "#369CD4", fontSize: 12 }}
          interval={0}

        />
        <Tooltip
          cursor={{ strokeWidth: 0 }}
          content={<CustomTooltip />}
        />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#4D748D"
          fillOpacity={1}
          strokeWidth={3}
          fill="url(#colorUv)"
          isAnimationActive={false}
          dot={{ stroke: "#4D748D", fill: "#FFFFFF", strokeWidth: 1.5, r: 5 }}
          activeDot={{
            stroke: "#4D748D",
            fill: "#4D748D",
            strokeWidth: 1,
            r: 5,
          }}
          label={<CustomizedLabel />}
        />
      </AreaChart>
    </div>
  );
};

export default AreaGraph;
