import { AxiosResponse } from "axios";

export interface IOpenedModal {
  name: string;
  type: string;
  title?: string;
  property_name: string;
  selected_property_name?: string;
  isVisible: boolean;
  input_type: InputTypeEnums;
  data_type?: string;
  select_key?: string;
  get_select_data_function?: (token: string) => Promise<AxiosResponse<any, any>>;
  value_text?: string;
  summary_text?: string;
  noShowPersonTitle?: boolean;
  noShowBoardMember?: boolean;
  noShowInclude?: boolean;
}

export interface ISelectData {
  country_id?: number;
  state_id?: number;
}

export enum InputTypeEnums {
  text_area_input,
  string_input,
  number_input,
  date_input,
  select_input,
  multi_level_select_input,
  executive_input,
  boards_input,
  subsidiaries_input,
  owner_input,
  address_input,
  key_persons_input,
  phone_input,
  multi_select_select_input,
  nace_code_input,
  null,
  stock_input,
}
