import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box} from "@mui/material";

import closeLogo from "../../../assets/close-circle.png";

import { ApplyButton } from "../../applyButton";

import { EiqGroupMembershipsEditData } from "./types";
import { CheckboxSelect } from "../../checkbox";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 20px",
  },
  body: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
  },
  closeButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 24,
    width: 24,
    backgroundImage: `url(${closeLogo})`,
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  title: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "28px",
    letterSpacing: "0",
    lineHeight: "34px",
    marginBottom: "10px",
  },
  cancelButton: {
    fontFamily: "Montserrat",
    fontSize: 12,
    borderRadius: 3,
    height: 38,
    textTransform: "none",
    border: "1px solid",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D748D",
    width: "74px",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
    marginTop: "20px",
  },
  dataContainer: {
    marginTop: "20px",
  },
  errorDiv: {
    color: "#c40014",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "10px",
  }
}));

const LABEL_STYLING = {
  color: "#333",
  fontFamily: "Montserrat",
  fontSize: "14px",
  letterSpacing: "0",
};

interface FormProps {
  initialEditData: EiqGroupMembershipsEditData;
  onSubmit: (editData: EiqGroupMembershipsEditData) => void;
  onCancel: () => void;
}

export const EditEiqGroupsForm = ({ initialEditData, onSubmit, onCancel }: FormProps) => {
  const classes = useStyles();

  const [editFieldData, setEditFieldData] = useState(initialEditData);

  const toggleGroupState = (identifier: number) => {
    const updatedGroups = editFieldData.data.map(group => {
      if (group.groupId === identifier) {
        return { ...group, isMember: !group.isMember };
      }
      return group;
    });


    setEditFieldData({ ...editFieldData, data: updatedGroups });
  }


  const handleApply = () => onSubmit(editFieldData);

  return (
    <div className={classes.container} style={{ maxHeight: "95%"}}>
      <div>
        <button onClick={onCancel} className={classes.closeButton} />
      </div>
      <div className={classes.title}>Edit Groups</div>
      <div className={classes.body}>
        <div className={classes.dataContainer}>
          {editFieldData.data.map((group) => {
            return <CheckboxSelect key={group.groupId} labelStyling={LABEL_STYLING} checkboxLabel={group.groupName} checked={group.isMember} setChecked={() => toggleGroupState(group.groupId)} />
          })}
          <Box sx={{ marginBottom: "10px" }}>
          </Box>
        </div>
      </div>
      <div className={classes.actions}>
        <button className={classes.cancelButton} onClick={onCancel}>
          Cancel
        </button>
        <ApplyButton text="Apply" apply={handleApply} />
      </div>
    </div>
  );
};
