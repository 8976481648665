import { makeStyles } from "@material-ui/core";
import { FC, useState, useRef, useEffect } from "react";
import { NavigationBar } from "../components/navigation";
import { SectionCard } from "../components/company-view/sectionCard";
import { RequestsTable } from "../components/user-requests/requestTable";
import { RequestsBanner } from "../components/user-requests/requestsBanner";
import { ModalComponent } from "../components/modalView";
import { apiConfig } from "../services/api/apiConfig";
import { Service } from "../services/api/service";
import { useAuth } from "../shared/hooks/auth-hook";
import buildQuery from "odata-query";
import { IRequest } from "../interfaces";

const useStyles = makeStyles(() => ({
  banner: {
    width: "100%",
    height: "362px",
    backgroundColor: "#F6F6F6",
    marginTop: "-15px",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "10px",
    paddingBottom: "50px",
  },
  leftNavigation: {
    width: "467px",
    marginTop: "40px",
  },
  message: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: "bold",
  },
}));

export const UserRequests: FC = () => {
  const classes = useStyles();
  const { token, user } = useAuth();
  const [newRequests, setNewRequests] = useState<IRequest[]>([]);
  const [pastRequests, setPastRequests] = useState<IRequest[]>([]);

  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [updateMessage, setUpdateMessage] = useState<string>("");

  const itemRefs = useRef<any>([null]);

  useEffect(() => {
    const params = {
      orderBy: "created_at asc",
      groupBy: "created_by",
    };
    Service.get(apiConfig.companyCreation, token as string, {
      ...params,
      filter: buildQuery({ filter: [{ status_id: 3 }] }).substring(9),
    })
      .then((response: any) => response.data.data)
      .then((data: any) => setNewRequests(data));

    Service.get(apiConfig.companyCreation, token as string, {
      ...params,
      filter: buildQuery({ filter: [{ status_id: { ne: 3 } }] }).substring(9),
    })
      .then((response: any) => response.data.data)
      .then((data: any) => setPastRequests(data));
  }, []);

  const sections = [
    { name: "REQUESTS", id: "requests" },
    { name: "HELP / TUTORIALS", id: "help", help: true },
  ]; // Nav sections

  const scrollToSection = (index: string) => {
    window.scrollTo({
      behavior: "smooth",
      top: itemRefs.current[index].offsetTop - 400,
    });
  };

  const handleRequestUpdated = (updated: boolean, data: string) => {
    setIsUpdated(updated);
    setUpdateMessage(data);
  };

  return (
    <div>
      <div className={classes.banner}>
        <RequestsBanner
          data={{}}
          fullName={user?.displayName}
          role={user?.role}
          profilePictureUrl={user?.profilePictureUrl}
        />
      </div>
      <div className={classes.body}>
        <div className={classes.leftNavigation}>
          <NavigationBar sectionNames={sections} scrollToSection={scrollToSection} />
        </div>
        <div style={{ marginTop: "40px" }}>
          <div id="requests">
            <SectionCard title="New Company Requests">
              <RequestsTable data={newRequests} type="new" isUpdated={handleRequestUpdated} />
            </SectionCard>
            <SectionCard title="Past requests">
              <RequestsTable data={pastRequests} type="past" isUpdated={handleRequestUpdated} />
            </SectionCard>
          </div>
        </div>
      </div>
      <ModalComponent title="" open={isUpdated} setOpen={(data: boolean) => setIsUpdated(data)}>
        <div>
          <span className={classes.message}>{updateMessage}</span>
        </div>
      </ModalComponent>
    </div>
  );
};
