import { IIndustry, Level, ParentItem, TaxonomySegment } from "../interfaces";

export const getParentsIds = (id: string): string[] => {
  const idsPath = id.split("-").reverse();

  let currentId = "";

  const pathIds: string[] = [];
  idsPath.forEach((idPart, index) => {
    currentId = `${idPart}${currentId ? '-' : ''}${currentId}`;
    pathIds.push(currentId);
  });
  return pathIds;
};

export const convertTaxonomySegmentToLevel = (taxonomySegments: TaxonomySegment[]): Level => {
  const result: Level = {
    items: {},
    selected_id: null,
  };

  taxonomySegments.forEach((segment) => {
    const idsPath = segment.id.split("-").reverse();
    const names = segment.path.split(" > ");

    let closestLevel = result;
    let currentId = "";
    idsPath.forEach((idPart, index) => {
      currentId = `${idPart}${currentId ? '-' : ''}${currentId}`;
      if (closestLevel.items[currentId]) {
        closestLevel.items[currentId].childrenCount += 1;
        closestLevel = closestLevel.items[currentId].children!
        return;
      }

      const children: Level = {
        selected_id: null,
        items: {},
      };
      const currentElement: ParentItem = {
        children,
        childrenCount: 0,
        id: currentId,
        name: names[index],
        parentId: currentId.split('-').filter((_, index) => index > 0).join('-') || null,
      };
      closestLevel.items[currentId] = currentElement;

      closestLevel = children;
    });
  });

  return result;
};

export const formatIndustry = ({ description, name, sourceId, path }: IIndustry): { sourceId: number; value: string; explanation: string } => ({
  explanation: description,
  value: path || name,
  sourceId,
});
