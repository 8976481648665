import React, { useState } from "react";
import { Box, IconButton, Paper, Switch } from "@mui/material";
import { Add, Check, Close, Delete, Edit } from "@mui/icons-material";
import { TextField } from "@material-ui/core";

import type { QuarterlyRevenueItem } from "./edit-data";

type ManualEditState = {
  value: number | "";
  currency: string;
};

export const RevenueItemCard = ({
  item,
  canEdit,
  canToggleInclude,
  isHighestPriority,
  onCreate,
  onDelete,
  onToggleInclude,
  isEditing,
  onStartEdit,
  onCancelEdit,
  onApplyEdit,
}: {
  item: QuarterlyRevenueItem | undefined;
  canEdit: boolean;
  canToggleInclude: boolean;
  isHighestPriority: boolean;
  onCreate: () => void;
  onDelete: () => void;
  onToggleInclude: () => void;
  isEditing: boolean;
  onStartEdit: () => void;
  onCancelEdit: () => void;
  onApplyEdit: (data: ManualEditState) => void;
}) => {
  const [editState, setEditState] = useState<ManualEditState>({
    value: item?.value ?? "",
    currency: item?.currency || "USD",
  });

  const handleAdd = () => {
    onCreate();
    onStartEdit();
  };

  // Empty state for non-editable cells
  if (!item && !canEdit)
    return (
      <Paper
        elevation={1}
        sx={{
          p: 0.5,
          minHeight: "50px",
          width: "165px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#f5f5f5",
          fontSize: "0.875rem",
        }}
      >
        -
      </Paper>
    );

  // Add button for empty editable cells
  // if (!item && canEdit) // TODO Help typescript to understand item is not undefined after this if statement
  if (!item)
    return (
      <Paper
        elevation={1}
        sx={{
          p: 0.5,
          minHeight: "50px",
          width: "165px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#f5f5f5",
          cursor: "pointer",
        }}
        onClick={handleAdd}
      >
        <Add fontSize="small" />
      </Paper>
    );

  return (
    <Paper
      elevation={isHighestPriority ? 3 : 1}
      sx={{
        p: 0.5,
        position: "relative",
        minHeight: "50px",
        width: "165px",
        bgcolor: isHighestPriority ? "#e3f2fd" : "#fff",
        opacity: item.include ? 1 : 0.5,
        border: isHighestPriority ? "1px solid #90caf9" : "1px solid #e0e0e0",
      }}
    >
      {isEditing ? (
        <Box display="flex" flexDirection="column" gap={0.5}>
          <TextField
            autoFocus
            label="Amount"
            type="number"
            value={editState.value}
            onChange={(e) =>
              setEditState((prev) => ({
                ...prev,
                value: e.target.value === "" ? "" : parseFloat(e.target.value),
              }))
            }
            size="small"
            variant="standard"
          />
          <TextField
            label="Currency"
            value={editState.currency}
            onChange={(e) =>
              setEditState((prev) => ({
                ...prev,
                currency: e.target.value,
              }))
            }
            size="small"
            variant="standard"
          />
          <Box display="flex" justifyContent="flex-end" gap={0.5}>
            <IconButton size="small" onClick={onCancelEdit} sx={{ padding: 0.5 }}>
              <Close fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={() => onApplyEdit(editState)} color="primary" sx={{ padding: 0.5 }}>
              <Check fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={1}>
            <Box>
              <Box
                fontSize="0.875rem"
                fontWeight={isHighestPriority ? "bold" : "normal"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {formatCurrency(item.value, item.currency)}
              </Box>
              <Box fontSize="0.75rem" color="text.secondary">
                {item.currency}
              </Box>
            </Box>
            {canToggleInclude && (
              <Switch
                checked={item.include}
                onChange={onToggleInclude}
                size="small"
                sx={{
                  transform: "scale(0.7)",
                  ml: 0.5,
                  "& .MuiSwitch-track": {
                    opacity: 0.8,
                  },
                }}
              />
            )}
            {canEdit && (
              <Box display="flex" justifyContent="flex-end" gap={0.5}>
                <IconButton size="small" onClick={onStartEdit} sx={{ padding: 0.5 }}>
                  <Edit fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={onDelete} sx={{ padding: 0.5 }}>
                  <Delete fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Paper>
  );
};

const formatCurrency = (value: number, currency: string) => {
  const formatter = new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: currency || "USD",
    maximumFractionDigits: 2,
    notation: value >= 1000000 ? "compact" : "standard",
    compactDisplay: "short",
  });

  try {
    return formatter.format(value);
  } catch (e) {
    // Fallback for invalid currency codes
    return new Intl.NumberFormat(navigator.language, {
      notation: value >= 1000000 ? "compact" : "standard",
      compactDisplay: "short",
      maximumFractionDigits: 2,
    }).format(value);
  }
};
