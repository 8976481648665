import axios from "axios";
export const Service = {
  get: (url: string, token: string, params?: unknown) =>
    axios.get(url, { params, headers: { Authorization: "Bearer " + token } }),
  post: (url: string, token: string, data: unknown) =>
    axios.post(url, data, { headers: { Authorization: "Bearer " + token } }),
  put: (url: string, token: string, data: unknown) =>
    axios.put(url, data, { headers: { Authorization: "Bearer " + token } }),
  patch: (url: string, token: string, data: unknown) =>
    axios.patch(url, data, { headers: { Authorization: "Bearer " + token } }),
  delete: (url: string, token: string, data: unknown) =>
    axios.delete(url, { headers: { Authorization: "Bearer " + token } }),
};
