import { Service } from "../../services/api/service";
import { apiConfig } from "../../services/api/apiConfig";

export const fetchData = async (id: number | string, token: string) => {
  const [companyResponse, companyInsightsResponse] = await Promise.all([
    Service.get(`${apiConfig.companiesDataExtractor}/${id}`, token as string),
    Service.get(`${apiConfig.companiesV1}/${id}?$select=insights`, token as string),
  ]);

  let companyData = {};
  let dataSources = [];
  if (companyResponse.status === 200) {
    companyData = { ...companyData, ...companyResponse.data.data };
    dataSources = companyResponse?.data?.meta?.dataSources;
  }

  if (companyInsightsResponse.status === 200) {
    companyData = { ...companyData, ...companyInsightsResponse.data.data };
  }

  return { companyData, dataSources };
};
