import { QueryClient, QueryClientProvider } from "react-query";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";

import { Login } from "./pages/Login";
import { CompanyView } from "./pages/companyView";
import { ResultsComponent } from "./pages/resultsPage";
import { SearchComponent } from "./pages/searchPage";
import { UserRequests } from "./pages/userRequests";
import { CompanyUpdates } from "./pages/CompanyUpdates";

import { AuthProvider } from "./shared/components/AuthProvider";
import { ProtectedRoute } from "./shared/components/ProtectedRoute";
import { MainNavigation } from "./shared/components/MainNavigation";
import { FooterComponent } from "./components/footer";
import { HelpComponent } from "./components/help";
import { ManageUsers } from "./pages/manageUsers";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <MainNavigation />
        <main className="margin-top-0-on-print">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <SearchComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/result"
              element={
                <ProtectedRoute>
                  <ResultsComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/companies/:id"
              element={
                <ProtectedRoute>
                  <CompanyView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/request"
              element={
                <ProtectedRoute>
                  <UserRequests />
                </ProtectedRoute>
              }
            />
            <Route
              path="/help"
              element={
                <ProtectedRoute>
                  <HelpComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage-users"
              element={
                <ProtectedRoute>
                  <ManageUsers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/company-updates"
              element={
                <ProtectedRoute>
                  <CompanyUpdates />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
        <FooterComponent />
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
