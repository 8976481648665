import { FC, SyntheticEvent } from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core";

interface Props {
  onClick: (e: SyntheticEvent) => void;
  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  buttonCancel: {
    padding: "12px 24px",
    minWidth: "110px",
    color: "#4D748D",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "12px",
    lineHeight: "14px",
    textTransform: "none",
    border: "none",
    borderRadius: 3,
    boxShadow: "0px 0px 0px 1px #4d748d inset",
    cursor: "pointer",
    backgroundColor: "#FFFFFF",
  },
}));

export const ButtonCancel: FC<Props> = ({ onClick, children }) => {
  const clasess = useStyles();

  return (
    <button className={clasess.buttonCancel} onClick={onClick}>
      {children}
    </button>
  );
};
