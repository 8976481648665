export default function nFormatter(num: number, abbr?: boolean) {
  if (num < 1e6) return num?.toLocaleString("en");
  const lookup = [
    { value: 1e6, symbol: abbr ? " M" :" Million" },
    { value: 1e9, symbol: abbr ? " B" : " Billion" },
    { value: 1e12, symbol: abbr ? " T" :" Trillion" },
  ];
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(1) + item.symbol : "0";
}
