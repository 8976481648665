import { FC, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { makeStyles, Modal, TextField } from "@material-ui/core";
import { Autocomplete, Box } from "@mui/material";

import closeLogo from "../../../assets/close-circle.png";

import { useAuth } from "../../../shared/hooks/auth-hook";
import { ApplyButton } from "../../applyButton";
import { Avatar } from "../../avatar";

import { CompanyReference } from "./edit-subsidiaries-modal";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  closeButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 24,
    width: 24,
    backgroundImage: `url(${closeLogo})`,
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  title: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "24px",
    letterSpacing: "0",
    lineHeight: "34px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  cancelButton: {
    fontFamily: "Montserrat",
    fontSize: 12,
    borderRadius: 3,
    height: 38,
    textTransform: "none",
    border: "1px solid",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D748D",
    width: "74px",
  },
  actions: {
    display: "flex",
    paddingRight: "30px",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  addSubsidiaryText: {
    marginBlock: "10px",
  },
  addSubsidiaryInputContainer: {
    position: "relative",
  },
}));

interface Props {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  onSave: (company: CompanyReference) => void;
  companyId: number;
  subsidiaryIds: number[];
}

type CollectionQueryResponse<T> = {
  data: T[];
  count: number;
};

type CompanySearchFilters = {
  id: {
    isNotIn: number[];
  };
};

async function getCompanies(token: string, search: string, filters: CompanySearchFilters) {
  const params = new URLSearchParams();
  if (search.trim()) {
    params.set("search", search.trim());
  }
  if (filters.id.isNotIn.length) {
    for (const id of filters.id.isNotIn) {
      params.append("ignore_ids", id.toString());
    }
  }

  let url = `${process.env.REACT_APP_DATA_MANAGER_URL}/api/v1/companies-as-subsidiary`;
  const query = params.toString();
  if (query) {
    url += `?${query}`;
  }

  const res = await fetch(url, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (res.ok) {
    const collection = (await res.json()) as CollectionQueryResponse<CompanyReference>;
    return collection;
  } else {
    try {
      const err = await res.json();
      console.warn("companies-as-subsidiary", err);
    } catch {
      console.warn("company-updates: unknown error");
    }
    throw new Error("companies-as-subsidiary failed");
  }
}

const useCompanies = (token: string, search: string, filters: CompanySearchFilters) => {
  return useQuery({
    queryKey: ["companies-as-subsidiary", search, filters],
    queryFn: () => getCompanies(token, search, filters),
    staleTime: Infinity,
    enabled: !!search.trim(),
  });
};

const defaultValues = {
  search: "",
  selectedSubsidiary: null,
};

export const AddSubsidiaryModal: FC<Props> = ({ isVisible, setIsVisible, onSave, companyId, subsidiaryIds }) => {
  const classes = useStyles();
  const { token } = useAuth();

  const [filters] = useState<CompanySearchFilters>({ id: { isNotIn: [...subsidiaryIds, companyId] } });

  const [search, setSearch] = useState(defaultValues.search);
  const [debouncedSearch, setDebouncedValue] = useState(defaultValues.search);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(search.trim());
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [search]);

  const [selectedSubsidiary, setSelectedSubsidiary] = useState<CompanyReference | null>(
    defaultValues.selectedSubsidiary
  );

  const queryClient = useQueryClient();
  const { status, data, error } = useCompanies(token!, debouncedSearch, filters);

  const resetForm = () => {
    setSearch(defaultValues.search);
    setSelectedSubsidiary(defaultValues.selectedSubsidiary);
  };

  const onClose = () => {
    setIsVisible(false);
    resetForm();
  };

  const onApply = () => {
    if (selectedSubsidiary) {
      onSave(selectedSubsidiary);
      resetForm();
      setIsVisible(false);
    }
  };

  return (
    <Modal open={isVisible} onClose={onClose}>
      <div className={classes.container}>
        <div>
          <button onClick={onClose} className={classes.closeButton} />
        </div>
        <div className={classes.title}>Add Subsidiary</div>
        <div className={classes.addSubsidiaryInputContainer}>
          <div className={classes.addSubsidiaryText}>Search Company</div>
          <Autocomplete
            id="subsidiary-country"
            sx={{ width: 300 }}
            options={data?.data ?? []}
            loading={status === "loading"}
            autoHighlight
            getOptionLabel={(option) => option.name}
            clearOnBlur={false}
            filterOptions={(x) => x}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selectedSubsidiary}
            inputValue={search}
            onChange={(e, v) => setSelectedSubsidiary(v)}
            onInputChange={(e, value) => {
              setSearch(value);
            }}
            renderOption={(props, option) => {
              return (
                <Box key={option.id} component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                  <Avatar
                    logo={option.image ?? undefined}
                    name={option.name}
                    style={{ height: "24px", width: "24px", marginRight: "20px", objectFit: "contain" }}
                  />
                  {option.name}
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </div>
        <div className={classes.actions}>
          <button className={classes.cancelButton} onClick={onClose}>
            Cancel
          </button>
          <span style={{ marginLeft: 10 }} />
          <ApplyButton text="Apply" apply={onApply} disabled={!selectedSubsidiary} />
        </div>
      </div>
    </Modal>
  );
};
