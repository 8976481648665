import { FC, SyntheticEvent } from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core";

interface Props {
  onClick: (e: SyntheticEvent) => void;
  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  buttonApply: {
    padding: "12px 24px",
    minWidth: "110px",
    color: "#FFFFFF",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: 0,
    textTransform: "none",
    border: "none",
    borderRadius: 3,
    backgroundColor: "#4D748D",
    "&:hover": {
      backgroundColor: "#608dab",
    },
    "&:disabled": {
      backgroundColor: "#bebebe",
      color: "#5c5c5c",
    },
  },
}));

export const ButtonApply: FC<Props> = ({ onClick, children }) => {
  const clasess = useStyles();

  return (
    <button className={clasess.buttonApply} onClick={onClick}>
      {children}
    </button>
  );
};
