import { makeStyles } from "@material-ui/core";
import { FC, useState } from "react";
import { IUser, Person } from "../../interfaces";
import CloseCircle from "../../assets/close-circle.png";
import { ModalComponent } from "../modalView";
const useStyles = makeStyles(() => ({
  container: {
    height: "60px",
    width: "100%",
    padding: "10px",
    borderBottom: "1px solid #ECECEC",
    display: "flex",
    cursor: "pointer",
    justifyContent: "space-between",
    flexDirection: "row",
    "&:hover": {
      boxShadow: "17px 0 40px rgba(0,0,0,0.06), 2px 0px 20px rgba(0,0,0,0.1)",
    },
  },
  userImage: { width: "40px", height: "40px" },
  userImg: {
    width: "40px",
    height: "40px",
    borderRadius: "100%",
    overflow: "hidden",
  },
  userDetail: { marginTop: "5px", marginLeft: "14px", display: "flex", flexDirection: "column" },
  userFullname: {
    height: "15px",
    fontFamily: "Oswald",
    fontSize: "15px",
    lineHeight: "15px",
    color: "#4D748D",
    fontWeight: 400,
  },
  userEmail: {
    height: "15px",
    fontFamily: "Oswald",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#4D748D",
    fontWeight: 200,
    marginLeft: "5px",
  },
  userRole: {
    height: "14px",
    fontFamily: "Montserrat",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#666666",
    fontWeight: 400,
  },
  userRow: {
    display: "flex",
    flexDirection: "row",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    "& .MuiCircularProgress-root": {
      width: "20px !important",
      height: "20px !important",
      color: "#4D748D",
      marginRight: "5px",
      marginTop: "5px",
    },
  },
  button: {
    boxSizing: "border-box",
    height: "38px",
    width: "110px",
    border: "1px solid #4D748D",
    borderRadius: "3px",
    marginRight: "15px",
  },
  title: {
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
    marginBottom: "4px",
  },
}));
interface Props {
  data: IUser;
  index: number;
  onClick: () => void;
  onRemove: () => void;
}
export const UserDetail: FC<Props> = ({ data, index, onClick, onRemove }) => {
  const classes = useStyles();
  const [removingUserId, setRemovingUserId] = useState(0);
  const [username, setUsername] = useState("");
  const handleCancel = () => {
    setRemovingUserId(0);
  };
  return (
    <>
      <ModalComponent
        open={removingUserId > 0}
        setOpen={() => setRemovingUserId(0)}
        title={``}
        onClose={() => setRemovingUserId(0)}
      >
        <div className={classes.formGroup} style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span className={classes.title}>{`Are you sure you want to delete ${username}`}</span>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <button
              className={classes.button}
              type="button"
              style={{ backgroundColor: "#FFFFFF", color: "#4D748D", cursor: "pointer" }}
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className={classes.button}
              type="submit"
              style={{
                backgroundColor: "#4D748D",
                color: "#FFFFFF",
                cursor: "pointer",
              }}
              onClick={() => {
                onRemove();
                setRemovingUserId(0);
                setUsername("");
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </ModalComponent>
      <div className={classes.container} style={index > 1 ? { marginTop: "1px" } : undefined}>
        <div className={classes.userRow} onClick={onClick}>
          <div className={classes.userImage}>
            <img src={data.profilePictureUrl} className={classes.userImg} />
          </div>
          <div className={classes.userDetail}>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div className={classes.userFullname}>{data.displayName}</div>
              <div className={classes.userEmail}> ( {data.email} )</div>
            </div>

            <div className={classes.userRole}>{data.role}</div>
          </div>
        </div>
        <div>
          <img
            src={CloseCircle}
            onClick={() => {
              setRemovingUserId(data.id);
              setUsername(data.displayName);
            }}
          />{" "}
        </div>
      </div>
    </>
  );
};
