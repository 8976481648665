import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

export class ExcelExport {
  export = async (fileName: string, exportData: any) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    //convert json to excel
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    // Align cells: wrap text
    const cells = Object.keys(wb?.Sheets?.data);
    cells?.forEach((cell: string) => {
      if (cell === "!ref") return;
      ws[cell].s = { alignment: { vertical: "center", horizontal: "center", wrapText: true } };
    });

    //save file
    const buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
}
