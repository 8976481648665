import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LoginFunction, LogoutFunction, AuthContext } from "../context/auth-context";
import type { User } from "../types/user";

let logoutTimer: number | null = null;

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const [token, setToken] = useState<string | null>(null);
  const [tokenExpirationDate, setTokenExpirationDate] = useState<Date | null>();
  const [user, setUser] = useState<User | null>(null);
  const [permissions, setPermissions] = useState<{ [key: string]: boolean }>({});

  const login = useCallback<LoginFunction>(({ token, userData, expirationDate }, callback) => {
    const { permissions, ...user } = userData;
    const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);

    setUser(user);
    setPermissions(permissions);
    setTokenExpirationDate(tokenExpirationDate);
    setToken(token);

    localStorage.setItem(
      "userData",
      JSON.stringify({
        ...userData,
        token: token,
        expiration: tokenExpirationDate.toISOString(),
      })
    );

    callback();
  }, []);

  const logout = useCallback<LogoutFunction>((callback) => {
    localStorage.removeItem("userData");

    setToken(null);
    setTokenExpirationDate(null);
    setPermissions({});
    setToken(null);

    callback();
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime) as unknown as number;
    } else {
      clearTimeout(logoutTimer as number);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData") as string);
    if (storedData && storedData.token && new Date(storedData.expiration) > new Date()) {
      const { token, expiration, ...userData } = storedData;
      login({ token, userData, expirationDate: new Date(expiration) }, () => {
        console.log("Autologin on: " + pathname);
        navigate(`${pathname}${search}`, { replace: true });
      });
    }
  }, [login]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token,
        user,
        hasPermission: (p: string) => permissions && permissions[p] === true,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
