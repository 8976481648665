import { FC, ReactNode } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 12px 0 rgba(0,0,0,0.06)",
    marginRight: "10px",
    flex: "1",
    marginTop: "16px",
    position: "relative",
  },
}));

interface Props {
  children: ReactNode;
  title?: string;
}

export const RevenueCard: FC<Props> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};
