import { makeStyles, Modal, TextField } from "@material-ui/core";
import { FC, useState } from "react";

import closeLogo from "../../../assets/close-circle.png";
import { ApplyButton } from "../../applyButton";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  closeButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 24,
    width: 24,
    backgroundImage: `url(${closeLogo})`,
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  title: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "24px",
    letterSpacing: "0",
    lineHeight: "34px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  cancelButton: {
    fontFamily: "Montserrat",
    fontSize: 12,
    borderRadius: 3,
    height: 38,
    textTransform: "none",
    border: "1px solid",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D748D",
    width: "74px",
  },
  actions: {
    display: "flex",
    paddingRight: "30px",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  addSubsidiaryText: {
    marginBlock: "10px",
  },
  addSubsidiaryInputContainer: {
    position: "relative",
  },
  companies_results: {
    position: "absolute",
    top: "80px",
    boxShadow: "0px 0px 5px 0px rgb(0 0 0 / 75%)",
    zIndex: 99,
    backgroundColor: "white",
    width: "100%",
    borderRadius: "3px",
  },
  each_company_result: {
    padding: "5px 10px",
    cursor: "pointer",
    paddingLeft: "10px",
    display: "flex",
    alignItems: "center",
  },
  selectedRow: {
    marginTop: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflowY: "hidden",
  },
}));

interface Props {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  onSave: (name: string) => void;
}

const defaultValues = {
  companyName: "",
};

export const AddExternalSubsidiaryModal: FC<Props> = ({ isVisible, setIsVisible, onSave }) => {
  const classes = useStyles();

  const [companyName, setCompanyName] = useState(defaultValues.companyName);

  const onClose = () => {
    setCompanyName(defaultValues.companyName);
    setIsVisible(false);
  };

  const onApply = () => {
    const name = companyName.trim();
    if (name) {
      onSave(companyName);
      setCompanyName(defaultValues.companyName);
      setIsVisible(false);
    }
  };

  return (
    <Modal open={isVisible} onClose={onClose}>
      <div className={classes.container}>
        <div>
          <button onClick={onClose} className={classes.closeButton} />
        </div>
        <div className={classes.title}>Add External Subsidiary</div>
        <div className={classes.addSubsidiaryInputContainer}>
          <div className={classes.addSubsidiaryText}>Add Subsidiary</div>
          <TextField
            size="small"
            variant="outlined"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>
        <div className={classes.actions}>
          <button className={classes.cancelButton} onClick={onClose}>
            Cancel
          </button>
          <span style={{ marginLeft: 10 }} />
          <ApplyButton text="Apply" apply={onApply} disabled={!companyName.trim()} />
        </div>
      </div>
    </Modal>
  );
};
