import { FC, SyntheticEvent, useState } from "react";
import { makeStyles } from "@material-ui/core";
import chartLogo from "../../../assets/chart.svg";
import arrowLogo from "../../../assets/collapse.svg";
import editButtonIcon from "../../../assets/edit.png";
import { InsightItem } from "./insight.model";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ECECEC",
  },
  chart_logo: {
    height: "30px",
    width: "30px",
    marginRight: "15px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    padding: "15px",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F6F6F6 !important",
    },
  },
  header_strategy_name: {
    color: "#4D748D",
    fontFamily: "Oswald",
    fontSize: "15px",
    letterSpacing: "0",
    lineHeight: "15px",
  },
  header_strategy_detail: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: "12px",
    letterSpacing: "0",
    lineHeight: "14px",
  },
  header_title: {
    display: "flex",
    flexDirection: "row",
  },
  arrow: {
    height: "10px",
    width: "10px",
    backgroundColor: "transparent",
  },
  body: {
    padding: "30px 15px",
  },
  body_title: {
    display: "flex",
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "32px",
    letterSpacing: "0",
    lineHeight: "47px",
    marginBottom: "37px",
  },
  body_exploration: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "0",
    lineHeight: "24px",
  },
  insight_edit_button: {
    border: "none",
    padding: "8px",
    background: "none",
    marginLeft: "auto",
    cursor: "pointer",
  },
}));

interface Props {
  data: InsightItem;
  isEdit: boolean;
  onEdit: (e: SyntheticEvent) => void;
}

export const InsightsAccordion: FC<Props> = ({ data, isEdit, onEdit }) => {
  const [isVisible, setIsVisible] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div
        className={`${classes.header} ${
          isVisible ? "background-color-f6f6f6" : "background-color-ffffff"
        } background-color-ffffff-on-print`}
        onClick={() => setIsVisible(!isVisible)}
      >
        <div className={classes.header_title}>
          <img src={chartLogo} className={classes.chart_logo} alt="chart logo" />
          <div>
            <div className={classes.header_strategy_name}>{data?.type}</div>
            <div className={classes.header_strategy_detail}>Details</div>
          </div>
        </div>
        <img
          src={arrowLogo}
          alt="arrow-icon"
          className={classes.arrow}
          style={{ transform: isVisible ? "rotate(0deg)" : "rotate(180deg)" }}
        />
      </div>
      <div className={`${classes.body} ${isVisible ? "display-block" : "display-none"} display-block-on-print`}>
        <div className={classes.body_title}>
          <div>{data?.type}</div>
          {isEdit && isVisible && (
            <button className={classes.insight_edit_button}>
              <img onClick={onEdit} src={editButtonIcon} width="20" height="20" alt="Edit Insight Item" />
            </button>
          )}
        </div>
        <div className={classes.body_exploration}>{data?.value}</div>
      </div>
    </div>
  );
};
