import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "40px",
  },
  title: {
    fontFamily: "Montserrat",
    color: "#4D748D",
    fontSize: 16,
    fontWeight: 600,
  },
  listWrapper: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
  },
  editButton: {
    background: "transparent",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "20px",
    width: "20px",
    marginLeft: "10px",
    border: "none",
    cursor: "pointer",
    alignItems: "center",
    display: "inline-flex",
    marginBottom: "-3px",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  }
}));
