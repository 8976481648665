import { FC, ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import closeLogo from "../assets/close-circle.png";
import Modal from "@mui/material/Modal";

const useStyles = makeStyles(() => ({
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: "100px",
    maxHeight: "411px",
    width: "335px",
    borderRadius: "5px",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
    backgroundColor: "#FFFFFF",
    padding: "30px 50px",
  },
  closeButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 24,
    width: 24,
    backgroundImage: `url(${closeLogo})`,
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  title: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "32px",
  },
}));

interface Props {
  children: ReactNode;
  title: string;
  open: boolean;
  setOpen: (data: boolean) => void;
  onClose?: () => void;
  zIndex?: number;
}

export const ModalComponent: FC<Props> = ({ children, title, open, setOpen, onClose, zIndex }) => {
  const classes = useStyles();
  const handleClose = () => {
    if (onClose) onClose();
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
      >
        <div className={classes.box} style={{ zIndex: zIndex ? zIndex : 500 }}>
          <button onClick={handleClose} className={classes.closeButton} />
          {title !== "" && <span className={classes.title}>{title}</span>}
          {children}
        </div>
      </Modal>
    </div>
  );
};
