import { CircularProgress, makeStyles, Modal, Typography } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import loginPageBackground from "../assets/login-page-bg.png";
import { useAuth } from "../shared/hooks/auth-hook";
import { Service } from "../services/api/service";
import { apiConfig } from "../services/api/apiConfig";
const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    minWidth: "400px",
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  loginPage: {
    contain: "content",
    backgroundImage: `url(${loginPageBackground})`,
    backgroundSize: "cover",
    minHeight: "calc(100vh - 119px)",
  },
  loginModal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "120px auto 0",
    width: "max-content",
    padding: "60px 50px",
    backgroundColor: "#fff",
  },
  welcomeHeader: {
    width: "530px",
    margin: "40px 0 0px",
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "39px",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "44px",
    textAlign: "center",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 20px",
  },
  errorContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 20px",
  },
  loginPleaseLogin: {
    marginTop: "20px",
    fontFamily: "Oswald",
    fontSize: "24px",
    fontWeight: 500,
    color: "#333333",
    letterSpacing: 0,
  },
}));

export const Login = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const from = (location.state as any)?.from?.pathname || "/";

  const auth = useAuth();
  const googleSignInButton = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const handleCredentialResponse = async (response: { credential: string }) => {
    setIsLoading(true);

    const token = response.credential;
    try {
      const ownProfileGet = await Service.get(apiConfig.me, token);
      const ownProfile = ownProfileGet.data;
      if (ownProfile.error) throw new Error(ownProfile.error?.message);
      const permissions = ownProfile.permissions;
      ownProfile.permissions = {};
      for (const permission of permissions) {
        ownProfile.permissions[permission] = true;
      }
      // console.log(token);
      // const url = new URL("https://oauth2.googleapis.com/tokeninfo");
      // url.searchParams.append("id_token", token);
      // const res = await fetch(url.toString());
      // const googleUserProfile = await res.json();
      // const ownProfile = {
      //   ...parseGoogleProfile(googleUserProfile),
      //   permissions: {
      //     profile: true,
      //     "Company.Read": true,
      //     "Company.ReadWrite": true,
      //   },
      // };
      auth.login({ token, userData: ownProfile }, () => {
        navigate(from, { replace: true });
      });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setError(String(err));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: CLIENT_ID,
      callback: handleCredentialResponse,
    });

    if (googleSignInButton.current) {
      google.accounts.id.renderButton(googleSignInButton.current, {
        type: "standard",
        theme: "outline",
        size: "large",
        shape: "pill",
      });
    }
  });
  return (
    <>
      <Modal open={isLoading}>
        <div className={classes.container}>
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        </div>
      </Modal>
      <Modal open={error ? true : false} onClose={() => setError(undefined)}>
        <div className={classes.container}>
          <Typography className={classes.errorContainer} color="error">
            {error}
          </Typography>
        </div>
      </Modal>
      {/* {isLoading && null} AS OVERLAY */}
      <div className={classes.loginPage}>
        <div className={classes.loginModal}>
          <h2 className={classes.welcomeHeader}>
            Welcome to EnsembleIQ <br />
            Company Database
          </h2>
          <h4 className={classes.loginPleaseLogin}>Please Login</h4>
          <div id="parent_id" ref={googleSignInButton}></div>
        </div>
      </div>
    </>
  );
};

const parseGoogleProfile = (googleUserProfile: unknown) => {
  const { email, name, picture, exp, sub } = googleUserProfile as any;

  return {
    sub: sub as string,
    email: email as string,
    displayName: name as string,
    firstName: name as string,
    lastName: name as string,
    pictureUrl: picture as string,
    exp: exp as string,
  };
};
