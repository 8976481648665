import { FC, useState, useEffect } from "react";
import { useAuth } from "../../shared/hooks/auth-hook";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { apiConfig } from "../../services/api/apiConfig";
import { Service } from "../../services/api/service";
import { MultiSelect } from "../multiselect";
import { ApplyButton } from "../applyButton";
import { ICountry, ISelectItem, IState } from "../../interfaces";
import closeLogo from "../../assets/close-circle.png";
import menuIcon from "../../assets/more-circle.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    width: "auto",
    minWidth: "400px",
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 20px",
  },
  closeButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 24,
    width: 24,
    backgroundImage: `url(${closeLogo})`,
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  title: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "28px",
    letterSpacing: "0",
    lineHeight: "34px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  body: {
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
  },
  selectedSection: {
    marginTop: "15px",
    display: "flex",
    flexDirection: "column",
    maxHeight: "150px",
    overflowY: "auto",
  },
  selectedTitle: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "24px",
  },
  cancelButton: {
    fontFamily: "Montserrat",
    fontSize: 12,
    borderRadius: 3,
    height: 38,
    textTransform: "none",
    border: "1px solid",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D748D",
    width: "74px",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  listItem: {
    fontFamily: "Montserrat",
    fontSize: 15,
    color: "#666666",
  },
  closeModalContainer: {
    marginInline: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  menuIcon: {
    height: 25,
  },
  menuText: {
    marginLeft: 10,
    color: "#4D748D",
  },
}));

interface Props {
  title: string;
  property_name: string;
  selected: string[] | number[];
  setSelected: (data: number[] | string[]) => void;
}

export const SelectDisplay: FC<Props> = ({ title, property_name, selected, setSelected }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);

  const [isVisible, setIsVisible] = useState(false);
  const openModal = () => setIsVisible(true);

  const [options, setOptions] = useState<ICountry[] | IState[] | ISelectItem[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const [selectedIds, setSelectedIds] = useState<number[] | string[]>([]);
  const { token } = useAuth();

  const select_items_title: Record<string, string> = {
    country: "Select Countries to Display",
    state: "Select States to Display",
    store_type: "Select Store Types to Display",
  };

  useEffect(() => {
    if (property_name === "store_type") {
      fetchData(apiConfig.storeTypes)
        .then((response) => {
          setOptions((options) => [...options, ...response]);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const countries_iso = ["USA", "CAN"];
      const countries_id: number[] = [];

      fetchData(apiConfig.countries)
        .then((response) => {
          if (property_name === "country") {
            setOptions(response);
          } else {
            for (const iso of countries_iso) {
              const found_item = response?.find((item: ICountry) => item?.iso3 === iso);
              countries_id.push(found_item?.id);
            }

            for (const id of countries_id) {
              fetchData(`${apiConfig.countries}/${id}/states`).then((response) => {
                setOptions((options) => [...options, ...response]);
                setOptions((options) => [...options, { id: 0, name: "-", country_id: null }]);
              });
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [property_name]);

  useEffect(() => {
    if (selected != null && options?.length > 0) {
      setSelectedIds(selected);
      findOptionNames(selected);
    }
  }, [selected, options]);

  const fetchData = async (path: string) => {
    const response = await Service.get(path, token as string);
    return response.data;
  };

  const handleSave = () => {
    setSelected(selectedIds);
    setIsVisible(false);
  };

  const handleSelected = (data: number[] | string[]) => {
    setSelectedIds(data);
    findOptionNames(data);
  };

  const closeModal = () => {
    setSelected(selected);
    setSelectedIds(selected);
    setIsVisible(false);
  };

  const findOptionNames = (data: number[] | string[]) => {
    const temp_selected: string[] = [];
    data?.forEach((id: number | string) => {
      temp_selected.push(options?.find((item: ICountry | ISelectItem | IState) => item?.id === id)?.name as string);
    });
    setSelectedOptions(temp_selected);
  };

  return (
    <div>
      <div className={classes.closeModalContainer} onClick={openModal}>
        <img src={menuIcon} className={classes.menuIcon} alt="menu" />
        <div className={classes.menuText}>
          {select_items_title[title]} {selected?.length > 0 ? `(${selected?.length})` : ""}
        </div>
      </div>
      {loading ? (
        <Modal open={loading} onClose={closeModal} disableEnforceFocus>
          <div className={classes.container}>
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          </div>
        </Modal>
      ) : (
        <Modal open={isVisible} onClose={closeModal} disableEnforceFocus>
          <div className={classes.container}>
            <div>
              <button onClick={closeModal} className={classes.closeButton} />
            </div>
            <div className={classes.title}>{select_items_title[title]}</div>
            <>
              <div className={classes.body}>
                <MultiSelect
                  items={options}
                  selected={selectedIds}
                  setSelected={handleSelected}
                  stringify={(item: ISelectItem | ICountry | IState) => item?.name}
                  value={(item: ISelectItem | ICountry | IState) => item?.id}
                  title={select_items_title[title]}
                />
              </div>
            </>
            {selectedIds?.length > 0 && (
              <>
                <div className={classes.selectedSection}>
                  <span className={classes.selectedTitle}>Selected Items:</span>
                  <ul>
                    {selectedOptions?.map((name: string) => (
                      <li key={name} className={classes.listItem}>
                        {name}
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
            <div className={classes.actions}>
              <button className={classes.cancelButton} onClick={closeModal}>
                Cancel
              </button>
              <span style={{ marginLeft: 10 }} />
              <ApplyButton text="Apply" apply={handleSave} />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
