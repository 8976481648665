import { FC, useState } from "react";
import { Dialog, makeStyles } from "@material-ui/core";

import AddMoreIcon from "../../../assets/add-circle.png";

import { InsightsAccordion } from "./insightsAccordion";
import { InsightEditDialog } from "./insightEditDialog";
import { FormErrors, FormValidateHandler, InsightEditFormData, InsightItem } from "./insight.model";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "15px",
  },
  empty: {
    width: "100%",
    color: "#333",
    fontSize: "18px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    marginLeft: "5px",
  },
  addMore: {
    marginTop: "45px",
    position: "relative",
    textAlign: "center",
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: "50%",
      width: "100%",
      height: "1px",
      backgroundColor: "#4D748D",
    },
  },
  addMoreButton: {
    border: "none",
    background: "white",
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "24px",
    padding: "0 15px",
    zIndex: 10,
    position: "relative",
    cursor: "pointer",
  },
  addMoreButtonIcon: {
    verticalAlign: "text-top",
    marginRight: "10px",
  },
}));

interface Props {
  isEdit: boolean;
  saveInsights: (insights: Array<InsightItem | InsightEditFormData>) => void;
  data: { insights?: InsightItem[] };
}
export const InsightsSection: FC<Props> = ({ data, saveInsights, isEdit }) => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [insightItemToEdit, setInsightItemToEdit] = useState<InsightItem | null>(null);

  const classes = useStyles();
  const { insights = [] } = data;

  const handleEdit = (item: InsightItem) => () => {
    setInsightItemToEdit(item);
    setEditModalOpen(true);
  };

  const handleEditNew = () => {
    setInsightItemToEdit(null);
    setEditModalOpen(true);
  };

  const handleEditCancel = () => {
    setEditModalOpen(false);
  };

  const handleEditSave = (data: InsightEditFormData) => {
    setEditModalOpen(false);
    const editedIndex = insights.findIndex((item) => item.id === data.id);

    if (editedIndex !== -1) {
      const items: Array<InsightEditFormData | InsightItem> = insights;
      console.log(items);
      items.splice(editedIndex, 1, data);
      console.log(items);
      console.log("----");
      saveInsights(items);
    } else {
      const updatedInsights = [...insights, data];
      saveInsights(updatedInsights);
    }
  };

  const renderItems = (insights: InsightItem[] | undefined) =>
    insights && insights.length
      ? insights.map((insightItem) => (
          <InsightsAccordion data={insightItem} key={insightItem.id} isEdit={isEdit} onEdit={handleEdit(insightItem)} />
        ))
      : null;

  const renderNotItemsMessage = (insights: InsightItem[] | undefined, isEdit: boolean) =>
    !isEdit && !insights?.length ? <div className={classes.empty}>No Data.</div> : null;

  const renderAddInsightSection = (isEdit: boolean) =>
    isEdit ? (
      <div className={classes.addMore}>
        <button onClick={handleEditNew} className={classes.addMoreButton}>
          <img src={AddMoreIcon} width="16" height="16" className={classes.addMoreButtonIcon} alt="Add more"></img>
          Add More
        </button>
      </div>
    ) : null;

  return (
    <div className={classes.container}>
      {renderItems(insights)}
      {renderNotItemsMessage(insights, isEdit)}
      {renderAddInsightSection(isEdit)}
      <Dialog open={isEditModalOpen} maxWidth={false}>
        <InsightEditDialog
          data={insightItemToEdit}
          onSave={handleEditSave}
          onCancel={handleEditCancel}
          validate={getValidateHandler(insights)}
        ></InsightEditDialog>
      </Dialog>
    </div>
  );
};

const getValidateHandler: (insights: InsightItem[]) => FormValidateHandler<InsightEditFormData> =
  (insights) => (newInsightItem) => {
    const errors: FormErrors<InsightEditFormData> = {
      type: [],
      value: [],
      id: [],
    };

    if (!newInsightItem.value) {
      errors.value.push("Insight description is required");
    }

    if (!newInsightItem.type) {
      errors.type.push("Insight name is required");
    }

    const type = newInsightItem.type.trim();
    const foundDuplicateByType = insights.some(
      (insightItem) => insightItem.type === type && insightItem.id !== newInsightItem.id
    );
    if (foundDuplicateByType) {
      errors.type.push("Insight with given name already exists");
    }

    if (errors.id.length === 0 && errors.type.length === 0 && errors.value.length === 0) {
      return null;
    }

    return errors;
  };
