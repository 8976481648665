import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tooltip: {
    fontSize: "10px",
    lineHeight: "14px",
    fontFamily: "Montserrat",
    "& a": {
      color: "#4D748D",
    },
  },
  summaryText: {
    width: "100%",
    border: "none",
    minHeight: "200px",
    maxHeight: "300px",
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: 0,
    textOverflow: "ellipsis",
    overflowY: "scroll",
  },
  keyInformations: {
    marginTop: "45px",
    display: "flex",
    flexDirection: "column",
  },
  keyInformationTitle: {
    fontFamily: "Oswald",
    fontSize: "28px",
    lineHeight: "30px",
    color: "#333",
  },
  sectionTitle: {
    marginTop: "30px",
    marginBottom: "14px",
    fontFamily: "Oswald",
    fontSize: "22px",
    lineHeight: "24px",
    color: "#333",
  },
  sectionSubTitle: {
    marginTop: "10px",
    fontFamily: "Oswald",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#333",
    marginLeft: "10px",
  },
  subSectionConatiner: {},
  sectionContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "14px",
  },
  button: {
    background: "transparent",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "20px",
    width: "20px",
    marginLeft: "10px",
    border: "none",
    cursor: "pointer",
    alignItems: "center",
    display: "inline-flex",
    marginBottom: "-3px",
  },
  industryTagsContainer: {
    display: "flex",
    gap: "8px",
    flexWrap: "wrap",
  },
  industryTablesContainer: {
    gap: "8px",
    flex: "auto",
  },
  industryTag: {
    padding: "4px 8px",
    lineHeight: "24px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    textWrap: "nowrap",
    color: "#333",
    boxShadow: "0 2px 20px 0 rgba(0,0,0,0.1)",
    borderRadius: "2px",
  },
}));
