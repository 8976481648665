import { makeStyles } from "@material-ui/core";
import { FC } from "react";
import { RequestsAccordion } from "./requestsAccordion";
import { IRequest, IRequestDetail } from "../../interfaces";

const useStyles = makeStyles(() => ({
  header: {
    height: "34px",
    backgroundColor: "#F6F6F6",
    display: "flex",
    padding: "10px 15px",
    width: "100%",
  },
  headerText: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
  },
  lastHeader: {
    marginRight: "36px",
    justifySelf: "flex-end",
    marginLeft: "auto",
  },
}));

interface Props {
  data: IRequest[];
  type: string;
  isUpdated: (data: boolean, response: any) => void;
}

export const RequestsTable: FC<Props> = ({ data, type, isUpdated }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.header}>
        <span className={classes.headerText} style={{ width: "20%", marginRight: "30px" }}>
          User Full Name
        </span>
        <span className={classes.headerText} style={{ width: "25%", marginRight: "60px" }}>
          Requests
        </span>
        <span className={`${classes.headerText} ${classes.lastHeader}`}>Approval Decision</span>
      </div>
      {data?.map((request: any, index: number) => (
        <RequestsAccordion key={index} data={request} type={type} isUpdated={isUpdated} />
      ))}
    </div>
  );
};
