import { makeStyles } from "@material-ui/core";
import { FC, useState, useRef, useEffect, FormEvent } from "react";
import { NavigationBar } from "../components/navigation";
import { RequestsBanner } from "../components/user-requests/requestsBanner";
import { ModalComponent } from "../components/modalView";
import { useAuth } from "../shared/hooks/auth-hook";
import { UsersListSectionCard } from "../components/manage-users/usersListSectionCard";
import { UserListTable } from "../components/manage-users/userListTable";
import { AddRole } from "../components/manage-users/addRole";
import { Service } from "../services/api/service";
import { apiConfig } from "../services/api/apiConfig";
import External from "../assets/external.svg";
import { IUser } from "../interfaces";
import { ManageUsersBanner } from "../components/manage-users/manageUsersBanner";
const useStyles = makeStyles(() => ({
  banner: {
    width: "100%",
    height: "362px",
    backgroundColor: "#F6F6F6",
    marginTop: "-15px",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "10px",
    paddingBottom: "50px",
  },
  leftNavigation: {
    width: "467px",
    marginTop: "40px",
  },
  message: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: "bold",
  },
  helpContainer: {
    padding: "40px 36px 45px 30px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E1E1E1",
    display: "flex",
    flexDirection: "column",
    width: "853px",
  },
  helpH1: {
    fontFamily: "Oswald",
    fontSize: "32px",
    lineHeight: "34px",
    fontWeight: 500,
    marginBottom: "5px",
  },
  helpSubject: {
    marginTop: "40px",
  },
  helpH2: {
    fontFamily: "Oswald",
    fontSize: "16px",
    lineHeight: "17px",
    fontWeight: 500,
  },
  helpDetail: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 500,
    color: "#666666",
    width: "100%",
  },
  howTo: {
    marginTop: "15px",
    height: "26px",
  },
  howToLink: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    lineHeight: "26px",
    color: "#2F556D",
    fontWeight: 600,
    display: "flex",
    flexDirection: "row",
  },
  howToLinkImage: {
    width: "20px",
    height: "20px",
  },
  updateUserError: {
    color: "#c40014",
    fontFamily: "Montserrat",
    fontSize: "11px",
    fontWeight: "bold",
  },
}));

export const ManageUsers: FC = () => {
  const classes = useStyles();
  const { token, user, hasPermission } = useAuth();

  const [activeItem, setActiveItem] = useState("users");
  const [modalOpened, setModalOpened] = useState(false);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUserEmail, setSelectedUserEmail] = useState<string>("");
  const [selectedUserRoleId, setSelectedUserRoleId] = useState<number>(0);
  const [searchResult, setSearchResult] = useState([]);
  const [searching, setSearching] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [userRemovedModalOpened, setUserRemovedModalOpened] = useState(false);
  const [userRemovedMessage, setUserRemovedMessage] = useState("");
  const [userRemovedErrorMessage, setUserRemovedErrorMessage] = useState("");
  const itemRefs = useRef<any>([null]);
  const height = window.innerHeight;

  useEffect(() => {
    window.scrollTo(0, 0);
    const HandleScroll = () => {
      itemRefs.current.forEach((item: any, index: number) => {
        if (item?.getBoundingClientRect().y < (height * 3) / 4) setActiveItem(item.id);
      });
    };
    window.addEventListener("scroll", HandleScroll);
    return () => window.removeEventListener("scroll", HandleScroll);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = async () => {
      const roles = await Service.get(apiConfig.userRoles, token as string);
      const users = await Service.get(apiConfig.users, token as string);
      if (!roles.data.error) setRoles(roles.data);
      if (!users.data.error) setUsers(users.data);
    };
    fetchData();
  }, []);
  const addRole = async (email: string, roleId: number) => {
    try {
      const update = await Service.put(`${apiConfig.users}/${email}/role`, token as string, { roleId });
      handleOnSave();
    } catch (err: any) {
      if (err?.response?.data?.error) {
        setErrorMessage(err?.response?.data?.message);
      }
    }
  };
  const removeRole = async (email: string) => {
    try {
      const remove = await Service.delete(`${apiConfig.users}/${email}/role`, token as string, { email });
      setUserRemovedMessage(remove.data);
      setUserRemovedModalOpened(true);
      handleOnSave();
    } catch (err: any) {
      if (err?.response?.data?.error) {
        setUserRemovedErrorMessage(err?.response?.data?.message);
        setUserRemovedModalOpened(true);
      }
    }
  };
  const sections = [
    { name: "USERS", id: "users" },
    { name: "HELP / TUTORIALS", id: "help" },
  ]; // Nav sections

  const scrollToSection = (index: string) => {
    window.scrollTo({
      behavior: "smooth",
      top: itemRefs.current[index].offsetTop - 400,
    });
  };

  const handleOnSave = async () => {
    try {
      const users = await Service.get(apiConfig.users, token as string);
      if (!users.data.error) setUsers(users.data);
    } catch (err) {
      setUsers([]);
      console.log(err);
    }
    setModalOpened(false);
    setSelectedUserEmail("");
    setSelectedUserRoleId(0);
  };
  const handleOnCancel = () => {
    setSelectedUserEmail("");
    setSelectedUserRoleId(0);
  };
  const searchUser = (keyword: string) => {
    if (keyword === "") {
      setSearching(false);
      setSearchResult([]);
    } else {
      const results = users.filter(
        (item: IUser) =>
          item.firstName.toLowerCase().startsWith(keyword.toLowerCase()) ||
          item.lastName.toLowerCase().startsWith(keyword.toLowerCase())
      );
      setSearchResult(results);
      setSearching(true);
    }
  };
  return (
    <div>
      <ModalComponent
        open={modalOpened}
        setOpen={setModalOpened}
        title={`${selectedUserEmail ? "Edit" : "Add"}  Permission`}
      >
        <AddRole
          roles={roles}
          setOpen={setModalOpened}
          modalOpened={modalOpened}
          onSave={handleOnSave}
          onCancel={handleOnCancel}
          currentRoleId={selectedUserRoleId}
          currentEmail={selectedUserEmail}
          errorMessage={errorMessage}
          addRole={addRole}
        />
      </ModalComponent>
      <ModalComponent title="" open={userRemovedModalOpened} setOpen={setUserRemovedModalOpened}>
        <div style={{ marginTop: "10px" }}>
          {userRemovedMessage && <span className={classes.message}>{userRemovedMessage}</span>}
          {userRemovedErrorMessage && <span className={classes.updateUserError}>{userRemovedErrorMessage}</span>}
        </div>
      </ModalComponent>
      <div className={classes.banner}>
        <ManageUsersBanner
          data={{}}
          fullName={user?.displayName}
          role={user?.role}
          profilePictureUrl={user?.profilePictureUrl}
        />
      </div>
      <div className={classes.body}>
        <div className={classes.leftNavigation}>
          <NavigationBar sectionNames={sections} scrollToSection={scrollToSection} />
        </div>
        <div style={{ marginTop: "40px" }}>
          <div id="users">
            <UsersListSectionCard
              title="Users"
              onClickButton={() => {
                setErrorMessage("");
                setSelectedUserEmail("");
                setSelectedUserRoleId(0);
                setModalOpened(true);
              }}
              searchOnChange={(keyword) => searchUser(keyword)}
            >
              <UserListTable
                data={searching ? searchResult : users}
                selectUser={(roleId: number, email: string) => {
                  if (hasPermission("UserRoles.ReadWrite.Editor") || hasPermission("UserRoles.ReadWrite.All")) {
                    setSelectedUserRoleId(roleId);
                    setSelectedUserEmail(email);
                    setModalOpened(true);
                  }
                }}
                onRemove={(email) => removeRole(email)}
              />
            </UsersListSectionCard>
          </div>
          <div id="help">
            <div className={classes.helpContainer}>
              <span className={classes.helpH1}>Help - Tutorials</span>
              <div className={classes.helpSubject}>
                <div className={classes.helpH2}>Help Title</div>
                <p className={classes.helpDetail}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.
                </p>
                <div className={classes.howTo}>
                  <a href="#" className={classes.howToLink}>
                    <span>How to: help link</span>
                    <div style={{ marginLeft: "13px" }}>
                      <img src={External} className={classes.howToLinkImage} />
                    </div>
                  </a>
                </div>
              </div>
              <div className={classes.helpSubject}>
                <div className={classes.helpH2}>Help Title</div>
                <p className={classes.helpDetail}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.
                </p>
                <div className={classes.howTo}>
                  <a href="#" className={classes.howToLink}>
                    <span>How to: help link</span>
                    <div style={{ marginLeft: "13px" }}>
                      <img src={External} className={classes.howToLinkImage} />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
