import { FC, useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ParentItem, Level, TreeItem } from "../../interfaces";
import { Tree } from "./tree";
import { ClearFilters } from "../clearfilters";
import { ApplyButton } from "../applyButton";
import rightArrow from "../../assets/rightArrow.svg";
import { getParentsIds } from "../../utils/industries";

interface Props {
  title: string;
  data: Level;
  selected: (string | number)[] | string | number;
  selectedName?: string;
  setSelected: (data: (string | number)[] | string | number, level: Level, selectedName?: string) => void;
  width?: string;
  isSingle?: boolean;
  isDisabled?: boolean;
  bottom?: string;
  right?: string;
}

const useStyles = makeStyles(() => ({
  frame: {
    marginRight: "20px",
    position: "relative",
    lineHeight: "1.4375em",
    backgroundImage: `url(${rightArrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
  },
  title: {
    color: "#4d748d",
    fontFamily: "Montserrat, sans-serif",
    fontSize: 12,
    border: "1px solid #4d748d",
    borderRadius: 3,
    padding: "13px 10px 10px",
    paddingRight: "32px",
  },
  container: {
    position: "absolute",
    backgroundColor: "#f9f9f9",
    padding: "12px 16px",
    zIndex: 10,
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.4), 0 2px 20px 0 rgba(0,0,0,0.1)",
    marginTop: "10px",
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
    alignItems: "center",
  },
  tree: {
    maxWidth: "800px",
    minWidth: "160px",
    width: "auto",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      height: "10px",
    },
  },
  box: {
    marginRight: "20px",
  },
}));

export const MultiLevelFilter: FC<Props> = ({
  title,
  data,
  selected,
  setSelected,
  isSingle = false,
  selectedName = "",
  width = "100%",
  isDisabled = false,
  bottom,
  right,
}) => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [level, setLevel] = useState<Level>({ selected_id: null, items: {} });
  const [selectedItems, setSelectedItems] = useState<(string | number)[]>([]);
  const [singleItem, setSingleItem] = useState<string | number>(0);
  const [singleItemTitle, setSingleItemTitle] = useState<string>("");
  const [semiSelectedItems, setSemiSelectedItems] = useState<(string | number)[]>([]);
  const [parentIds, setParentIds] = useState<(string | number)[]>([]);
  const [initialParentIds, setInitialParentIds] = useState<(string | number)[]>([]);
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef); //close div on click outside

  useEffect(() => {
    setLevel(data);
    if (!isSingle && Array.isArray(selected))
      (selected as (string | number)[])?.forEach((id) =>
        setIndeterminateItems(data, id, selected as (string | number)[], semiSelectedItems)
      );
  }, [data]);

  useEffect(() => {
    if (isSingle) {
      if (selected) {
        setSingleItem(selected as string | number);
        setSingleItemTitle(selectedName);
        setParentIds(getParentsIds(String(selected)));
        setInitialParentIds(getParentsIds(String(selected)));
      }
    } else {
      setSelectedItems(selected as (string | number)[]);
    }
  }, [selected]);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsVisible(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const isObject = (value: Level) => {
    return !!(value && typeof value === "object" && !Array.isArray(value));
  };

  const findNestedObject = (object: Level, keyToMatch: string | number): Level | null => {
    const items = object?.items;
    const entries = Object.entries(items);

    if (entries.length > 0 && isObject(object)) {
      for (let i = 0; i < entries.length; i++) {
        const [objectKey, objectValue] = entries[i];

        if (objectKey === keyToMatch) {
          return object;
        }

        if (objectValue.children) {
          const child = findNestedObject(objectValue?.children, keyToMatch);

          if (child) {
            return child;
          }
        }
      }
    }

    return null;
  };

  const onItemClick = (item: ParentItem, justShowList = false) => {
    const temp_level = { ...level };
    const selected_level = findNestedObject(temp_level, item.id);

    if (selected_level !== null) {
      if (selected_level?.items[item?.id].children) {
        !justShowList && updateSelectedItems(item);
      } else {
        if (item?.childrenCount === 0) {
          (selected_level as Level).items[item?.id].children = null;
          !justShowList && updateSelectedItems(item);
          updateSelectedItems(item);
          setLevel(temp_level);
        }
      }
    }
  };

  const updateSelectedItems = (item: ParentItem) => {
    if (isSingle) {
      item?.id === singleItem ? setSingleItem(0) : setSingleItem(item?.id);
      item?.name === singleItemTitle ? setSingleItemTitle("") : setSingleItemTitle(item?.name);
    } else {
      const temp_array = [...selectedItems];
      const temp_semi_array = [...semiSelectedItems];
      const index = temp_array.indexOf(item?.id);

      if (index > -1) {
        temp_array.splice(index, 1);
      } else {
        temp_array.push(item?.id);
      }

      selectChildren(temp_array, item);
      setIndeterminateItems(level, item?.id, temp_array, temp_semi_array);
    }
    setParentIds([]);
  };

  const setIndeterminateItems = (
    level: Level,
    itemId: string | number,
    selected_items: (string | number)[],
    semi_selected_items: (string | number)[]
  ) => {
    const item = findNestedObject(level, itemId)?.items[itemId] as ParentItem;
    const parent_id = item?.parentId as string | number;

    const checkAllChildrenSelected = allSelected(item, selected_items, semi_selected_items);

    if (checkAllChildrenSelected === "all") {
      if (!selected_items.includes(item?.id)) selected_items.push(item?.id);
      if (semi_selected_items.includes(item?.id)) semi_selected_items.splice(semi_selected_items.indexOf(item?.id), 1);
    } else if (checkAllChildrenSelected === "some") {
      if (!semi_selected_items.includes(item?.id)) semi_selected_items.push(item?.id);
      if (selected_items.includes(item?.id)) selected_items.splice(selected_items.indexOf(item?.id), 1);
    } else if (checkAllChildrenSelected === "none") {
      if (semi_selected_items.includes(item?.id)) semi_selected_items.splice(semi_selected_items.indexOf(item?.id), 1);
      if (selected_items.includes(item?.id)) selected_items.splice(selected_items.indexOf(item?.id), 1);
    }
    if (parent_id) setIndeterminateItems(level, parent_id, selected_items, semi_selected_items);

    semi_selected_items.forEach((id: string | number) => {
      if (selected_items.includes(id)) semi_selected_items.splice(semi_selected_items.indexOf(id), 1);
    }); //after all checks, if they still have same item, it should be selected, not indeterminate.

    setSelectedItems(selected_items);
    setSemiSelectedItems(semi_selected_items);
  };

  const allSelected = (
    item: ParentItem,
    selected_items: (string | number)[],
    indeterminate_items: (string | number)[]
  ) => {
    let selected = null;

    if (item?.children) {
      const itemsArray = [];
      if (item.children && item.children.items) {
        for (const key of Object.keys(item.children?.items)) {
          const keyInt = parseInt(key);
          if (item.children.items[keyInt]) itemsArray.push(item.children.items[keyInt]);
        }
      }

      if (itemsArray.every((item: ParentItem) => selected_items.includes(item?.id))) {
        for (const childItem of itemsArray) {
          if (selected_items.includes(childItem.id)) {
            selected = "all";
            break;
          }
        } //if all children is selected
      } else if (
        itemsArray.some((item: ParentItem) => selected_items.includes(item?.id)) ||
        itemsArray.some((item: ParentItem) => indeterminate_items.includes(item?.id))
      ) {
        selected = "some";
      } else {
        selected = "none";
      }
    }

    return selected;
  };

  const selectChildren = (array: (string | number)[], item: ParentItem) => {
    if (item?.children !== null) {
      const children = (item?.children as Level)?.items;

      Object.keys(children)?.forEach((id) => {
        const index = array.indexOf(id);
        if (index > -1 && !array.includes(children[Number(id)]?.parentId as string)) {
          array.splice(index, 1);
        } else if (index < 0 && array.includes(children[id]?.parentId as string)) {
          array.push(id);
        }

        if (children[id].children) selectChildren(array, children[id]);
      });

      setSelectedItems(array);
    }
  };

  const cancel = () => {
    if (isSingle) {
      setSingleItem(selected as string);
      setSingleItemTitle(selectedName);
      setParentIds(initialParentIds);
    } else {
      setSelectedItems(selected as (string | number)[]);
      if ((selected as (string | number)[])?.length > 0) {
        (selected as (string | number)[])?.forEach((id) =>
          setIndeterminateItems(data, id, selected as (string | number)[], [])
        );
      } else {
        setSemiSelectedItems([]);
      }
    }
    setIsVisible(false);
  };

  const apply = () => {
    setSelected(isSingle ? singleItem : selectedItems, level, singleItemTitle);
    setIsVisible(false);
  };

  const clear = () => {
    setSelectedItems([]);
    setSingleItem('');
    setSingleItemTitle("");
    setSemiSelectedItems([]);
    setParentIds([]);
    setInitialParentIds([]);
  };

  return (
    <div style={{ width: width }} className={classes.box} ref={wrapperRef}>
      <div className={classes.frame} style={{ width: width }}>
        <div onClick={() => !isDisabled && setIsVisible(!isVisible)} className={classes.title}>
          {isSingle && singleItemTitle
            ? `${singleItemTitle}`
            : !isSingle && selectedItems?.length
            ? `${title} (${selectedItems.length})`
            : title}
        </div>
      </div>
      {isVisible && (
        <div className={classes.container} style={{ bottom: bottom, right: right }}>
          <div className={classes.tree}>
            <Tree
              level={level}
              onClick={onItemClick}
              selectedList={selectedItems}
              singleItem={singleItem}
              semiSelectedList={semiSelectedItems}
              parentItems={parentIds}
            />
          </div>
          <div className={classes.buttons}>
            <div>
              <ClearFilters text="Clear all" clear={clear} />
            </div>
            <div>
              <ClearFilters text="Cancel" clear={cancel} />
              <span style={{ marginLeft: 10 }}></span>
              <ApplyButton apply={apply} text="Apply" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
