import { FC } from "react";
import "../../styles/main.scss";
import { makeStyles } from "@material-ui/core/styles";
import starIcon from "../../assets/star.png";
import rightArrow from "../../assets/rightArrow.png";
import editIcon from "../../assets/edit.png";
import { ISearchCompany } from "../../interfaces";
import { NavLink } from "react-router-dom";
import { Avatar } from "../avatar";

interface Props {
  company: ISearchCompany;
}

const useStyles = makeStyles(() => ({
  root: {
    height: 60,
    display: "flex",
    borderRadius: 2,
    backgroundColor: "#FFFFFF",
    "&:hover": {
      boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
    },
  },
  companyInfo: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    flex: 1,
    cursor: "pointer",
  },
  companyName: {
    color: "#4D748D",
    fontFamily: "Oswald",
    fontSize: 15,
  },
  companyDetail: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: 12,
  },
  button: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    alignItems: "center",
    marginLeft: 30,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 20,
    width: 20,
  },
}));

export const CompanyRow: FC<Props> = ({ company }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar name={company.name} logo={company.logo} style={{ height: 50, width: 50 }} />
      <NavLink to={`/companies/${company.id}`} state={{ edit: false }} style={{ textDecoration: "none" }}>
        <div className={classes.companyInfo}>
          <span className={classes.companyName}>
            {company.isArchived ? `${company.name} [ARCHIVED]` : company.name}
          </span>
          <span className={classes.companyDetail}>{company.primaryIndustry ?? ""}</span>{" "}
          {/*TO DO: Update this after API updates */}
        </div>
      </NavLink>
      <div style={{ margin: "auto", marginRight: 0 }}>
        <button
          className={classes.button}
          style={{ backgroundImage: `url("${starIcon}")` }}
          title="action not implemented"
        ></button>
        <NavLink to={`/companies/${company.id}`} state={{ edit: true }} style={{ textDecoration: "none" }}>
          <button
            className={classes.button}
            style={{ backgroundImage: `url("${editIcon}")` }}
            title="action not implemented"
          ></button>
        </NavLink>
        <NavLink to={`/companies/${company.id}`} state={{ edit: false }} style={{ textDecoration: "none" }}>
          <button className={classes.button} style={{ backgroundImage: `url("${rightArrow}")`, width: 30 }}></button>
        </NavLink>
      </div>
    </div>
  );
};
