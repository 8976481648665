import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";

import closeLogo from "../../../assets/close-circle.png";
import { CheckboxSelect } from "../../checkbox";

import { CompanyReference, DataSource, EditSubsidiariesData, SubsidiaryItem } from "./edit-subsidiaries-modal";
import { AddSubsidiaryModal } from "./add-subsidiary-modal";
import { AddExternalSubsidiaryModal } from "./add-external-subsidiary-modal";

const useStyles = makeStyles(() => ({
  dialogContainer: {
    position: "absolute",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    borderRadius: "5px",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  dialogSurface: {
    minWidth: "400px",
    backgroundColor: "#FFFFFF",
    padding: "30px 50px",
    position: "relative",
  },
  dialogTitle: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "32px",
    fontWeight: "normal",
    letterSpacing: 0,
    lineHeight: "34px",
    margin: "0 0 10px",
  },
  dialogCloseButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 24,
    width: 24,
    backgroundImage: `url(${closeLogo})`,
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  dialogContent: {},
  dialogActions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
    marginTop: "30px",
  },
  cancelButton: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 12,
    borderRadius: 3,
    height: 38,
    textTransform: "none",
    border: "1px solid",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D748D",
    width: "74px",
  },
  applyButton: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 12,
    borderRadius: 3,
    height: 38,
    textTransform: "none",
    border: "1px solid",
    cursor: "pointer",
    textAlign: "center",
    width: "74px",
    color: "#FFFFFF",
    backgroundColor: "#4D748D",
    "&:hover": {
      backgroundColor: "#608dab",
    },
    "&:disabled": {
      backgroundColor: "#bebebe",
      color: "#5c5c5c",
    },
  },

  editSubsidiaryForm: {
    display: "grid",
    gridTemplateAreas: `
    "h-1 h-2"
    "c-1 c-2"
    "m-1 m-2"
    `,
    gap: "20px 40px",
  },

  subsidiaryItemName: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: 0,
  },
}));

interface Props {
  companyId: number;
  initialEditFieldData: EditSubsidiariesData;
  onSave: (data: EditSubsidiariesData) => void;
  onCancel: () => void;
}

const AddItemChip = styled(Chip)(() => ({
  color: "#4D748D !important",
  fontWeight: 600,
}));
const AddItemIcon = styled(AddCircleOutline)(() => ({
  color: "#4D748D !important",
}));
const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ManualItemsContainer = styled(Paper)(() => ({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  listStyle: "none",
  maxHeight: "120px",
  overflow: "auto",
}));

export const EditSubsidiariesForm = ({ companyId, initialEditFieldData, onSave, onCancel }: Props) => {
  const classes = useStyles();

  const [editFieldData, setEditFieldData] = useState(initialEditFieldData);

  const [openLinkSubsidiaryDialog, setOpenLinkSubsidiaryDialog] = useState(false);
  const [openLinkExternalSubsidiaryDialog, setOpenLinkExternalSubsidiaryDialog] = useState(false);

  const sourcesById = editFieldData.dataSources.reduce((acc, source) => {
    acc[source.id] = source;
    return acc;
  }, {} as Record<string, DataSource>);

  const manualSourceId = editFieldData.dataSources.find((source) => source.name === "Manual")?.id ?? 2;

  const fields = editFieldData.data.map((field, index) => {
    return {
      index,
      field,
    };
  });

  const internalSubsidiaryItems = fields.filter((x) => x.field.company !== null);
  const internalSubsidiaryItemsAddedManually = internalSubsidiaryItems.filter(
    (x) => x.field.sourceId === manualSourceId
  );

  const otherSubsidiaryItems = fields.filter((x) => x.field.company === null);
  const otherSubsidiaryItemsAddedManually = otherSubsidiaryItems.filter((x) => x.field.sourceId === manualSourceId);

  const addNewSubsidiary = (company: CompanyReference) => {
    const newSub: SubsidiaryItem = {
      id: null,
      company: {
        id: company.id,
        name: company.name,
        image: company.image,
      },
      externalCompany: {
        id: company.id.toString(),
        name: company.name,
      },
      sourceId: manualSourceId,
      include: true,
    };

    setEditFieldData((prevState) => {
      return {
        ...prevState,
        data: [newSub, ...prevState.data],
      };
    });
  };

  const addNewExternalSubsidiary = (name: string) => {
    const newSub: SubsidiaryItem = {
      id: null,
      company: null,
      externalCompany: {
        id: null,
        name,
      },
      sourceId: manualSourceId,
      include: true,
    };

    setEditFieldData((prevState) => {
      return {
        ...prevState,
        data: [newSub, ...prevState.data],
      };
    });
  };

  const updateCheckedForItem = (index: number, checked: boolean) => {
    setEditFieldData((prevState) => {
      return {
        ...prevState,
        data: prevState.data.map((item, i) => (i == index ? { ...item, include: checked } : item)),
      };
    });
  };

  const removeItem = (index: number) => {
    setEditFieldData((prevState) => {
      return {
        ...prevState,
        data: prevState.data.filter((item, i) => i !== index),
      };
    });
  };

  return (
    <>
      <div className={classes.dialogContainer}>
        <div className={classes.dialogSurface}>
          <h2 className={classes.dialogTitle}>Edit: Subsidiaries</h2>
          <button className={classes.dialogCloseButton} onClick={onCancel} />
          <div className={classes.dialogContent}>
            <div className={classes.editSubsidiaryForm}>
              <h3 style={{ gridArea: "h-1" }}>Subsidiaries in EIQ Database</h3>
              <section style={{ gridArea: "c-1" }}>
                <h4>Consolidated Data</h4>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ width: 500, maxHeight: 400 }}>
                    <Table stickyHeader size="small" aria-label="company subsidiaries table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Company</TableCell>
                          <TableCell align="left">Data Source</TableCell>
                          <TableCell align="left">Include</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {internalSubsidiaryItems.map(({ field, index }) => {
                          const image = field.company?.image ?? null;
                          const name = field.company ? field.company.name : field.externalCompany.name;
                          const initials = name
                            .split(" ")
                            .slice(0, 2)
                            .map((n) => n[0])
                            .join("");

                          return (
                            <StyledTableRow key={index}>
                              <TableCell component="th" scope="row">
                                <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                                  {image ? (
                                    <Avatar
                                      variant="rounded"
                                      alt={name}
                                      src={image}
                                      imgProps={{ sx: { objectFit: "contain" } }}
                                    />
                                  ) : (
                                    <Avatar variant="rounded" alt={name}>
                                      {initials}
                                    </Avatar>
                                  )}
                                  <span className={classes.subsidiaryItemName}>{name}</span>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">{sourcesById[field.sourceId]?.name}</TableCell>
                              <TableCell align="center">
                                <CheckboxSelect
                                  checkboxLabel=""
                                  checked={field.include}
                                  setChecked={(checked: boolean) => {
                                    updateCheckedForItem(index, checked);
                                  }}
                                />
                              </TableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </section>
              <section style={{ gridArea: "m-1" }}>
                <h4>Enter Data Manually</h4>
                <ManualItemsContainer>
                  <ListItem key="link-subsidiary">
                    <AddItemChip
                      icon={<AddItemIcon />}
                      label="Link Subsidiary"
                      variant="outlined"
                      onClick={() => {
                        setOpenLinkSubsidiaryDialog(true);
                      }}
                    />
                  </ListItem>
                  {internalSubsidiaryItemsAddedManually.map(({ field, index }) => {
                    return (
                      <ListItem key={index}>
                        <Chip
                          avatar={
                            <Avatar
                              alt={field.company!.name}
                              src={field.company!.image!}
                              imgProps={{ sx: { objectFit: "contain" } }}
                            />
                          }
                          label={field.company!.name}
                          variant="outlined"
                          onDelete={() => {
                            removeItem(index);
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </ManualItemsContainer>
              </section>
              <h3 style={{ gridArea: "h-2" }}>Other Subsidiaries</h3>
              <section style={{ gridArea: "c-2" }}>
                <h4>Consolidated Data</h4>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ width: 500, maxHeight: 400 }}>
                    <Table stickyHeader size="small" aria-label="company external-subsidiaries table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Company</TableCell>
                          <TableCell align="left">Data Source</TableCell>
                          <TableCell align="left">Include</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {otherSubsidiaryItems.map(({ field, index }) => {
                          const name = field.externalCompany.name;
                          const initials = name
                            .split(" ")
                            .slice(0, 2)
                            .map((n) => n[0])
                            .join("");

                          return (
                            <StyledTableRow key={index}>
                              <TableCell component="th" scope="row">
                                <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                                  <Avatar variant="rounded" alt={name}>
                                    {initials}
                                  </Avatar>
                                  <span className={classes.subsidiaryItemName}>{name}</span>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">{sourcesById[field.sourceId]?.name}</TableCell>
                              <TableCell align="center">
                                <CheckboxSelect
                                  checkboxLabel=""
                                  checked={field.include}
                                  setChecked={(checked: boolean) => {
                                    updateCheckedForItem(index, checked);
                                  }}
                                />
                              </TableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </section>
              <section style={{ gridArea: "m-2" }}>
                <h4>Enter Data Manually</h4>
                <ManualItemsContainer>
                  <ListItem key="add-external-subsidiary">
                    <AddItemChip
                      icon={<AddItemIcon />}
                      label="Link External Subsidiary"
                      variant="outlined"
                      onClick={() => {
                        setOpenLinkExternalSubsidiaryDialog(true);
                      }}
                    />
                  </ListItem>
                  {otherSubsidiaryItemsAddedManually.map(({ field, index }) => {
                    const name = field.externalCompany.name;
                    const initials = name
                      .split(" ")
                      .slice(0, 2)
                      .map((n) => n[0])
                      .join("");

                    return (
                      <ListItem key={index}>
                        <Chip
                          avatar={<Avatar alt={name}>{initials}</Avatar>}
                          label={name}
                          variant="outlined"
                          onDelete={() => {
                            removeItem(index);
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </ManualItemsContainer>
              </section>
            </div>
          </div>
          <div className={classes.dialogActions}>
            <button className={classes.cancelButton} onClick={onCancel}>
              Cancel
            </button>
            <button className={classes.applyButton} onClick={() => onSave(editFieldData)}>
              Apply
            </button>
          </div>
        </div>
      </div>
      <AddSubsidiaryModal
        isVisible={openLinkSubsidiaryDialog}
        setIsVisible={setOpenLinkSubsidiaryDialog}
        onSave={(company) => {
          addNewSubsidiary(company);
          setOpenLinkSubsidiaryDialog(false);
        }}
        subsidiaryIds={internalSubsidiaryItems.map((v) => v.field.company!.id)}
        companyId={companyId}
      />
      <AddExternalSubsidiaryModal
        isVisible={openLinkExternalSubsidiaryDialog}
        setIsVisible={setOpenLinkExternalSubsidiaryDialog}
        onSave={(name) => {
          addNewExternalSubsidiary(name);
          setOpenLinkExternalSubsidiaryDialog(false);
        }}
      />
    </>
  );
};
