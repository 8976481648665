import { FC, ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  dropdown: {
    position: "relative",
    display: "inline-block",
    "&:hover $dropdownContent": {
      display: "block",
    },
  },
  dropdownContent: {
    display: "none",
    position: "absolute",
    minWidth: "160px",
    maxHeight: "100px",
    padding: "10px 15px",
    zIndex: 1,
    width: "160px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  image: {
    width: "24px",
    height: "24px",
  },
  title: {
    color: "#333333",
    backgroundColor: "transparent",
    border: "none",
    fontSize: "14px",
    lineHeight: "18px",
    fontFamily: "Montserrat",
    margin: 0,
    fontWeight: 500,
    cursor: "pointer",
    marginLeft: "10px",
  },
  titleDiv: {
    display: "flex",
    placeItems: "center",
    marginTop: "5px",
  },
}));

interface Props {
  title: string;
  children: ReactNode;
  icon?: string;
}

export const DropdownMenu: FC<Props> = ({ title, children, icon }) => {
  const classes = useStyles();

  return (
    <div className={classes.dropdown}>
      <div className={classes.titleDiv}>
        <img className={classes.image} src={icon} alt="icon" />
        <span className={classes.title}>{title}</span>
      </div>
      <div className={classes.dropdownContent}>{children}</div>
    </div>
  );
};
