import { FC, useState } from "react";
import { makeStyles } from "@material-ui/core";
import circleLogo from "../../../assets/menu.svg";
import Popover from "@mui/material/Popover";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "16px",
    letterSpacing: "0",
    lineHeight: "17px",
    marginBottom: "10px",
  },
  logo: {
    width: "25px",
    padding: "5px 0",
    cursor: "pointer",
  },
  pop_up: {
    borderRadius: "2.73px",
    boxShadow: "0 2px 10px 0 rgba(0,0,0,0.1)",
    right: 30,
    top: 60,
    "& div": {
      borderBottom: "0.5px solid #DDDDDD",
      padding: "10px 25px",
      color: "#717171",
      fontFamily: "Montserrat",
      fontSize: "10px",
      letterSpacing: "0",
      lineHeight: "14px",
      textAlign: "center",
      cursor: "pointer",
    },
  },
  selected_option: {
    fontWeight: 600,
    color: "#4D748D !important",
  },
}));

interface Props {
  title: string;
  selected: string;
  setSelected: (title: string) => void;
  options: string[];
}

export const DateRangePopup: FC<Props> = ({ title, selected, setSelected, options }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLImageElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={classes.header}>
      <div className={classes.title}>{title}</div>
{/*      <div>
        <img src={circleLogo} alt="options logo" className={classes.logo} onClick={handleClick} />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <div className={classes.pop_up}>
          {options.map((option: string, index: number) => (
            <div
              className={selected === option ? classes.selected_option : ""}
              onClick={() => setSelected(option)}
              key={index}
            >
               First letter uppercase
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </div>
          ))}
        </div>
      </Popover>*/}
    </div>
  );
};
