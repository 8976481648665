import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import "../styles/main.scss";
import rightArrow from "../assets/rightArrow.svg";

//component props
interface Props<DataItem> {
  items: DataItem[];
  title: string;
  stringify: (item: DataItem) => string;
  value: (item: DataItem) => string | number;
  setSelected: (data: string[] | number[]) => void;
  selected: Array<string | number>;
  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  frame: {
    backgroundImage: `url(${rightArrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    marginRight: "20px",
  },
  formControlRoot: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      color: "#4d748d",
      fontFamily: "Montserrat, sans-serif",
      fontSize: 12,
      border: "1px solid #4d748d",
      borderRadius: 3,
    },
  },
  selectRoot: {
    "& .MuiSelect-select": {
      border: "1px solid #4d748d",
      borderRadius: 3,
      padding: "13px 10px 10px",
    },
  },
  labelRoot: {
    color: "#4d748d !important",
    fontFamily: "Montserrat, sans-serif !important",
    fontSize: "12px !important",
  },
  listItemRoot: {
    "& .MuiTypography-root": {
      color: "#333333",
      fontFamily: "Montserrat, sans-serif ",
      fontSize: 12,
      display: "block",
    },
  },
}));

//Material UI Select Properties
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
      padding: 0,
    },
  },
};

export const MultiSelect = <DataItem extends object>({
  items,
  title,
  stringify,
  value,
  setSelected,
  selected,
  disabled,
}: Props<DataItem>) => {
  const classes = useStyles();
  //set selected items
  const handleChange = (event: any) => {
    !disabled && setSelected(event.target.value);
  };

  return (
    <div style={{ width: "100%" }} className={classes.frame}>
      <FormControl
        variant="outlined"
        classes={{
          root: classes.formControlRoot,
        }}
        style={{ width: "100%" }}
      >
        <InputLabel shrink={false} htmlFor="multiple-checkbox" className={classes.labelRoot}>
          {title} {selected.length ? `(${selected.length})` : ""}
        </InputLabel>
        <Select
          multiple
          displayEmpty
          value={selected}
          onChange={handleChange}
          renderValue={() => ""}
          MenuProps={MenuProps}
          className={classes.selectRoot}
          IconComponent={() => null}
        >
          {items.length === 0 && (
            <span
              style={{
                fontStyle: "italic",
                color: "#333333",
                fontFamily: "Montserrat, sans-serif",
                fontSize: 12,
                height: 35,
                fontWeight: 500,
                paddingLeft: "5px",
              }}
            >
              No Results
            </span>
          )}
          {items?.map((item: DataItem, index: number) => {
            return value(item) ? (
              <MenuItem
                key={index}
                value={value(item)}
                style={{
                  padding: 3,
                  height: 35,
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={selected.indexOf(value(item)) > -1}
                    style={{
                      color: disabled ? "#cccccc" : "#5c7c8c",
                      fill: "#2f556d",
                    }}
                    disabled={disabled}
                  />
                </ListItemIcon>
                <ListItemText primary={stringify(item)} classes={{ root: classes.listItemRoot }} />
              </MenuItem>
            ) : (
              <hr key={index}></hr>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
