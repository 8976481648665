import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    padding: theme.spacing(1.5, 3, 1.5),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    minWidth: "400px",
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 20px",
  },
}));

export const LoadingTemplate = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    </div>
  );
};
