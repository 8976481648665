import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const ToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip classes={{ popper: className }} enterDelay={300} leaveDelay={100} {...props} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "#000",
    fontSize: 11,
    boxShadow: "0 2px 10px 0 rgba(0,0,0,0.1)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff",
  },
}));

export default ToolTip;
