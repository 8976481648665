import { FC } from "react";

import dollarLogo from "../../../../assets/dollar-circle.svg";
import edit from "../../../../assets/edit.png";
import shopLogo from "../../../../assets/shop.svg";
import { FeatureCard} from "../../featureCard";
import { IDataItem } from "../../../../interfaces";
import { ISelectData } from "../../../../pages/companyView/types";
import { ICompanyEditModalFunctions } from "../../../../utils/companyEditModalFunctions";
import { QuarterlyRevenue } from "../../revenue-range/revenueRange";
import { FieldEditData } from "../../types";

type Props = {
  data: any;
  isEdit: boolean;
  classes: Record<string, string>;
  getHighestPriorityData: (property_name: string) => any;
  getHighestPriorityYearlyData: (property_name: string, key?: string, item_id?: number) => any;
  getHighestPriorityQuarterlyData: (property_name?: string, key?: string, item_id?: number) => any;
  editModalFunctions: ICompanyEditModalFunctions;
  editDataForFields: FieldEditData;
  getDataSource: (id: number) => { name: string; endpoint: string; id: number };
  selectedItems: Record<string, number[]>;
  quarterlyRevenue: QuarterlyRevenue | null;
}

export const FinancialsSection: FC<Props> = ({
  data,
  isEdit,
  classes,
  getHighestPriorityYearlyData,
  getHighestPriorityQuarterlyData,
  editModalFunctions,
  editDataForFields,
  getDataSource,
  selectedItems,
  quarterlyRevenue,
  getHighestPriorityData,
 }) => {

  const storesCountryClassName = !(
    (getHighestPriorityYearlyData("store_num_by_country", "country", 0) &&
      (selectedItems?.country as number[])?.length > 0) ||
    data?.store_num_by_country?.length
  )
    ? " no-print"
    : "";
  const storesStateClassName = !(
    (getHighestPriorityYearlyData("store_num_by_state", "state", 0) &&
      (selectedItems?.state as number[])?.length > 0) ||
    data?.store_num_by_state?.length
  )
    ? " no-print"
    : "";
  const storesStoreTypeClassName = !(
    (getHighestPriorityYearlyData("store_num_by_store_type", "store_type", 0) &&
      (selectedItems?.store_type as number[])?.length > 0) ||
    data?.store_num_by_store_type?.length
  )
    ? " no-print"
    : "";
  const shopperCountCountryClassName = !(
    (getHighestPriorityYearlyData("shopper_count_by_country", "country", 0) &&
      (editDataForFields?.shopper_count_selected_countries?.data as ISelectData[])?.length > 0) ||
    data?.shopper_count_by_country?.value.length > 0
  )
    ? "no-print"
    : "";

  return (
    <>
      {(isEdit ||
          (getHighestPriorityYearlyData("revenue_by_country_yearly", "country", 0)
            || quarterlyRevenue
            || getHighestPriorityQuarterlyData("revenue_by_country_quarterly", "country", 0)
            || getHighestPriorityYearlyData("store_num_by_country", "country", 0)
            || getHighestPriorityYearlyData("store_num_by_state", "state", 0)
            || getHighestPriorityYearlyData("store_num_by_store_type", "store_type", 0)
            || data?.shopper_count ||
            getHighestPriorityYearlyData("shopper_count_by_country", "country", 0))
        ) &&
        <div className={classes.sectionTitle}>Financials</div>
      }
      <div>
        {(getHighestPriorityYearlyData("revenue_by_country_yearly", "country", 0) || isEdit) &&
          <div className={classes.sectionTitle}>Yearly Revenue</div>
        }
        <div className={classes.subSectionConatiner}>
          {(getHighestPriorityYearlyData("revenue_by_country_yearly", "country", 0) || isEdit) &&
            <>
              <div className={classes.sectionSubTitle}>
                By Country{" "}
                {isEdit && (
                  <button
                    className={classes.button}
                    style={{
                      backgroundImage: `url(${edit})`,
                    }}
                    onClick={editModalFunctions.openYearlyRevenueByCountryEdit}
                  ></button>
                )}
              </div>
              <div className={classes.sectionContainer} style={{ marginTop: "15px" }}>
                {getHighestPriorityYearlyData("revenue_by_country_yearly", "country", 0) &&
                isEdit &&
                (editDataForFields?.revenue_selected_countries?.data as ISelectData[])?.length > 0
                  ? (editDataForFields?.revenue_selected_countries?.data as ISelectData[])?.map((item: ISelectData) =>
                    getHighestPriorityYearlyData("revenue_by_country_yearly", "country", item?.country_id)?.length >
                    0 ? (
                      <FeatureCard
                        key={item?.country_id}
                        title={`${
                          getHighestPriorityYearlyData("revenue_by_country_yearly", "country", item?.country_id)[0]
                            ?.country?.name
                        } (${
                          getHighestPriorityYearlyData("revenue_by_country_yearly", "country", item?.country_id)[0]
                            ?.year
                        })`}
                        data={getHighestPriorityYearlyData("revenue_by_country_yearly", "country", item?.country_id)[0]}
                        seperator
                        logo={dollarLogo}
                        isEdit={isEdit}
                        clicked={editModalFunctions.openYearlyRevenueByCountryEdit}
                        getDataSource={getDataSource}
                        sourceIds={getHighestPriorityYearlyData(
                          "revenue_by_country_yearly",
                          "country",
                          item?.country_id,
                        ).map((item: any) => item.sourceId | item.data_source_id)}
                        currency={
                          getHighestPriorityYearlyData("revenue_by_country_yearly", "country", item?.country_id)[0]
                            ?.currency || data?.currency?.value?.sign
                        }
                      />
                    ) : null,
                  )
                  : data?.revenue_by_country_yearly
                    ? data?.revenue_by_country_yearly?.value?.map((count: IDataItem) => (
                      <FeatureCard
                        key={count?.id}
                        title={`${count?.country?.name as string} (${count.year})`}
                        seperator
                        data={{ value: count?.value, sourceId: data?.revenue_by_country_yearly?.sourceId }}
                        logo={dollarLogo}
                        isEdit={isEdit}
                        clicked={editModalFunctions.openYearlyRevenueByCountryEdit}
                        getDataSource={getDataSource}
                        sourceIds={count.sourceId ? [count.sourceId] : []}
                        currency={count.currency || data?.currency?.value?.sign}
                      />
                    ))
                    : null}
              </div>
            </>
          }
        </div>
        {(quarterlyRevenue || isEdit) &&
          <div>
            <div className={classes.sectionTitle}>Quarterly Revenue</div>
            <div className={classes.subSectionConatiner}>
              <div className={classes.sectionContainer} style={{ marginTop: "15px" }}>
                {quarterlyRevenue && !isEdit ? (
                  <FeatureCard
                    title="Quarterly Revenue"
                    titleSize="s"
                    data={quarterlyRevenue}
                    seperator
                    logo={dollarLogo}
                    isEdit={isEdit}
                    clicked={editModalFunctions.openQuarterlyRevenueEdit}
                    sourceIds={[quarterlyRevenue.sourceId]}
                    getDataSource={getDataSource}
                    showYear={quarterlyRevenue && !!quarterlyRevenue.year}
                    currency={quarterlyRevenue.currency || data?.currency?.value?.sign}
                    showQuarter
                    decimalYearPart
                  />
                ) : isEdit ? (
                  <FeatureCard
                    title="Quarterly Revenue"
                    titleSize="s"
                    data={
                      getHighestPriorityYearlyData("quarterly_revenue") &&
                      getHighestPriorityYearlyData("quarterly_revenue")[0]
                        ? getHighestPriorityYearlyData("quarterly_revenue")[0]
                        : quarterlyRevenue
                    }
                    seperator
                    logo={dollarLogo}
                    isEdit={isEdit}
                    clicked={editModalFunctions.openQuarterlyRevenueEdit}
                    sourceIds={[
                      getHighestPriorityYearlyData("quarterly_revenue") &&
                      getHighestPriorityYearlyData("quarterly_revenue")[0]
                        ? getHighestPriorityYearlyData("quarterly_revenue")[0].data_source_id
                        : quarterlyRevenue
                          ? quarterlyRevenue.sourceId
                          : null,
                    ]}
                    getDataSource={getDataSource}
                    showYear={!!(quarterlyRevenue && !!quarterlyRevenue.year)}
                    showQuarter
                    decimalYearPart
                    currency={quarterlyRevenue?.currency || data?.currency?.value?.sign}
                  />
                ) : null}
              </div>
              <div className={classes.sectionSubTitle}>
                By Country{" "}
                {isEdit && (
                  <button
                    className={classes.button}
                    style={{
                      backgroundImage: `url(${edit})`,
                    }}
                    onClick={editModalFunctions.openQuarterlyRevenueCountryEdit}
                  ></button>
                )}
              </div>
              <div className={classes.sectionContainer} style={{ marginTop: "15px" }}>
                {getHighestPriorityQuarterlyData("revenue_by_country_quarterly", "country", 0) &&
                isEdit &&
                (editDataForFields?.revenue_selected_countries?.data as ISelectData[])?.length > 0
                  ? (editDataForFields?.revenue_selected_countries?.data as ISelectData[])?.map((item: ISelectData) =>
                    getHighestPriorityQuarterlyData("revenue_by_country_quarterly", "country", item?.country_id)
                      ?.length > 0 ? (
                      <FeatureCard
                        key={item?.country_id}
                        title={`${
                          getHighestPriorityQuarterlyData(
                            "revenue_by_country_quarterly",
                            "country",
                            item?.country_id,
                          )[0]?.country?.name
                        }`}
                        data={
                          getHighestPriorityQuarterlyData(
                            "revenue_by_country_quarterly",
                            "country",
                            item?.country_id,
                          )[0]
                        }
                        seperator
                        logo={dollarLogo}
                        isEdit={isEdit}
                        clicked={editModalFunctions.openQuarterlyRevenueCountryEdit}
                        getDataSource={getDataSource}
                        sourceIds={getHighestPriorityQuarterlyData(
                          "revenue_by_country_quarterly",
                          "country",
                          item?.country_id,
                        ).map((item: any) => item.sourceId | item.data_source_id)}
                        currency={
                          getHighestPriorityQuarterlyData(
                            "revenue_by_country_quarterly",
                            "country",
                            item?.country_id,
                          )[0]?.currency || data?.currency?.value?.sign
                        }
                        showQuarter
                        showYear
                        decimalYearPart
                      />
                    ) : null,
                  )
                  : data?.revenue_by_country_quarterly
                    ? data?.revenue_by_country_quarterly?.value?.map((count: IDataItem) => (
                      <FeatureCard
                        key={count?.id}
                        title={`${count?.country?.name as string}`}
                        seperator
                        data={{
                          value: count?.value,
                          sourceId: data?.revenue_by_country_quarterly?.sourceId,
                          year: count.year,
                          quarter: count.quarter,
                        }}
                        logo={dollarLogo}
                        isEdit={isEdit}
                        clicked={editModalFunctions.openQuarterlyRevenueCountryEdit}
                        getDataSource={getDataSource}
                        sourceIds={count.sourceId ? [count.sourceId] : []}
                        currency={count.currency || data?.currency?.value?.sign}
                        showYear
                        showQuarter
                        decimalYearPart
                      />
                    ))
                    : null}
              </div>
            </div>
          </div>
        }
        {(isEdit || (
            getHighestPriorityYearlyData("store_num_by_country", "country", 0)
            || getHighestPriorityYearlyData("store_num_by_state", "state", 0)
            || getHighestPriorityYearlyData("store_num_by_store_type", "store_type", 0))) &&
          <div>
            <div className={classes.sectionTitle}>Stores</div>
            {(data?.store_num === null || data?.store_num === undefined) &&
            !isEdit &&
            !getHighestPriorityYearlyData("stores") ? null : (
              <div className={`${classes.subSectionConatiner}${storesCountryClassName}`}>
                <div className={classes.sectionSubTitle}>
                  {isEdit && (
                    <button
                      className={classes.button}
                      style={{
                        backgroundImage: `url(${edit})`,
                      }}
                      onClick={editModalFunctions.openStoresEdit}
                    ></button>
                  )}
                </div>
                <div className={classes.sectionContainer} style={{ marginTop: "15px" }}>

                </div>
              </div>
            )}
            {(getHighestPriorityYearlyData("store_num_by_country", "country", 0) || isEdit) &&
              <div className={`${classes.subSectionConatiner}${storesCountryClassName}`}>
                <div className={classes.sectionSubTitle}>
                  By Country{" "}
                  {isEdit && (
                    <button
                      className={classes.button}
                      style={{
                        backgroundImage: `url(${edit})`,
                      }}
                      onClick={editModalFunctions.openCountryStoresEdit}
                    ></button>
                  )}
                </div>

                <div className={classes.sectionContainer} style={{ marginTop: "15px" }}>
                  {getHighestPriorityYearlyData("store_num_by_country", "country", 0) &&
                  (selectedItems?.country as number[])?.length > 0
                    ? selectedItems?.country?.map((id: number) =>
                      getHighestPriorityYearlyData("store_num_by_country", "country", id)?.length > 0 ? (
                        <FeatureCard
                          key={id}
                          title={getHighestPriorityYearlyData("store_num_by_country", "country", id)[0]?.country?.name}
                          data={getHighestPriorityYearlyData("store_num_by_country", "country", id)[0]}
                          logo={shopLogo}
                          isEdit={isEdit}
                          clicked={editModalFunctions.openCountryStoresEdit}
                          getDataSource={getDataSource}
                          sourceIds={getHighestPriorityYearlyData("store_num_by_country", "country", id).map(
                            (item: any) => item.sourceId | item.data_source_id,
                          )}
                        />
                      ) : null,
                    )
                    : data?.store_num_by_country?.length > 0
                      ? data?.store_num_by_country?.map((store: IDataItem) => (
                        <FeatureCard
                          key={store?.id}
                          title={store?.country?.name as string}
                          data={{ value: store?.value, sourceId: null }}
                          logo={shopLogo}
                          isEdit={isEdit}
                          clicked={editModalFunctions.openCountryStoresEdit}
                          getDataSource={getDataSource}
                          sourceIds={store.sourceId ? [store.sourceId] : []}
                        />
                      ))
                      : null}
                </div>
              </div>
            }
            {(getHighestPriorityYearlyData("store_num_by_state", "state", 0) || isEdit) &&
              <div className={`${classes.subSectionConatiner}${storesStateClassName}`}>
                <div className={classes.sectionSubTitle}>
                  By State/Province{" "}
                  {isEdit && (
                    <button
                      className={classes.button}
                      style={{
                        backgroundImage: `url(${edit})`,
                      }}
                      onClick={editModalFunctions.openStateStoresEdit}
                    ></button>
                  )}
                </div>
                <div className={classes.sectionContainer} style={{ marginTop: "15px" }}>
                  {getHighestPriorityYearlyData("store_num_by_state", "state", 0) &&
                  (selectedItems?.state as number[])?.length > 0
                    ? selectedItems?.state?.map((id: number) =>
                      getHighestPriorityYearlyData("store_num_by_state", "state", id)?.length > 0 ? (
                        <FeatureCard
                          key={id}
                          title={getHighestPriorityYearlyData("store_num_by_state", "state", id)[0]?.state?.name}
                          data={getHighestPriorityYearlyData("store_num_by_state", "state", id)[0]}
                          logo={shopLogo}
                          isEdit={isEdit}
                          clicked={editModalFunctions.openStateStoresEdit}
                          getDataSource={getDataSource}
                          sourceIds={getHighestPriorityYearlyData("store_num_by_state", "state", id).map(
                            (item: any) => item.sourceId | item.data_source_id,
                          )}
                        />
                      ) : null,
                    )
                    : data?.store_num_by_state?.length > 0
                      ? data?.store_num_by_state?.map((store: IDataItem) => (
                        <FeatureCard
                          key={store?.id}
                          title={store?.state?.name as string}
                          data={{ value: store?.value, sourceId: null }}
                          logo={shopLogo}
                          isEdit={isEdit}
                          clicked={editModalFunctions.openStateStoresEdit}
                          getDataSource={getDataSource}
                          sourceIds={store.sourceId ? [store.sourceId] : []}
                        />
                      ))
                      : null}
                </div>
              </div>
            }
            {(getHighestPriorityYearlyData("store_num_by_store_type", "store_type", 0) || isEdit) &&
              <div className={`${classes.subSectionConatiner}${storesStoreTypeClassName}`}>
                <div className={classes.sectionSubTitle}>
                  By Store Type{" "}
                  {isEdit && (
                    <button
                      className={classes.button}
                      style={{
                        backgroundImage: `url(${edit})`,
                      }}
                      onClick={editModalFunctions.openTypeStoresEdit}
                    ></button>
                  )}
                </div>
                <div className={classes.sectionContainer} style={{ marginTop: "15px" }}>
                  {getHighestPriorityYearlyData("store_num_by_store_type", "store_type", 0) &&
                  (selectedItems?.store_type as number[])?.length > 0
                    ? selectedItems?.store_type?.map((id: number) =>
                      getHighestPriorityYearlyData("store_num_by_store_type", "store_type", id)?.length > 0 ? (
                        <FeatureCard
                          key={id}
                          title={
                            getHighestPriorityYearlyData("store_num_by_store_type", "store_type", id)[0]?.store_type?.name
                          }
                          data={getHighestPriorityYearlyData("store_num_by_store_type", "store_type", id)[0]}
                          logo={shopLogo}
                          isEdit={isEdit}
                          clicked={editModalFunctions.openTypeStoresEdit}
                          getDataSource={getDataSource}
                          sourceIds={getHighestPriorityYearlyData("store_num_by_store_type", "store_type", id).map(
                            (item: any) => item.sourceId | item.data_source_id,
                          )}
                        />
                      ) : null,
                    )
                    : data?.store_num_by_store_type?.length > 0
                      ? data?.store_num_by_store_type?.map((store: IDataItem) => (
                        <FeatureCard
                          key={store?.id}
                          title={store?.store_type?.name as string}
                          data={{ value: store?.value, sourceId: null }}
                          logo={shopLogo}
                          isEdit={isEdit}
                          clicked={editModalFunctions.openTypeStoresEdit}
                          getDataSource={getDataSource}
                          sourceIds={store.sourceId ? [store.sourceId] : []}
                        />
                      ))
                      : null}
                </div>
              </div>}
          </div>}

        {((data?.shopper_count ||
              getHighestPriorityYearlyData("shopper_count_by_country", "country", 0))
            || isEdit) &&
          <div className={shopperCountCountryClassName}>
            <div className={classes.sectionTitle}>Shopper Count</div>
            {(data?.shopper_count === null || data?.shopper_count === undefined) &&
            !isEdit &&
            !getHighestPriorityData("shopper_count") ? null : (
              <div className={classes.subSectionConatiner}>
                <div className={classes.sectionSubTitle}>
                  Worldwide
                  {isEdit && (
                    <button
                      className={classes.button}
                      style={{
                        backgroundImage: `url(${edit})`,
                      }}
                      onClick={editModalFunctions.openShopperCountEdit}
                    ></button>
                  )}
                </div>
                <div className={classes.sectionContainer} style={{ marginTop: "15px" }}>
                  <FeatureCard
                    title="Shopper count"
                    data={
                      getHighestPriorityData("shopper_count")
                        ? getHighestPriorityData("shopper_count")
                        : data?.shopper_count
                    }
                    seperator
                    logo={shopLogo}
                    isEdit={isEdit}
                    clicked={editModalFunctions.openShopperCountEdit}
                    getDataSource={getDataSource}
                    sourceIds={[
                      getHighestPriorityData("shopper_count")
                        ? getHighestPriorityData("shopper_count")?.sourceId
                        : data?.shopper_count?.sourceId,
                    ]}
                  />
                </div>
              </div>
            )}
            {(getHighestPriorityYearlyData("shopper_count_by_country", "country", 0) || isEdit) &&
              <div className={classes.subSectionConatiner}>
                <div className={classes.sectionSubTitle}>
                  By Country{" "}
                  {isEdit && (
                    <button
                      className={classes.button}
                      style={{
                        backgroundImage: `url(${edit})`,
                      }}
                      onClick={editModalFunctions.openShopperCountByCountryEdit}
                    ></button>
                  )}
                </div>
                <div className={classes.sectionContainer} style={{ marginTop: "15px" }}>
                  {getHighestPriorityYearlyData("shopper_count_by_country", "country", 0) &&
                  (editDataForFields?.shopper_count_selected_countries?.data as ISelectData[])?.length > 0
                    ? (editDataForFields?.shopper_count_selected_countries?.data as ISelectData[])?.map(
                      (item: ISelectData) =>
                        getHighestPriorityYearlyData("shopper_count_by_country", "country", item?.country_id)?.length >
                        0 ? (
                          <FeatureCard
                            key={item?.country_id}
                            title={
                              getHighestPriorityYearlyData("shopper_count_by_country", "country", item?.country_id)[0]
                                ?.country?.name
                            }
                            data={
                              getHighestPriorityYearlyData("shopper_count_by_country", "country", item?.country_id)[0]
                            }
                            logo={shopLogo}
                            isEdit={isEdit}
                            clicked={editModalFunctions.openShopperCountByCountryEdit}
                            getDataSource={getDataSource}
                            sourceIds={getHighestPriorityYearlyData(
                              "shopper_count_by_country",
                              "country",
                              item?.country_id,
                            ).map((item: any) => item.sourceId)}
                          />
                        ) : null,
                    )
                    : data?.shopper_count_by_country
                      ? data?.shopper_count_by_country?.value?.map((count: IDataItem) => (
                        <FeatureCard
                          key={count?.id}
                          title={count?.country?.name as string}
                          data={{ value: count?.value, sourceId: data?.shopper_count_by_country?.sourceId }}
                          logo={shopLogo}
                          isEdit={isEdit}
                          clicked={editModalFunctions.openShopperCountByCountryEdit}
                          getDataSource={getDataSource}
                          sourceIds={count.sourceId ? [count.sourceId] : []}
                        />
                      ))
                      : null}
                </div>
              </div>
            }
          </div>}
      </div>
    </>
  )
}