import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  footer: {
    padding: "13px 0",
    color: "#717171",
    fontFamily: "Montserrat",
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: 1,
    textAlign: "center",
    background: "#e9ecef",
  },
}));

export const FooterComponent: FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <p style={{ margin: 0 }}>©2022 EnsembleIQ, All Rights Reserved</p>
    </footer>
  );
};
