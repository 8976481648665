import { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";

import { apiConfig } from "../../../services/api/apiConfig";
import { useAuth } from "../../../shared/hooks/auth-hook";

import { LoadingTemplate } from "./loading-template";
import { EditSubsidiariesForm } from "./edit-subsidiaries-form";

interface Props {
  companyId: number;
  editFieldData: EditSubsidiariesData | null;
  onSave: (data: EditSubsidiariesData) => void;
  onClose: () => void;
}

declare function structuredClone<T>(value: T): T;

export interface CompanyReference {
  id: number;
  name: string;
  image: string | null;
}

interface Subsidiary {
  id: number | null;
  company: CompanyReference | null;
  externalCompany: {
    id: string | null;
    name: string;
  };
}

export interface SubsidiaryItem extends Subsidiary {
  sourceId: number;
  include: boolean;
}

export type DataSource = { id: number; name: string; priority: number };

type EditFieldData<T> = {
  data: T[];
  dataSources: DataSource[];
  field_name: string;
};

export type EditSubsidiariesData = EditFieldData<SubsidiaryItem>;

async function getSubsidiariesForEdit(companyId: number, token: string) {
  const url = `${apiConfig.companiesDataManager}/${companyId}/subsidiaries`;

  const res = await fetch(url, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (res.ok) {
    const data = await res.json();
    return data as EditSubsidiariesData;
  } else {
    const body = await res.json();
    throw new Error(`error loading subsidiaries for edit. ${body}`);
  }
}

export const EditSubsidiariesModal = ({ companyId, editFieldData, onSave, onClose }: Props) => {
  const { token } = useAuth();

  const [loading, setLoading] = useState(false);

  const [initialFormValue, setInitialFormValue] = useState(structuredClone(editFieldData));

  useEffect(() => {
    let ignore = false;
    if (initialFormValue === null) {
      if (token !== null && token !== "") {
        setLoading(true);
        setInitialFormValue(null);

        getSubsidiariesForEdit(companyId, token)
          .then((result) => {
            if (!ignore) {
              setInitialFormValue(result);
            }
          })
          .catch(console.warn)
          .finally(() => {
            if (!ignore) {
              setLoading(false);
            }
          });
      }
    }

    return () => {
      ignore = true;
    };
  }, [companyId, token]);

  return (
    <>
      <Modal open={true} onClose={onClose} style={{ zIndex: 500 }}>
        {loading ? (
          <LoadingTemplate />
        ) : initialFormValue ? (
          <EditSubsidiariesForm
            companyId={companyId}
            initialEditFieldData={initialFormValue}
            onSave={(data) => {
              onSave(data);
              onClose();
            }}
            onCancel={onClose}
          />
        ) : (
          <div></div>
        )}
      </Modal>
    </>
  );
};
