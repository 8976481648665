import { FC, ReactElement, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { makeStyles } from "@material-ui/core";
import ArrowLogo from "../../assets/UpArrow.png";

const useStyles = makeStyles(() => ({
  icon: {
    position: "absolute",
    right: "20px",
    top: "8px",
  },
  lastColumn: {
    position: "relative",
  },
  dropdown_icon: {
    height: "30px",
    width: "30px",
    cursor: "pointer",
  },
}));

interface Props {
  firstColumn?: string;
  rowSpan?: number;
  rowTitle: string | JSX.Element;
  result?: any;
  details?: any;
  editComponent?: JSX.Element;
}

export const CompanyViewTableRow: FC<Props> = ({ firstColumn, rowSpan, rowTitle, result, details, editComponent }) => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const checkIfArray = (item: string | string[]): boolean => {
    return Array.isArray(item);
  };

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const shouldShowLogo = (): boolean => {
    if ((checkIfArray(result) && result.length > 1) || (checkIfArray(details) && details.length > 1)) {
      return true;
    } else return false;
  };

  return (
    <>
      {firstColumn ? (
        <TableCell component="th" rowSpan={rowSpan}>
          {firstColumn}
        </TableCell>
      ) : null}
      <TableCell component="th" align="left">
        {rowTitle}
      </TableCell>
      <TableCell align="left">
        {/* 
                    If result is a single value, we show it.
                    But it might be array, so if it is array,
                    we show its first value. But if user clicks arrow to expand
                    we will show all data
                */}
        <div className={`${isVisible ? "height-14-px" : "height-auto"} height-auto-on-print`}>
          {result
            ? checkIfArray(result)
              ? result.map((el: string, index: number) => <p key={index}>{el}</p>)
              : result
            : ""}
        </div>
      </TableCell>
      <TableCell align="left" className={editComponent ? undefined : classes.lastColumn}>
        {/* 
                    If we have details prop, we show its first line. 
                    But if we click arrow to expand, we will show all data
                */}
        <div className={`${isVisible ? "height-14-px" : "height-auto"} height-auto-on-print`}>
          {details
            ? checkIfArray(details)
              ? details.map((el: string, index: number) => <p key={index}>{el}</p>)
              : details
            : ""}
        </div>
        {shouldShowLogo() ? (
          <div className={classes.icon}>
            <img
              src={ArrowLogo}
              alt="dropdown icon"
              className={classes.dropdown_icon}
              onClick={handleClick}
              style={{
                transform: isVisible ? "rotate(0deg)" : "rotate(180deg)",
              }}
            />
          </div>
        ) : null}
      </TableCell>
      {editComponent &&
        <TableCell align="left" className={classes.lastColumn}>
          {editComponent}
        </TableCell>
      }
    </>
  );
};
