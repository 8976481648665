import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../hooks/auth-hook";

type Protected = ({ children }: { children: JSX.Element }) => JSX.Element;

export const ProtectedRoute: Protected = ({ children }) => {
  const { token } = useAuth();
  const location = useLocation();

  if (!token) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
};
