import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useQuery } from "react-query";
import { useAuth } from "../../shared/hooks/auth-hook";
import { TextField } from "@mui/material";

type CollectionQueryResponse<T> = {
  data: T[];
  count: number;
};

type UpdateStatus = "In progress" | "Completed" | "Completed with warnings" | "Failed";
type UpdateStatusOption = { id: number; name: UpdateStatus };

async function getStatusOptions(token: string) {
  const url = `${process.env.REACT_APP_DATA_MANAGER_URL}/company-update-statuses`;

  const res = await fetch(url, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (res.ok) {
    const collection = (await res.json()) as CollectionQueryResponse<UpdateStatusOption>;
    return collection;
  } else {
    try {
      const err = await res.json();
      console.warn("company-update-statuses", err);
    } catch {
      console.warn("company-update-statuses: unknown error");
    }
    throw new Error("company-update-statuses failed");
  }
}

type Filters = {
  dataSourceId?: number;
  companyId?: number;
  statusId?: number;
};

type Props = {
  filters: Filters | null;
  setFilters: (value: ((prevState: Filters) => Filters) | Filters) => void;
};

export const Filters: React.FC<Props> = ({ filters, setFilters }) => {
  const { token } = useAuth();

  const { status, data } = useQuery({
    queryKey: ["company-updates", "statuses"],
    queryFn: () => getStatusOptions(token!),
    staleTime: Infinity,
  });

  return (
    <Autocomplete
      value={data?.data?.find((s) => s.id === filters?.statusId) ?? null}
      onChange={(_event, newValue) => {
        setFilters((prevState) => ({ ...prevState, statusId: newValue?.id }));
      }}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disablePortal
      options={status === "success" ? data.data : []}
      loading={status === "loading"}
      size="small"
      sx={{ width: 230, marginBottom: 1 }}
      renderInput={(params) => <TextField {...params} label="Status" />}
    />
  );
};
