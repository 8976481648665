import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";

import { useAuth } from "../../../shared/hooks/auth-hook";
import { LoadingTemplate } from "../edit-subsidiaries/loading-template";

import { EditData, getEditData } from "./edit-data";
import { EditStockAndOwnershipTypeForm } from "./edit-stock-and-ownership-type-form";

declare function structuredClone<T>(value: T): T;

interface Props {
  companyId: number;
  editFieldData: EditData | null;
  onSave: (data: EditData) => void;
  onClose: () => void;
}

export const EditStockAndOwnershipTypeModal = ({ companyId, editFieldData, onSave, onClose }: Props) => {
  const { token } = useAuth();

  const [loading, setLoading] = useState(false);

  const [initialFormValue, setInitialFormValue] = useState(structuredClone(editFieldData));

  useEffect(() => {
    let ignore = false;
    if (initialFormValue === null) {
      if (token !== null && token !== "") {
        setLoading(true);
        setInitialFormValue(null);

        getEditData(companyId, token)
          .then((result) => {
            if (!ignore) {
              setInitialFormValue(result);
            }
          })
          .catch(console.warn)
          .finally(() => {
            if (!ignore) {
              setLoading(false);
            }
          });
      }
    }

    return () => {
      ignore = true;
    };
  }, [companyId, token]);

  return (
    <Modal open={true} onClose={onClose}>
      {loading ? (
        <LoadingTemplate />
      ) : initialFormValue ? (
        <EditStockAndOwnershipTypeForm
          companyId={companyId}
          initialEditFieldData={initialFormValue}
          onSave={(data) => {
            onSave(data);
            onClose();
          }}
          onCancel={onClose}
        ></EditStockAndOwnershipTypeForm>
      ) : (
        <div></div>
      )}
    </Modal>
  );
};
