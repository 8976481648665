import { FC, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { RevenueCard } from "./revenueCard";
import CircularProgress from "./circularProgress";
import nFormatter from "../../../utils/numberToStringConverter";
import { DateRangePopup } from "./dateRangePopup";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
    width: "50%",
  },
  main_container: {
    padding: "20px 28px",
  },
  target_container: {
    marginTop: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  text_container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  title_style: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "16px",
    letterSpacing: "0",
    lineHeight: "17px",
    marginBottom: "5px",
  },
  text_style: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: "12px",
    letterSpacing: "0",
    lineHeight: "14px",
  },
}));

interface Props {
  data: any;
  title: string;
}

export const Target: FC<Props> = ({ title, data }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<string>("yearly");
  const percentage = Number((data[selected]?.accomplished / data[selected]?.target) * 100).toFixed(1);

  return (
    <div className={classes.container}>
      <RevenueCard>
        <div className={classes.main_container}>
          {/* <DateRangePopup title={title} selected={selected} setSelected={setSelected} /> */}
          <div className={classes.target_container}>
            <div className={classes.text_container}>
              <div style={{ marginBottom: 10 }}>
                <div className={classes.title_style}>Accomplished</div>
                <div className={classes.text_style}>{nFormatter(data[selected]?.accomplished)}</div>
              </div>
              <div>
                <div className={classes.title_style}>Remanining</div>
                <div className={classes.text_style}>
                  {nFormatter(data[selected]?.target - data[selected]?.accomplished)}
                </div>
              </div>
            </div>
            <div>
              <CircularProgress data={percentage} />
            </div>
          </div>
        </div>
      </RevenueCard>
    </div>
  );
};
