import { FC, ReactElement, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import { makeStyles } from "@material-ui/core";
import { CompanyViewTableRow } from "./companyViewTableRow";
import ArrowLogo from "../../assets/UpArrow.png";
import edit from "../../assets/edit.png";
import { currencyFormatter } from "../../utils/currencyFormatter";

const useStyles = makeStyles(() => ({
  tableTite: {
    fontSize: "22px",
    lineHeight: "24px",
    fontFamily: "Oswald",
    color: "#333",
  },
  headerTitle: {
    fontSize: "12px !important",
    lineHeight: "14px !important",
    fontFamily: "Montserrat !important",
    color: "#666 !important",
    padding: "9px !important",
  },
  headerRow: {
    backgroundColor: "#F6F6F6 !important",
    borderTop: "1px solid #E1E1E1 !important",
    borderBottom: "1px solid #E1E1E1 !important",
  },
  button: {
    color: "#FFFFFF",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "12px",
    lineHeight: "14px",
    backgroundColor: "#4D748D",
    cursor: "pointer",
    borderRadius: 3,
    padding: "12px 16px",
    border: "none",
  },
  icon: {
    position: "absolute",
    right: "20px",
    top: "15px",
  },
  dropdown_icon: {
    height: "40px",
    width: "40px",
    cursor: "pointer",
  },
  editButton: {
    background: "transparent",
    backgroundImage: `url(${edit})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "20px",
    width: "20px",
    marginLeft: "10px",
    border: "none",
    cursor: "pointer",
    alignItems: "center",
    display: "inline-flex",
  },
}));

interface Props {
  title: string;
  headers: Header[];
  rows: IRow[];
  source?: string;
  collapse?: boolean;
  isEdit?: boolean;
  editFunction?: () => void;
}

interface Header {
  title: string;
  width: string;
}

interface IRow {
  firstColumn?: string;
  rowSpan?: number;
  rowTitle: string | JSX.Element;
  result?: string;
  details?: string;
  currency?: string;
  editComponent?: ReactElement;
}

export const CompanyViewTable: FC<Props> = ({
  title,
  headers,
  rows,
  source,
  collapse = false,
  editFunction,
  isEdit = false,
}) => {
  const classes = useStyles();
  const [isShowMore, setIsShowMore] = useState(source === "alternative_names" ? false : true);
  const [isVisible, setIsVisible] = useState(!collapse);
  const maxNumberOfRow = 9;

  const handleClick = () => {
    setIsVisible(!isVisible);
  };
  return (
    <Paper className="company-information-table">
      <Toolbar>
        <p className={classes.tableTite}>{title}</p>
        {editFunction && isEdit && <button className={classes.editButton} onClick={editFunction}></button>}
        {source === "alternative_names" && (
          <div className={classes.icon}>
            <img
              src={ArrowLogo}
              alt="dropdown icon"
              className={`${classes.dropdown_icon} rotate-0-deg-on-print`}
              onClick={handleClick}
              style={{
                transform: isVisible ? "rotate(0deg)" : "rotate(180deg)",
              }}
            />
          </div>
        )}
      </Toolbar>
      <TableContainer>
        <Table className={`${isVisible ? "display-table" : "display-none"} display-table-on-print`}>
          <>
            <TableHead>
              <TableRow className={classes.headerRow}>
                {headers.map((header: any, index: number) => (
                  <TableCell width={header.width} key={index} className={classes.headerTitle} align="left">
                    {header.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows?.map(
                (row: IRow, index: number) => (
                  // isShowMore || index < maxNumberOfRow ? (
                  <TableRow
                    key={index}
                    className={`${
                      isShowMore || index < maxNumberOfRow ? "display-table-row" : "display-none"
                    } display-table-row-on-print`}
                  >
                    <CompanyViewTableRow
                      firstColumn={row?.firstColumn}
                      rowSpan={row?.rowSpan}
                      rowTitle={row?.rowTitle}
                      result={row.currency && row.result ? currencyFormatter.format(parseInt(row.result)) : row?.result}
                      details={row?.details}
                      editComponent={row?.editComponent}
                    />
                  </TableRow>
                )
                // ) : null
              )}
            </TableBody>
            {source === "alternative_names" && rows.length > maxNumberOfRow ? (
              <TableFooter
                component="tbody"
                style={{
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "white",
                  boxShadow: "0 0 0 1px rgb(224 224 224)",
                }}
                className="no-print"
              >
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <button onClick={() => setIsShowMore(!isShowMore)} className={`${classes.button} no-print`}>
                      {isShowMore ? "Show Less" : "Show All"}
                    </button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            ) : null}
          </>
        </Table>
      </TableContainer>
    </Paper>
  );
};
