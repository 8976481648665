import { FC, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { RevenueCard } from "./revenueCard";
import LinearProgress from "./linearProgress";
import nFormatter from "../../../utils/numberToStringConverter";
import { DateRangePopup } from "./dateRangePopup";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
    width: "100%",
  },
  main_container: {
    padding: "20px 28px",
  },
  total_revenue: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: "12px",
    letterSpacing: "0",
    lineHeight: "14px",
    marginBottom: "20px",
  },
}));

interface Props {
  data: any;
}

export const SaleStats: FC<Props> = ({ data }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<string>("yearly");

  return (
    <div className={classes.container}>
      <RevenueCard>
        <div className={classes.main_container}>
          {/* <DateRangePopup title={data?.name} selected={selected} setSelected={setSelected} /> */}
          <div>
            <div className={classes.total_revenue}>
              {nFormatter(data?.yearly_revenue?.value[0]?.value)} Total Revenue
            </div>
            <div>
              {data.sales &&
                Object.keys(data.sales).map((key: any, index: number) => {
                  let total = 0;
                  Object.keys(data.sales).forEach(
                    (el: any) => (total += Number(data.sales[el][selected]?.value.slice(-1)[0].value))
                  );
                  const prc = (data?.sales[key][selected]?.value.slice(-1)[0].value / total) * 100;
                  return (
                    <LinearProgress
                      key={index}
                      data={{
                        prc,
                        value: data?.sales[key][selected]?.value.slice(-1)[0].value,
                        title: data?.sales[key]?.title,
                      }}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </RevenueCard>
    </div>
  );
};
