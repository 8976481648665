import { FC } from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Props {
  title: string;
  setDateValue: (value: Array<Date>) => void;
  dates: (Date | null)[];
}

const useStyles = makeStyles(() => ({
  formControlRoot: {
    margin: 10,
    "& .MuiFormLabel-root, .MuiInputLabel-root.Mui-focused": {
      color: "#4d748d",
      fontFamily: "Montserrat, sans-serif",
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      color: "#4d748d",
      fontFamily: "Montserrat, sans-serif",
      fontSize: 14,
    },
  },
  inputRoot: {
    "&$notchedOutline": {
      border: "1px solid #4d748d !important",
      borderRadius: 3,
    },
  },
  notchedOutline: {
    border: "1px solid #4d748d !important",
  },
  popperRoot: {
    "& .react-datepicker": {
      fontFamily: "Montserrat, sans-serif",
      color: "#333333",
      fontWeight: 400,
    },
    "& .react-datepicker__triangle": {
      transform: "translate3d(220.8px, 0px, 0px) !important",
    },
    "& .react-datepicker__current-month": {
      fontFamily: "Montserrat, sans-serif",
      color: "#333333",
      fontWeight: 600,
    },
    "& .react-datepicker__day--keyboard-selected, .react-datepicker__day--in-range, .react-datepicker__day--selected": {
      borderRadius: "0.3rem",
      backgroundColor: "#2f556d",
      color: "#fff",
    },
  },
}));

export const DatePickerComponent: FC<Props> = ({ dates, title, setDateValue }) => {
  const classes = useStyles();

  const onChange = (dates: any) => {
    setDateValue(dates);
  };

  return (
    <div>
      <DatePicker
        selected={dates[0]}
        onChange={onChange}
        startDate={dates[0]}
        endDate={dates[1]}
        dateFormat="dd/MM/yyyy"
        selectsRange
        maxDate={new Date()}
        popperClassName={classes.popperRoot}
        popperPlacement="auto"
        customInput={
          <TextField
            variant="outlined"
            className={classes.formControlRoot}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                focused: classes.inputRoot,
                notchedOutline: classes.notchedOutline,
              },
            }}
            label={title}
          />
        }
      />
    </div>
  );
};
