import { makeStyles } from "@material-ui/core";
import { FC, FormEvent, useEffect, useState } from "react";
import { IRole } from "../../interfaces";
import { apiConfig } from "../../services/api/apiConfig";
import { Service } from "../../services/api/service";
import { useAuth } from "../../shared/hooks/auth-hook";
const useStyles = makeStyles(() => ({
  formGroup: {
    display: "flex",
    marginTop: "30px",
    marginBottom: "10px",
    flexDirection: "column",
    "& .MuiCircularProgress-root": {
      width: "20px !important",
      height: "20px !important",
      color: "#4D748D",
      marginRight: "5px",
      marginTop: "5px",
    },
  },
  title: {
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
    marginBottom: "4px",
  },
  input: {
    border: "1px solid #E1E1E1",
    padding: 10,
    width: "100%",
    color: "#666666",
    fontWeight: 400,
    fontFamily: "Montserrat",
    "&::placeholder": {
      color: "#666666",
      fontWeight: 400,
      fontFamily: "Montserrat",
      fontSize: 12,
    },
    "&:focus": {
      outline: "none",
    },
  },
  select: {
    border: "1px solid #E1E1E1",
    padding: 10,
    width: "100%",
    color: "#666666",
    fontWeight: 400,
    fontFamily: "Montserrat",
    marginRight: "10px",
    "&:focus": {
      outline: "none",
    },
  },
  button: {
    boxSizing: "border-box",
    height: "38px",
    width: "110px",
    border: "1px solid #4D748D",
    borderRadius: "3px",
    marginRight: "15px",
  },
  updateUserError: {
    color: "#c40014",
    fontFamily: "Montserrat",
    fontSize: "11px",
    fontWeight: "bold",
  },
}));
interface Props {
  roles: IRole[];
  setOpen: (open: boolean) => void;
  modalOpened: boolean;
  onSave: () => void;
  onCancel: () => void;
  currentRoleId?: number;
  currentEmail?: string;
  addRole: (email: string, roleId: number) => void;
  errorMessage?: string;
}
export const AddRole: FC<Props> = ({
  roles,
  setOpen,
  modalOpened,
  currentRoleId,
  currentEmail,
  addRole,
  errorMessage,
  onCancel,
}) => {
  const classes = useStyles();
  const { token } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [roleId, setRoleId] = useState<number>(0);
  useEffect(() => {
    setEmail(currentEmail || "");
    setRoleId(currentRoleId || 0);
  }, [modalOpened]);
  useEffect(() => {
    currentRoleId && setRoleId(currentRoleId);
  }, [currentRoleId]);
  useEffect(() => {
    currentEmail && setEmail(currentEmail);
  }, [currentEmail]);
  const isEmail = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const buttonEnabled = isEmail(email) && roleId > 0;
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        addRole(email, roleId);
      }}
    >
      <div className={classes.formGroup}>
        <span className={classes.title}>Email</span>
        <input
          type="text"
          className={classes.input}
          name="Email"
          id="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          autoComplete="off"
        />
        <span className={classes.title}>Role</span>
        <select
          className={classes.select}
          name="year"
          value={roleId}
          onChange={(e) => {
            setRoleId(parseInt(e.target.value));
          }}
        >
          <option value={0}>Not Selected</option>
          {roles.map((role, index) => (
            <option key={index} value={role.id}>
              {role.name}
            </option>
          ))}
        </select>
        <div style={{ display: "flex", marginTop: "15px" }}>
          <button
            className={classes.button}
            type="button"
            style={{ backgroundColor: "#FFFFFF", color: "#4D748D", cursor: "pointer" }}
            onClick={() => {
              setOpen(false);
              onCancel();
            }}
          >
            Cancel
          </button>
          <button
            className={classes.button}
            type="submit"
            // onClick={(e: FormEvent<HTMLFormElement>) => addRole(e)}
            style={{
              backgroundColor: "#4D748D",
              color: "#FFFFFF",
              cursor: buttonEnabled ? "pointer" : "default",
              opacity: buttonEnabled ? "100%" : "40%",
            }}
            disabled={!buttonEnabled}
          >
            Apply
          </button>
        </div>
        <div className={classes.formGroup} style={{ marginTop: "15px" }}>
          <span className={classes.updateUserError}>{errorMessage}</span>
        </div>
      </div>
    </form>
  );
};
