import { ICompanyEditModalFunctions } from "../../../../utils/companyEditModalFunctions";
import {EditComponent} from "./editComponent";
import { Fragment } from "react";

type Code = "NACE" | "NAICS" | "ISIC";

export const buildClassificationRows = (data: Record<string, unknown>[], type: Code, isEdit: boolean, editModalFunctions: ICompanyEditModalFunctions
) => {
  return data?.length ? data?.sort((x) =>
    x.isPrimary ? -1 : 1
  ).map((item, index) => {
    return {
      rowSpan: data.length,
      rowTitle: String(item.name),
      result: String(item.code),
      sourceId: item.sourceId,
      details: `${item.isPrimary ? "Primary" : "Secondary"}`,
      editComponent: isEdit && index === 0 ? <EditComponent editAction={() => handleIndustryCodeEditClick(editModalFunctions, type)} /> : <Fragment/>,
      ...(index === 0 && {firstColumn: type}),
    }
  }) : [{
    firstColumn: type,
    rowSpan: 1,
    rowTitle: "",
    result: "",
    details: "",
    editComponent: isEdit ? <EditComponent editAction={() => handleIndustryCodeEditClick(editModalFunctions, type)} /> : <Fragment/>,
  }];
};

const handleIndustryCodeEditClick = (editModalFunctions: ICompanyEditModalFunctions, type: string) => {
  switch (type) {
    case "NACE":
      editModalFunctions.openNaceClassificationEdit();
      break;
    case "NAICS":
      editModalFunctions.openNaicsClassificationEdit();
      break;
    case "ISIC":
      editModalFunctions.openIsicClassificationEdit();
      break;
    default:
      console.warn(`unknown industry code type ${type}`);
      break;
  }
};

