import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";

import { ICompanyEditModalFunctions } from "../../../utils/companyEditModalFunctions";
import { LoadingTemplate } from "../edit-subsidiaries/loading-template";
import { useAuth } from "../../../shared/hooks/auth-hook";

import { getQuarterlyRevenueEditData, QuarterlyRevenuesEditData } from "./edit-data";
import { EditQuarterlyRevenuesForm } from "./edit-quarterly-revenues-form";

declare function structuredClone<T>(value: T): T;

interface Props {
  companyId: number;
  editModalFunctions: ICompanyEditModalFunctions;
  editData: QuarterlyRevenuesEditData | null;
  onSubmit: (editData: QuarterlyRevenuesEditData) => void;
  onCancel: () => void;
}

export const EditQuarterlyRevenuesModal = ({ companyId, editData, editModalFunctions, onSubmit, onCancel }: Props) => {
  const { token } = useAuth();

  const [loading, setLoading] = useState(false);

  const [initialFormValue, setInitialFormValue] = useState(structuredClone(editData));

  useEffect(() => {
    let ignore = false;
    if (initialFormValue === null) {
      if (token !== null && token !== "") {
        setLoading(true);
        setInitialFormValue(null);

        getQuarterlyRevenueEditData(companyId, token)
          .then((result) => {
            if (!ignore) {
              setInitialFormValue(result);
            }
          })
          .catch(console.warn)
          .finally(() => {
            if (!ignore) {
              setLoading(false);
            }
          });
      }
    }

    return () => {
      ignore = true;
    };
  }, [companyId, token]);

  return (
    <Modal open={true} onClose={onCancel}>
      {loading ? (
        <LoadingTemplate />
      ) : initialFormValue ? (
        <EditQuarterlyRevenuesForm
          editModalFunctions={editModalFunctions}
          initialEditData={initialFormValue}
          onSubmit={onSubmit}
          onCancel={onCancel}
        ></EditQuarterlyRevenuesForm>
      ) : (
        <div></div>
      )}
    </Modal>
  );
};
