import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import closeLogo from "../../../assets/close-circle.png";
import { useAuth } from "../../../shared/hooks/auth-hook";
import { Service } from "../../../services/api/service";
import { apiConfig } from "../../../services/api/apiConfig";

import { DataSource, RevenueItem, YearlyRevenuesEditData } from "./edit-data";
import { ChangeDataSourcePriorityModal } from "../ChangeDataSourcePriorityModal";
import { ICompanyEditModalFunctions } from "../../../utils/companyEditModalFunctions";
import DeleteIcon from "../../../assets/close-circle.png";
import AddRowIcon from "../../../assets/add-circle.png";
import { ApplyButton } from "../../applyButton";
import { IDataSource } from "../SingleFieldEditModal";
import { EditData } from "../edit-stock-and-ownership-type/edit-data";
import { EditYearlyRevenuesFormTable } from "./edit-yearly-revenue-form-table";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 20px",
  },
  body: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
  },
  closeButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 24,
    width: 24,
    backgroundImage: `url(${closeLogo})`,
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  title: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "28px",
    letterSpacing: "0",
    lineHeight: "34px",
    marginBottom: "10px",
  },
  cancelButton: {
    fontFamily: "Montserrat",
    fontSize: 12,
    borderRadius: 3,
    height: 38,
    textTransform: "none",
    border: "1px solid",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D748D",
    width: "74px",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "20px",
  },
  dataContainer: {
    marginTop: "20px",
  },
  dataTitleRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  dataTitle: {
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
  },
  revenueSelectLink: {
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
    marginRight: "5px",
    textDecoration: "underline",
    cursor: "pointer",
  },
  dataRow: {
    display: "flex",
    marginBottom: "10px",
    alignItems: "center",
    flexDirection: "row",
  },
  addRowContainer: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  addRowText: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: "14px",
  },
  addColumnIcon: {
    height: "20px",
    width: "20px",
    marginRight: "10px",
  },
  select: {
    border: "1px solid #E1E1E1",
    padding: 10,
    width: "90px",
    color: "#666666",
    fontWeight: 400,
    fontFamily: "Montserrat",
    marginRight: "10px",
    "&:focus": {
      outline: "none",
    },
  },
  dataSourceRowAction: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    paddingLeft: "5px",
    marginRight: "10px",
    width: "20px",
    display: "grid",
    "& label": {
      margin: "0px !important",
    },
  },
  dataSourceRowActionDelete: {
    height: "20px",
    width: "20px",
    cursor: "pointer",
  },
  dataSourceRowYear: {
    fontFamily: "Montserrat",
    minWidth: "25px",
    fontSize: 12,
    color: "#333333",
    fontWeight: 600,
    width: "50px",
  },
  dataSourceRowData: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    flexDirection: "row",
    display: "flex",
    textAlign: "center",
  },
  overflowContainer: {
    minHeight: "70px",
    maxHeight: "150px",
    overflowY: "auto",
  },
  noData: {
    fontStyle: "italic",
    color: "grey",
    fontFamily: "Montserrat",
    fontSize: 12,
    height: 35,
    fontWeight: 500,
    paddingLeft: "5px",
  },
  errorDiv: {
    color: "#c40014",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "10px",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontFamily: "Montserrat, sans-serif",
  color: "#333",
  fontWeight: 500,
  [`&.${tableCellClasses.head}`]: {
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}.disabled`]: {
    color: "grey",
  },
}));

interface FormProps {
  editModalFunctions: ICompanyEditModalFunctions;
  initialEditData: YearlyRevenuesEditData;
  onSubmit: (editData: YearlyRevenuesEditData) => void;
  onCancel: () => void;
}

export const EditYearlyRevenuesForm = ({ editModalFunctions, initialEditData, onSubmit, onCancel }: FormProps) => {
  const classes = useStyles();

  // TODO Get rid of "?? 2"
  const manualDataSourceId = initialEditData.dataSources.find((it) => it.type === "Manual")?.id ?? 2;

  const [editFieldData, setEditFieldData] = useState(initialEditData);

  const handlePriorityChange = (newDataSource: IDataSource[]) => {
    setEditFieldData({
      ...editFieldData,
      dataSources: newDataSource as any[],
    });
  };

  const handleApply = () => onSubmit(editFieldData);

  return (
    <div className={classes.container} style={{ maxHeight: "95%", width: "1000px" }}>
      <div>
        <button onClick={onCancel} className={classes.closeButton} />
      </div>
      <div className={classes.title}>Edit Annual Revenues</div>
      <div className={classes.body}>
        {editFieldData.dataSources && (
          <div>
            <ChangeDataSourcePriorityModal
              name="Annual Revenues"
              onClose={handlePriorityChange}
              data={editFieldData.dataSources}
            />
          </div>
        )}
        <div className={classes.dataContainer}>
          <EditYearlyRevenuesFormTable
            editData={editFieldData}
            setEditData={setEditFieldData}
          ></EditYearlyRevenuesFormTable>
        </div>
      </div>
      <div className={classes.actions}>
        <button className={classes.cancelButton} onClick={onCancel}>
          Cancel
        </button>
        <span style={{ marginLeft: 10 }} />
        <ApplyButton text="Apply" apply={handleApply} />
      </div>
    </div>
  );
};
