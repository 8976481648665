import { InputTypeEnums, IOpenedModal } from "./types";

export const EDIT_MODAL_INITIAL_STATE: IOpenedModal = {
  name: "",
  type: "",
  title: "",
  input_type: InputTypeEnums.null,
  property_name: "",
  isVisible: false,
};

export const COMPANY_PERSON_FIELDS = [
  { title: "CEO", field: "ceo" },
  { title: "Chairman", field: "chairman" },
  { title: "Owner", field: "owner" },
];

export const PERSON_PROPERTY_NAMES = ["ceo", "chairman", "owner", "founders", "board_members", "executives"];

export const FIELDS_INITIAL_STATE = {
  stock: null,
  ownershipType: null,

  summary: null,
  industry: null,
  stores: null,
  store_num_by_country: null,
  store_num_by_state: null,
  store_num_by_store_type: null,
  employee_num: null,
  foundation_year: null,
  executives: null,
  hq_address: null,
  domain: null,
  name: null,
  revenue_by_country_yearly: null,
  revenue_by_country_quarterly: null,
  revenue_selected_countries: null,
  alternative_names: null,
  subsidiaries: null,
  chairman: null,
  founders: null,
  owner: null,
  ceo: null,
  board_members: null,
  phone: null,
  shopper_count_by_country: null,
  shopper_count_selected_countries: null,
  rtn_member: null,
  p2pi_member: null,
  linkedin: null,
  secondary_industries: null,
  company_domains: null,
  annual_reports: null,
  operation_areas: null,
  naceClassification: null,
  naicsClassification: null,
  isicClassification: null,
  yearly_revenue: null,
  quarterly_revenue: null,
  eiqMembershipGroups: null,
};

export const NAV_SECTIONS = [
  { name: "COMPANY OVERVIEW", id: "overview" },
  { name: "ALTERNATIVE NAMES OR SUBSIDIAIRIES", id: "alternative" },
  { name: "STRATEGY, MARKETING, VISION", id: "strategy" },
  { name: "REVENUE AND REVENUE RANGE", id: "revenue" },
  { name: "NEWS", id: "news" },
  { name: "ADDITIONAL LINKS", id: "links" },
];
