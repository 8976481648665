import { FC } from "react";
import { makeStyles } from "@material-ui/core";
import ToolTip from "../toolTip";
import { Avatar } from "../../avatar";
import { ShowDataSources } from "../ShowDatasources";

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: "10px",
    lineHeight: "14px",
    fontFamily: "Montserrat",
    "& a": {
      color: "#4D748D",
    },
  },
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "180px",
    marginBottom: "50px",
    maxHeight: "141px",
    height: "141px",
    cursor: "pointer",
  },
  name: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "20px",
    letterSpacing: "0",
    lineHeight: "22px",
    textAlign: "center",
    marginBottom: "15px ",
    maxWidth: "90%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: " ellipsis",
  },
  industry: {
    color: "#717171",
    fontFamily: "Montserrat",
    fontSize: "12px",
    letterSpacing: "0",
    fontWeight: 500,
    lineHeight: "14px",
    textAlign: "center",
  },
  delete: {
    position: "absolute",
    right: "40px",
    top: "0px",
    fontWeight: 600,
    color: "#4D748D",
    fontSize: "18px",
  },
  addText: {
    width: "max-content",
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "0",
    lineHeight: "24px",
  },
  inputContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  button: {
    fontFamily: "Montserrat",
    fontSize: 12,
    borderRadius: 3,
    height: 38,
    textTransform: "none",
    border: "1px solid",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D748D",
    padding: "10px 20px",
  },
  actions: {
    marginTop: "20px",
    marginLeft: "auto",
    display: "flex",
    justifyContent: "flex-end",
    "& button": {
      marginRight: "10px",
    },
  },
}));

interface Props {
  data: any;
  isEdit: boolean;
  sourceId: { name: string; endpoint: string; id: number };
  getDataSource: (id: number) => { name: string; endpoint: string; id: number };
}

export const CompanySection: FC<Props> = ({ data, sourceId, getDataSource }) => {
  const classes = useStyles();
  const image = data?.image ? data.image : null;

  return (
    <>
      <ShowDataSources
        data={{ sourceId: sourceId?.id }}
        getDataSource={getDataSource}
        // title={
        //   sourceId ? (
        //     <p className={classes.tooltip}>This data is from {" " + sourceId?.name}</p>
        //   ) : (
        //     <p className={classes.tooltip}>No data source is found</p>
        //   )
        // }
        // placement="top"
        // arrow
      >
        <div className={classes.container} style={{ display: "block" }}>
          <div className={classes.container}>
            <Avatar
              name={data?.name}
              logo={image}
              style={{
                height: "80px",
                width: "80px",
                borderRadius: "50%",
                fontSize: "13px !important",
                objectFit: "contain",
              }}
            />
            <div className={classes.name}>{data?.name}</div>
          </div>
        </div>
      </ShowDataSources>
    </>
  );
};
