import { FC } from "react";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

interface Props {
  text: string;
  clear: () => void;
}

const UnderlinedButton = styled(Button)<ButtonProps>(() => ({
  color: "#4D748D",
  "&:hover": {
    backgroundColor: "transparent",
    textDecoration: "underline",
  },
  fontFamily: "Montserrat, sans-serif",
  fontSize: 12,
  textTransform: "none",
  textDecoration: "underline",
  minWidth: 0,
  padding: 0,
}));

export const ClearFilters: FC<Props> = ({ text, clear }) => {
  return <UnderlinedButton onClick={clear}>{text}</UnderlinedButton>;
};
