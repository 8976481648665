export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  notation: "compact",
  compactDisplay: "long",
});

const currencyFormatOptions = {
  notation: "compact",
  compactDisplay: "short",
  currencyDisplay: "symbol",
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
} as const;

export class CurrencyFormatter {
  private currencyFormatters: Record<string, Intl.NumberFormat> = {};

  private readonly defaultCurrency: string = "usd";
  private readonly defaultCurrencyFormatter: Intl.NumberFormat;

  private readonly locale: string = "en-US";

  constructor(defaults?: Partial<{ currency: string; locale: string }>) {
    this.locale = CurrencyFormatter.getSupportedLocale(defaults?.locale);

    try {
      const defaultCurrency = defaults?.currency || this.defaultCurrency;
      const defaultCurrencyFormatter = new Intl.NumberFormat(this.locale, {
        style: "currency",
        currency: defaultCurrency,
        ...currencyFormatOptions,
      });

      this.defaultCurrency = defaultCurrency.toLowerCase();
      this.currencyFormatters[defaultCurrency.toLowerCase()] = defaultCurrencyFormatter;
      this.defaultCurrencyFormatter = defaultCurrencyFormatter;
    } catch (e) {
      console.log(`[CurrencyFormatter:constructor] cannot resolve default currency "${defaults?.currency}"`, e);
      this.defaultCurrencyFormatter = new Intl.NumberFormat(this.locale, {
        style: "currency",
        currency: this.defaultCurrency,
        ...currencyFormatOptions,
      });
    }
  }

  format(value: number, currency?: string): string {
    const formatter = this.resolveCurrency(currency);

    return formatter.format(value);
  }

  private resolveCurrency(currency?: string): Intl.NumberFormat {
    const currencyToUse = currency?.toLowerCase() || this.defaultCurrency;

    const formatterExists = this.currencyFormatters[currencyToUse];

    if (formatterExists) {
      return formatterExists;
    }

    try {
      const formatter = new Intl.NumberFormat(this.locale, {
        style: "currency",
        currency: currencyToUse,
        ...currencyFormatOptions,
      });
      this.currencyFormatters[currencyToUse] = formatter;
      return formatter;
    } catch (e) {
      console.log(`[CurrencyFormatter:resolveCurrency] cannot resolve currency "${currency}"`, e);
      return this.defaultCurrencyFormatter;
    }
  }

  private static getSupportedLocale(locale?: string): string {
    try {
      const defaultLocale = locale ?? navigator.language;
      const [localeSupported] = Intl.NumberFormat.supportedLocalesOf([defaultLocale, ...navigator.languages]);
      if (localeSupported) {
        return localeSupported;
      }
    } catch (e) {
      console.log(`[CurrencyFormatter:getSupportedLocale] cannot resolve locale "${locale}"`, e);
    }

    try {
      const [localeSupported] = Intl.NumberFormat.supportedLocalesOf([...navigator.languages]);
      if (localeSupported) {
        return localeSupported;
      }
    } catch (e) {
      console.log(
        `[CurrencyFormatter:getSupportedLocale] cannot resolve navigator.language locale "${navigator?.language}"`,
        e
      );
    }

    return "en-US";
  }
}

export const defaultCurrencyFormatter = new CurrencyFormatter();
