import { apiConfig } from "../../../services/api/apiConfig";

export type DataSourceType = "Manual" | "External" | "Computed";

export type DataSource = {
  id: number;
  name: string;
  type: DataSourceType;
  priority: number;
};

export type QuarterlyRevenueItem = {
  id: number | null;
  year: number;
  quarter: number;
  dataSourceId: number;
  include: boolean;
  value: number;
  currency: string;
};

export type QuarterlyRevenuesEditData = {
  data: QuarterlyRevenueItem[];
  dataSources: DataSource[];
};

export async function getQuarterlyRevenueEditData(companyId: number, token: string): Promise<QuarterlyRevenuesEditData> {
  const url = `${apiConfig.companiesDataManager}/${companyId}/quarterly-revenues`;

  const res = await fetch(url, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (res.ok) {
    const data = await res.json();
    return data as QuarterlyRevenuesEditData;
  } else {
    const body = await res.json();
    throw new Error(`error yearly revenues for edit. ${body}`);
  }
}
