import { AxiosResponse } from "axios";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { FormControl, MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CircularProgress } from "@material-ui/core";

import closeLogo from "../../assets/close-circle.png";
import { ApplyButton } from "../applyButton";
import { CheckboxSelect } from "../checkbox";
import PersonEdit from "./PersonEdit";
import { MultiLevelFilter } from "../multiLevelFilter.tsx/filter";
import { ChangeDataSourcePriorityModal } from "./ChangeDataSourcePriorityModal";
import { IListData } from "./ListFieldEditModal";
import { InputTypeEnums } from "../../pages/companyView/types";

import { ICountry, IMaterialUISelectItem, ISelectItem, IState, Level, TaxonomySegment } from "../../interfaces";
import { apiConfig } from "../../services/api/apiConfig";
import { Service } from "../../services/api/service";
import { useAuth } from "../../shared/hooks/auth-hook";
import { convertTaxonomySegmentToLevel } from "../../utils/industries";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    width: "auto",
    minWidth: "400px",
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  closeButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 24,
    width: 24,
    backgroundImage: `url(${closeLogo})`,
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  title: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "28px",
    letterSpacing: "0",
    lineHeight: "34px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  body: {
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 20px",
  },
  dataSourceRow: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "15px",
  },
  dataSourceRowName: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "right",
    justifyContent: "flex-start",
    flex: 2,
    color: "#333",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 500,
    letterSpacing: "0",
  },
  dataSourceRowNameAddress: {
    flex: 1.5,
    justifyContent: "flex-start",
  },
  dataSourceRowValue: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "40px",
    flex: 3,
    color: "#333",
    fontFamily: "Montserrat",
    fontSize: "18px",
    letterSpacing: "0",
    "& div": {
      minWidth: "150px",
    },
  },
  dataSourceRowValueMultiSelect: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "40px",
    flex: 3,
    color: "#333",
    fontFamily: "Montserrat",
    fontSize: "18px",
    letterSpacing: "0",
  },
  dataSourceRowIndex: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontWeight: 500,
    minWidth: "25px",
  },
  dataSourceRowData: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    flexDirection: "row",
    display: "flex",
    "& div": {
      width: "150px",
    },
  },
  dataSourceRowAction: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    paddingLeft: "20px",
    width: "20%",
    display: "grid",
    marginRight: "10px",
    placeItems: "center",
    "& label": {
      margin: "0px !important",
    },
  },
  dataBoardMemberTitleRow: {
    marginLeft: "15px",
    "& div": {
      width: "150px",
    },
  },
  dataExecutiveOtherRows: {
    marginLeft: "15px",
    "& div": {
      width: "350px",
    },
  },
  dataSourceRowValueAddress: {
    flex: 4,
    marginLeft: 0,
  },
  dataSourceRowValueData: {
    "& div": {
      minWidth: "auto !important",
    },
  },
  executiveRowOtherInputs: {
    marginLeft: "15px !important",
    "& div": {
      width: "300px",
    },
  },
  textAreaContainer: {
    "& div": {
      width: "500px",
    },
  },
  cancelButton: {
    fontFamily: "Montserrat",
    fontSize: 12,
    borderRadius: 3,
    height: 38,
    textTransform: "none",
    border: "1px solid",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D748D",
    width: "74px",
  },
  actions: {
    display: "flex",
    paddingRight: "30px",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  addressBody: { display: "flex", flexDirection: "column", width: "100%" },
  addressRow: { display: "flex", flexDirection: "row", marginBottom: "10px", width: "100%" },
  noData: {
    fontStyle: "italic",
    color: "grey",
    fontFamily: "Montserrat",
    fontSize: 12,
    height: 35,
    fontWeight: 500,
    paddingLeft: "5px",
    flex: 4,
  },
}));

interface Props {
  companyId: number;
  name: string;
  title?: string;
  property_name: string;
  isVisible: boolean;
  input_type: InputTypeEnums;
  property_edit_data_for_fields: IEditFieldModalProp<ISingleData> | null;
  handleSaveEdit: (property_name: string, data: IEditFieldModalProp<ISingleData | IListData>) => void;
  get_select_data_function?: (token: string) => Promise<AxiosResponse<any, any>>;
  cancel: () => void;
  value_text?: string;
  summary_text?: string;
  select_key?: string;
}

export const SingleFieldEditModal: FC<Props> = ({
  companyId,
  name,
  title,
  property_edit_data_for_fields,
  input_type,
  handleSaveEdit,
  property_name,
  isVisible,
  get_select_data_function,
  cancel,
  value_text,
  summary_text,
  select_key,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [editDataForField, setEditDataForField] = useState<IEditFieldModalProp<ISingleData> | null>(null);
  const [copyEditData, setCopyEditData] = useState<IEditFieldModalProp<ISingleData> | null>(null);
  const [selectData, setSelectData] = useState<IMaterialUISelectItem[]>([]);
  const [selectDataFlat, setSelectDataFlat] = useState<any[]>([]);
  const [selectDataLevel, setSelectDataLevel] = useState<Level>({ selected_id: null, items: {} });
  const booleanData: Record<string, boolean> = { true: true, false: false };
  const [countries, setCountries] = useState<ICountry[]>([]);
  const { token } = useAuth();

  useEffect(() => {
    if (property_edit_data_for_fields === null || property_edit_data_for_fields === undefined) fetchData();
    else {
      setEditDataForField(property_edit_data_for_fields);
      setCopyEditData(property_edit_data_for_fields);
    }
    if (get_select_data_function) {
      get_select_data_function(token as string)
        .then((response) => {
          if (property_name === "industry" || property_name === "secondary_industries") return response.data;
          return response?.data?.data;
        })
        .then((data) => {
          if (input_type === InputTypeEnums.select_input) {
            setSelectData(data?.map((item: ISelectItem) => ({ value: item.id, label: item.name })));
          } else if (input_type === InputTypeEnums.multi_level_select_input) {
            setSelectDataFlat(data);
            setSelectDataLevel(convertTaxonomySegmentToLevel(data));
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (select_key === "boolean") {
      const data = [
        { value: "true", label: "True" },
        { value: "false", label: "False" },
      ];
      setSelectData(data);
    }

    if (property_name === "hq_address") {
      Service.get(apiConfig.countries, token as string)
        .then((response) => setCountries(response.data))
        .catch((err) => {
          console.log(err);
        });
    }
  }, [property_name]);
  // ACTION

  const fetchData = async () => {
    setLoading(true);
    await Service.get(`${apiConfig.companiesDataManager}/${companyId}/${property_name}`, token as string)
      .then((response: any) => {
        setEditDataForField(response.data);
        setCopyEditData(response.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const fetchStates = async (country_id: number | string, data: ISingleData) => {
    await Service.get(`${apiConfig.countries}/${country_id}/states`, token as string)
      .then((response: any) => {
        const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;
        setEditDataForField({
          ...newEditDataForField,
          data: newEditDataForField?.data.map((r: ISingleData) => (r === data ? { ...r, states: response.data } : r)),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSave = () => {
    if (editDataForField) {
      editDataForField?.data?.map((item: ISingleData) => {
        if (item?.states) delete item.states;

        if (select_key === "boolean") {
          item.value = booleanData[item?.value as string] ?? null;
        }
      });
      handleSaveEdit(property_name, editDataForField);
      close();
    }
  };

  const handleClose = () => {
    if (copyEditData) handleSaveEdit(property_name, copyEditData);
    close();
  };

  const close = () => {
    setEditDataForField(null);
    setCopyEditData(null);
    cancel();
  };

  const handlePriorityChange = (newDataSource: IDataSource[]) => {
    if (editDataForField) {
      setEditDataForField({
        ...editDataForField,
        dataSources: newDataSource,
      });
    }
  };

  // ROWS
  const prepareRows = () => {
    if (editDataForField) {
      return editDataForField?.dataSources
        ?.sort((a: IDataSource, b: IDataSource) => (a.priority > b.priority ? -1 : 1))
        .map((data_source: IDataSource, index: number) => {
          const data: ISingleData | undefined = getDataByDataSourceId(data_source.id);
          const is_this_row_manual_entry = data_source?.name === "Manual Entry";
          const selectValue = selectData?.find((item: IMaterialUISelectItem) => item.value === data?.value);
          const valueForSelect = is_this_row_manual_entry ? data?.value : selectValue?.label;
          if (input_type === InputTypeEnums.key_persons_input && is_this_row_manual_entry && !data) {
            setEditDataForField({
              ...editDataForField,
              data: [
                ...editDataForField.data,
                {
                  data_source_id: data_source.id,
                  value: "",
                  summary: "",
                  title,
                  include: true,
                  first_name: "",
                  last_name: "",
                  full_name: "",
                  image: "",
                  linkedin_url: "",
                  board_member: false,
                },
              ],
            });
          }

          if (input_type === InputTypeEnums.stock_input && is_this_row_manual_entry && !data) {
            setEditDataForField({
              ...editDataForField,
              data: [
                ...editDataForField.data,
                { data_source_id: data_source.id, sourceId: data_source.id, ticker: "", exchange: "" },
              ],
            });
          }

          if (input_type === InputTypeEnums.address_input && is_this_row_manual_entry && !data) {
            setEditDataForField({
              ...editDataForField,
              data: [
                ...editDataForField.data,
                {
                  data_source_id: data_source.id,
                  hq_country: undefined,
                  hq_state: undefined,
                  hq_address: "",
                  hq_zip: "",
                },
              ],
            });
          }

          if (
            ((input_type === InputTypeEnums.key_persons_input || input_type === InputTypeEnums.owner_input) &&
              is_this_row_manual_entry) ||
            is_this_row_manual_entry ||
            !is_this_row_manual_entry
          ) {
            if (input_type !== InputTypeEnums.key_persons_input && input_type !== InputTypeEnums.owner_input) {
              return (
                <div className={classes.dataSourceRow} key={index}>
                  <div
                    className={`${classes.dataSourceRowName} ${
                      input_type === InputTypeEnums.address_input ? classes.dataSourceRowNameAddress : null
                    }`}
                  >
                    {data_source !== undefined ? data_source.name : ""}
                  </div>
                  <div
                    className={
                      input_type === InputTypeEnums.multi_level_select_input
                        ? `${classes.dataSourceRowValueMultiSelect}`
                        : `${classes.dataSourceRowValue} ${
                            input_type === InputTypeEnums.date_input
                              ? classes.dataSourceRowValueData
                              : input_type === InputTypeEnums.address_input
                              ? classes.dataSourceRowValueAddress
                              : null
                          }`
                    }
                  >
                    {getWhichInputWillBeRendered(is_this_row_manual_entry, data as ISingleData, valueForSelect)}
                  </div>
                </div>
              );
            } else {
              return renderKeyPersons(data as ISingleData, index, is_this_row_manual_entry, data_source);
            }
          } else {
            if (is_this_row_manual_entry) {
              setEditDataForField({
                ...editDataForField,
                data: [...editDataForField.data, { data_source_id: data_source.id, value: "", title: "" }],
              });
            }
          }
        });
    }
    return null;
  };

  const getWhichInputWillBeRendered = (
    is_this_row_manual_entry: boolean,
    data: ISingleData | null,
    valueForSelect: string | number | boolean | null | undefined
  ) => {
    switch (input_type) {
      case InputTypeEnums.select_input:
        return renderSelectInput(is_this_row_manual_entry, data, valueForSelect);

      case InputTypeEnums.multi_level_select_input:
        return renderMultiLevelInput(is_this_row_manual_entry, data);

      case InputTypeEnums.address_input:
        return renderAddressInput(is_this_row_manual_entry, data);

      case InputTypeEnums.text_area_input:
        return renderTextAreaInput(is_this_row_manual_entry, data);

      case InputTypeEnums.number_input:
        return renderNumberTextInput(is_this_row_manual_entry, data);

      case InputTypeEnums.string_input:
        return renderStringTextInput(is_this_row_manual_entry, data);

      case InputTypeEnums.date_input:
        return renderDateInput(is_this_row_manual_entry, data);

      case InputTypeEnums.key_persons_input:
        return (
          <PersonEdit
            data={data as IListData}
            editDataForField={editDataForField as IEditFieldModalProp<IListData>}
            index={0}
            is_manual_entry={is_this_row_manual_entry}
            setEditDataForField={setEditDataForField}
            noShowBoardMember
            noShowTitle
            noShowInclude
            single
            handleDelete={handleDelete}
          />
        );

      case InputTypeEnums.phone_input:
        return renderPhoneInput(is_this_row_manual_entry, data);

      case InputTypeEnums.stock_input:
        return renderStockTickerInput(is_this_row_manual_entry, data);
      default:
        return null;
    }
  };

  const renderSelectInput = (
    is_this_row_manual_entry: boolean,
    data: ISingleData | null,
    valueForSelect: string | number | boolean | null | undefined
  ) => {
    if (selectData.length) {
      valueForSelect =
        valueForSelect === null ? "" : select_key === "boolean" ? String(valueForSelect) : valueForSelect;

      if (
        (select_key === "boolean" && data?.value !== null) ||
        (!select_key && data?.value !== "") ||
        is_this_row_manual_entry
      ) {
        return (
          <FormControl>
            <TextField
              autoFocus
              select={is_this_row_manual_entry}
              size="small"
              variant="outlined"
              disabled={!is_this_row_manual_entry}
              fullWidth
              value={valueForSelect}
              SelectProps={{
                MenuProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
              onChange={(e) => handleSelectBooleanChange(e, data)}
            >
              <MenuItem value="" style={{ height: 30 }}></MenuItem>
              {selectData?.map((data: IMaterialUISelectItem) => (
                <MenuItem key={data?.value} value={data?.value}>
                  {data?.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        );
      } else {
        return <div className={classes.noData}>No Data Found</div>;
      }
    } else {
      return null;
    }
  };

  const renderMultiLevelInput = (is_this_row_manual_entry: boolean, data: ISingleData | null) => {
    if (selectDataLevel?.items) {
      const inputData = data as TaxonomySegment | null;
      if (inputData?.id !== "" || is_this_row_manual_entry) {
        return (
          <MultiLevelFilter
            title={name}
            data={selectDataLevel}
            selected={inputData?.id ?? ""}
            selectedName={inputData?.name}
            setSelected={(selected, level) =>
              handleLevelSelect(
                data?.sourceId ?? (is_this_row_manual_entry ? 2 : data?.sourceId),
                selected as string,
                level
              )
            }
            isSingle
            isDisabled={!is_this_row_manual_entry}
            width="200px"
            right="-50%"
            bottom="-70%"
          />
        );
      } else {
        return <div className={classes.noData}>No Data Found</div>;
      }
    } else {
      return null;
    }
  };

  const renderTextAreaInput = (is_this_row_manual_entry: boolean, data: ISingleData | null) => {
    if (data?.value || is_this_row_manual_entry) {
      return (
        <div className={classes.textAreaContainer}>
          <TextField
            autoFocus
            size="small"
            variant="outlined"
            disabled={!is_this_row_manual_entry}
            value={data?.value}
            multiline
            rows={3}
            onChange={(e) => {
              const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;
              setEditDataForField({
                ...newEditDataForField,
                data: newEditDataForField?.data.map((r: ISingleData) =>
                  r === data ? { ...r, value: e.target.value } : r
                ),
              });
            }}
          />
        </div>
      );
    } else {
      return <div className={classes.noData}>No Data Found</div>;
    }
  };

  const renderNumberTextInput = (is_this_row_manual_entry: boolean, data: ISingleData | null) => {
    if (data?.value || is_this_row_manual_entry) {
      return (
        <FormControl>
          <TextField
            autoFocus
            size="small"
            variant="outlined"
            disabled={!is_this_row_manual_entry}
            value={data?.value}
            onChange={(e) => {
              if (e.target.value !== "" && isNaN(Number(e.target.value))) return;
              const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;
              setEditDataForField({
                ...newEditDataForField,
                data: newEditDataForField?.data.map((r: ISingleData) =>
                  r === data ? { ...r, value: e.target.value } : r
                ),
              });
            }}
          />
        </FormControl>
      );
    } else {
      return <div className={classes.noData}>No Data Found</div>;
    }
  };

  const renderStringTextInput = (is_this_row_manual_entry: boolean, data: ISingleData | null) => {
    if (data?.value || is_this_row_manual_entry) {
      return (
        <FormControl>
          <TextField
            autoFocus
            size="small"
            variant="outlined"
            disabled={!is_this_row_manual_entry}
            value={data?.value}
            onChange={(e) => {
              const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;
              setEditDataForField({
                ...newEditDataForField,
                data: newEditDataForField?.data.map((r: ISingleData) =>
                  r === data ? { ...r, value: e.target.value } : r
                ),
              });
            }}
          />
        </FormControl>
      );
    } else {
      return <div className={classes.noData}>No Data Found</div>;
    }
  };

  const renderStockTickerInput = (is_this_row_manual_entry: boolean, data: ISingleData | null) => {
    return data?.ticker || is_this_row_manual_entry ? (
      <>
        <TextField
          autoComplete="off"
          id="stock-ticker"
          label="Ticker"
          variant="standard"
          sx={{ m: 1, width: "7ch" }}
          size="small"
          value={data?.ticker || ""}
          disabled={!is_this_row_manual_entry}
          onChange={(e) => handleStockTickerChange(e, data)}
        />
        <TextField
          autoComplete="off"
          id="stock-exchange"
          label="Exchange"
          variant="standard"
          sx={{ m: 1, width: "15ch" }}
          size="small"
          value={data?.exchange || ""}
          disabled={!is_this_row_manual_entry}
          onChange={(e) => handleStockExchangeChange(e, data)}
        />
      </>
    ) : (
      <div className={classes.noData}>No Data Found</div>
    );
  };

  const renderPhoneInput = (is_this_row_manual_entry: boolean, data: ISingleData | null) => {
    if (data?.value || is_this_row_manual_entry) {
      return (
        <FormControl>
          <TextField
            autoFocus
            size="small"
            variant="outlined"
            disabled={!is_this_row_manual_entry}
            value={data?.value}
            onChange={(e) => handlePhoneChange(e, data)}
          />
        </FormControl>
      );
    } else {
      return <div className={classes.noData}>No Data Found</div>;
    }
  };

  const renderDateInput = (is_this_row_manual_entry: boolean, data: ISingleData | null) => {
    if (data?.value || is_this_row_manual_entry) {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormControl>
            <DatePicker
              autoFocus
              views={["year", "month", "day"]}
              value={data?.value === "" ? null : new Date(data?.value as string)}
              inputFormat="yyyy-MM-dd"
              mask="____-__-__"
              minDate={new Date("1650-01-01")}
              maxDate={new Date()}
              disabled={!is_this_row_manual_entry}
              onChange={(newValue) => {
                const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;
                setEditDataForField({
                  ...newEditDataForField,
                  data: newEditDataForField?.data.map((r: ISingleData) =>
                    r === data ? { ...r, value: newValue ? new Date(newValue).toISOString().split("T")[0] : "" } : r
                  ),
                });
              }}
              renderInput={(params) => <TextField {...params} helperText={null} />}
            />
          </FormControl>
        </LocalizationProvider>
      );
    } else {
      return <div className={classes.noData}>No Data Found</div>;
    }
  };

  const renderExecutiveInput = (
    is_this_row_manual_entry: boolean,
    data_source: IDataSource,
    data: ISingleData,
    index: number
  ) => {
    return (
      <div className={classes.dataSourceRow} key={index}>
        <div className={classes.dataSourceRowName}>{data_source !== undefined ? data_source.name : ""}</div>
        {data?.value || is_this_row_manual_entry ? (
          <>
            <div className={classes.dataSourceRowValue}>
              <TextField
                size="small"
                variant="outlined"
                disabled={!is_this_row_manual_entry}
                value={data?.value}
                onChange={(e) => {
                  const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;
                  setEditDataForField({
                    ...newEditDataForField,
                    data: newEditDataForField?.data.map((r: ISingleData) =>
                      r === data ? { ...r, value: e.target.value } : r
                    ),
                  });
                }}
              />
            </div>
            <div className={`${classes.dataSourceRowValue} ${classes.executiveRowOtherInputs}`}>
              <TextField
                size="small"
                variant="outlined"
                disabled={!is_this_row_manual_entry}
                value={data?.summary}
                multiline
                rows={2}
                onChange={(e) => {
                  const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;
                  setEditDataForField({
                    ...newEditDataForField,
                    data: newEditDataForField?.data.map((r: ISingleData) =>
                      r === data ? { ...r, summary: e.target.value } : r
                    ),
                  });
                }}
              />
            </div>
            <div className={`${classes.dataSourceRowValue} ${classes.executiveRowOtherInputs}`}>
              <TextField
                size="small"
                variant="outlined"
                disabled={!is_this_row_manual_entry}
                value={data?.image}
                multiline
                rows={2}
                onChange={(e) => {
                  const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;
                  setEditDataForField({
                    ...newEditDataForField,
                    data: newEditDataForField?.data.map((r: ISingleData) =>
                      r === data ? { ...r, image: e.target.value } : r
                    ),
                  });
                }}
              />
            </div>
            <div className={classes.dataSourceRowValue}>
              <CheckboxSelect
                checkboxLabel=""
                checked={data?.board_member ? true : false}
                setChecked={(checked: boolean) => {
                  const newEditDataForField = editDataForField as IEditFieldModalProp<IListData>;
                  setEditDataForField({
                    ...newEditDataForField,
                    data: newEditDataForField?.data.map((d) => (d === data ? { ...d, board_member: checked } : d)),
                  });
                }}
              />
            </div>
          </>
        ) : (
          <div className={classes.noData}>No Data Found</div>
        )}
      </div>
    );
  };

  const renderAddressInput = (is_this_row_manual_entry: boolean, data: ISingleData | null) => {
    if (
      data?.hq_address ||
      data?.hq_country?.name ||
      data?.hq_state?.name ||
      data?.hq_zip ||
      is_this_row_manual_entry
    ) {
      if (data?.hq_country?.id && !data?.states) fetchStates(data?.hq_country?.id, data);
      return (
        <div className={classes.addressBody}>
          <div className={classes.addressRow}>
            <TextField
              label="Address"
              size="small"
              variant="outlined"
              disabled={!is_this_row_manual_entry}
              value={data?.hq_address}
              sx={{ marginRight: "10px", width: "100%" }}
              onChange={(e) => {
                const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;
                const temp_data = data as ISingleData;
                temp_data.hq_address = e.target.value;
                setEditDataForField({
                  ...newEditDataForField,
                  data: newEditDataForField?.data.map((r: ISingleData) =>
                    r === data
                      ? {
                          ...r,
                          hq_address: temp_data.hq_address,
                        }
                      : r
                  ),
                });
              }}
            />
          </div>
          <div className={classes.addressRow}>
            <FormControl style={{ marginRight: "10px", width: "50%" }}>
              <TextField
                select={is_this_row_manual_entry}
                label="Country"
                size="small"
                variant="outlined"
                disabled={!is_this_row_manual_entry}
                fullWidth
                value={is_this_row_manual_entry ? data?.hq_country?.id : data?.hq_country?.name || ""}
                SelectProps={{
                  MenuProps: {
                    style: {
                      maxHeight: 200,
                      width: 200,
                    },
                  },
                }}
                onChange={(e) => handleCountryChange(e, data)}
              >
                <MenuItem value={0} style={{ height: 30 }}></MenuItem>
                {countries?.map((data: ICountry) => (
                  <MenuItem key={data?.id} value={data?.id}>
                    {data?.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl style={{ marginRight: "10px", width: "50%" }}>
              <TextField
                select={is_this_row_manual_entry}
                label="State"
                size="small"
                variant="outlined"
                disabled={!is_this_row_manual_entry}
                fullWidth
                value={is_this_row_manual_entry ? data?.hq_state?.id : data?.hq_state?.name || ""}
                SelectProps={{
                  MenuProps: {
                    style: {
                      maxHeight: 200,
                      width: 200,
                    },
                  },
                }}
                onChange={(e) => {
                  const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;
                  const value = Number(e.target.value);
                  const temp_data = data as ISingleData;
                  const state_name = data?.states?.find((state: IState) => state?.id === value)?.name;
                  temp_data.hq_state = { id: value, name: state_name as string };
                  setEditDataForField({
                    ...newEditDataForField,
                    data: newEditDataForField?.data.map((r: ISingleData) =>
                      r === data
                        ? {
                            ...r,
                            hq_state: temp_data.hq_state,
                          }
                        : r
                    ),
                  });
                }}
              >
                <MenuItem value={0} style={{ height: 30 }}></MenuItem>
                {data?.states?.map((data: IState) => (
                  <MenuItem key={data?.id} value={data?.id}>
                    {data?.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
          <div className={classes.addressRow}>
            <TextField
              label="City"
              size="small"
              variant="outlined"
              disabled={!is_this_row_manual_entry}
              value={data?.hq_city}
              sx={{ marginRight: "10px", width: "50%" }}
              onChange={(e) => {
                const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;
                const temp_data = data as ISingleData;
                temp_data.hq_city = e.target.value;
                setEditDataForField({
                  ...newEditDataForField,
                  data: newEditDataForField?.data.map((r: ISingleData) =>
                    r === data
                      ? {
                          ...r,
                          hq_city: temp_data.hq_city,
                        }
                      : r
                  ),
                });
              }}
            />
            <TextField
              label="Zip/Postal Code"
              size="small"
              variant="outlined"
              disabled={!is_this_row_manual_entry}
              value={data?.hq_zip}
              sx={{ marginRight: "10px", width: "50%" }}
              onChange={(e) => {
                const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;
                const temp_data = data as ISingleData;
                temp_data.hq_zip = e.target.value;
                setEditDataForField({
                  ...newEditDataForField,
                  data: newEditDataForField?.data.map((r: ISingleData) =>
                    r === data
                      ? {
                          ...r,
                          hq_zip: temp_data.hq_zip,
                        }
                      : r
                  ),
                });
              }}
            />
          </div>
        </div>
      );
    } else {
      return <div className={classes.noData}>No Data Found</div>;
    }
  };

  const renderKeyPersons = (
    data: ISingleData | null,
    index: number,
    is_manual_entry: boolean,
    data_source: IDataSource
  ) => {
    return (
      <div key={index}>
        <div className={classes.dataSourceRowName}>{data_source?.name}</div>
        {data !== null && data !== undefined && renderKeyPersonsHeaders(is_manual_entry)}
        {data !== null && data !== undefined && (
          <div className={classes.dataSourceRow}>
            <PersonEdit
              data={data as IListData}
              editDataForField={editDataForField as IEditFieldModalProp<IListData>}
              index={index}
              data_source={data_source?.name}
              is_manual_entry={is_manual_entry}
              setEditDataForField={(newEditDataForField: IEditFieldModalProp<IListData> | null) => {
                newEditDataForField = {
                  ...newEditDataForField,
                  data: newEditDataForField?.data.map((item) => ({
                    ...item,
                    full_name: item.first_name + " " + item.last_name,
                  })),
                } as IEditFieldModalProp<IListData> | null;
                setEditDataForField(newEditDataForField);
              }}
              noShowBoardMember
              noShowTitle
              noShowInclude
              single
              handleDelete={handleDelete}
            />
          </div>
        )}
        {!(data !== null && data !== undefined) && <div className={classes.noData}>No Data Found</div>}
      </div>
    );
  };

  // TODO Redesign to avoid comparison by reference. Use sourceId mapping instead.
  const handleLevelSelect = (sourceId: number | undefined, selectedId: string | number | null, level: Level) => {
    const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;

    const selectedElement =
      selectedId && sourceId ? { ...selectDataFlat.find((element) => element.id === selectedId), sourceId } : null;

    let updatedData;
    if (selectedElement) {
      const sourceEntry = newEditDataForField?.data.find((r: ISingleData) => r.sourceId === sourceId);
      if (sourceEntry) {
        updatedData = newEditDataForField?.data.map((r: ISingleData) =>
          r.sourceId === sourceId ? selectedElement : r
        );
      } else {
        updatedData = [...(newEditDataForField?.data ?? []), selectedElement];
      }
    } else {
      updatedData = newEditDataForField?.data.filter((r) => r.sourceId !== sourceId);
    }

    setEditDataForField({
      ...newEditDataForField,
      data: updatedData,
    });
    setSelectDataLevel(level);
  };

  const handleCountryChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    data: ISingleData | null
  ) => {
    const value = Number(event.target.value);
    const old_country_id = data?.hq_country?.id;
    const temp_data = data as ISingleData;

    const country_name = value === 0 ? "" : countries.find((country: ICountry) => country?.id === value)?.name;

    temp_data.hq_country = { id: value, name: country_name as string };

    const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;
    setEditDataForField({
      ...newEditDataForField,
      data: newEditDataForField?.data.map((r: ISingleData) =>
        r === data
          ? {
              ...r,
              hq_country: temp_data.hq_country,
            }
          : r
      ),
    });

    if (value === 0) {
      setEditDataForField({
        ...newEditDataForField,
        data: newEditDataForField?.data.map((r: ISingleData) =>
          r === data
            ? {
                ...r,
                hq_state: { id: 0, name: "" },
              }
            : r
        ),
      });
      delete (data as ISingleData)?.states;
      return;
    }

    if (old_country_id !== temp_data?.hq_country?.id) {
      fetchStates(event.target.value, data as ISingleData);
    }
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, data: ISingleData | null) => {
    let value = event.target.value;
    const regex = /^[0-9-]*$/;

    if (!regex.test(event.target.value)) {
      value = value.replace(value.slice(-1), "");
    }

    const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;
    setEditDataForField({
      ...newEditDataForField,
      data: newEditDataForField?.data.map((r: ISingleData) => (r === data ? { ...r, value: value } : r)),
    });
  };

  const handleStockTickerChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    data: ISingleData | null
  ) => {
    const value = event.target.value;

    const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;
    setEditDataForField({
      ...newEditDataForField,
      data: newEditDataForField?.data.map((r: ISingleData) => (r === data ? { ...r, ticker: value } : r)),
    });
  };
  const handleStockExchangeChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    data: ISingleData | null
  ) => {
    const value = event.target.value;

    const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;
    setEditDataForField({
      ...newEditDataForField,
      data: newEditDataForField?.data.map((r: ISingleData) => (r === data ? { ...r, exchange: value } : r)),
    });
  };

  const handleSelectBooleanChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    data: ISingleData | null
  ) => {
    const newEditDataForField = editDataForField as IEditFieldModalProp<ISingleData>;

    if (select_key === "boolean") {
      setEditDataForField({
        ...newEditDataForField,
        data: newEditDataForField?.data.map((r: ISingleData) =>
          r === data
            ? {
                ...r,
                value: booleanData[event.target.value] ?? "",
              }
            : r
        ),
      });
    } else {
      setEditDataForField({
        ...newEditDataForField,
        data: newEditDataForField?.data.map((r: ISingleData) =>
          r === data
            ? {
                ...r,
                value: event.target.value,
                title: event.target.value
                  ? String(selectData.find((item) => item.value === event.target.value)?.label)
                  : "",
              }
            : r
        ),
      });
    }
  };

  const getDataByDataSourceId = (dataSourceId: number): ISingleData | undefined => {
    if (title)
      return editDataForField?.data.find(
        (data: ISingleData) => data.title === title && data.data_source_id === dataSourceId
      );

    return editDataForField?.data.find(
      (data: ISingleData) =>
        data.data_source_id === dataSourceId || ((data as any).sourceId && (data as any).sourceId === dataSourceId)
    );
  };

  const handleDelete = (item_to_be_deleted: IListData) => {
    if (editDataForField)
      setEditDataForField({
        ...editDataForField,
        data: editDataForField?.data.filter((data: ISingleData) => data !== item_to_be_deleted),
      });
  };

  // HEADERS
  const headerRow = () => {
    return renderHeaderRows();
  };

  const renderHeaderRows = () => {
    if (input_type === InputTypeEnums.key_persons_input) return null;

    if (input_type === InputTypeEnums.stock_input) return null;

    return (
      <div className={classes.dataSourceRow}>
        <div
          className={`${classes.dataSourceRowName} ${
            input_type === InputTypeEnums.address_input ? classes.dataSourceRowNameAddress : null
          }`}
        >
          Data Source
        </div>
        <div
          className={`${classes.dataSourceRowValue} ${
            input_type === InputTypeEnums.address_input ? classes.dataSourceRowValueAddress : null
          }`}
          style={{ minWidth: input_type === InputTypeEnums.text_area_input ? "500px" : "auto" }}
        >
          {value_text ? value_text : "Value"}
        </div>
      </div>
    );
  };

  const renderKeyPersonsHeaders = (is_manual_entry?: boolean) => {
    return (
      <div className={classes.dataSourceRow}>
        <div className={classes.dataSourceRowData}>
          <div>First Name</div>
        </div>
        <div className={`${classes.dataSourceRowData} ${classes.dataBoardMemberTitleRow}`}>
          <div>Last Name</div>
        </div>
        <div className={`${classes.dataSourceRowData} ${classes.dataExecutiveOtherRows}`}>
          <div>{summary_text ? summary_text : "Summary"}</div>
        </div>
        {is_manual_entry && (
          <>
            <div className={classes.dataSourceRowAction}>Delete</div>
            <div className={classes.dataSourceRowAction}>Edit</div>
          </>
        )}
      </div>
    );
  };

  const renderExecutiveHeaderRows = () => {
    return (
      <div className={classes.dataSourceRow}>
        <div className={classes.dataSourceRowName}>Data Source</div>
        <div className={classes.dataSourceRowValue}>
          <div>{value_text ? value_text : "Value"}</div>
        </div>
        <div className={`${classes.dataSourceRowValue} ${classes.executiveRowOtherInputs}`}>
          <div>{summary_text ? summary_text : "Summary"}</div>
        </div>
        <div className={`${classes.dataSourceRowValue} ${classes.executiveRowOtherInputs}`}>
          <div>Image</div>
        </div>
        <div className={`${classes.dataSourceRowValue} `}>
          <div>Board Member</div>
        </div>
      </div>
    );
  };

  return loading ? (
    <Modal open={loading} onClose={handleClose} disableEnforceFocus>
      <div className={classes.container}>
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      </div>
    </Modal>
  ) : (
    <Modal open={isVisible} onClose={handleClose} disableEnforceFocus>
      <div className={classes.container}>
        <div>
          <button onClick={cancel} className={classes.closeButton} />
        </div>
        <div className={classes.title}>Edit: {name}</div>
        <>
          <div
            className={classes.body}
            style={{
              minWidth: InputTypeEnums.address_input === input_type ? "600px" : "100%",
              minHeight: InputTypeEnums.address_input === input_type ? "400px" : "100%",
            }}
          >
            {headerRow()}
            {prepareRows()}
          </div>
          <div>
            {editDataForField?.dataSources && (
              <ChangeDataSourcePriorityModal
                name={name}
                onClose={handlePriorityChange}
                data={editDataForField.dataSources}
              />
            )}
          </div>
        </>
        <div className={classes.actions}>
          <button className={classes.cancelButton} onClick={handleClose}>
            Cancel
          </button>
          <span style={{ marginLeft: 10 }} />
          <ApplyButton text="Apply" apply={handleSave} />
        </div>
      </div>
    </Modal>
  );
};

export interface IEditFieldModalProp<T> {
  dataSources: IDataSource[];
  overrideWithManual: boolean;
  data: T[];
  field_name?: string;
}

export interface IDataSource {
  id: number;
  name: string;
  priority: number;
  type?: "Computed" | "External" | "Manual";
}

export interface ISingleData {
  data_source_id: number;
  value?: string | number | null | boolean;
  summary?: string;
  image?: string;
  title?: string;
  include?: boolean;
  hq_address?: string;
  hq_country?: ISelectItem;
  hq_state?: ISelectItem;
  hq_city?: string;
  hq_zip?: string;
  states?: IState[];
  linkedin_url?: string;
  board_member?: boolean;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  ticker?: string;
  exchange?: string;
  sourceId?: number;
}
