import { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { IRequestDetail } from "../../interfaces";
import { useAuth } from "../../shared/hooks/auth-hook";
import check from "../../assets/check.svg";
import cross from "../../assets/cross.svg";
import { apiConfig } from "../../services/api/apiConfig";
import { Service } from "../../services/api/service";

const useStyles = makeStyles(() => ({
  container: {
    height: "255px",
    borderRadius: "2px",
    backgroundColor: "#F6F6F6",
    padding: "13px 18px",
    marginBottom: "8px",
    overflowY: "scroll",
  },
  title: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "22px",
    fontWeight: 500,
    marginRight: "15px",
    "& > a": {
      color: "#333333",
      fontFamily: "Oswald",
      fontSize: "22px",
      fontWeight: 500,
      marginRight: "15px",
      textDecoration: "none",
    },
  },
  domain: {
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: "12px",
    placeSelf: "center",
  },
  line: {
    border: "1px solid #E1E1E1",
    marginTop: "13px",
  },
  buttons: {
    marginLeft: "auto",
    placeSelf: "center",
  },
  approvalText: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
  },
  button: {
    fontFamily: "Montserrat",
    fontSize: 12,
    borderRadius: 3,
    height: 35,
    width: 70,
    textTransform: "none",
    border: "1px solid",
    cursor: "pointer",
  },
}));

interface Props {
  details: IRequestDetail[];
  type: string;
  isUpdated: (data: boolean, response: any) => void;
}

const statusToMessage: Record<string, string> = {
  APPROVED: "Company Creation Request was approved!",
  REJECTED: "Company Creation Request was rejected!",
};

export const RequestCard: FC<Props> = ({ details, type, isUpdated }) => {
  const classes = useStyles();
  const [requestDetails, setRequestDetails] = useState<IRequestDetail[]>([]);
  const { token, hasPermission } = useAuth();

  useEffect(() => {
    setRequestDetails(details);
  }, [details]);

  const onSubmitRequestReview = (id: number, status: string) => {
    updateDetails(id, "Submitting...");

    Service.post(`${apiConfig.admin}/company-creation-requests/${id}/status`, token as string, {
      status: status.toUpperCase(),
    })
      .then((response) => response.data)
      .then((data) => {
        const requestStatus = data?.status ?? "";
        const message = statusToMessage[requestStatus] ?? "Company creation request is updated";

        isUpdated(true, message);
        updateDetails(id, status);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateDetails = (detailId: number, status: string) => {
    const newDetails: IRequestDetail[] = details.map((it) => {
      if (it.id === detailId) {
        return { ...it, approvalStatus: status };
      }
      return it;
    });
    setRequestDetails(newDetails);
  };

  return (
    <div className={classes.container}>
      {requestDetails.map((detail: any, index: number) => (
        <div key={detail.id}>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
              <span className={classes.title}>
                {detail?.company_id ? (
                  <a href={`/companies/${detail?.company_id}`}>{detail?.data?.name}</a>
                ) : (
                  detail?.data?.name
                )}
              </span>
              <span className={classes.domain}>- {detail?.data?.domain_primary}</span>
            </div>
            <div className={classes.buttons}>
              {type === "new" && hasPermission("CompanyCreate.ReadWrite.All") && (
                <div>
                  {detail?.approvalStatus ? (
                    <span className={classes.approvalText}>
                      {(detail?.approvalStatus === "Approved" || detail?.approvalStatus === "Rejected") && (
                        <img
                          src={detail?.approvalStatus === "Approved" ? check : cross}
                          alt="icon"
                          style={{ marginRight: "8px" }}
                        />
                      )}
                      {detail?.approvalStatus}
                    </span>
                  ) : (
                    <div>
                      <button
                        className={classes.button}
                        onClick={() => onSubmitRequestReview(detail.id, "Rejected")}
                        style={{ marginRight: "10px", backgroundColor: "#FFFFFF", color: "#4D748D" }}
                      >
                        Reject
                      </button>
                      <button
                        className={classes.button}
                        onClick={() => onSubmitRequestReview(detail.id, "Approved")}
                        style={{ backgroundColor: "#4D748D", color: "#FFFFFF" }}
                      >
                        Approve
                      </button>
                    </div>
                  )}
                </div>
              )}
              {type === "past" && (
                <span className={classes.approvalText}>
                  <img src={detail?.status === "Approved" ? check : cross} alt="icon" style={{ marginRight: "8px" }} />
                  {detail?.status}
                </span>
              )}
            </div>
          </div>
          <div className={classes.line}></div>
        </div>
      ))}
    </div>
  );
};
