import { FC, useEffect, useState, ChangeEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import closeLogo from "../../assets/close-circle.png";
import Modal from "@material-ui/core/Modal";
import { CircularProgress } from "@material-ui/core";
import { TextField, FormControl, MenuItem } from "@mui/material";
import { CheckboxSelect } from "../checkbox";
import { ApplyButton } from "../applyButton";
import { ChangeDataSourcePriorityModal } from "./ChangeDataSourcePriorityModal";
import { IListData } from "./ListFieldEditModal";
import { ISingleData } from "./SingleFieldEditModal";
import { ICompanyEditModalFunctions } from "../../utils/companyEditModalFunctions";
import { apiConfig } from "../../services/api/apiConfig";
import { Service } from "../../services/api/service";
import { useAuth } from "../../shared/hooks/auth-hook";
import AddRowIcon from "../../assets/add-circle.png";
import DeleteIcon from "../../assets/close-circle.png";
import { ICountry, IRangeItem, ISelectItem, IState, IStoreSelectItem } from "../../interfaces";
import { InputTypeEnums, ISelectData } from "../../pages/companyView/types";
import { SelectDisplay } from "./SelectToDisplay";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 20px",
  },
  body: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
  },
  closeButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 24,
    width: 24,
    backgroundImage: `url(${closeLogo})`,
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  title: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "28px",
    letterSpacing: "0",
    lineHeight: "34px",
    marginBottom: "10px",
  },
  cancelButton: {
    fontFamily: "Montserrat",
    fontSize: 12,
    borderRadius: 3,
    height: 38,
    textTransform: "none",
    border: "1px solid",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D748D",
    width: "74px",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "20px",
  },
  dataContainer: {
    marginTop: "20px",
  },
  dataTitleRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  dataTitle: {
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
  },
  revenueSelectLink: {
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
    marginRight: "5px",
    textDecoration: "underline",
    cursor: "pointer",
  },
  dataRow: {
    display: "flex",
    marginBottom: "10px",
    alignItems: "center",
    flexDirection: "row",
  },
  addRowContainer: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  addRowText: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: "14px",
  },
  addColumnIcon: {
    height: "20px",
    width: "20px",
    marginRight: "10px",
  },
  select: {
    border: "1px solid #E1E1E1",
    padding: 10,
    width: "90px",
    color: "#666666",
    fontWeight: 400,
    fontFamily: "Montserrat",
    marginRight: "10px",
    "&:focus": {
      outline: "none",
    },
  },
  dataSourceRowAction: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    paddingLeft: "5px",
    marginRight: "10px",
    width: "20px",
    display: "grid",
    "& label": {
      margin: "0px !important",
    },
  },
  dataSourceRowActionDelete: {
    height: "20px",
    width: "20px",
    cursor: "pointer",
  },
  dataSourceRowYear: {
    fontFamily: "Montserrat",
    minWidth: "25px",
    fontSize: 12,
    color: "#333333",
    fontWeight: 600,
    width: "50px",
  },
  dataSourceRowData: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    flexDirection: "row",
    display: "flex",
    textAlign: "center",
  },
  overflowContainer: {
    minHeight: "70px",
    maxHeight: "150px",
    overflowY: "auto",
  },
  noData: {
    fontStyle: "italic",
    color: "grey",
    fontFamily: "Montserrat",
    fontSize: 12,
    height: 35,
    fontWeight: 500,
    paddingLeft: "5px",
  },
  errorDiv: {
    color: "#c40014",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "10px",
  },
}));

interface Props {
  name: string;
  property_name: string;
  isVisible: boolean;
  data_type: string;
  property_edit_data_for_fields: IEditFieldModalProp<ITableData> | null;
  select_data_for_fields?: IEditFieldModalProp<ISelectData> | null;
  select_key: string;
  handleSaveEdit: (
    property_name: string,
    data: IEditFieldModalProp<ISingleData | IListData | ITableData>,
    additional_data?: Record<string, number[] | IEditFieldModalProp<ISelectData>>
  ) => void;
  cancel: () => void;
  companyId: number;
  editModalFunctions: ICompanyEditModalFunctions;
  input_type: InputTypeEnums;
  selected_items?: string[] | number[];
  selected_property_name?: string;
}

interface IRow {
  year: number;
  row_id?: number;
  country?: IStoreSelectItem | null;
  originalData?: ITableData[];
  dataSourceId: number;
}

export const TableFieldEditModal: FC<Props> = ({
  name,
  property_name,
  data_type,
  isVisible,
  cancel,
  companyId,
  property_edit_data_for_fields,
  select_data_for_fields = null,
  select_key = "",
  handleSaveEdit,
  editModalFunctions,
  input_type,
  selected_items,
  selected_property_name = "",
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [editDataForField, setEditDataForField] = useState<IEditFieldModalProp<ITableData> | null>(null);
  const [selectDataForFields, setSelectDataForFields] = useState<IEditFieldModalProp<ISelectData> | null>(null);
  const [copyEditData, setCopyEditData] = useState<IEditFieldModalProp<ITableData> | null>(null);
  const [selectData, setSelectData] = useState<IStoreSelectItem[]>([]);
  const [rangeItems, setRangeItems] = useState<IRangeItem[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [duplicateRefresh, setDuplicateRefresh] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[] | number[]>([]);
  const [quarter_row_id, setQuarterRowId] = useState<number>(0);
  const [rangeField, setRangeField] = useState<boolean>(false);
  const { token } = useAuth();

  const manual_entry_data_source_id = editDataForField?.dataSources.find(
    (ds: IDataSource) => ds.name === "Manual Entry"
  )?.id;
  const divided_data = select_key !== "";
  const item_key = select_key === "store_type" ? "name" : "id";
  const month_quarter_input_width = data_type === "month" ? "67px" : "150px";
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const quarters = ["Q1", "Q2", "Q3", "Q4"];
  const range_item_headers = ["Actual Value", "Range"];

  useEffect(() => {
    if (property_edit_data_for_fields === null) {
      fetchData();
    } else {
      setEditDataForField(property_edit_data_for_fields);
      setCopyEditData(property_edit_data_for_fields);
    }

    let range_items_path = "";
    if (property_name === "employee_num") {
      setRangeField(true);
      range_items_path = `${apiConfig.employeeNumberRanges}`;
      fetchSelectData(range_items_path).then((data) => setRangeItems(data));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property_name]);

  useEffect(() => {
    if (!property_name.includes("store_num")) {
      setSelectDataForFields(select_data_for_fields);
      const temp_array: number[] = [];
      select_data_for_fields?.data?.forEach((item: ISelectData) => {
        temp_array.push(item?.country_id as number);
      });
      setSelectedItems(temp_array);
    } //TO DO This will be updated after shopper count is merged
  }, [selected_property_name]);

  useEffect(() => {
    property_name.includes("store_num") && setSelectedItems(selected_items as number[]); //TO DO This will be updated after shopper count is merged
  }, [selected_items]);

  useEffect(() => {
    if (input_type === InputTypeEnums.select_input) {
      const countries_iso = ["USA", "CAN"];
      const countries_id: number[] = [];

      fetchSelectData(apiConfig.countries).then((response) => {
        if (select_key === "country") {
          setSelectData(response);
        } else if (select_key === "state") {
          for (const iso of countries_iso) {
            const found_item = response?.find((item: ICountry) => item?.iso3 === iso);
            countries_id.push(found_item?.id);
          }

          for (const id of countries_id) {
            fetchSelectData(`${apiConfig.countries}/${id}/states`).then((response) => {
              setSelectData((selectData) => [...selectData, ...response]);
            });
          }
        }
      });
    }
  }, [input_type]);

  useEffect(() => {
    setErrorMessage(
      checkDuplicates() ? "There are more than one of the same rows. Please change or remove others" : ""
    );
  }, [editDataForField?.data, duplicateRefresh]);

  useEffect(() => {
    if (editDataForField?.data) {
      let counter = setRowId() || quarter_row_id;

      if (editDataForField.data.find((item) => item.row_id === undefined)) {
        const rowIdAddedQuarters = editDataForField.data.sort((x, y) => {
          if (x.year !== y.year) return x.year > y.year ? -1 : 1;
          if (x.quarter && y.quarter && x.quarter !== y.quarter) return x.quarter > y.quarter ? 1 : -1;
          return -1;
        });
        let currentYear: number | undefined;
        let currentDataSourceId: number | undefined;
        for (const item of rowIdAddedQuarters) {
          if (currentYear !== item.year || currentDataSourceId !== item.data_source_id) {
            const findSame = rowIdAddedQuarters.find(
              (item1) =>
                item1.row_id &&
                item1.year === item.year &&
                item1.data_source_id === item.data_source_id &&
                item1.country?.id === item.country?.id
            );
            if (findSame) {
              item.row_id = findSame.row_id;
              continue;
            }
            currentYear = item.year;
            currentDataSourceId = item.data_source_id;
            item.row_id = ++counter;
          } else {
            item.row_id = counter;
          }
        }
        setEditDataForField({
          ...editDataForField,
          data: rowIdAddedQuarters,
        });

        setQuarterRowId(counter);
      }
    }
  }, [editDataForField, quarter_row_id]);

  const fetchSelectData = async (path: string) => {
    const response = await Service.get(path, token as string);
    return response.data;
  };

  const fetchData = async () => {
    setLoading(true);
    const path = `${apiConfig.companiesDataManager}/${companyId}/${property_name}`;
    await Service.get(path, token as string)
      .then((response: any) => {
        setEditDataForField(response.data);
        setCopyEditData(response.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const handleSave = () => {
    if (validateData() && editDataForField) {
      data_type === "quarter" && removeEmptyQuarterValues();
      if (divided_data && property_name.includes("store_num")) {
        handleSaveEdit(property_name, editDataForField, { [select_key]: selectedItems as number[] }); // TO DO update this part after shopper count is merged
      } else if (divided_data && !property_name.includes("store_num")) {
        handleSaveEdit(property_name, editDataForField, {
          [selected_property_name]: selectDataForFields as IEditFieldModalProp<ISelectData>,
        });
      } else {
        handleSaveEdit(property_name, editDataForField);
      }
      close();
    }
  };

  const removeEmptyQuarterValues = () => {
    const newEditDataForField = editDataForField as IEditFieldModalProp<ITableData>;
    newEditDataForField.data = newEditDataForField?.data?.filter((r: ITableData) => r.value !== "");
    setEditDataForField({ ...newEditDataForField });
  };

  const handleClose = () => {
    if (copyEditData) handleSaveEdit(property_name, copyEditData);
    close();
  };

  const close = () => {
    setEditDataForField(null);
    setSelectDataForFields(select_data_for_fields);
    setCopyEditData(null);
    cancel();
  };

  const handlePriorityChange = (newDataSource: IDataSource[]) => {
    if (editDataForField) {
      const max_priority = newDataSource.reduce((prev, current) => (prev.priority > current.priority ? prev : current));

      const override = max_priority.id === manual_entry_data_source_id;
      setEditDataForField({
        ...editDataForField,
        dataSources: newDataSource,
        overrideWithManual: override,
      });
    }
  };

  const handlePrioritizeManualInputCheckboxChange = (checked: boolean) => {
    const newEditDataForField = editDataForField as IEditFieldModalProp<ITableData>;
    const max_priority = editDataForField?.dataSources.reduce((prev, current) =>
      prev.priority > current.priority ? prev : current
    );
    if (checked) {
      setEditDataForField({
        ...newEditDataForField,
        dataSources: newEditDataForField?.dataSources.map((item: IDataSource) =>
          item.id === manual_entry_data_source_id ? { ...item, priority: Number(max_priority?.priority) + 1 } : item
        ),
        overrideWithManual: true,
      });
    } else {
      setEditDataForField({
        ...newEditDataForField,
        overrideWithManual: false,
      });
    }
  };

  const addRow = () => {
    if (editDataForField) {
      const years: number[] = [];
      editDataForField?.data?.forEach((data: ITableData) => {
        if (years.indexOf(data?.year) < 0) years.push(data?.year);
      });

      let year: number = new Date().getFullYear();

      years.forEach((y: number, index: number) => {
        if (year === y) year -= 1;
      });

      let new_data: ITableData[] = [];

      if (data_type === "year") {
        const row_id = setRowId() || quarter_row_id + 1;
        setQuarterRowId(row_id);
        new_data = [
          {
            data_source_id: Number(manual_entry_data_source_id),
            value: "",
            year: year,
            row_id: row_id,
            include: true,
          },
        ];
        if (divided_data) (new_data[0][select_key as keyof ITableData] as IStoreSelectItem) = { id: null, name: "" };
      }

      if (data_type === "quarter") {
        const row_id = setRowId() || quarter_row_id + 1;
        setQuarterRowId(row_id);
        for (let index = 0; index < 4; index++) {
          const data: ITableData = {
            data_source_id: Number(manual_entry_data_source_id),
            value: "",
            year: year,
            quarter: index + 1,
            row_id: row_id,
            include: true,
          };
          if (divided_data) (data[select_key as keyof ITableData] as IStoreSelectItem) = { id: null, name: "" };
          new_data.push(data);
        }
      }
      setEditDataForField({
        ...editDataForField,
        data: [...editDataForField.data, ...new_data],
      });
    }
  };

  const setRowId = () => {
    if (editDataForField?.data) {
      const row_ids: number[] = [];

      editDataForField?.data?.forEach((item: ITableData) => {
        if (item.row_id !== undefined && row_ids.indexOf(item?.row_id) < 0) row_ids.push(item?.row_id);
      });
      const biggest_row_id = row_ids.length > 0 ? Math.max(...row_ids) : 0;

      return biggest_row_id + 1;
    }
  };

  const sortYearly = () => {
    const new_data: ITableData[] = [...(editDataForField?.data as ITableData[])];
    new_data.sort((a: ITableData, b: ITableData) => (a?.year > b?.year ? -1 : 1));

    for (let i = 0; i < new_data.length - 1; i++) {
      if (new_data[i]?.year === new_data[i + 1]?.year) {
        const currents_priority = editDataForField?.dataSources?.filter(
          (item: IDataSource) => item.id === new_data[i]?.data_source_id
        )[0]?.priority;
        const nexts_priority = editDataForField?.dataSources.filter(
          (item: IDataSource) => item.id === new_data[i + 1]?.data_source_id
        )[0]?.priority;
        if (currents_priority && nexts_priority) {
          if (currents_priority < nexts_priority) {
            const temp = new_data[i];
            new_data[i] = new_data[i + 1];
            new_data[i + 1] = temp;
          }
        }
      }
    }
    return new_data;
  };

  const sortMonthly = () => {
    const new_data: ITableData[] = [...(editDataForField?.data as ITableData[])];
    new_data.sort((a: ITableData, b: ITableData) =>
      String(a?.year) + String(a?.month) > String(b?.year) + String(b?.month) ? -1 : 1
    );

    for (let i = 0; i < new_data.length - 1; i++) {
      if (
        String(new_data[i]?.year) + String(new_data[i]?.month) ===
        String(new_data[i + 1]?.year) + String(new_data[i + 1]?.month)
      ) {
        const currents_priority = editDataForField?.dataSources?.filter(
          (item: IDataSource) => item.id === new_data[i]?.data_source_id
        )[0]?.priority;
        const nexts_priority = editDataForField?.dataSources.filter(
          (item: IDataSource) => item.id === new_data[i + 1]?.data_source_id
        )[0]?.priority;
        if (currents_priority && nexts_priority) {
          if (currents_priority < nexts_priority) {
            new_data.splice(i, 1);
          } else {
            new_data.splice(i + 1, 1);
          }
          i--;
        }
      }
    }
    return new_data;
  };

  const sortQuarterly = () => {
    const new_data: ITableData[] = [...(editDataForField?.data as ITableData[])];
    new_data.sort((a: ITableData, b: ITableData) =>
      String(a.year) + String(a?.quarter) > String(b.year) + String(b?.quarter) ? -1 : 1
    );

    for (let i = 0; i < new_data.length - 1; i++) {
      if (
        String(new_data[i]?.year) + String(new_data[i]?.quarter) ===
          String(new_data[i + 1]?.year) + String(new_data[i + 1]?.quarter) &&
        new_data[i]?.country?.id &&
        new_data[i]?.country?.id === new_data[i + 1]?.country?.id
      ) {
        const currents_priority = editDataForField?.dataSources?.filter(
          (item: IDataSource) => item.id === new_data[i]?.data_source_id
        )[0]?.priority;
        const nexts_priority = editDataForField?.dataSources.filter(
          (item: IDataSource) => item.id === new_data[i + 1]?.data_source_id
        )[0]?.priority;
        if (currents_priority && nexts_priority) {
          if (currents_priority < nexts_priority) {
            new_data.splice(i, 1);
          } else {
            new_data.splice(i + 1, 1);
          }
          i--;
        }
      }
    }
    return new_data;
  };

  const renderConsolidatedData = () => {
    if (editDataForField?.data.length === 0) return <div className={classes.noData}>No Data Found</div>;

    if (data_type === "year") {
      const new_data = sortYearly();
      const key = select_key as keyof ITableData;
      return renderYearlyTable(divided_data ? (editDataForField?.data as ITableData[]) : new_data, key);
    } else {
      const field_count = data_type === "month" ? 12 : 4;
      const new_data = data_type === "month" ? sortMonthly() : sortQuarterly();
      return renderMonthlyQuarterlyTable(new_data as ITableData[], field_count, data_type);
    }
  };

  const renderYearlyTable = (data: ITableData[], key: keyof ITableData) => {
    return (
      <>
        {rangeField && renderHeaders(range_item_headers, false)}
        <div className={classes.overflowContainer}>
          {data.map((item: ITableData, index: number) => {
            return (
              <div className={classes.dataRow} key={index}>
                <span className={classes.dataSourceRowYear}>{item?.year}</span>
                <TextField
                  size="small"
                  variant="outlined"
                  disabled
                  value={item?.value as string}
                  inputProps={{ style: { fontSize: 14, fontFamily: "Montserrat", color: "#333333" } }}
                />
                {divided_data && key !== "store_type" ? (
                  <FormControl style={{ marginLeft: "10px" }}>
                    <TextField
                      label={(item[key] as ICountry | IState)?.name ? "" : key === "country" ? "Country" : "State"}
                      size="small"
                      variant="outlined"
                      disabled
                      fullWidth
                      value={(item[key] as ICountry | IState)?.name || ""}
                      SelectProps={{
                        MenuProps: {
                          style: {
                            maxHeight: 200,
                            width: 200,
                          },
                        },
                      }}
                      InputLabelProps={{ shrink: false }}
                    ></TextField>
                  </FormControl>
                ) : key === "store_type" ? (
                  <FormControl style={{ marginLeft: "10px" }}>
                    <TextField
                      size="small"
                      variant="outlined"
                      disabled
                      value={(item?.store_type?.name as string) || ""}
                      inputProps={{
                        style: { fontSize: 14, fontFamily: "Montserrat", color: "#333333", marginLeft: "10px" },
                      }}
                    />
                  </FormControl>
                ) : null}
                {rangeField && (
                  <>
                    <FormControl style={{ marginLeft: "10px", width: "150px" }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        disabled
                        fullWidth
                        value={item?.range?.name || ""}
                        SelectProps={{
                          MenuProps: {
                            style: {
                              maxHeight: 200,
                              width: 200,
                            },
                          },
                        }}
                        InputLabelProps={{ shrink: false }}
                      ></TextField>
                    </FormControl>
                  </>
                )}
                <div className={classes.dataSourceRowAction}>
                  <CheckboxSelect
                    checkboxLabel=""
                    checked={item.include ? true : false}
                    setChecked={(checked: boolean) => {
                      const newEditDataForField = editDataForField as IEditFieldModalProp<ITableData>;
                      setEditDataForField({
                        ...newEditDataForField,
                        data: newEditDataForField?.data.map((d) => (d === item ? { ...d, include: checked } : d)),
                      });
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const handleSelectChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: keyof ITableData,
    data?: ITableData | null,
    row?: IRow | null
  ) => {
    const value = Number(event.target.value);
    const newEditDataForField = editDataForField as IEditFieldModalProp<ITableData>;

    if (data) {
      setEditDataForField({
        ...newEditDataForField,
        data: newEditDataForField?.data.map((r: ITableData) =>
          r === data
            ? {
                ...r,
                [key]: selectData.find((item: IStoreSelectItem) => item?.id === value) as IStoreSelectItem,
              }
            : r
        ),
      });
    } else {
      setEditDataForField({
        ...newEditDataForField,
        data: newEditDataForField?.data.map((r: ITableData) =>
          r?.year === row?.year && r?.country?.id === row?.country?.id && r.row_id === row.row_id
            ? {
                ...r,
                [key]: selectData.find((item: IStoreSelectItem) => item?.id === value) as IStoreSelectItem,
              }
            : r
        ),
      });
    }
  };

  const handleStringChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    data: ITableData,
    key: keyof ITableData
  ) => {
    const newEditDataForField = editDataForField as IEditFieldModalProp<ITableData>;
    setEditDataForField({
      ...newEditDataForField,
      data: newEditDataForField?.data.map((r: ITableData) =>
        r === data
          ? {
              ...r,
              [key]: { id: r?.store_type?.id ? r?.store_type?.id : null, name: event.target.value },
            }
          : r
      ),
    });
  };

  const renderMonthlyQuarterlyTable = (data: ITableData[], fieldCount: number, key: string) => {
    const rows: IRow[] = [];
    data?.map((item: ITableData) => {
      const object: IRow = { year: item?.year, originalData: [item], dataSourceId: item.data_source_id };
      if (item?.country) object.country = item?.country;
      const findedIndex = rows?.findIndex(
        (row: IRow) =>
          row.year === object.year && row.dataSourceId === object.dataSourceId && row.country?.id === object.country?.id
      );
      if (findedIndex < 0) rows.push(object);
      else if (findedIndex > -1 && rows[findedIndex]) {
        rows[findedIndex].originalData?.push(item);
      }
    });
    return (
      <>
        {data.length ? renderHeaders(data_type === "month" ? months : quarters, false) : null}
        <div className={classes.overflowContainer}>
          {rows?.map((row: IRow, index: number) => (
            <div className={classes.dataRow} key={index}>
              <span className={classes.dataSourceRowYear}>{row?.year}</span>
              {[...Array(fieldCount)].map((item: ITableData, i: number) => (
                <div
                  className={classes.dataSourceRowData}
                  style={{ width: month_quarter_input_width, marginRight: "5px" }}
                  key={i}
                >
                  <TextField
                    size="small"
                    variant="outlined"
                    inputProps={{ style: { fontSize: 14, fontFamily: "Montserrat", color: "#333333" } }}
                    disabled
                    value={
                      (row?.originalData
                        ? row.originalData.find((item) => item[key as keyof typeof item] === i + 1)?.value
                        : ("" as string)) || ""
                    }
                  />
                </div>
              ))}
              {divided_data ? (
                <FormControl>
                  <TextField
                    label={row?.country?.name ? "" : "Country"}
                    size="small"
                    variant="outlined"
                    disabled
                    fullWidth
                    value={row?.country?.name || ""}
                    SelectProps={{
                      MenuProps: {
                        style: {
                          maxHeight: 200,
                          width: 200,
                        },
                      },
                    }}
                    InputLabelProps={{ shrink: false }}
                  ></TextField>
                </FormControl>
              ) : null}
              <div className={classes.dataSourceRowAction}>
                <CheckboxSelect
                  checkboxLabel=""
                  checked={
                    row?.originalData
                      ? row.originalData[0]
                        ? row.originalData.length === row.originalData.filter((item) => item.include).length
                          ? true
                          : false
                        : false
                      : false
                  }
                  setChecked={(checked: boolean) => {
                    const newEditDataForField = editDataForField as IEditFieldModalProp<ITableData>;
                    setEditDataForField({
                      ...newEditDataForField,
                      data: newEditDataForField?.data.map((d) =>
                        d.year === row.year &&
                        d.data_source_id === row.dataSourceId &&
                        d.country?.id === row.country?.id
                          ? { ...d, include: checked }
                          : d
                      ),
                    });
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const renderManualData = () => {
    const manual_data = editDataForField?.data?.filter(
      (data: ITableData) => data.data_source_id === manual_entry_data_source_id
    );
    if (manual_data?.length === 0) return <div className={classes.noData}>No Data Found</div>;

    const key = select_key as keyof ITableData;

    if (data_type === "year") {
      if (divided_data && select_key === "store_type") return renderYearlyStoreNumbersByStoreType(key);
      return renderYearlyManualData(key);
    } else if (data_type === "month") {
      return renderMonthlyManualData();
    } else if (data_type === "quarter") {
      return renderQuarterlyManualData(key);
    }
  };

  const renderYearlyManualData = (key: keyof ITableData) => {
    const new_data: ITableData[] = [...(editDataForField?.data as ITableData[])];

    return (
      <>
        {rangeField && renderHeaders(range_item_headers, true)}
        <div className={classes.overflowContainer}>
          {new_data
            ?.filter((data: ITableData) => data.data_source_id === manual_entry_data_source_id)
            .map((data: ITableData, index: number) => {
              return (
                <div className={classes.dataRow} key={index}>
                  {yearSelect(data as ITableData)}
                  <TextField
                    size="small"
                    variant="outlined"
                    value={data?.value}
                    type={input_type === InputTypeEnums.number_input ? "number" : "text"}
                    title={data?.value as string}
                    inputProps={{ style: { fontSize: 14, fontFamily: "Montserrat", color: "#333333" } }}
                    onChange={(e) =>
                      changeManualData(
                        e,
                        new_data,
                        index,
                        data?.year,
                        "",
                        (data[key] as ICountry | IState)?.id,
                        data?.row_id as number
                      )
                    }
                  />
                  {divided_data && (
                    <FormControl style={{ marginLeft: "10px", width: "200px" }}>
                      <TextField
                        select
                        label={(data[key] as ICountry | IState)?.id ? "" : key === "country" ? "Country" : "State"}
                        size="small"
                        variant="outlined"
                        value={(data[key] as ICountry | IState)?.id || ""}
                        fullWidth
                        SelectProps={{
                          MenuProps: {
                            style: {
                              maxHeight: 200,
                              width: 200,
                            },
                          },
                        }}
                        InputLabelProps={{ shrink: false }}
                        onChange={(e) => handleSelectChange(e, key, data)}
                      >
                        <MenuItem value={""} style={{ height: 30 }}></MenuItem>
                        {selectData?.map((data: IStoreSelectItem, index: number) => (
                          <MenuItem key={index} value={data?.id as number}>
                            {data?.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  )}
                  {rangeField && (
                    <FormControl style={{ marginLeft: "10px", width: "150px" }}>
                      <select
                        className={classes.select}
                        style={{ width: "150px" }}
                        name="range_items"
                        value={data?.range?.id ? data?.range?.id : 0}
                        onChange={(e) => {
                          const newEditDataforField = editDataForField as IEditFieldModalProp<ITableData>;
                          setEditDataForField({
                            ...newEditDataforField,
                            data: newEditDataforField?.data.map((item: ITableData) =>
                              item === data
                                ? {
                                    ...item,
                                    range: rangeItems.find((item) => +e.target.value === item.id),
                                  }
                                : item
                            ),
                          });
                        }}
                      >
                        <option value={0}>Not Selected</option>
                        {rangeItems.map((rangeItem, index) => (
                          <option key={index} value={rangeItem.id}>
                            {rangeItem.name}
                          </option>
                        ))}
                      </select>
                    </FormControl>
                  )}
                  <div className={classes.dataSourceRowAction}>
                    <img
                      src={DeleteIcon}
                      className={classes.dataSourceRowActionDelete}
                      alt="delete icon"
                      onClick={() => deleteYearlyManualData(data?.year, data)}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </>
    );
  };

  const renderYearlyStoreNumbersByStoreType = (key: keyof ITableData) => {
    const new_data: ITableData[] = [...(editDataForField?.data as ITableData[])];

    return (
      <div className={classes.overflowContainer}>
        {new_data
          ?.filter((data: ITableData) => data.data_source_id === manual_entry_data_source_id)
          .map((data: ITableData, index: number) => {
            return (
              <div className={classes.dataRow} key={index}>
                {yearSelect(data as ITableData)}
                <TextField
                  size="small"
                  variant="outlined"
                  value={data?.value}
                  type="number"
                  title={data?.value as string}
                  inputProps={{ style: { fontSize: 14, fontFamily: "Montserrat", color: "#333333" } }}
                  onChange={(e) =>
                    changeManualDataForStoreType(e, new_data, data?.year, (data[key] as ISelectItem)?.name)
                  }
                />
                {divided_data && (
                  <FormControl style={{ marginLeft: "10px" }}>
                    <TextField
                      size="small"
                      variant="outlined"
                      value={(data?.store_type?.name as string) || ""}
                      inputProps={{
                        style: { fontSize: 14, fontFamily: "Montserrat", color: "#333333", marginLeft: "10px" },
                      }}
                      onChange={(e) => handleStringChange(e, data, key)}
                    />
                  </FormControl>
                )}
                <div className={classes.dataSourceRowAction}>
                  <img
                    src={DeleteIcon}
                    className={classes.dataSourceRowActionDelete}
                    alt="delete icon"
                    onClick={() => deleteYearlyManualData(data?.year, data)}
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const renderMonthlyManualData = () => {
    const new_data: ITableData[] = [...(editDataForField?.data as ITableData[])];
    const new_data_monthly = new_data?.filter(
      (data: ITableData) => data.data_source_id === manual_entry_data_source_id
    );

    const years: number[] = [];
    new_data_monthly?.map((item: ITableData) => {
      if (years.indexOf(item?.year) < 0) years.push(item.year);
    });

    return (
      <>
        {new_data_monthly.length ? renderHeaders(data_type === "month" ? months : quarters, true) : null}
        <div className={classes.overflowContainer}>
          {years.map((year: number, index: number) => (
            <div className={classes.dataRow} key={index}>
              {yearSelect(new_data_monthly.find((item) => item?.year === year) as ITableData)}
              {[...Array(12)].map((item: ITableData, i: number) => (
                <div
                  className={classes.dataSourceRowData}
                  style={{ width: month_quarter_input_width, marginRight: "5px" }}
                  key={i}
                >
                  <TextField
                    size="small"
                    variant="outlined"
                    inputProps={{ style: { fontSize: 12, fontFamily: "Montserrat", color: "#333333" } }}
                    type="number"
                    value={new_data_monthly.find((item) => item?.month === i + 1 && item?.year === year)?.value || ""}
                    title={
                      new_data_monthly.find((item) => item?.month === i + 1 && item?.year === year)?.value as string
                    }
                    onChange={(e) => changeManualData(e, new_data_monthly, i, year, "month")}
                  />
                </div>
              ))}
              <div className={classes.dataSourceRowAction}>
                <img
                  src={DeleteIcon}
                  className={classes.dataSourceRowActionDelete}
                  alt="delete icon"
                  onClick={() => deleteDividedManualData(year)}
                />
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const renderQuarterlyManualData = (key: keyof ITableData) => {
    const new_data: ITableData[] = [...(editDataForField?.data as ITableData[])];
    const new_data_quarterly = new_data?.filter(
      (data: ITableData) => data.data_source_id === manual_entry_data_source_id
    );

    // interface IRow {
    //   year: number;
    //   row_id: number;
    //   country?: IStoreSelectItem | null;
    // }
    const rows: IRow[] = [];
    new_data_quarterly?.map((item: ITableData) => {
      const object: IRow = { year: item?.year, row_id: item?.row_id as number, dataSourceId: item.data_source_id };
      if (divided_data) object.country = item?.country ? item?.country : { id: null, name: "" };
      if (rows?.findIndex((row: IRow) => row.row_id === object.row_id) < 0) rows.push(object);
    });
    return (
      <>
        {new_data_quarterly.length ? renderHeaders(data_type === "month" ? months : quarters, true) : null}
        <div className={classes.overflowContainer}>
          {rows.map((row: IRow, index: number) => (
            <div className={classes.dataRow} key={index}>
              {yearSelect(new_data_quarterly.find((item) => item?.row_id === row?.row_id) as ITableData)}
              {[...Array(4)].map((item: ITableData, i: number) => (
                <div
                  className={classes.dataSourceRowData}
                  style={{ width: month_quarter_input_width, marginRight: "5px" }}
                  key={i}
                >
                  <TextField
                    size="small"
                    variant="outlined"
                    inputProps={{ style: { fontSize: 14, fontFamily: "Montserrat", color: "#333333" } }}
                    type="number"
                    value={
                      new_data_quarterly.find(
                        (item) => item?.quarter === i + 1 && item?.year === row?.year && item?.row_id === row?.row_id
                      )?.value || ""
                    }
                    title={
                      new_data_quarterly.find(
                        (item) => item?.quarter === i + 1 && item?.year === row?.year && item?.row_id === row?.row_id
                      )?.value as string
                    }
                    onChange={(e) =>
                      changeManualData(
                        e,
                        new_data_quarterly,
                        i,
                        row?.year,
                        "quarter",
                        row?.country?.id as number,
                        row?.row_id as number
                      )
                    }
                  />
                </div>
              ))}
              {divided_data ? (
                <FormControl style={{ width: "200px" }}>
                  <TextField
                    select
                    label={row?.country?.name ? "" : "Country"}
                    size="small"
                    variant="outlined"
                    value={row?.country?.id ? row?.country?.id : ""}
                    fullWidth
                    SelectProps={{
                      MenuProps: {
                        style: {
                          maxHeight: 200,
                          width: 200,
                        },
                      },
                    }}
                    InputLabelProps={{ shrink: false }}
                    onChange={(e) => handleSelectChange(e, key, null, row)}
                  >
                    <MenuItem value={""} style={{ height: 30 }}></MenuItem>
                    {selectData?.map((data: IStoreSelectItem, index: number) => (
                      <MenuItem key={index} value={data?.id as number}>
                        {data?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              ) : null}
              <div className={classes.dataSourceRowAction}>
                <img
                  src={DeleteIcon}
                  className={classes.dataSourceRowActionDelete}
                  alt="delete icon"
                  onClick={() => deleteDividedManualData(row?.row_id as number)}
                />
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const renderHeaders = (headers: string[], manual: boolean) => {
    const input_width = rangeField && !manual ? "200px" : "150px";
    return (
      <div className={classes.dataRow}>
        <div className={classes.dataSourceRowYear}>{}</div>
        {headers.map((header: string) => (
          <div
            key={header}
            className={classes.dataSourceRowData}
            style={{
              marginLeft: rangeField && manual ? "30px" : "0px",
              textAlign: rangeField && !manual ? "left" : "center",
            }}
          >
            <div style={{ width: input_width, marginRight: "5px" }}>{header}</div>
          </div>
        ))}
        {divided_data && (
          <div className={classes.dataSourceRowData}>
            <div style={{ width: input_width, marginRight: "5px" }}>Country</div>
          </div>
        )}
        {manual && <div className={classes.dataSourceRowAction}>{}</div>}
      </div>
    );
  };

  const changeManualData = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    data: ITableData[],
    index: number,
    year: number,
    key: string,
    select_id?: number | string,
    row_id?: number
  ) => {
    const newEditDataforField = editDataForField as IEditFieldModalProp<ITableData>;
    let existing_item = {};

    if (divided_data) {
      if (checkDuplicates()) return;
      existing_item = data.find((item) =>
        key === ""
          ? item?.data_source_id === manual_entry_data_source_id &&
            (item[select_key as keyof ITableData] as IStoreSelectItem)?.id === select_id &&
            item?.year === year
          : item?.data_source_id === manual_entry_data_source_id &&
            item[key as keyof typeof item] === index + 1 &&
            item?.row_id === row_id
      ) as ITableData;
    } else {
      existing_item = data.find((item) =>
        key === ""
          ? item?.data_source_id === manual_entry_data_source_id && item?.year === year
          : item?.data_source_id === manual_entry_data_source_id &&
            item?.year === year &&
            item[key as keyof typeof item] === index + 1
      ) as ITableData;
    }

    if (existing_item) {
      setEditDataForField({
        ...newEditDataforField,
        data: newEditDataforField?.data.map((item: ITableData) =>
          item === existing_item
            ? { ...item, value: input_type === InputTypeEnums.number_input ? Number(e.target.value) : e.target.value }
            : item
        ),
      });
    } else {
      const new_data: ITableData = {
        data_source_id: Number(manual_entry_data_source_id),
        value: Number(e.target.value),
        year: year,
        include: true,
      };
      key !== "" && ((new_data[key as keyof ITableData] as number) = index + 1);
      select_id !== undefined && (new_data.country = { id: select_id as number, name: "" });

      newEditDataforField?.data.push(new_data);
      setEditDataForField({ ...newEditDataforField });
    }
  };

  const changeManualDataForStoreType = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    data: ITableData[],
    year: number,
    type_name: string
  ) => {
    if (checkDuplicates()) return;
    const newEditDataforField = editDataForField as IEditFieldModalProp<ITableData>;
    const existing_item = data.find(
      (item) =>
        item?.data_source_id === manual_entry_data_source_id &&
        (item[select_key as keyof ITableData] as IStoreSelectItem)?.name === type_name &&
        item?.year === year
    ) as ITableData;

    if (existing_item) {
      setEditDataForField({
        ...newEditDataforField,
        data: newEditDataforField?.data.map((item: ITableData) =>
          item === existing_item ? { ...item, value: Number(e.target.value) } : item
        ),
      });
    } else {
      const new_data = {
        data_source_id: Number(manual_entry_data_source_id),
        value: Number(e.target.value),
        year: year,
        include: true,
      };
      newEditDataforField?.data.push(new_data);
      setEditDataForField({ ...newEditDataforField });
    }
  };

  const deleteYearlyManualData = (year: number, data: ITableData) => {
    const newEditDataForField = editDataForField as IEditFieldModalProp<ITableData>;
    const index = editDataForField?.data?.findIndex(
      (item: ITableData) =>
        (item?.data_source_id === data?.data_source_id &&
          item?.year === data?.year &&
          item?.value === data?.value &&
          (item[select_key as keyof ITableData] as IStoreSelectItem) &&
          (item[select_key as keyof ITableData] as IStoreSelectItem)[item_key] ===
            (data[select_key as keyof ITableData] as IStoreSelectItem)[item_key]) ||
        (item?.data_source_id === data?.data_source_id && item?.year === data?.year && item?.value === data?.value)
    );

    newEditDataForField?.data?.splice(index as number, 1);
    setEditDataForField({ ...newEditDataForField });
    setDuplicateRefresh(!duplicateRefresh);
  };

  const deleteDividedManualData = (row_id: number) => {
    const newEditDataForField = editDataForField as IEditFieldModalProp<ITableData>;

    newEditDataForField.data = newEditDataForField?.data?.filter((item: ITableData) => {
      return item?.row_id !== row_id;
    });

    setEditDataForField({ ...newEditDataForField });
    setDuplicateRefresh(!duplicateRefresh);
  };

  const yearSelect = (data: ITableData) => {
    const currentYear = new Date().getFullYear();
    const range = (start: number, stop: number, step: number) =>
      Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

    const years: number[] = range(currentYear, currentYear - 12, -1);

    if (data?.data_source_id === manual_entry_data_source_id) {
      return (
        <select
          className={classes.select}
          name="year"
          value={data?.year}
          onChange={(e) => {
            const newEditDataForField = editDataForField as IEditFieldModalProp<ITableData>;
            newEditDataForField.data = newEditDataForField?.data?.map((r: ITableData) =>
              r.row_id === data.row_id ? { ...r, year: Number(e.target.value) } : r
            );
            setEditDataForField({ ...newEditDataForField });
          }}
        >
          {years.map((year: number, index: number) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </select>
      );
    } else {
      return <span className={classes.dataSourceRowYear}>{data?.year}</span>;
    }
  };

  const checkDuplicates = () => {
    const compare_values = editDataForField?.data?.map(
      (item: ITableData) =>
        `${item?.year}-${
          (item[select_key as keyof ITableData] as IStoreSelectItem) &&
          (item[select_key as keyof ITableData] as IStoreSelectItem)[item_key]
        }-${data_type === "quarter" && item?.quarter}-${
          item?.data_source_id === manual_entry_data_source_id && item?.data_source_id
        }-${item.include ? "-1" : "-0"}`
    );

    const uniqe_values = new Set(compare_values);
    if (uniqe_values?.size !== 0 && uniqe_values?.size !== compare_values?.length) return true;
    return false;
  };

  const validateData = () => {
    let validation = true;

    if (divided_data) {
      if (data_type === "year") {
        editDataForField?.data?.forEach((item: ITableData) => {
          if (
            item?.value === "" ||
            item?.value === 0 ||
            !(item[select_key as keyof ITableData] as IStoreSelectItem) ||
            ((item[select_key as keyof ITableData] as IStoreSelectItem) &&
              (item[select_key as keyof ITableData] as IStoreSelectItem)[item_key] === "") ||
            (typeof (item[select_key as keyof ITableData] as IStoreSelectItem)[item_key] === "string" &&
              !((item[select_key as keyof ITableData] as IStoreSelectItem)[item_key] as string).trim())
          ) {
            setErrorMessage("There cannot be empty values. Please enter.");
            validation = false;
          }
        });
      }

      if (data_type === "quarter") {
        const rows: number[] = [];

        editDataForField?.data?.map((item: ITableData) => {
          if (rows.indexOf(item?.row_id as number) < 0) rows.push(item?.row_id as number);
        });

        rows?.forEach((row_number: number) => {
          const row = editDataForField?.data?.filter((item: ITableData) => item?.row_id === row_number);
          if (
            row?.every((item: ITableData) => item?.value === "") ||
            row?.some((item: ITableData) => item?.country?.id === null)
          ) {
            setErrorMessage("There cannot be empty values. Please enter.");
            validation = false;
          }
        });
      }
    }

    if (rangeField) {
      if (data_type === "year") {
        editDataForField?.data?.forEach((item: ITableData) => {
          if (!item?.range && ((item?.value === "" && !item?.range) || item?.value === 0)) {
            setErrorMessage("There cannot be empty values. Please enter.");
            validation = false;
          }
        });
      }
    }

    return validation;
  };

  const formSelectedData = (selected_data: number[] | string[]) => {
    setSelectedItems(selected_data);

    const selected_data_key = `${select_key}_id`;
    const temp_data: ISelectData[] = [];

    (selected_data as number[])?.forEach((item: number) => {
      const temp_object = { [selected_data_key]: item };
      temp_data.push(temp_object);
    });

    const newSelectDataForFields = selectDataForFields as IEditFieldModalProp<ISelectData>;
    setSelectDataForFields({
      ...newSelectDataForFields,
      data: temp_data,
    });
  };

  return loading ? (
    <Modal open={loading} onClose={handleClose} disableEnforceFocus>
      <div className={classes.container}>
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      </div>
    </Modal>
  ) : editDataForField ? (
    <>
      <Modal open={isVisible} onClose={handleClose} disableEnforceFocus>
        <div className={classes.container} style={{ maxHeight: !loading && "95%", width: !loading && "1000px" }}>
          <div>
            <button onClick={cancel} className={classes.closeButton} />
          </div>
          <div className={classes.title}>Edit: {name}</div>

          <div className={classes.body}>
            <div style={{ marginBottom: "10px" }}>
              <CheckboxSelect
                checkboxLabel="Prioritize Manual Data"
                size="small"
                labelStyling={{ fontSize: "16px", fontWeight: 500, color: "#4D748D" }}
                checked={editDataForField.overrideWithManual || false}
                setChecked={handlePrioritizeManualInputCheckboxChange}
              />
            </div>
            {divided_data && (
              <div style={{ marginBottom: "10px" }}>
                <SelectDisplay
                  property_name={select_key}
                  selected={selectedItems}
                  setSelected={formSelectedData}
                  title={select_key}
                />
              </div>
            )}
            {editDataForField?.dataSources && (
              <div>
                <ChangeDataSourcePriorityModal
                  name={name}
                  onClose={handlePriorityChange}
                  data={editDataForField.dataSources}
                />
              </div>
            )}
            <div className={classes.dataContainer}>
              <div className={classes.dataTitleRow}>
                <span style={{ color: "#4D748D" }} className={classes.dataTitle}>
                  Consolidated Data
                </span>
                {property_name.includes("revenue") && (
                  <div>
                    {/* <span
                      className={classes.revenueSelectLink}
                      style={{ color: data_type === "month" ? "#4D748D" : "#333333" }}
                      onClick={editModalFunctions.openMonthlyRevenueEdit}
                    >
                      Monthly
                    </span> */}
                    <span
                      className={classes.revenueSelectLink}
                      style={{ color: data_type === "quarter" ? "#4D748D" : "#333333" }}
                      onClick={
                        property_name === "yearly_revenue" || property_name === "quarterly_revenue"
                          ? editModalFunctions.openQuarterlyRevenueEdit
                          : editModalFunctions.openQuarterlyRevenueCountryEdit
                      }
                    >
                      Quarterly
                    </span>
                    <span
                      className={classes.revenueSelectLink}
                      style={{ color: data_type === "year" ? "#4D748D" : "#333333" }}
                      onClick={
                        property_name === "yearly_revenue" || property_name === "quarterly_revenue"
                          ? editModalFunctions.openYearlyRevenueEdit
                          : editModalFunctions.openYearlyRevenueByCountryEdit
                      }
                    >
                      Yearly
                    </span>
                  </div>
                )}
              </div>
              {renderConsolidatedData()}
            </div>
            <div className={classes.dataContainer}>
              <div className={classes.dataTitleRow}>
                <span style={{ color: "333333", fontWeight: 600 }} className={classes.dataTitle}>
                  Enter Data Manually
                </span>
                {property_name.includes("revenue") && (
                  <div>
                    {/* <span
                      className={classes.revenueSelectLink}
                      style={{ color: data_type === "month" ? "#4D748D" : "#333333" }}
                      onClick={editModalFunctions.openMonthlyRevenueEdit}
                    >
                      Monthly
                    </span> */}
                    <span
                      className={classes.revenueSelectLink}
                      style={{ color: data_type === "quarter" ? "#4D748D" : "#333333" }}
                      onClick={editModalFunctions.openQuarterlyRevenueEdit}
                    >
                      Quarterly
                    </span>
                    <span
                      className={classes.revenueSelectLink}
                      style={{ color: data_type === "year" ? "#4D748D" : "#333333" }}
                      onClick={editModalFunctions.openYearlyRevenueEdit}
                    >
                      Yearly
                    </span>
                  </div>
                )}
              </div>
              {renderManualData()}
              <div className={classes.addRowContainer} onClick={addRow}>
                <img src={AddRowIcon} className={classes.addColumnIcon} alt="add row" />
                <div className={classes.addRowText}>Add row</div>
              </div>
            </div>
          </div>
          <div className={classes.errorDiv}>{errorMessage}</div>

          <div className={classes.actions}>
            <button className={classes.cancelButton} onClick={handleClose}>
              Cancel
            </button>
            <span style={{ marginLeft: 10 }} />
            <ApplyButton text="Apply" apply={handleSave} disabled={checkDuplicates()} />
          </div>
        </div>
      </Modal>
    </>
  ) : null;
};

export interface IEditFieldModalProp<T> {
  dataSources: IDataSource[];
  overrideWithManual: boolean;
  data: T[];
}

export interface IDataSource {
  id: number;
  name: string;
  priority: number;
}

export interface ITableData {
  data_source_id: number;
  value: string | number;
  year: number;
  quarter?: number;
  month?: number;
  title?: string;
  country?: IStoreSelectItem;
  state?: IStoreSelectItem;
  store_type?: IStoreSelectItem;
  row_id?: number;
  range?: IRangeItem;
  include: boolean;
}
