import { apiConfig } from "../../../services/api/apiConfig";

export type Stock = {
  ticker: string;
  exchange: string;
};

export type OwnershipType = {
  id: number;
  name: string;
};

type DataPoint<T> = {
  id: number | null;
  dataSourceId: number;
  valid?: boolean;
  value: T;
};

type StockDataPoint = DataPoint<Stock>;

export type OwnershipTypeDataPoint = DataPoint<OwnershipType>;

export type DataSource = {
  id: number;
  name: string;
  type: "Manual" | "External" | "Computed";
  priority: number;
};

type EditFieldData<T> = {
  data: T[];
  dataSources: DataSource[];
  field_name: string;
};

export type EditData = {
  stock: EditFieldData<StockDataPoint>;
  ownershipType: EditFieldData<OwnershipTypeDataPoint>;
};

async function getStockForEdit(companyId: number, token: string) {
  const url = `${apiConfig.companiesDataManager}/${companyId}/stock`;

  const res = await fetch(url, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (res.ok) {
    const data = await res.json();
    return data as EditFieldData<StockDataPoint>;
  } else {
    const body = await res.json();
    throw new Error(`error loading stock for edit. ${body}`);
  }
}

async function getOwnershipTypeForEdit(companyId: number, token: string) {
  const url = `${apiConfig.companiesDataManager}/${companyId}/ownership_type`;

  const res = await fetch(url, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (res.ok) {
    const data = await res.json();
    return data as EditFieldData<OwnershipTypeDataPoint>;
  } else {
    const body = await res.json();
    throw new Error(`error loading stock for edit. ${body}`);
  }
}

export async function getEditData(companyId: number, token: string) {
  const [stock, ownershipType] = await Promise.all([
    getStockForEdit(companyId, token),
    getOwnershipTypeForEdit(companyId, token),
  ]);
  return {
    stock,
    ownershipType,
  };
}

/*

const data: FieldOption<Stock>[] = [
  {
    id: 99,
    weight: 1,
    dataSource: {
      id: 1,
      name: "Diffbot",
      type: "External",
    },
    value: {
      ticker: "Diffbot Ticker",
      exchange: "NYSE",
    },
  },
  {
    id: 69,
    weight: 2,
    dataSource: {
      id: 4,
      name: "SEC",
      type: "External",
    },
    value: null,
  },
  {
    id: 420,
    weight: 3,
    dataSource: {
      id: 2,
      name: "Manual Entry",
      type: "Manual",
    },
    value: null,
  },
];

async function fetchData(): Promise<FieldOption<Stock>[]> {
  return new Promise((resolve) => {
    setTimeout(resolve, 500, data);
  });
}
 */
