import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import closeLogo from "../../../assets/close-circle.png";
import { useAuth } from "../../../shared/hooks/auth-hook";
import { Service } from "../../../services/api/service";
import { apiConfig } from "../../../services/api/apiConfig";

import { DataSource, EditData, OwnershipType, Stock } from "./edit-data";
import { ChangeDataSourcePriorityModal } from "../ChangeDataSourcePriorityModal";

const useStyles = makeStyles(() => ({
  dialogContainer: {
    position: "absolute",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    borderRadius: "5px",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  dialogSurface: {
    minWidth: "400px",
    backgroundColor: "#FFFFFF",
    padding: "30px 50px",
    position: "relative",
  },
  dialogTitle: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "32px",
    fontWeight: "normal",
    letterSpacing: 0,
    lineHeight: "34px",
    margin: "0 0 10px",
  },
  dialogCloseButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 24,
    width: 24,
    backgroundImage: `url(${closeLogo})`,
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  dialogContent: {},
  dialogActions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
    marginTop: "30px",
  },
  cancelButton: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 12,
    borderRadius: 3,
    height: 38,
    textTransform: "none",
    border: "1px solid",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D748D",
    width: "74px",
  },
  applyButton: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 12,
    borderRadius: 3,
    height: 38,
    textTransform: "none",
    border: "1px solid",
    cursor: "pointer",
    textAlign: "center",
    width: "74px",
    color: "#FFFFFF",
    backgroundColor: "#4D748D",
    "&:hover": {
      backgroundColor: "#608dab",
    },
    "&:disabled": {
      backgroundColor: "#bebebe",
      color: "#5c5c5c",
    },
  },

  formContainer: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gap: "20px 40px",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontFamily: "Montserrat, sans-serif",
  color: "#333",
  fontWeight: 500,
  [`&.${tableCellClasses.head}`]: {
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}.disabled`]: {
    color: "grey",
  },
}));

interface FormProps {
  companyId: number;
  initialEditFieldData: EditData;
  onSave: (data: EditData) => void;
  onCancel: () => void;
}

export const EditStockAndOwnershipTypeForm = ({ companyId, initialEditFieldData, onSave, onCancel }: FormProps) => {
  const classes = useStyles();
  const { token } = useAuth();

  const manualDataSourceId = initialEditFieldData.stock.dataSources.find((it) => it.type === "Manual")?.id ?? 2;

  const [editFieldData, setEditFieldData] = useState(() => {
    const { stock, ownershipType } = initialEditFieldData;

    const stockManualOption = stock.data.find((it) => it.dataSourceId === manualDataSourceId);
    if (!stockManualOption) {
      stock.data.push({
        id: null,
        dataSourceId: manualDataSourceId,
        valid: true,
        value: { ticker: "", exchange: "" },
      });
    }

    const ownershipTypeManualOption = ownershipType.data.find((it) => it.dataSourceId === manualDataSourceId);
    if (!ownershipTypeManualOption) {
      ownershipType.data.push({
        id: null,
        dataSourceId: manualDataSourceId,
        value: { id: 0, name: "" },
      });
    }

    return {
      stock,
      ownershipType,
    };
  });
  const [ownershipTypes, setOwnershipTypes] = useState<OwnershipType[]>([]);

  const updateStockTicker = (dataSourceId: number, value: Stock["ticker"]) =>
    setEditFieldData((prevState) => {
      return {
        ...prevState,
        stock: {
          ...prevState.stock,
          data: prevState.stock.data.map((it) =>
            it.dataSourceId === dataSourceId
              ? {
                  ...it,
                  value: { ...it.value, ticker: value },
                }
              : it
          ),
        },
      };
    });

  const updateStockExchange = (dataSourceId: number, value: Stock["exchange"]) =>
    setEditFieldData((prevState) => {
      return {
        ...prevState,
        stock: {
          ...prevState.stock,
          data: prevState.stock.data.map((it) =>
            it.dataSourceId === dataSourceId
              ? {
                  ...it,
                  value: { ...it.value, exchange: value },
                }
              : it
          ),
        },
      };
    });

  const updateStockValidState = (dataSourceId: number, valid: boolean) =>
    setEditFieldData((prevState) => {
      return {
        ...prevState,
        stock: {
          ...prevState.stock,
          data: prevState.stock.data.map((it) =>
            it.dataSourceId === dataSourceId
              ? {
                  ...it,
                  valid: valid,
                }
              : it
          ),
        },
      };
    });

  const updateOwnershipType = (dataSourceId: number, value: string | number | undefined) => {
    const selectedType = ownershipTypes.find((type) => type.id === value) ?? { id: 0, name: "" };

    setEditFieldData((prevState) => {
      return {
        ...prevState,
        ownershipType: {
          ...prevState.ownershipType,
          data: prevState.ownershipType.data.map((it) =>
            it.dataSourceId === dataSourceId
              ? {
                  ...it,
                  value: { ...selectedType },
                }
              : it
          ),
        },
      };
    });
  };

  // TODO Update Typescript & tsconfig
  interface ArrayHackES2023 {
    toSorted(cb: (a: DataSource, b: DataSource) => number): DataSource[];
  }

  const stockDataSources = (editFieldData.stock.dataSources as any as ArrayHackES2023).toSorted(
    (a, b) => b.priority - a.priority
  );
  const ownershipTypeDataSources = (editFieldData.ownershipType.dataSources as any as ArrayHackES2023).toSorted(
    (a, b) => b.priority - a.priority
  );

  const computeOwnershipTypeFromStock = () => {
    const hasValidStock = editFieldData.stock.data.some(
      (it) => it.valid && it.value.ticker.trim() && it.value.exchange.trim()
    );
    const publicOwnershipType = ownershipTypes.find((it) => it.name === "Public") ?? null;
    const privateOwnershipType = ownershipTypes.find((it) => it.name === "Private") ?? null;

    return hasValidStock ? publicOwnershipType : privateOwnershipType;
  };

  const computedOwnershipTypeFromStock = computeOwnershipTypeFromStock();

  const handleStockPriorityChange = (dataSources: DataSource[]) => {
    setEditFieldData((prevState) => {
      return {
        ...prevState,
        stock: {
          ...prevState.stock,
          dataSources: [...dataSources],
        },
      };
    });
  };
  const handleOwnershipTypePriorityChange = (dataSources: DataSource[]) => {
    setEditFieldData((prevState) => {
      return {
        ...prevState,
        ownershipType: {
          ...prevState.ownershipType,
          dataSources: [...dataSources],
        },
      };
    });
  };

  useEffect(() => {
    if (token && ownershipTypes.length === 0) {
      const fetchOwnershipTypes = async () => {
        try {
          const response = await Service.get(apiConfig.ownershipTypes, token as string);
          const data = await response.data;
          setOwnershipTypes(data);
        } catch (error) {
          console.error("Error fetching ownership types:", error);
        }
      };

      fetchOwnershipTypes();
    }
  }, []);

  const save = () => {
    const { ownershipType } = editFieldData;
    const companyStockDataSourceId =
      ownershipType.dataSources.find((it) => it.type === "Computed" && it.name === "Company Stock")?.id ?? -1;

    const ownershipTypeOptions = ownershipType.data.filter((it) => it.dataSourceId !== companyStockDataSourceId);

    if (computedOwnershipTypeFromStock !== null) {
      ownershipTypeOptions.push({
        id: null,
        dataSourceId: companyStockDataSourceId,
        value: { ...computedOwnershipTypeFromStock },
      });
    }
    const editDataWithComputedValue: EditData = {
      ...editFieldData,
      ownershipType: {
        ...ownershipType,
        data: ownershipTypeOptions,
      },
    };

    onSave(editDataWithComputedValue);
  };

  return (
    <>
      <div className={classes.dialogContainer}>
        <div className={classes.dialogSurface}>
          <h2 className={classes.dialogTitle}>Edit: Stock & Ownership Type</h2>
          <button className={classes.dialogCloseButton} onClick={onCancel} />
          <div className={classes.dialogContent}>
            <div className={classes.formContainer}>
              <section>
                <h3>Stock Ticker</h3>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ maxHeight: 400 }}>
                    <Table stickyHeader size="small" aria-label="company stock-options table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">Data Source</StyledTableCell>
                          <StyledTableCell align="left">Ticker</StyledTableCell>
                          <StyledTableCell align="left">Exchange</StyledTableCell>
                          <StyledTableCell align="left">Valid</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {stockDataSources.map((dataSource) => {
                          const dataPoint = editFieldData.stock.data.find((it) => it.dataSourceId === dataSource.id);

                          const isManual = dataSource.type === "Manual";

                          return (
                            <TableRow key={dataSource.id}>
                              <StyledTableCell align="left">{dataSource.name}</StyledTableCell>
                              <StyledTableCell align="left" className={!isManual ? "disabled" : ""}>
                                {isManual ? (
                                  <TextField
                                    autoComplete="off"
                                    label="Ticker"
                                    variant="standard"
                                    sx={{ fontFamily: "Montserrat", fontWeight: 500, width: "7ch" }}
                                    size="small"
                                    value={dataPoint ? dataPoint.value.ticker : "No value"}
                                    onChange={(e) => updateStockTicker(dataSource.id, e.target.value)}
                                  />
                                ) : dataPoint ? (
                                  <span>
                                    {(dataPoint as any).valid ? (
                                      <span>{dataPoint.value.ticker}</span>
                                    ) : (
                                      <>
                                        <span>
                                          <s>{dataPoint.value.ticker}</s>
                                        </span>
                                        {/*<FormHelperText>Ticker was not found in TwelveData</FormHelperText>*/}
                                      </>
                                    )}
                                  </span>
                                ) : (
                                  <span>
                                    <i>No Data Found</i>
                                  </span>
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="left" className={!isManual ? "disabled" : ""}>
                                {isManual ? (
                                  <TextField
                                    autoComplete="off"
                                    label="Exchange"
                                    variant="standard"
                                    sx={{ fontFamily: "Montserrat", fontWeight: 500, width: "15ch" }}
                                    size="small"
                                    value={dataPoint ? dataPoint.value.exchange : "No value"}
                                    disabled={!isManual}
                                    onChange={(e) => updateStockExchange(dataSource.id, e.target.value)}
                                  />
                                ) : dataPoint ? (
                                  <span>
                                    {(dataPoint as any).valid ? (
                                      <span>{dataPoint.value.exchange}</span>
                                    ) : (
                                      <>
                                        <span>
                                          <s>{dataPoint.value.exchange}</s>
                                        </span>
                                        {/*<FormHelperText>Ticker was not found in TwelveData</FormHelperText> TODO Need to check if still needed*/}
                                      </>
                                    )}
                                  </span>
                                ) : (
                                  <span>
                                    <i>No Data Found</i>
                                  </span>
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="left" className={!isManual ? "disabled" : ""}>
                                {dataPoint ? (
                                  <Checkbox
                                    style={{
                                      color: dataPoint.valid ? "#2F556D" : "#666666",
                                      fill: "#2f556d",
                                    }}
                                    checked={dataPoint.valid}
                                    onChange={(e) => updateStockValidState(dataSource.id, e.target.checked)}
                                  />
                                ) : null}
                              </StyledTableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <div style={{ marginTop: "20px" }}>
                  {editFieldData.stock.dataSources && (
                    <ChangeDataSourcePriorityModal
                      name="Stock Ticker"
                      onClose={handleStockPriorityChange as any}
                      data={editFieldData.stock.dataSources}
                    />
                  )}
                </div>
              </section>
              <section>
                <h3>Ownership Type</h3>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ width: 500, maxHeight: 400 }}>
                    <Table stickyHeader size="small" aria-label="company stock-ownership-type-options table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">Data Source</StyledTableCell>
                          <StyledTableCell align="left">Ownership Type</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ownershipTypeDataSources.map((dataSource) => {
                          const dataPoint = editFieldData.ownershipType.data.find(
                            (it) => it.dataSourceId === dataSource.id
                          );

                          const isManual = dataSource.type === "Manual";
                          const isCompanyStockSource =
                            dataSource.type === "Computed" && dataSource.name === "Company Stock";

                          const originalValue = dataPoint?.value ?? null;
                          const originalId = dataPoint?.value?.id;

                          const computedValue = isCompanyStockSource ? computedOwnershipTypeFromStock : originalValue;
                          const computedId = isCompanyStockSource ? computedOwnershipTypeFromStock?.id : originalId;

                          const hint = originalId !== computedId ? `(Computed based on Stock Ticker values)` : "";

                          return (
                            <TableRow key={dataSource.id}>
                              <StyledTableCell align="left">{dataSource.name}</StyledTableCell>
                              <StyledTableCell align="left" className={!isManual ? "disabled" : ""}>
                                {isManual ? (
                                  <FormControl variant="standard" sx={{ width: "30ch" }}>
                                    <Select
                                      sx={{ fontFamily: "Montserrat", fontWeight: 500 }}
                                      value={dataPoint ? dataPoint.value.id : undefined}
                                      onChange={(event) => {
                                        updateOwnershipType(dataSource.id, event.target.value);
                                      }}
                                    >
                                      <MenuItem value={0}>
                                        <em>Select Ownership Type</em>
                                      </MenuItem>
                                      {ownershipTypes.map((type) => (
                                        <MenuItem key={type.id} value={type.id}>
                                          {type.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                ) : computedValue ? (
                                  <>
                                    <span>{computedValue.name}</span>
                                    <FormHelperText>{hint}</FormHelperText>
                                  </>
                                ) : (
                                  <span>
                                    <i>No Data Found</i>
                                  </span>
                                )}
                              </StyledTableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <div style={{ marginTop: "20px" }}>
                  {editFieldData.ownershipType && (
                    <ChangeDataSourcePriorityModal
                      name="Ownership Type"
                      onClose={handleOwnershipTypePriorityChange as any}
                      data={editFieldData.ownershipType.dataSources}
                    />
                  )}
                </div>
              </section>
            </div>
          </div>
          <div className={classes.dialogActions}>
            <button className={classes.cancelButton} onClick={onCancel}>
              Cancel
            </button>
            <button className={classes.applyButton} onClick={save}>
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
