import { makeStyles } from "@material-ui/core";
import { FC } from "react";
import EditIcon from "../../../../assets/edit.png";

const useStyles = makeStyles((theme) => ({
  dataSourceRowActionIcon: {
    height: "20px",
    weight: "20px",
    cursor: "pointer",
  },
  dataSourceRowAction: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    width: "20%",
  },
}));

interface Props {
  editAction: () => void;
}

export const EditComponent: FC<Props> = ({editAction}) => {
  const classes = useStyles();
  return (
    <div className={classes.dataSourceRowAction} style={{ width: "50px" }}>
      <img
        src={EditIcon}
        className={classes.dataSourceRowActionIcon}
        alt="edit icon"
        onClick={editAction}
      />
  </div>)
};
