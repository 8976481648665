import { makeStyles } from "@material-ui/core";
import { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#F6F6F6",
    height: "100%",
    position: "relative",
  },
  leftArrow: {
    border: "solid #333333",
    borderWidth: "0 3px 3px 0",
    display: "inline-block",
    padding: 3,
    transform: "rotate(135deg)",
    marginRight: 10,
  },
  backButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    alignItems: "center",
    display: "inline-flex",
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 600,
    height: 30,
  },
  backButtonDiv: {
    marginLeft: 15,
    marginTop: 15,
    paddingTop: "5px",
  },
}));

interface Props {
  buttonTitle: string;
  location: string | number;
  children: ReactNode;
}

export const Banner: FC<Props> = ({ buttonTitle, children, location }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={`${classes.backButtonDiv} no-print`}>
        <button
          className={classes.backButton}
          onClick={() => {
            navigate(location as keyof typeof location);
          }}
        >
          <i className={classes.leftArrow} />
          {buttonTitle}
        </button>
      </div>
      {children}
    </div>
  );
};
