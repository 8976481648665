import { FC } from "react";
import "../../styles/main.scss";
import { makeStyles } from "@material-ui/core/styles";
import searchIcon from "../../assets/searchIcon.png";

interface Props {
  label: string;
  value: string;
  setValue: (value: string) => void;
  search: () => void;
}

const useStyles = makeStyles(() => ({
  formControl: {
    border: "1px solid #E1E1E1",
    borderRadius: 3,
    boxSizing: "border-box",
    display: "flex",
  },
  input: {
    border: "none",
    padding: 10,
    width: "100%",
    color: "#666666",
    fontWeight: 400,
    fontFamily: "Montserrat",
    "&::placeholder": {
      color: "#666666",
      fontWeight: 400,
      fontFamily: "Montserrat",
      fontSize: 12,
    },
    "&:focus": {
      outline: "none",
    },
  },
  searchButton: {
    border: "none",
    background: "none",
    cursor: "pointer",
  },
}));

export const ResultsSearch: FC<Props> = ({ label, value, setValue, search }) => {
  const classes = useStyles();
  const handleChange = (event: any) => {
    setValue(event.target.value);
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      search();
    }
  }

  return (
    <div className={classes.formControl}>
      <input
        type="text"
        className={classes.input}
        placeholder={label}
        name="search"
        id="search"
        value={value}
        onChange={(event) => handleChange(event)}
        autoComplete="off"
        onKeyDown={(event) => handleKeyDown(event)}
      />
      <button type="button" className={classes.searchButton} onClick={search}>
        <img src={searchIcon} alt="Search" />
      </button>
    </div>
  );
};
