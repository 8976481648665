import { FC } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "10px",
    "& div:last-child": {
      marginBottom: "0px !important",
    },
  },
  card_container: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    marginBottom: "60px",
  },
  image: {
    height: "180px",
    width: "180px",
    objectFit: "cover",
    objectPosition: "center center",
    marginRight: "30px",
  },
  date: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: "12px",
    letterSpacing: "0",
    lineHeight: "14px",
    marginBottom: "5px",
  },
  title: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "22px",
    letterSpacing: "0",
    lineHeight: "24px",
    marginBottom: "15px",
  },
  content: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "0",
    lineHeight: "24px",
    marginBottom: "15px",
  },
  anchor: {
    color: "#2F556D",
    fontFamily: "Montserrat",
    fontSize: "12px",
    letterSpacing: "0",
    lineHeight: "14px",
  },
  empty: {
    width: "100%",
    color: "#333",
    fontSize: "18px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    marginLeft: "5px",
  },
}));

interface Props {
  data: any;
}

export const News: FC<Props> = ({ data }) => {
  const classes = useStyles();

  const renderCards = () => {
    return data?.news?.value?.map((el: any, index: number) => {
      return (
        <div className={classes.card_container} key={index}>
          <div>
            <img src={el.image_url} alt="new media" className={classes.image} />
          </div>
          <div>
            <div className={classes.date}>{el.date}</div>
            <div className={classes.title}>{el.title}</div>
            <div className={classes.content}>{el.content}</div>
            <a href={el.external_url} className={classes.anchor}>
              Read article
            </a>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={classes.container}>
      {data?.news?.value?.length ? renderCards() : <div className={classes.empty}>No Data.</div>}
    </div>
  );
};
