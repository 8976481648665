import { FC, useState } from "react";

import { EiqGroupMembershipsEditData, Group } from "../../../components/company-edit/edit-eiq-groups/types";
import { EditEiqGroupsModal } from "../../../components/company-edit/edit-eiq-groups/edit-eiq-groups-modal";
import { useStyles } from "./styles";
import { DataSourceType } from "../../../components/company-edit/edit-revenue-modal/edit-data";
import edit from "../../../assets/edit.png";

type Props = {
  groups: Group[];
  isEdit?: boolean;
  onSubmit: (editData: EiqGroupMembershipsEditData) => void;
}

export const GroupsSection: FC<Props> = ({groups, isEdit, onSubmit}) => {

  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const editData = {
    data: groups,
    overrideWithManual: false,
    dataSources: [
      {
        id: 2,
        name: "Manual Entry",
        priority: 1,
        type: "Manual" as DataSourceType,
      },
    ]
  }

  const isGroupMember = groups?.filter((x) => x.isMember);

  return (
    <div className={classes.container}>
      <div className={classes.titleWrapper}>
        {(isGroupMember?.length > 0 || isEdit ) && <div className={classes.title}>EIQ Groups</div>}
        {isEdit && (
          <button
            className={classes.editButton}
            style={{
              backgroundImage: `url(${edit})`,
            }}
            onClick={() => setOpen(true)}
          ></button>)
        }
      </div>
      {groups?.length > 0 &&
        <ul className={classes.listWrapper}>
        {groups.filter((group) => group.isMember).map(({ groupName, groupId }) => {
          return <li key={groupId}>{groupName}</li>;
        })}
      </ul>
      }
      {isEdit && (
        <>
          <EditEiqGroupsModal
            modalOpen={open}
            editData={editData}
            onSubmit={(editData) => {
              onSubmit(editData);
              setOpen(false);
            }}
            onCancel={() => setOpen(false)} />
        </>
      )}
    </div>
)
};