import { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import arrowLogo from "../../assets/collapse.svg";
import { RequestCard } from "./requestCard";
import { Avatar } from "../avatar";
import { IRequest, IRequestDetail } from "../../interfaces";
import { apiConfig } from "../../services/api/apiConfig";
import { Service } from "../../services/api/service";
import { useAuth } from "../../shared/hooks/auth-hook";
import buildQuery from "odata-query";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ECECEC",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    padding: "15px 15px 15px 5px",
    width: "100%",
    alignItems: "center",
    cursor: "pointer",
  },
  userName: {
    color: "#4D748D",
    fontFamily: "Oswald",
    fontSize: "15px",
  },
  userDetail: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: "12px",
  },
  user: {
    display: "flex",
    flexDirection: "row",
    width: "20%",
    marginRight: "30px",
    alignItems: "center",
  },
  arrow: {
    height: "10px",
    width: "10px",
    backgroundColor: "transparent",
  },
  requestInfo: {
    display: "flex",
    flexDirection: "row",
    marginRight: "60px",
  },
  requestCount: {
    color: "#66859B",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
    marginRight: "15px",
  },
  requestDate: {
    color: "#66859B",
    fontFamily: "Montserrat",
    fontSize: "12px",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "12px 6px",
  },
  pending: {
    justifySelf: "flex-end",
    marginLeft: "auto",
  },
  pendingText: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
    marginRight: "28px",
  },
}));

interface Props {
  data: IRequest;
  type: string;
  isUpdated: (data: boolean, response: any) => void;
}

export const RequestsAccordion: FC<Props> = ({ data, type, isUpdated }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [details, setDetails] = useState<IRequestDetail[]>([]);
  const classes = useStyles();

  const { token, hasPermission } = useAuth();
  const userName: string = JSON.parse(localStorage.getItem("userData") as string)?.displayName;

  useEffect(() => {
    if (isVisible) handleRequestDetails(data?.created_by?.id);
  }, [isVisible]);

  const convertDate = (date: string) => {
    return new Date(date).toDateString().split(" ").slice(1).join(" ");
  };

  const getRequestDetails = (filter: any) => {
    const params = {
      orderBy: "created_at asc",
      groupBy: undefined,
      filter: buildQuery({ filter }).substring(9),
    };
    Service.get(apiConfig.companyCreation, token as string, params)
      .then((response: any) => response.data.data)
      .then((data: any) => setDetails(data));
  };

  const handleRequestDetails = (userId: string) => {
    const filter =
      type === "new"
        ? [{ status_id: { eq: 3 } }, { created_by: { eq: userId } }]
        : [{ status_id: { ne: 3 } }, { created_by: { eq: userId } }];
    getRequestDetails(filter);
  };

  const renderRequests = () => {
    if (hasPermission("CompanyCreate.ReadWrite.All")) {
      return (
        <div className={classes.row} onClick={() => setIsVisible(!isVisible)}>
          <div className={classes.user}>
            <Avatar
              logo={data?.created_by?.photo}
              name={data?.created_by?.fullName}
              style={{ fontSize: "14px", objectFit: "contain", height: "40px", width: "40px", marginRight: "14px" }}
            />
            <div>
              <div className={classes.userName}>{data?.created_by?.fullName}</div>
              <div className={classes.userDetail}></div> {/*TO DO: replace with data */}
            </div>
          </div>
          <div className={classes.requestInfo}>
            <span className={classes.requestCount}>
              {data?.count} {type === "new" ? " new request" : " past request"}
            </span>
            <span className={classes.requestDate}>- {convertDate(data?.newest_request_creation_date)}</span>{" "}
            {/*TO DO: replace with data */}
          </div>
          <div className={classes.pending}>
            <span className={classes.pendingText}>
              {data?.count} {type === "new" ? " pending approval" : " past approval"}
            </span>
            <img
              src={arrowLogo}
              alt="arrow-icon"
              className={classes.arrow}
              style={{ transform: isVisible ? "rotate(180deg)" : "rotate(0deg)" }}
            />
          </div>
        </div>
      );
    } else if (
      !hasPermission("CompanyCreate.ReadWrite.All") &&
      hasPermission("CompanyCreate.ReadWrite.asUser") &&
      userName === data?.created_by?.fullName
    ) {
      return (
        <div className={classes.row} onClick={() => setIsVisible(!isVisible)}>
          <div className={classes.user}>
            <Avatar
              logo={data?.created_by?.photo}
              name={data?.created_by?.fullName}
              style={{ fontSize: "14px", objectFit: "contain", height: "40px", width: "40px", marginRight: "14px" }}
            />
            <div>
              <div className={classes.userName}>{data?.created_by?.fullName}</div>
              <div className={classes.userDetail}></div> {/*TO DO: replace with data */}
            </div>
          </div>
          <div className={classes.requestInfo}>
            <span className={classes.requestCount}>
              {data?.count} {type === "new" ? " new request" : " past request"}
            </span>
            <span className={classes.requestDate}>- {convertDate(data?.newest_request_creation_date)}</span>{" "}
            {/*TO DO: replace with data */}
          </div>
          <div className={classes.pending}>
            <span className={classes.pendingText}>
              {data?.count} {type === "new" ? " pending approval" : " past approval"}
            </span>
            <img
              src={arrowLogo}
              alt="arrow-icon"
              className={classes.arrow}
              style={{ transform: isVisible ? "rotate(180deg)" : "rotate(0deg)" }}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className={classes.container}>
      {renderRequests()}
      {isVisible && (
        <div className={classes.body}>
          <RequestCard details={details} type={type} isUpdated={isUpdated} />
        </div>
      )}
    </div>
  );
};
