import { FC } from "react";
import { CompanyViewTable } from "../../companyViewTable";
import { buildClassificationRows } from "./helpers";
import { ICompanyEditModalFunctions } from "../../../../utils/companyEditModalFunctions";

interface Props {
  naicsData: Record<string, unknown>[];
  isicData: Record<string, unknown>[];
  naceData: Record<string, unknown>[];
  isEdit?: boolean;
  getDataSource: (id: number) => { name: string; endpoint: string; id: number };
  editModalFunctions: ICompanyEditModalFunctions;
}
export const IndustryCodesTable: FC<Props> = ({naicsData, naceData, isicData, isEdit, editModalFunctions, getDataSource}) => {
  const naicsRows = buildClassificationRows(naicsData, "NAICS", isEdit || false, editModalFunctions);
  const naceRows = buildClassificationRows(naceData, "NACE", isEdit || false, editModalFunctions);
  const isicRows = buildClassificationRows(isicData, "ISIC", isEdit || false, editModalFunctions);

  return (
    <CompanyViewTable
      title={"Industry Codes"}
      headers={[
        { title: "Industry code", width: "20%" },
        { title: "Name", width: "30%" },
        { title: "Code", width: "20%" },
        { title: "Sector", width: "20%" },
        { title: "", width: "10%" },
      ]}
      rows={[
        ...naicsRows,
        ...naceRows,
        ...isicRows,
      ]}
    />
  )
}
