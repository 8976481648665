export const SOURCES_TO_SHOW_VIEW = ["Diffbot", "SEC"];

export const getDataSourceUrl = (dataSource: string, entityId: string) => {
  console.log(dataSource);
  if (!SOURCES_TO_SHOW_VIEW.includes(dataSource)) return undefined;

  if (dataSource === "Diffbot") {
    return `http://diffbot.com/entity/${entityId}`;
  }

  if (dataSource === "SEC") {
    return `https://www.sec.gov/edgar/browse/?CIK=${entityId}`;
  }
}
