import { ChangeEvent, FC, ReactElement } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@material-ui/core";
import "../styles/main.scss";

interface Props {
  checkboxLabel: string | ReactElement;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  labelStyling?: object;
  size?: "small" | "medium";
  marginLeft?: string;
}

export const CheckboxSelect: FC<Props> = ({
  checkboxLabel,
  checked,
  setChecked,
  labelStyling,
  size,
  marginLeft = "0",
}) => {
  return (
    <div style={{ marginLeft: marginLeft }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              style={{
                color: checked ? "#2F556D" : "#666666",
                fill: "#2f556d",
              }}
              checked={checked}
              size={size}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setChecked(event.target.checked)}
            />
          }
          label={
            <Typography variant="h6" style={labelStyling}>
              {checkboxLabel}
            </Typography>
          }
        />
      </FormGroup>
    </div>
  );
};
