import { FC, useEffect, useState } from "react";
import "../../styles/main.scss";
import usePagination from "@mui/material/usePagination";
import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { MenuItem, Select } from "@mui/material";
import { ApplyButton } from "../applyButton";

interface Props {
  page: number;
  totalPageNumber: number;
  handleChangePage: (event: any, page: number, resultLength?: number) => void;
  resultLength: number;
}

const useStyles = makeStyles(() => ({
  pageNumber: {
    color: "#333333",
    fontFamily: "Open Sans",
    fontSize: 15,
    fontWeight: 600,
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: "50px",
    width: 30,
    cursor: "pointer",
  },
  activePageNumber: {
    color: "#2F556D",
    lineHeight: "50px",
    borderRadius: "50%",
    fontWeight: 700,
    width: 50,
    backgroundColor: "#FFFFFF",
    boxShadow: " 0 10px 30px 0 rgba(0,0,0,0.2)",
  },
  pageNav: {
    color: "#333",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  pageNavDisabled: {
    color: "#ccc",
    cursor: "default",
  },
  pagination: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 15,
  },
  ellipsis: {
    cursor: "default",
  },
}));
const perPages = [10, 25, 50, 100];
export const Pager: FC<Props> = ({ page, totalPageNumber, handleChangePage, resultLength }) => {
  const classes = useStyles();
  const [selectedResultLength, setSelectedResultLength] = useState<number>(resultLength || 10);
  const { items } = usePagination({
    count: totalPageNumber,
    page: page,
    onChange: handleChangePage,
    siblingCount: 2,
  });
  useEffect(() => {
    setSelectedResultLength(resultLength);
  }, [resultLength]);
  return (
    <div className={classes.pagination}>
      {items.map(({ page, type, selected, disabled, ...item }, index) => {
        let children = null;

        if (type === "start-ellipsis" || type === "end-ellipsis") {
          children = <div className={`${classes.pageNumber} ${classes.ellipsis}`}>...</div>;
        } else if (type === "page") {
          children = (
            <div className={`${classes.pageNumber} ${selected ? classes.activePageNumber : ""}`} {...item}>
              {page}
            </div>
          );
        } else {
          children = (
            <div
              className={`${classes.pageNav} ${disabled ? classes.pageNavDisabled : ""}`}
              {...(disabled ? {} : { ...item })}
            >
              {type === "previous" ? (
                <NavigateBeforeIcon fontSize="large" />
              ) : type === "next" ? (
                <NavigateNextIcon fontSize="large" />
              ) : (
                ""
              )}
            </div>
          );
        }
        return <div key={index}>{children}</div>;
      })}
      <span>Results Per Page</span>
      <Select
        value={selectedResultLength}
        onChange={(e) => setSelectedResultLength(parseInt(e.target.value + ""))}
        style={{ width: "100px", textAlign: "center" }}
      >
        {perPages.map((item, key) => (
          <MenuItem key={key} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <ApplyButton text="Apply" apply={(e) => handleChangePage(e, page, selectedResultLength)} />
    </div>
  );
};
