import { makeStyles } from "@material-ui/core";
import { FC, useState } from "react";

const useStyles = makeStyles(() => ({
  initials: {
    borderRadius: 3,
    margin: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "radial-gradient(circle, #FFFFFF 0%, #F5F5F5 100%)",
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: 500,
    color: "#333",
  },
  image: {
    borderRadius: 3,
    background: "radial-gradient(circle, #FFFFFF 0%, #F5F5F5 100%)",
    margin: 4,
    objectFit: "contain",
  },
}));

interface Props {
  name: string;
  logo?: string;
  style?: Record<string, unknown>;
}

export const Avatar: FC<Props> = ({ name, logo, style }) => {
  const classes = useStyles();
  const [is_image_broken, set_is_image_broken] = useState(false);

  return logo && !is_image_broken ? (
    <img
      style={style}
      className={classes.image}
      alt="logo"
      src={logo}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        set_is_image_broken(true);
      }}
    />
  ) : (
    <div className={classes.initials} style={style}>
      {name
        ?.split(" ")
        ?.slice(0, 2)
        ?.map((n) => n[0])
        ?.join("")}
    </div>
  );
};
