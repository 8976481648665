import { FC, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { ChangeIndicator } from "./changeIndicator";
import { RevenueCard } from "./revenueCard";
import AreaGraph from "./areaGraph";
import { DateRangePopup } from "./dateRangePopup";
import { ICompanyEditModalFunctions } from "../../../utils/companyEditModalFunctions";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
  },
  main_container: {
    padding: "20px 28px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "16px",
    letterSpacing: "0",
    lineHeight: "17px",
    marginBottom: "10px",
  },
  logo: {
    width: "25px",
    cursor: "pointer",
  },
  pop_up: {
    borderRadius: "2.73px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 10px 0 rgba(0,0,0,0.1)",
    right: 30,
    top: 60,
    "& div": {
      borderBottom: "0.5px solid #DDDDDD",
      padding: "10px 25px",
      color: "#717171",
      fontFamily: "Montserrat",
      fontSize: "10px",
      letterSpacing: "0",
      lineHeight: "14px",
      textAlign: "center",
      cursor: "pointer",
    },
  },
  selected_option: {
    fontWeight: 600,
    color: "#4D748D !important",
  },
}));

interface Props {
  data: any;
  property_name: string;
  title: string;
  editModalFunctions: ICompanyEditModalFunctions;
}

export const Chart: FC<Props> = ({ title, data, property_name, editModalFunctions }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<string>(
    Object.keys(data).filter((range: string) => data[range]?.value?.length)[0]
  );
  const selectedData = data[selected]?.value?.sort((a: any, b: any) => {
    const am = (a.year ?? 0) * 12 + ((a.quarter ?? 1) - 1) * 3;
    const bm = (b.year ?? 0) * 12 + ((b.quarter ?? 1) - 1) * 3;
    return am - bm;
  });

  let change = 0;
  if (selectedData && selectedData.length > 1) {
    change =
      ((selectedData[selectedData.length - 1]?.value - selectedData[selectedData.length - 2]?.value) * 100) /
      selectedData[selectedData.length - 1]?.value;
  }

  const openTable = () => {
    if (selected === "yearly") {
      property_name === "revenue" ? editModalFunctions.openYearlyRevenueEdit() : editModalFunctions.openSalesEdit();
    } else if (selected === "quarterly") {
      property_name === "revenue" ? editModalFunctions.openQuarterlyRevenueEdit() : editModalFunctions.openSalesEdit();
    }
    // else {
    //   property_name === "revenue" ? editModalFunctions.openMonthlyRevenueEdit() : editModalFunctions.openSalesEdit();
    // }
  };

  return (
    <div className={classes.container}>
      <RevenueCard title={title}>
        <div className={classes.main_container}>
          <DateRangePopup
            options={Object.keys(data).filter((range: string) => data[range]?.value?.length)}
            title={title}
            selected={selected}
            setSelected={setSelected}
          />
          <div>
            <ChangeIndicator change={change.toFixed(2)} />
          </div>
          <div onClick={openTable}>
            <AreaGraph data={data} selected={selected} />
          </div>
        </div>
      </RevenueCard>
    </div>
  );
};
