import { QueueItem } from "../interfaces";

export class AsyncQueue {
  queue: QueueItem[] = [];
  working = false;
  responses: any = {};
  counter = 0;
  insert(id: any, func: () => void, callback?: (err: any, response: any) => void) {
    this.queue.push({ id, func, callback });
    if (!this.working) {
      this.run();
    }
  }
  async run() {
    this.working = true;
    let item = this.queue[this.counter];
    while (item !== undefined) {
      if (!this.responses[item.id]) {
        try {
          const response = await item.func();
          this.responses[item.id] = response;
          item.callback && item.callback(undefined, response);
        } catch (err) {
          item.callback && item.callback("Error", undefined);
        }
      } else {
        const response = this.responses[item.id];
        item.callback && item.callback(undefined, response);
      }
      this.counter++;
      item = this.queue[this.counter];
    }
    this.working = false;
  }
}
