import { makeStyles } from "@material-ui/core";
import { FormEvent, FC, useState, useCallback, ChangeEvent } from "react";
import { apiConfig } from "../../services/api/apiConfig";
import { Service } from "../../services/api/service";
import { useAuth } from "../../shared/hooks/auth-hook";
import debounce from "lodash/debounce";

const useStyles = makeStyles(() => ({
  title: {
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
    marginBottom: "4px",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    "& .MuiCircularProgress-root": {
      width: "20px !important",
      height: "20px !important",
      color: "#4D748D",
      marginRight: "5px",
      marginTop: "5px",
    },
  },
  input: {
    border: "1px solid #E1E1E1",
    padding: 10,
    width: "100%",
    color: "#666666",
    fontWeight: 400,
    fontFamily: "Montserrat",
    "&::placeholder": {
      color: "#666666",
      fontWeight: 400,
      fontFamily: "Montserrat",
      fontSize: 12,
    },
    "&:focus": {
      outline: "none",
    },
  },
  button: {
    boxSizing: "border-box",
    height: "38px",
    width: "110px",
    border: "1px solid #4D748D",
    borderRadius: "3px",
    marginRight: "15px",
  },
  nameError: {
    color: "#c40014",
    fontFamily: "Montserrat",
    fontSize: "11px",
    fontWeight: "bold",
  },
}));

interface Props {
  setOpen: (open: boolean, message: string) => void;
}

export const CompanyCreate: FC<Props> = ({ setOpen }) => {
  const classes = useStyles();
  const [name, setName] = useState<string>("");
  const [domain, setDomain] = useState<string>("");
  const [domainMessage, setDomainMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { token } = useAuth();
  const buttonEnabled = name.length > 0 && domain.length > 0;

  const handleDomainChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDomain(value);
    setDomainMessage("");
    setErrorMessage("");

    if (value.length !== 0 && name.length > 0) {
      validateFormDebounced({ name, domain: value });
    }
  };
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setDomainMessage("");
    setErrorMessage("");

    if (event.target.value.length !== 0 && name.length > 0) {
      validateFormDebounced({ name: event.target.value, domain });
    }
  };

  const validateForm = async (values = { name: "", domain: "" }) => {
    await Service.post(`${apiConfig.v1}/company-creation-requests/validate`, token as string, values)
      .then((response) => {
        const data = response.data;
        const isValid = data?.valid ?? false;
        if (isValid) {
          setDomainMessage("");
        } else {
          const errors = data?.errors;
          let message = "";
          try {
            message = errors.map((it: { message: string; path: string[] }) => it.message).join("\n");
          } catch (e) {
            message = JSON.stringify(data);
            console.warn(data, `couldn't parse message error`);
          }
          setDomainMessage(message);
        }
      })
      .catch((error) => {
        const errorResponse = error.response;
        let message = errorResponse?.data?.message;
        try {
          message = JSON.parse(message)
            .map((it: { message: string; path: string[] }) => it.message)
            .join("\n");
        } catch (e) {
          message = JSON.stringify(message);
          console.warn(message, `couldn't parse message error`);
        }
        setDomainMessage(message);
      });
  };

  const validateFormDebounced = useCallback(debounce(validateForm, 500), []);

  const submitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage("");
    Service.post(`${apiConfig.v1}/company-creation-requests`, token as string, {
      name: name,
      domain: domain,
    })
      .then((response) => response.data)
      .then((data) => {
        setOpen(false, "Your request has been submitted!");
      })
      .catch((error) => {
        const errorResponse = error.response;
        let message = errorResponse?.data?.message;
        try {
          message = JSON.parse(message)
            .map((it: { message: string; path: string[] }) => it.message)
            .join("\n");
        } catch (e) {
          message = JSON.stringify(message);
          console.warn(message, `couldn't parse message error`);
        }
        setErrorMessage(message);
      });
  };

  const handleCancel = () => {
    setOpen(false, "");
  };

  return (
    <form onSubmit={submitForm}>
      <div className={classes.formGroup} style={{ marginTop: "30px", marginBottom: "10px" }}>
        <span className={classes.title}>Company</span>
        <input
          type="text"
          className={classes.input}
          name="name"
          id="name"
          value={name}
          onChange={(event) => handleNameChange(event)}
          autoComplete="off"
        />
      </div>
      <div className={classes.formGroup} style={{ marginBottom: "10px" }}>
        <span className={classes.title}>Search Domain</span>
        <input
          type="text"
          id="domain"
          name="domain"
          className={classes.input}
          value={domain}
          onChange={(event) => handleDomainChange(event)}
          autoComplete="off"
        />
      </div>
      <div className={classes.formGroup} style={{ marginTop: "5px", marginBottom: "15px" }}>
        <span className={classes.nameError}>{domainMessage}</span>
      </div>
      <div style={{ display: "flex" }}>
        <button
          className={classes.button}
          type="button"
          style={{ backgroundColor: "#FFFFFF", color: "#4D748D", cursor: "pointer" }}
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className={classes.button}
          type="submit"
          style={{
            backgroundColor: "#4D748D",
            color: "#FFFFFF",
            cursor: buttonEnabled ? "pointer" : "default",
            opacity: buttonEnabled ? "100%" : "40%",
          }}
          disabled={!buttonEnabled || domainMessage.length > 0}
        >
          Apply
        </button>
      </div>
      <div className={classes.formGroup} style={{ marginTop: "15px" }}>
        <span className={classes.nameError}>{errorMessage}</span>
      </div>
    </form>
  );
};
