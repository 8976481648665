import { FormLabel, makeStyles } from "@material-ui/core";
import { FC, useState, useEffect } from "react";
import { IListData } from "./ListFieldEditModal";
import { IEditFieldModalProp } from "./SingleFieldEditModal";
import { TextField } from "@mui/material";
import { CheckboxSelect } from "../checkbox";
import DeleteIcon from "../../assets/close-circle.png";
import EditIcon from "../../assets/edit.png";
import { ModalComponent } from "../modalView";
const useStyles = makeStyles((theme) => ({
  dataSourceRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10px",
  },
  dataSourceRowIndex: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontWeight: 500,
    minWidth: "25px",
  },
  dataSourceRowActionDelete: {
    height: "20px",
    weight: "20px",
    cursor: "pointer",
  },
  dataSourceRowData: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    flexDirection: "row",
    display: "flex",
    "& div": {
      width: "150px",
    },
  },
  dataExecutiveOtherRows: {
    marginLeft: "15px",
    "& div": {
      width: "350px",
    },
  },
  dataSourceRowAction: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    paddingLeft: "20px",
    width: "20%",
    display: "grid",
    marginRight: "10px",
    placeItems: "center",
    "& label": {
      margin: "0px !important",
    },
  },
  dataBoardMemberTitleRow: {
    marginLeft: "15px",
    "& div": {
      width: "150px",
    },
  },
  dataSourceRowName: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "right",
    justifyContent: "flex-start",
    flex: 2,
    color: "#333",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 500,
    letterSpacing: "0",
  },
  formGroup: {
    display: "flex",
    marginTop: "30px",
    marginBottom: "10px",
    flexDirection: "column",
    "& .MuiCircularProgress-root": {
      width: "20px !important",
      height: "20px !important",
      color: "#4D748D",
      marginRight: "5px",
      marginTop: "5px",
    },
  },
  formTitle: {
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
    marginBottom: "4px",
  },
  formButton: {
    boxSizing: "border-box",
    height: "38px",
    width: "110px",
    border: "1px solid #4D748D",
    borderRadius: "3px",
    marginRight: "15px",
  },
}));
interface Props {
  index: number;
  data: IListData;
  editDataForField: IEditFieldModalProp<IListData> | null;
  setEditDataForField: (d: IEditFieldModalProp<IListData> | null) => void;
  is_manual_entry: boolean;
  handleDelete: (data: IListData) => void;
  noShowBoardMember?: boolean;
  noShowTitle?: boolean;
  noShowInclude?: boolean;
  single?: boolean;
  data_source?: string;
}
export const PersonEdit: FC<Props> = ({
  data,
  index,
  setEditDataForField,
  editDataForField,
  is_manual_entry,
  handleDelete,
  noShowBoardMember,
  noShowTitle,
  noShowInclude,
  single,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [imageTemp, setImageTemp] = useState<undefined | string>(undefined);
  const [linkedinTemp, setLinkedinTemp] = useState<undefined | string>(undefined);
  const onChange = (field: string, value: string | boolean) => {
    if (is_manual_entry || field === "include" || field === "board_member") {
      const newEditDataForField = editDataForField as IEditFieldModalProp<IListData>;
      setEditDataForField({
        ...newEditDataForField,
        data: newEditDataForField?.data.map((d) => (d === data ? { ...d, [field]: value } : d)),
      });
    }
  };
  const onChangeMulti = (fieldsAndValues: { field: keyof IListData; value: string | boolean }[]) => {
    const newEditDataForField = editDataForField as IEditFieldModalProp<IListData>;
    let findData = newEditDataForField.data.find((item) => item === data) as IListData;
    if (findData) {
      for (const field of fieldsAndValues) {
        if (is_manual_entry || field.field === "include" || field.field === "board_member") {
          findData = { ...findData, [field.field]: field.value };
        }
      }
      setEditDataForField({
        ...newEditDataForField,
        data: newEditDataForField.data.map((d) => (d === data ? findData : d)),
      });
    }
  };
  useEffect(() => {
    if (open) {
      setImageTemp(undefined);
      setLinkedinTemp(undefined);
    }
  }, [open]);
  return (
    <div className={classes.dataSourceRow} key={index}>
      <ModalComponent open={open} setOpen={setOpen} title="Person Edit" zIndex={501}>
        <div className={classes.formGroup}>
          <FormLabel htmlFor="image" className={classes.formTitle}>
            Image
          </FormLabel>
          <TextField
            size="small"
            variant="outlined"
            value={imageTemp !== undefined ? imageTemp : data.image || ""}
            disabled={!is_manual_entry}
            onChange={(e) => setImageTemp(e.target.value)}
            multiline
            minRows={2}
            maxRows={2}
            id="image"
            style={{ marginBottom: "10px" }}
          />
          <FormLabel htmlFor="linkedin" className={classes.formTitle}>
            Linkedin
          </FormLabel>
          <TextField
            size="small"
            variant="outlined"
            value={linkedinTemp !== undefined ? linkedinTemp : data.linkedin_url || ""}
            disabled={!is_manual_entry}
            onChange={(e) => setLinkedinTemp(e.target.value)}
            multiline
            minRows={2}
            maxRows={2}
            id="linkedin"
            style={{ marginBottom: "10px" }}
          />
          <div style={{ display: "flex", marginTop: "15px" }}>
            <button
              className={classes.formButton}
              type="button"
              style={{ backgroundColor: "#FFFFFF", color: "#4D748D", cursor: "pointer" }}
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              className={classes.formButton}
              type="submit"
              onClick={(e) => {
                const newChanges: { field: keyof IListData; value: string | boolean }[] = [];
                if (imageTemp) newChanges.push({ field: "image", value: imageTemp });
                if (linkedinTemp) newChanges.push({ field: "linkedin_url", value: linkedinTemp });
                onChangeMulti(newChanges);
                setOpen(false);
              }}
              style={{
                backgroundColor: "#4D748D",
                color: "#FFFFFF",
                cursor: "pointer",
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </ModalComponent>
      {!single && <div className={classes.dataSourceRowIndex}>{index + 1}</div>}
      <div className={classes.dataSourceRowData}>
        <TextField
          size="small"
          variant="outlined"
          value={data.first_name || ""}
          disabled={!is_manual_entry}
          onChange={(e) => onChange("first_name", e.target.value)}
        />
      </div>
      <div className={`${classes.dataSourceRowData} ${classes.dataBoardMemberTitleRow}`}>
        <TextField
          size="small"
          variant="outlined"
          value={data.last_name || ""}
          disabled={!is_manual_entry}
          onChange={(e) => onChange("last_name", e.target.value)}
        />
      </div>
      <div className={`${classes.dataSourceRowData} ${classes.dataExecutiveOtherRows}`}>
        <TextField
          size="small"
          variant="outlined"
          value={data.summary || ""}
          disabled={!is_manual_entry}
          onChange={(e) => onChange("summary", e.target.value)}
          multiline
          minRows={2}
          maxRows={2}
        />
      </div>
      {!noShowTitle && (
        <div className={`${classes.dataSourceRowData} ${classes.dataBoardMemberTitleRow}`}>
          <TextField
            size="small"
            variant="outlined"
            value={data.title || ""}
            disabled={!is_manual_entry}
            onChange={(e) => onChange("title", e.target.value)}
          />
        </div>
      )}
      {!noShowBoardMember && (
        <div className={classes.dataSourceRowAction} style={{ width: "50px" }}>
          <CheckboxSelect
            checkboxLabel=""
            checked={data.board_member ? true : false}
            setChecked={(checked: boolean) => {
              onChange("board_member", checked);
            }}
          />
        </div>
      )}
      {!noShowInclude && (
        <div className={classes.dataSourceRowAction} style={{ width: "50px" }}>
          <CheckboxSelect
            checkboxLabel=""
            checked={data.include ? true : false}
            setChecked={(checked: boolean) => {
              onChange("include", checked);
            }}
          />
        </div>
      )}
      {is_manual_entry && (
        <div className={classes.dataSourceRowAction} style={{ width: "50px" }}>
          <img
            src={DeleteIcon}
            className={classes.dataSourceRowActionDelete}
            alt="delete icon"
            onClick={() => handleDelete(data)}
          />
        </div>
      )}
      {is_manual_entry && (
        <div className={classes.dataSourceRowAction} style={{ width: "50px" }}>
          <img
            src={EditIcon}
            className={classes.dataSourceRowActionDelete}
            alt="edit icon"
            onClick={() => setOpen(true)}
          />
        </div>
      )}
    </div>
  );
};
export default PersonEdit;
