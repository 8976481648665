import { IEditFieldModalProp, ISingleData } from "../../company-edit/SingleFieldEditModal";
import { FieldEditData } from "../types";

export const getHighestPriorityDataIndustry = (editDataForFields: FieldEditData) => {
  if (editDataForFields.industry) {
    const sortedDataSources = editDataForFields.industry?.dataSources.sort(
      (prev, current) => current.priority - prev.priority
    );

    if (sortedDataSources) {
      for (const ds of sortedDataSources) {
        const found_data = (editDataForFields.industry as IEditFieldModalProp<ISingleData>)?.data?.find(
          (item: ISingleData) => item.sourceId === ds?.id
        );
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!found_data) {
          return { data: found_data };
        }
      }
    }
    return { data: null };
  }

  return null;
};

export const getHighestPriorityDataSecondaryIndustries = (editDataForFields: FieldEditData) => {
  if (editDataForFields.secondary_industries) {
    const sortedDataSources = editDataForFields.secondary_industries?.dataSources.sort(
      (prev, current) => current.priority - prev.priority
    );

    let data: ISingleData[] = [];
    if (sortedDataSources)
      for (const ds of sortedDataSources) {
        const found_data = (editDataForFields.secondary_industries as IEditFieldModalProp<ISingleData>)?.data
          ?.filter((item: ISingleData) => item.data_source_id === ds?.id && item.include)
          .map((item) => ({ ...item, sourceId: item.data_source_id, name: item.title }));
        data = [...data, ...found_data];
      }
    return data;
  }
};