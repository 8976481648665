import { Modal } from "@mui/material";

import { EiqGroupMembershipsEditData } from "./types";
import { EditEiqGroupsForm } from "./edit-eiq-groups-form";

declare function structuredClone<T>(value: T): T;

interface Props {
  editData: EiqGroupMembershipsEditData;
  onSubmit: (editData: EiqGroupMembershipsEditData) => void;
  onCancel: () => void;
  modalOpen: boolean;
}

export const EditEiqGroupsModal = ({editData, onSubmit, onCancel, modalOpen}: Props) => {

  return (
    <Modal open={modalOpen} onClose={onCancel}>
        <EditEiqGroupsForm
          initialEditData={structuredClone(editData)}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
    </Modal>
  );
};
