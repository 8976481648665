import { FC } from "react";
import { makeStyles } from "@material-ui/core";
import greenArrow from "../../../assets/green-arrow.svg";
import redArrow from "../../../assets/red-arrow.svg";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  arrow_container: {
    color: "#51C03E",
    backgroundColor: "#CAF1C3",
    borderRadius: "50%",
    height: "15px",
    width: "15px",
    display: "grid",
    placeItems: "center",
    marginRight: "5px",
    fontSize: "13px",
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: "10px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "11px",
  },
}));

interface Props {
  change: string;
}

export const ChangeIndicator: FC<Props> = ({ change }) => {
  const classes = useStyles();
  const type = Number(change) > 0 ? "Growth" : Number(change) === 0 ? "Same" : "Decrease";
  const arrow = type === "Growth" ? greenArrow : redArrow;
  const sign = type === "Growth" ? "+" : "";
  const color = { color: type === "Growth" ? "#51C03E" : type === "Same" ? "#333" : "#a62323" };
  const bgColor = { backgroundColor: type === "Growth" ? "#CAF1C3" : type === "Same" ? "#333" : "#f0aeae" };

  return (
    <div className={classes.container}>
      {type !== "Same" && (
        <div className={classes.arrow_container} style={{ ...color, ...bgColor }}>
          <img src={arrow} alt="indicator logo" />
        </div>
      )}
      <div className={classes.text} style={color}>
        {sign + change + "% "} {type !== "Same" ? "+ " + type : ""}
      </div>
    </div>
  );
};
