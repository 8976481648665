import { useState } from "react";
import { FeatureCard } from "../../featureCard";
import peopleLogo from "../../../../assets/people.svg";
import {EditEiqGroupsModal} from "../../../company-edit/edit-eiq-groups/edit-eiq-groups-modal";
import { EiqGroupMembershipsEditData, Group } from "../../../company-edit/edit-eiq-groups/types";
import { DataSourceType } from "../../../company-edit/edit-revenue-modal/edit-data";

type Props = {
  isEdit: boolean;
  groups: Group[];
  getDataSource: (id: number) => { name: string; endpoint: string; id: number };
  editSubmit: (editData: EiqGroupMembershipsEditData) => void;
}
export const MembershipCards = ({isEdit, groups, getDataSource, editSubmit}: Props) => {

  const [editModalOpen, setEditModalOpen] = useState(false);

  const editData = {
    data: groups,
    overrideWithManual: false,
    dataSources: [
      {
        id: 2,
        name: "Manual Entry",
        priority: 1,
        type: "Manual" as DataSourceType,
      },
    ]
  }

  const rtnMember = groups?.find((x) => x.groupName === "RTN")?.isMember || false;
  const p2pMember = groups?.find((x) => x.groupName === "P2P")?.isMember || false;

  return (
    <>

        <FeatureCard
          title="RTN Member"
          data={{sourceId: 2, value: +rtnMember}}
          logo={peopleLogo}
          isEdit={isEdit}
          isDataBoolean
          clicked={() => {if (isEdit) setEditModalOpen(true)}}
          getDataSource={getDataSource}
          sourceIds={[2]}
        />

        <FeatureCard
          title="P2P Member"
          data={{sourceId: 2, value: +p2pMember}}
          logo={peopleLogo}
          isEdit={isEdit}
          isDataBoolean
          clicked={() => {if (isEdit) setEditModalOpen(true)}}
          getDataSource={getDataSource}
          sourceIds={[2]}
        />

      {isEdit &&
        <EditEiqGroupsModal
          editData={editData}
          onSubmit={(editData) => {
            editSubmit(editData);
            setEditModalOpen(false);
          }}
          modalOpen={editModalOpen}
          onCancel={() => setEditModalOpen(false)} />
      }
      </>
  )
};