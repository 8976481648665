import { FC } from "react";
import { makeStyles } from "@material-ui/core";
import externalLogo from "../../../assets/external.svg";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "10px",
    "& div:last-child": {
      marginBottom: "0px !important",
    },
  },
  card_container: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    marginBottom: "20px",
  },
  anchor: {
    color: "#2F556D",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
    letterSpacing: "0",
    lineHeight: "26px",
    marginRight: "15px",
  },
  empty: {
    width: "100%",
    color: "#333",
    fontSize: "18px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    marginLeft: "5px",
  },
}));

interface Props {
  data: any;
}

export const AdditionalLinks: FC<Props> = ({ data }) => {
  const classes = useStyles();

  const renderCards = () => {
    return data?.additional_links?.value?.map((el: any, index: number) => {
      return (
        <div className={classes.card_container} key={index}>
          <a href={el.external_url} className={classes.anchor}>
            {el.title}
          </a>
          <img src={externalLogo} alt="external logo" />
        </div>
      );
    });
  };

  return (
    <div className={classes.container}>
      {data?.additional_links?.value?.length ? renderCards() : <div className={classes.empty}>No Data.</div>}
    </div>
  );
};
