import { makeStyles } from "@material-ui/core";
import { FC } from "react";
import { Banner } from "../banner";

const useStyles = makeStyles(() => ({
  main: {
    marginLeft: "70px",
    marginTop: "50px",
    display: "flex",
  },
  imageDiv: {
    height: "210px",
    width: "210px",
    backgroundColor: " #FFFFFF",
    boxShadow: "0 2px 13px 0 rgba(0,0,0,0.06)",
    borderRadius: "50%",
    marginRight: "45px",
    overflow: "hidden",
  },
  profileImage: {
    width: "100%",
    height: "100%",
  },
  titleDiv: {
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
  },
  title: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "39px",
    fontWeight: 500,
  },
  name: {
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 500,
    marginTop: "14px",
  },
}));

interface Props {
  data: any;
  fullName?: string;
  role?: string;
  profilePictureUrl?: string;
}

export const ManageUsersBanner: FC<Props> = ({ data, fullName, role, profilePictureUrl }) => {
  const classes = useStyles();

  return (
    <Banner buttonTitle="Back" location={-1}>
      <div className={classes.main}>
        <div className={classes.imageDiv}>
          <img src={profilePictureUrl} className={classes.profileImage} />
        </div>
        <div className={classes.titleDiv}>
          <span className={classes.title}>Manage Users</span>
          <span className={classes.name}>
            {fullName} - {role}
          </span>
        </div>
      </div>
    </Banner>
  );
};
