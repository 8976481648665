import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Alert } from "@mui/material";
import { Delete } from "@mui/icons-material";

import { styled } from "@mui/material/styles";
import { useMutation, useQueryClient } from "react-query";
import { apiConfig } from "../../services/api/apiConfig";

const deleteCompany = async (companyId: number) => {
  const userData = localStorage.getItem("userData");
  const token = userData ? JSON.parse(userData).token : "";

  const response = await fetch(`${apiConfig.v1}/companies/${companyId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    let message;

    try {
      const body = await response.json();
      if (typeof body.message === "string") {
        message = body.message;
      }
    } catch {
      console.warn("couldn't process error body");
    }

    message = message || "Failed to delete company";

    throw new Error(message);
  }

  return;
};

const CancelButton = styled(Button)(() => ({
  fontFamily: "Oswald",
  color: "rgb(77, 116, 141)",
  borderColor: "rgb(77, 116, 141)",
  backgroundColor: "#FFF",
  textTransform: "none",
}));

const DeleteButton = styled(Button)(() => ({
  fontFamily: "Oswald",
  color: "#FFF",
  backgroundColor: "rgb(204, 32, 40)",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgba(204, 32, 40, 0.4)",
  },
}));

const NavigateButton = styled(Button)(() => ({
  fontFamily: "Montserrat",
  color: "#FFF",
  backgroundColor: "rgb(77, 116, 141)",
  textTransform: "none",
}));

type Props = {
  companyId: number;
};

export const CompanyDeleteButton = ({ companyId }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, isLoading, isError, error, isSuccess, reset } = useMutation<void, { message: string }>({
    mutationFn: () => deleteCompany(companyId),
  });

  const onDeleteClick = () => setOpen(true);

  const onDeleteDiscard = () => {
    reset();
    setOpen(false);
  };

  const onDeleteConfirm = () => mutate();

  return (
    <>
      <button
        style={{
          boxSizing: "border-box",
          padding: "12px 16px",
          height: 38,
          fontFamily: "Montserrat",
          fontSize: 12,
          border: "1px solid rgb(204, 32, 40)",
          borderRadius: 3,
          cursor: "pointer",
          backgroundColor: "rgb(204, 32, 40)",
          color: "#fff",
        }}
        onClick={onDeleteClick}
      >
        Delete
      </button>

      <Dialog open={open}>
        {!isSuccess ? (
          <>
            <DialogTitle>Delete Company Profile?</DialogTitle>
            <DialogContent>
              <Alert severity="warning">
                This action will permanently delete this company profile and all associated data.
              </Alert>
              {isError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error?.message || "An error occurred while deleting the company"}
                </Alert>
              )}
            </DialogContent>
            <DialogActions>
              <CancelButton autoFocus onClick={onDeleteDiscard} disabled={isLoading}>
                Cancel
              </CancelButton>
              <DeleteButton onClick={onDeleteConfirm} disabled={isLoading} startIcon={<Delete />}>
                {isLoading ? "Deleting..." : "Delete"}
              </DeleteButton>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>Company Deleted Successfully</DialogTitle>
            <DialogContent>
              <DialogContentText>The company has been permanently deleted.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <NavigateButton onClick={() => navigate("/")}>Go to Search Page</NavigateButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};
