import { IListData } from "./ListFieldEditModal";

type ParseResultOK<T> = {
  value: T;
  status: "OK";
};
type ParseResultINVALID = {
  status: "INVALID";
  reason: string;
};
type ParseResult<T> = ParseResultOK<T> | ParseResultINVALID;

const computeInvalidCodeReason = (codeType: string, value: string, formats: string) =>
  `Invalid ${codeType} Code ${value}.\nPlease enter a valid code in one of the below formats:\n${formats}`;

const NACE_FORMATS = `"A", "01", "01.1", "01.11"`;
const computeInvalidNaceCodeReason = (value: string) => computeInvalidCodeReason("NACE", value, NACE_FORMATS);
function parseNaceCodeInput(input: unknown): ParseResult<string> {
  const value = typeof input === "string" ? input.trim().toUpperCase() : "";

  if (value.length === 0) {
    return {
      status: "INVALID",
      reason: "There cannot be empty values. Please enter.",
    };
  }

  if (value.length === 1) {
    const charCode = value.charCodeAt(0);
    const isLetter = 65 <= charCode && charCode <= 90;

    return isLetter
      ? {
          status: "OK",
          value: value,
        }
      : {
          status: "INVALID",
          reason: computeInvalidNaceCodeReason(value),
        };
  }

  if (value.length === 2) {
    const isNumber = !Number.isNaN(Number(value));

    return isNumber
      ? {
          status: "OK",
          value: value,
        }
      : {
          status: "INVALID",
          reason: computeInvalidNaceCodeReason(value),
        };
  }

  if (3 <= value.length && value.length <= 5) {
    const validCodeSeparator = value.indexOf(".") === 2;
    const codeNumber = Number.parseFloat(value);
    const validCodeNumber = 1 < codeNumber && codeNumber < 100;

    return validCodeSeparator && validCodeNumber
      ? {
          status: "OK",
          value: value,
        }
      : {
          status: "INVALID",
          reason: computeInvalidNaceCodeReason(value),
        };
  }

  return {
    status: "INVALID",
    reason: computeInvalidNaceCodeReason(value),
  };
}
function validateNaceClassification(data: IListData[] | undefined): ParseResult<null> {
  if (!data) {
    return {
      status: "INVALID",
      reason: "There cannot be empty values. Please enter.",
    };
  }

  for (let i = 0; i < data.length; i++) {
    const item = data[i];

    if (item.data_source_id === 2) {
      const res = parseNaceCodeInput(item.value);

      if (res.status === "INVALID") {
        return res;
      }

      item.value = res.value;
      // item.code = res.value;
      // item.sourceId = item.data_source_id;
    }
  }

  return {
    status: "OK",
    value: null,
  };
}

const ISIC_FORMATS = `"A", "01", "012", "0121"`;
const computeInvalidIsicCodeReason = (value: string) => computeInvalidCodeReason("ISIC", value, ISIC_FORMATS);
function parseIsicCodeInput(input: unknown): ParseResult<string> {
  const value = typeof input === "string" ? input.trim().toUpperCase() : "";

  if (value.length === 0) {
    return {
      status: "INVALID",
      reason: "There cannot be empty values. Please enter.",
    };
  }

  if (value.length === 1) {
    const charCode = value.charCodeAt(0);
    const isLetter = 65 <= charCode && charCode <= 90;

    return isLetter
      ? {
          status: "OK",
          value: value,
        }
      : {
          status: "INVALID",
          reason: computeInvalidIsicCodeReason(value),
        };
  }

  if (2 <= value.length && value.length <= 4) {
    const codeNumber = Number.parseInt(value, 10);
    const isPositiveNumber = !Number.isNaN(codeNumber) && codeNumber > 0;

    return isPositiveNumber
      ? {
          status: "OK",
          value: value,
        }
      : {
          status: "INVALID",
          reason: computeInvalidIsicCodeReason(value),
        };
  }

  return {
    status: "INVALID",
    reason: computeInvalidIsicCodeReason(value),
  };
}
function validateIsicClassification(data: IListData[] | undefined): ParseResult<null> {
  if (!data) {
    return {
      status: "INVALID",
      reason: "There cannot be empty values. Please enter.",
    };
  }

  for (let i = 0; i < data.length; i++) {
    const item = data[i];

    if (item.data_source_id === 2) {
      const res = parseIsicCodeInput(item.value);

      if (res.status === "INVALID") {
        return res;
      }

      item.value = res.value;
      // item.code = res.value;
      // item.sourceId = item.data_source_id;
    }
  }

  return {
    status: "OK",
    value: null,
  };
}

const NAICS_FORMATS = `"11", "111", "1121", "11211", "112112"`;
const computeInvalidNaicsCodeReason = (value: string) => computeInvalidCodeReason("NAICS", value, NAICS_FORMATS);
function parseNaicsCodeInput(input: unknown): ParseResult<string> {
  const value = typeof input === "string" ? input.trim().toUpperCase() : "";

  if (value.length === 0) {
    return {
      status: "INVALID",
      reason: "There cannot be empty values. Please enter.",
    };
  }

  if (2 <= value.length && value.length <= 6) {
    const codeNumber = Number.parseInt(value, 10);
    const isPositiveNumber = !Number.isNaN(codeNumber) && codeNumber > 0;

    return isPositiveNumber
      ? {
          status: "OK",
          value: value,
        }
      : {
          status: "INVALID",
          reason: computeInvalidNaicsCodeReason(value),
        };
  }

  return {
    status: "INVALID",
    reason: computeInvalidNaicsCodeReason(value),
  };
}
function validateNaicsClassification(data: IListData[] | undefined): ParseResult<null> {
  if (!data) {
    return {
      status: "INVALID",
      reason: "There cannot be empty values. Please enter.",
    };
  }

  for (let i = 0; i < data.length; i++) {
    const item = data[i];

    if (item.data_source_id === 2) {
      const res = parseNaicsCodeInput(item.value);

      if (res.status === "INVALID") {
        return res;
      }

      item.value = res.value;
      // item.code = res.value;
      // item.sourceId = item.data_source_id;
    }
  }

  return {
    status: "OK",
    value: null,
  };
}

export const validationRules: Record<string, undefined | ((data: IListData[] | undefined) => ParseResult<null>)> = {
  naceClassification: validateNaceClassification,
  naicsClassification: validateNaicsClassification,
  isicClassification: validateIsicClassification,
} as const;
